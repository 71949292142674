<template>
    <!-- Panel Selector Buttons -->
    <div class="flex mt-2 p-2">
        <button v-for="button in buttons"  
            class="options-picker" 
            :class="{'options-picker-active' : this.isActiveButton(button.name) == true}" 
            :key="button.name" 
            @click="setCurrentlyVisiblePanel(button.name)">
            {{button.text}}
        </button>    
    </div>
</template>

<script>
export default {
    name: "TabButtons",
    components: {},

    props: {
        buttons: {
            required: true,
        },

        active_button: {
            required: true,
            type: String,
        },

        panelChangedMethod: {
            required: true,
            type: Function,
        }
    },

    data() {
        return {

        }
    },

    computed: {},
    watch: {},
    created() {},
    mounted() {},

    methods: {
        setCurrentlyVisiblePanel(panel_name){
            this.panelChangedMethod(panel_name)
            this.$emit('panel-changed', panel_name) 
        },

        isActiveButton(panel_name){
            return this.active_button === panel_name
        },
    }
}
</script>

<style scoped>

</style>