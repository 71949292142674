<template>
    <div class="flex flex-col items-center gap-4">

        <div class="bg-gray-200 rounded-md w-full flex flex-col items-center justify-center gap-3 p-4">
            <div class="text-lg">
                <span class="font-bold uppercase"> {{run_name}} </span> Placement Creatives
            </div>
            <button class="btn-primary red" @click="deleteRunCreativesFromPlacement"> Remove Ad Set Creatives From Placement</button>
            <ImagePicker
                src_name="storage_path" 
                image_type="creatives"       
                picker_type="taker"
                getter_string="getTakerCreatives"
                fetcher_string="fetchTakerImages"
                :images_api="`run-creative-placement/${this.placement_uuid}/${this.run_uuid}`">
            </ImagePicker>
        </div>

        <div class="bg-gray-200 rounded-md w-full flex flex-col items-center justify-center gap-3 p-4">
            <div class="text-lg">
                <span class="font-bold uppercase"> {{run_name}} </span> Creatives 
            </div>
            <button class="btn-primary green" @click="addRunCreativesToPlacement"> Add Ad Set Creatives To Placement</button>
            <ImagePicker
                src_name="storage_path" 
                image_type="creatives"       
                picker_type="adder"
                getter_string="getFilteredRunCreatives"
                fetcher_string="fetchRunImages"
                :images_api="`run-creatives/${this.run_uuid}`">          
            </ImagePicker>
        </div>
    </div>
</template>
<script>
import ImagePicker from '@/components/ImagePickers/ImagePicker.vue'
export default {
    components: {
        ImagePicker
    },
    props: {
        placement_uuid: {
            required: true
        },
        run_uuid: {
            required: true
        },
        run_name: {
            required: false,
            type: String,
        }
    },
    methods: {
        addRunCreativesToPlacement() {
            let creative_uuids = this.selectedAdderImageData()
            let request = {
                placement_uuid: this.placement_uuid,
                creative_uuids: creative_uuids,
                run_uuid: this.run_uuid
            }
            const toasts = {'success': 'Creatives Added to Placement', 'error': 'Error Adding Creatives to Placement'}
            this.$http_request(this.$http.post, '/run-creative-placement', toasts, request)
                .then( () => {
                    this.refreshImages('adder')
                }).catch ( (error) => {
                    console.error(error)
                })
        },
        deleteRunCreativesFromPlacement() {
            let creative_uuids = this.selectedTakerImageData()
            let request = {
                placement_uuid: this.placement_uuid,
                creative_uuids: creative_uuids,
                run_uuid: this.run_uuid
            }
            const toasts = {'success': 'Creative(s) Deleted From The Placement', 'error': 'Error Deleting Creative(s) From The Placement'}
            this.$http_request(this.$http.post, '/run-creative-placement/delete', toasts, request)
                .then( () => {
                    this.refreshImages('taker')
                }).catch ( (error) => {
                    console.error(error)
                })
        },
        selectedAdderImageData() {
            return this.$store.getters[`picker_module/getAdderSelectedCreatives`]
        },
        selectedTakerImageData() {
            return this.$store.getters[`picker_module/getTakerSelectedCreatives`]
        },
        clearSelectedCreatives() {
            this.$store.commit('restore_point_module/setRestorationState',this.$store.getters['picker_module/getCurrentState'])
            this.$store.commit('picker_module/clearAllSelected')
        },
        refreshImages(picker_type){
            const clear_selected_request = {
                'picker_type': picker_type,
                'item_type': 'creatives'
            }

            this.$store.commit('picker_module/clearSelectedItems', clear_selected_request)
            this.$store.dispatch(`fetchTakerImages`, {api_url: `run-creative-placement/${this.placement_uuid}/${this.run_uuid}`, image_type: 'creatives'}) 
            this.$store.dispatch(`fetchAdderImages`, {api_url: `run-creatives/${this.run_uuid}`, image_type: 'creatives'}) 
        },
    }
}
</script>