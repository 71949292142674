import axios from 'axios'
import store from '@/store'
import router from '@/router'

export default class ErrorInterceptor {
    error = undefined
    http_status = undefined
    message = undefined
    exception = undefined

    constructor(error) {
        this.error = error
        this.http_status = error.response?.status ?? undefined;
        this.message = error.response?.data?.message ?? undefined;
        this.statusText = error.response?.statusText ?? undefined;
        // Check if the exception property exists and is a string before splitting
        const exceptionString = error.response?.data?.exception;
        this.exception = exceptionString ? exceptionString.split('\\').pop() : undefined;
    }

    async handleError(){

        if (this.http_status === 401) {
            // Check for specific 'Unauthorized' message
            if (this.statusText === 'Unauthorized') {
                store.dispatch('resetState');
                store.dispatch(['login_module/logout']);
                router.push('/login');
            }
            return Promise.reject(this.error);
        }

        // teapot error code
        if(this.http_status == 418){
            store.$notify({type: "error", text: "Can't Add Blame On Error"})
            return Promise.reject(this.error)
        }

        if(this.http_status == 419){ 
            store.commit('incrementCsrfTokenCount')
        
            if(store.getters['getCsrfTokenRetryCount'] > 5){
                router.push('/login')
                store.dispatch('resetState')
                store.dispatch(['login_module/logout'])
                return Promise.reject(this.error)
            }
            else {
                store.commit('resetCsrfTokenCount')
                await axios.get('/refresh-csrf-token') 
                //this is used to replay the http_request
                return axios(this.error.response.config)
            }
        }

        if(this.http_status == 429){ 
            store.$notify({type: "error", text: "Too Many Requests! Please Wait!"})
            return Promise.reject(this.error)
        }

        if(this.http_status == 500){
            if(this.message == "Payload Too Large"){
                store.$notify({type: "error", text: 'Uploaded Files Must Be Less Than 10mb'})
                return Promise.reject(this.error)
            } else {
                store.$notify({type: "error", text: 'Internal Servel Error'})
                return Promise.reject(this.error)
            }
        }

        if(this.exception == 'PostTooLargeException'){
            store.$notify({type: "error", text: 'Batches Of Images Must Be Less Than 75mb'})
            return Promise.reject(this.error)
        }

        return Promise.reject(this.error)
    }
}