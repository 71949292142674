import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'

// Libraries
import axios from 'axios'
import VueAxios from 'vue-axios'
import uuid from 'vue-uuid'
import Notifications from '@kyvg/vue3-notification'
import mitt from 'mitt';
const emitter = mitt();
import VueGtag from "vue-gtag";
import GtagDirective from './directives/GoogleAnalytics.js'

// Plugins
import UtilsPlugin from '@/plugins/UtilsPlugin.js'
import AdvertProgressPlugin from './plugins/AdvertProgressPlugin'
import { notify } from "@kyvg/vue3-notification";
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import addExportData from 'highcharts/modules/export-data';
exportingInit(Highcharts)
addExportData(Highcharts);

// Global Components
import Loading from '@/components/UtilityComponents/Loading.vue'
import DropdownComponent from '@/components/UtilityComponents/DropdownComponent.vue'
import UploadFile from '@/components/UtilityComponents/UploadFile.vue'

// JS files
import Interceptors from '@/classes/Interceptors'

axios.defaults.baseURL = '/api/'

axios.interceptors.response.use( 
  Interceptors.responseInterceptor,
  Interceptors.errorInterceptor
);

store.$http = axios
store.$notify = notify

const app = createApp(App).use(VueAxios, axios).use(Notifications).use(UtilsPlugin).use(AdvertProgressPlugin).use(store).use(router).use(uuid).use(HighchartsVue).use(VueGtag, {
  config: { id: "G-ZXMVQTFKNL" },
  params: {
    anonymize_ip: true
  }
})

app.directive('gtag', GtagDirective)

app.config.globalProperties.emitter = emitter;
app.component('LoadingComponent', Loading).component('DropdownComponent', DropdownComponent).component('UploadFile', UploadFile)

app.mount('#app')
