import storeFunctions from '@/plugins/StorePlugin.js'
export default class AdvertForm {
    uuid = null
    name = null
    start_date = null
    end_date = null
    start_time = null 
    end_time = null
    target_impressions = 0
    is_reward_video = false
    include_countries = false
    creative_uuids = {}
    game_uuids = {}
    country_uuids = {}
    language_names = {}
    
    constructor(){
        this.start_date = storeFunctions.dateTodayFormatted()
    }

    prePopulate(advert){
        if(!advert) return
        for(const [key, value] of Object.entries(advert)) this[key] = value
    }
}