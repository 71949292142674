<template>
  <div class="bg-white p-4 rounded-md flex flex-col gap-4">
    
    <div class="flex sm:flex-row sm:gap-4 flex-col gap-2 py-4 items-center border-b-2 border-gray-300">
      <label class="font-medium"> Existing Conversations: </label>
      <DropdownComponent 
        :key="convoDropdownRefresher" 
        :items="_existingConversations" 
        display='conversation_name'
        selected="Select a Conversation"
        @user_select="updateToSelectedConversation"
        class="lg:max-w-lg sm:max-w-xs w-full" />
    </div>

    <div class="flex sm:flex-row sm:gap-4 flex-col gap-2 items-center pt-2 pb-4 border-b-2 border-gray-300">
      <label class="font-medium">Conversation Name*: </label>
      <input placeholder="Enter Conversation Name" v-model="conversationGraph.conversationName" class="input-primary"/>
    </div>

    <div class="pt-2 pb-4 border-b-2 border-gray-300">
      <div class="flex sm:flex-row sm:gap-8 sm:flex-wrap flex-col gap-2 items-center">
        <label class="font-medium"> Dialog text: </label>
        <input v-model="newNodeText" class="input-primary"/>
        <div class="flex sm:flex-row sm:items-center flex-col gap-2">
          <button class="btn-primary green" @click="createNode(nodeOptions)">Create Node</button>
            <div class="flex items-center">
            <div class="mr-4">
              <input @change="updateCheckboxes(true)" type="checkbox" id="isStart" v-model="nodeOptions.isStart" class="form-checkbox">
              <label for="isStart" class="ml-2">Is Start</label>
            </div>
            <div class="mr-4">
              <input @change="updateCheckboxes(false)" type="checkbox" id="isEnd" v-model="nodeOptions.isEnd" class="form-checkbox">
              <label for="isEnd" class="ml-2">Is End</label>
            </div>
            <div>
              <input type="checkbox" id="multipleChoice" v-model="nodeOptions.multipleChoice" class="form-checkbox">
              <label for="multipleChoice" class="ml-2">Multiple Choice</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pb-4 border-b-2 border-gray-300" v-for="node in conversationGraph.nodes" :key="node.id" @mouseenter="hoveredNodeId = node.id" @mouseleave="hoveredNodeId = null">
      <div class="p-2 flex flex-row gap-4 items-center">
        <div class="flex items-center justify-center h-16 w-16 rounded-full bg-gray-400 gap-1">
          <span class="text-xl font-bold text-white">{{node.conversationId}}</span>
          <span class="text-sm font-bold" v-if="node.isStart"> Start </span>
          <span class="text-sm font-bold" v-if="node.isEnd"> End </span>
          <span class="text-sm font-bold" v-if="node.multipleChoice"> Multi Choice </span>
        </div>
        <div class="flex flex-col gap-2">
          <h2 class="text-lg font-medium p2">{{ node.text }}</h2>
          <div class="" v-for="edge in node.getEdges()" :key="edge.destinationId" >
            <div class="flex flex-row gap-2 items-center"> 
              <span v-if="!edge.singleChoice">Player Says: <b>'{{ edge.text }}'</b> | Goes To: <b>{{conversationGraph.getNodeConversationId(edge.destinationId)}}</b></span>
              <span v-else>(Single Choice) Player Says: <b>'{{ edge.text }}'</b> | Goes To: <b>{{conversationGraph.getNodeConversationId(edge.destinationId)}}</b></span>

              <button class="btn-primary red" @click="this.removeEdge(edge.getId())">X</button> 
            </div>
          </div>
        </div>
        <div class="flex flex-row gap-2 items-center" v-show="hoveredNodeId == node.id">
          <button class="btn-primary light-blue" @click="setEdgeSource(node)">Set As Source</button>
          <button class="btn-primary light-blue" @click="setEdgeDestination(node)">Set As Destination</button>
          <button class="btn-primary red" @click="removeNodeAndConnections(node)">X</button>
        </div>
      </div>
    </div>

    <div class="pb-4 border-b-2 border-gray-300">
      <h3 class="text-lg font-medium mb-2">Add An Edge To A Node:</h3>
      <div class="flex sm:flex-row sm:gap-6 sm:items-center flex-col gap-2">
        <span>Source node ID: <b>{{ edgeSource.conversationId }}</b> </span>
        <span>Destination node: <b>{{ edgeDestination.conversationId }}</b>  </span>
        <div class="flex flex-row gap-2 items-center">
          <label class="">Edge text: </label> 
          <input v-model="edgeText" class="input-primary"/> 
        </div>
        <button class="btn-primary indigo" @click="addEdgeToNode">Add Edge</button>
        <button class="btn-primary indigo" @click="addEdgeToNode({singleChoice: true})">Add Single Choice Edge</button>
      </div> 
    </div>

    <div>
      <h3 class="text-lg font-medium">Select A Campaign to Choose An Ad Set From (optional)</h3>
      <DropdownComponent @user_select="updateSelectedCampaign" :items="allCampaigns" display='name' />
    </div>
    <div>
      <h3 class="text-lg font-medium">Add Ad Set To Dialog</h3>
      <DropdownComponent class="mb-2 mt-2" @user_select="addRunToConversation" :items="displayedRuns" display='name' />
      <div class="mt-4 ml-2 pd-4">
        <ul class="list-disc list-inside">
          <li class="text-md flex items-center" v-for="runUuid in conversationRuns" :key="runUuid">
            <span class="white">{{ runNameFromRunUuid(runUuid) }}</span>
            <button class="btn-primary red" @click="removeRunFromConversation(runUuid)"> Remove </button>
          </li>
        </ul>
      </div>
    </div>

    <div class="flex sm:flex-row sm:gap-4 gap-2 flex-col">
      <button class="btn-primary green" @click="submit">Submit</button>
      <button class="btn-primary red" @click="clearAll">Clear All</button>
      <button class="btn-primary light-blue" @click="showJSON = !showJSON">Show JSON</button>
      <button class="btn-primary red" @click="deleteConversation">Delete Conversation</button>
    </div>

    <ul class="mt-2">
      <li class="missing-field" v-if="this.isUpdatingExistingConversation"> Warning! Currently Updating Existing Dialog (clear all or submit changes to start a new dialog)</li>
      <li class="missing-field" v-if="!this.createdStartNode"> Must Include a Start node </li>
      <li class="missing-field" v-if="!this.createdEndNode"> Must Include an End node </li>
      <li class="missing-field" v-if="!this.hasAtLeastOneRun"> Must Include at Least One Ad Set </li>
    </ul>

    <textarea disabled class="mt-4 w-full h-full resize:both overflow-auto" style="min-height: 15em;" v-if="showJSON" :value="prettyGraphJson"></textarea>
  </div>
</template>

<script>
import ConversationNode from "./ConversationNode.js";
import ConversationEdge from "./ConversationEdge.js";
import ConversationGraph from "./ConversationGraph.js";
import {v4 as uuidv4} from 'uuid';

const defaultNodeOptions = {
  isStart: false,
  isEnd: false,
  multipleChoice: false,
}

export default {
  data() {
    return {
      conversationGraph: {},
      hoveredNodeId: null,
      currentNode: null,
      nodeOptions: defaultNodeOptions,
      isUpdatingExistingConversation: false,
      showConversationsDropdown: false,
      newNodeText: "",
      createdStartNode: false,
      createdEndNode: false,
      edgeDestination: {},
      edgeSource: {},
      existingConversations: [],
      edgeText: "",
      characterIds: [],
      convoDropdownRefresher: uuidv4(),
      showJSON: false,
      displayedRuns: [],
      allRuns: [],
      allCampaigns: [],
      selectedCampaignUuid: null,
      selectedRunUuid: null,
    };
  },

  computed: {
    graphJSON() {
      return this.conversationGraph.getAsJSON()
    },

    prettyGraphJson(){
      return JSON.stringify(JSON.parse(this.graphJSON), null, 2)
    },

    conversationName() {
      return this.conversationGraph.conversationName
    },

    _existingConversations(){
      return this.existingConversations
    },

    conversationRuns(){
      return this.conversationGraph.runs
    },

    hasAtLeastOneRun(){
      return this.conversationRuns.length > 0
    }
  },

  watch: {
    existingConversations(){
      this.convoDropdownRefresher = uuidv4()
    },
    selectedCampaignUuid(){
      this.getCampiagnRuns()
    }
  },

  created(){
    this.characterIds = [uuidv4()]
    this.conversationGraph = new ConversationGraph({id: uuidv4()}, {characterIds: this.characterIds, conversationName: this.conversationName})
  },

  mounted(){
    this.getExistingConversations()
    this.getExistingCampaigns()
    this.getExistingRuns()
  },

  methods: {
    updateToSelectedConversation(conversation){
      this.isUpdatingExistingConversation = true
      
      const conversationName = conversation.conversation_name
      const conversationId = conversation.uuid
      const dialogJson = conversation.dialog_json
      const dialogObject = JSON.parse(dialogJson)
      const characterIds = dialogObject.characterIds
      const nodes = dialogObject.nodes

      this.characterIds = characterIds
      this.conversationName = conversationName
      this.conversationGraph = new ConversationGraph({id: conversationId}, {characterIds: characterIds, conversationName: conversationName})

      nodes.forEach(node => {
        const newNode = new ConversationNode(
          {id: node.id},
          {text: node.text, conversationId: node.conversationId, characterId: node.characterId, isStart: node?.isStart, isEnd: node?.isEnd}
        );

        node.edges.forEach((edge) => {
          const conversationEdge = new ConversationEdge(
            {id: edge.id, sourceId: edge.sourceId, destinationId: edge.destinationId},
            {text: edge.text}
          )
          newNode.addEdge(conversationEdge)
        })
        this.conversationGraph.addNode(newNode);
      })
    },

    deleteConversation(){
      if(!this.isUpdatingExistingConversation){
        this.$notify({type: "error", text:'You must be editing an existing conversation to delete it (otherwise use Clear All)'})
        return
      }
      if(!confirm('Are You Sure You Want To Delete This Dialog?')) return

      const toasts = {success: 'Deleted dialog', error: 'Failed to delete dialog'}
      const request = {conversationId: this.conversationGraph.getId()}
      this.$http_request(this.$http.post, `/delete-dialog`, toasts, request)
        .then(() => {
          this.clearAll(false)
          this.getExistingConversations()
        })
        .catch((error) => {
          console.error(error.message)
        })
    },
    
    clearAll(check){
      if(check) if(!confirm('Are You Sure You Want To Clear All? (This will not delete an existing conversation. Just reset the maker)')) return
      this.isUpdatingExistingConversation = false
      this.characterIds = [uuidv4()]
      this.conversationGraph = new ConversationGraph({id: uuidv4()}, {characterIds: this.characterIds, conversationName: ''})
    },

    goToNode(node) {
      this.currentNode = node;
    },
    
    createNode({isStart, isEnd, multipleChoice}) {
      if(isStart === null || isStart === undefined) isStart = false
      if(isEnd === null || isEnd === undefined) isEnd = false
      if(multipleChoice === null || multipleChoice === undefined) multipleChoice = false

      const newNode = new ConversationNode(
        {id: uuidv4()},
        {
          text: this.newNodeText, 
          conversationId: this.conversationGraph.nodes.length + 1, 
          characterId: this.conversationGraph.characterIds[0],
          isStart,
          isEnd,
          multipleChoice,
        }
      );

      this.conversationGraph.addNode(newNode);

      if(isStart === true) this.createdStartNode = true;
      if(isEnd === true) this.createdEndNode = true;

      this.goToNode(newNode);
      this.nodeOptions = defaultNodeOptions
    },

    updateCheckboxes(isStart) {
      if(isStart) {
        this.nodeOptions.isEnd = false;
      }
      else {
        this.nodeOptions.isStart = false;
      }
    },

    removeNodeAndConnections(node){
      this.conversationGraph.removeNodeAndConnections(node.id)
      if(node.isStart) this.createdStartNode = false;
      if(node.isEnd) this.createdEndNode = false;
    },

    addEdgeToNode({singleChoice}) {
      const sourceNode = this.conversationGraph.getNode(this.edgeSource.id);
      const destinationNode = this.conversationGraph.getNode(this.edgeDestination.id);

      const conversationEdge = new ConversationEdge(
        {id: uuidv4(), sourceId: sourceNode.id, destinationId: destinationNode.id},
        {text: this.edgeText, singleChoice}
      )
      if (sourceNode && destinationNode) {
        sourceNode.addEdge(conversationEdge);
      }
    },
    
    setEdgeSource(source) {
      this.edgeSource = source
    },

    setEdgeDestination(destination) {
      this.edgeDestination = destination
    },

    removeEdge(id){
      this.conversationGraph.removeEdge(id)
    },

    getExistingConversations(){
      const toasts = {success: 'Fetched dialogs', error: 'Failed to fetch dialogs'}
      this.$http_request(this.$http.get, `/get-dialogs`, toasts)
        .then(({data}) => {
          this.existingConversations = data
        })
        .catch((error) => {
          console.error(error.message)
        })
    },

    getExistingRuns(){
      const toasts = {success: 'Fetched Ad Sets', error: 'Failed to fetch Ad Sets'}
      this.$http_request(this.$http.get, `/runs/get/all`, toasts)
        .then(({data}) => {
          this.allRuns = data
          this.displayedRuns = data
        })
        .catch((error) => {
          console.error(error.message)
        })
    },

    getExistingCampaigns(){
      const toasts = {success: 'Fetched Campaigns', error: 'Failed to fetch Campaigns'}
      this.$http_request(this.$http.get, `/campaigns/get/all`, toasts)
        .then(({data}) => {
          this.allCampaigns = data
        })
        .catch((error) => {
          console.error(error.message)
        })
    },

    getCampiagnRuns(){
      const toasts = {success: 'Fetched Campaign Ad Sets', error: 'Failed to fetch Campaign Ad Sets'}
      this.$http_request(this.$http.get, `/runs/get-campaign-runs/${this.selectedCampaignUuid}`, toasts)
        .then(({data}) => {
          this.displayedRuns = data
        })
        .catch((error) => {
          console.error(error.message)
        })
    },

    updateSelectedCampaign(campaign){
      this.selectedCampaignUuid = campaign.uuid
    },

    addRunToConversation(run){
      this.$notify({type: "success", text: `Added Ad Set ${run.name} to conversation`})
      this.conversationGraph.runs.push(run.uuid) 
    },

    removeRunFromConversation(runUuid){
      this.$notify({type: "success", text: `Removed Ad Set ${this.runNameFromRunUuid(runUuid)} from conversation`})
      this.conversationGraph.removeRun(runUuid) 
    },

    runNameFromRunUuid(runUuid){
      const run = this.allRuns.find(run => run.uuid === runUuid)
      return run ? run.name : 'Unknown Ad Set'
    },

    submit(){
      if(!this.createdStartNode) {
        this.$notify({type: "error", text: "Must have a start node"})
        return
      }
      if(!this.createdEndNode){
        this.$notify({type: "error", text: "Must have an end node"})
        return
      } 
      if(!this.hasAtLeastOneRun){
        this.$notify({type: "error", text: "Must have at least one Run"})
        return
      } 
      let toasts = {success: 'Dialog added successfully', error: 'Failed to add dialog'}
      const request = {
          conversationName: this.conversationName,
          conversationId: this.conversationGraph.id,
          dialogJson: this.graphJSON,
      }

      let baseUrl = ''
      if(this.isUpdatingExistingConversation) {
        baseUrl = '/update-dialog'
        toasts = {success: 'Dialog updated successfully', error: 'Failed to update dialog'}
      }
      else {
        baseUrl = '/add-dialog'
        toasts = {success: 'Dialog added successfully', error: 'Failed to add dialog'}
      }

      this.$http_request(this.$http.post, baseUrl, toasts, request)
          .then(() => {
            this.clearAll(false)
            this.getExistingConversations()
          })
          .catch((error) => {
            console.error(error.message)
          })
    },
  }
};
</script>

<style scoped lang="postcss">
.missing-field {
  @apply text-white p-2 font-medium bg-red-500 hover:bg-red-700 cursor-default;
}

.input-primary {
  @apply block w-52 text-sm shadow-sm appearance-none border border-gray-400 rounded lg:max-w-lg sm:max-w-xs py-2 px-3 text-gray-700 leading-tight focus:outline-none;
}
</style>
