<template>
    <div class="container mx-auto p-4">
        <!-- Integration List -->
        <table class="table-auto w-full mb-4">
            <thead>
                <tr class="bg-gray-200">
                    <th class="px-4 py-2">Campaign Name</th>
                    <th class="px-4 py-2">Start Date</th>
                    <th class="px-4 py-2">End Date</th>
                    <th class="px-4 py-2">Target Engagements</th>
                    <th class="px-4 py-2">Delivered Engagements</th>
                    <th class="px-4 py-2">Integration Name</th>
                    <th class="px-4 py-2">Games</th>
                    <th class="px-4 py-2">Actions</th>
                    <th class="px-4 py-2">Linked Native Display Campaign</th>
                    <th class="px-4 py-2">Updated At</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="integration in integrations" :key="integration.id" :class="{ 'bg-blue-100': integration.id === activeRowId }" class="border-b">
                    <td class="px-4 py-2">{{ integration.integration_friendly_name }}</td>
                    <td class="px-4 py-2">{{ formatToMMddYYYY(integration.start_date) }}</td>
                    <td class="px-4 py-2">{{ formatToMMddYYYY(integration.end_date) }}</td>
                    <td class="px-4 py-2">{{ formatNumber(integration.target_engagements) }}</td>
                    <td class="px-4 py-2">{{ formatNumber(integration.delivered_engagements) }}</td>
                    <td class="px-4 py-2">{{ integration.event_id_05 }}</td>
                    <td class="px-4 py-2">
                        <ul>
                            <li v-for="game in integration.games" :key="game.ga_id">{{ game.game_name }}</li>
                        </ul>
                    </td>
                    <td class="px-4 py-2 flex flex-col items-start">
                        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mb-2" @click="editIntegration(integration)">Edit</button>
                        <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded" @click="deleteIntegration(integration.id)">Delete</button>
                    </td>
                    <td>        
                        {{ integrationCampaignName(integration) }}
                    </td>
                    <td class="px-4 py-2">{{ formatToMMddYYYY(integration.updated_at) }}</td>
                </tr>
            </tbody>
        </table>

        <!-- Add/Edit Integration Form -->
    <div v-if="showForm" class="mb-4">
        <form @submit.prevent="submitForm" class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="friendly-name">
                    Campaign Name
                </label>
                <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="friendly-name" type="text" v-model="form.integration_friendly_name" placeholder="Campaign Name">
            </div>

            <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="start-date">
                    Start Date
                </label>
                <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="start-date" type="date" v-model="form.start_date">
            </div>

            <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="end-date">
                    End Date
                </label>
                <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="end-date" type="date" v-model="form.end_date">
            </div>

            <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="target-engagements">
                    Target Engagements
                </label>
                <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="target-engagements" type="number" v-model="form.target_engagements" placeholder="0">
            </div>

            <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="event-id-05">
                    Integration Name
                </label>
                <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="event-id-05" type="text" v-model="form.event_id_05" placeholder="IntegrationName_2033">
            </div>

            <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="event-id-05">
                    Linked Native Display Campaign
                </label>
                <DropdownComponent 
                v-if="allCampaigns.length"
                :items='allCampaigns'
                display="name"
                :selected="this.currentCampaign ? this.currentCampaign.name : 'Select a Campaign'"
                @user_select="setCurrentCampaign($event)"
                class="sm:w-96 w-72 self-center"/>
            </div>  

            <div class="flex items-center justify-between">
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                    Save
                </button>
            </div>
        </form>
    </div>

    <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" @click="addIntegration">Add Integration</button>

    <!-- Design Event Categories List -->
    <table class="table-auto w-full mb-4 mt-4">
        <thead>
            <tr class="bg-gray-200">
                <th class="px-4 py-2">Category Name</th>
                <th class="px-4 py-2">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="category in designEventCategories" :key="category.uuid" :class="{ 'bg-blue-100': category.uuid === activeRowId }" class="border-b">
                <td class="px-4 py-2">{{ category.category_name }}</td>
                <td class="px-4 py-2 flex flex-col items-start">
                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mb-2" @click="editCategory(category)">Edit</button>
                    <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded" @click="deleteCategory(category.uuid)">Delete</button>
                </td>
            </tr>
        </tbody>
    </table>

    <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" @click="addCategory">Add Category</button>


    <!-- Add/Edit Category Form -->
    <div v-if="showCategoryForm" class="mb-4">
        <form @submit.prevent="submitCategoryForm" class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="category-name">
                    Category Name
                </label>
                <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="category-name" type="text" v-model="categoryForm.category_name" placeholder="Enter Category Name">
            </div>

            <!-- Only show this field when editing an existing category, assuming you have a way to distinguish between add and edit modes -->
            <div v-if="editingCategory" class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="category-uuid">
                    Category UUID
                </label>
                <input readonly class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-200" id="category-uuid" type="text" v-model="categoryForm.uuid" placeholder="UUID">
            </div>

            <div class="flex items-center justify-between">
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                    Save
                </button>
            </div>
        </form>
    </div>
    </div>
</template>



<script>
import moment from 'moment';

export default {
    name: "ManageIntegrations",
    components: {},
    props: {},
    data() {
        return {
            integrations: [],
            designEventCategories: [],
            currentIntegrationId: null,
            showForm: false,
            isEditMode: false,
            showCategoryForm: false,
            isCategoryEditMode: false,
            activeRowId: null,
            allCampaigns: [],
        }
    },
    computed: {
        selectedCampaignUuid() {
            return this.currentCampaign ? this.currentCampaign.uuid : null
        },
        currentCampaign() {
            return this.allCampaigns.find(c => c.uuid === this.form.campaign_uuid)
        }
    },
    watch: {},
    created() {
        this.getAllCampaigns()
        this.fetchIntegrations();
        this.fetchDesignEventCategories();
    },
    mounted() {},
    methods: {
        async fetchIntegrations() {
            const url = '/integrations';
            const toasts = {
                success: 'Integrations fetched successfully',
                error: 'Failed to fetch integrations'
            };

            await this.$http_request(
                this.$http.get,
                url,
                toasts
            ).then(response => {
                this.integrations = response.data.data;
                console.log('integrations', this.integrations)
            }).catch(error => {
                console.error('Fetch Integrations Error:', error);
            });
        },

        async fetchDesignEventCategories() {
            const url = '/design-event-categories';
            const toasts = {
                success: 'Event Categories fetched successfully',
                error: 'Failed to fetch event categories'
            };

            await this.$http_request(
                this.$http.get,
                url,
                toasts
            ).then(response => {
                this.designEventCategories = response.data.data;
            }).catch(error => {
                console.error('Fetch DesignEventCategories Error:', error);
            });
        },

        addIntegration() {
            if(this.showForm && !this.isEditMode) {
                this.showForm = false;
                this.resetForm();
                this.activeRowId = null;
                return;
            }
            this.showForm = false;
            this.activeRowId = null;
            this.resetForm();
            this.showForm = true;
            this.isEditMode = false;
        },

        editIntegration(integration) {
            // If the user clicks the edit button on the same row, close the form
            if(this.showForm && this.activeRowId === integration.id) {
                this.showForm = false;
                this.isEditMode = false;
                this.resetForm();
                this.activeRowId = null;
                return;
            }

            this.activeRowId = integration.id;

            this.form = { ...integration };
            this.showForm = true;
            this.isEditMode = true;
            this.currentIntegrationId = integration.id;
        },

        addCategory() {
            if (this.showCategoryForm && !this.isCategoryEditMode) {
                this.showCategoryForm = false;
                this.resetCategoryForm();
                this.activeCategoryId = null;
                return;
            }
            this.showCategoryForm = false;
            this.activeCategoryId = null;
            this.resetCategoryForm();
            this.showCategoryForm = true;
            this.isCategoryEditMode = false;
        },

        editCategory(category) {
            // If the user clicks the edit button on the same row, close the form
            if (this.showCategoryForm && this.activeCategoryId === category.uuid) {
                this.showCategoryForm = false;
                this.isCategoryEditMode = false;
                this.resetCategoryForm();
                this.activeCategoryId = null;
                return;
            }

            this.activeCategoryId = category.uuid;

            // Assuming category data structure matches the form fields
            this.categoryForm = { ...category };
            this.showCategoryForm = true;
            this.isCategoryEditMode = true;
            this.currentCategoryId = category.uuid; // This assumes categories are uniquely identified by their UUID
        },

        resetCategoryForm() {
            this.categoryForm = {
                category_name: '',
            };
        },

        async submitForm() {
            const url = this.isEditMode ? `/integrations/${this.currentIntegrationId}` : '/integrations';
            const method = this.isEditMode ? this.$http.put : this.$http.post;
            const toasts = {
                success: `Integration ${this.isEditMode ? 'updated' : 'added'} successfully`,
                error: `Failed to ${this.isEditMode ? 'update' : 'add'} integration`
            };

            console.log('submitForm', this.form)

            await this.$http_request(
                method,
                url,
                toasts,
                this.form 
            ).then(() => {
                this.showForm = false;
                this.resetForm();
                this.fetchIntegrations(); // Refresh the list
                this.activeRowId = null;
            }).catch(error => {
                console.error('Submit Form Error:', error);

                if (error.response && error.response.data && error.response.data.errors) {
                    let messages = Object.values(error.response.data.errors).map(e => e.join(' ')).join('\n');
                    this.$notify({type: "error", text: messages});
                } else {
                    this.$notify({type: "error", text: error.message});
                }
            });
        },

        async submitCategoryForm() {
            const url = this.isCategoryEditMode ? `/design-event-categories/${this.currentCategoryId}` : '/design-event-categories';
            const method = this.isCategoryEditMode ? this.$http.put : this.$http.post;
            const toasts = {
                success: `Category ${this.isCategoryEditMode ? 'updated' : 'added'} successfully`,
                error: `Failed to ${this.isCategoryEditMode ? 'update' : 'add'} category`
            };

            console.log('submitCategoryForm', this.categoryForm)

            await this.$http_request(
                method,
                url,
                toasts,
                this.categoryForm 
            ).then(() => {
                this.showCategoryForm = false;
                this.resetCategoryForm();
                this.fetchDesignEventCategories(); // Refresh the list
                this.activeCategoryId = null;
            }).catch(error => {
                console.error('Submit Category Form Error:', error);

                if (error.response && error.response.data && error.response.data.errors) {
                    let messages = Object.values(error.response.data.errors).map(e => e.join(' ')).join('\n');
                    this.$notify({type: "error", text: messages});
                } else {
                    this.$notify({type: "error", text: error.message});
                }
            });
        },

        async deleteIntegration(uuid) {
            this.activeRowId = uuid
            if (confirm("Are you sure you want to delete this integration?")) {
                const url = `/integrations/${uuid}`;
                const toasts = {
                    success: 'Integration successfully deleted.',
                    error: 'Failed to delete integration.'
                };

                try {
                    await this.$http_request(
                        this.$http.delete,
                        url,
                        toasts
                    );

                    this.activeRowId = null;

                    // Refresh the list after deletion
                    this.fetchIntegrations();
                } catch (error) {
                    console.error('Delete Integration Error:', error);
                }
            }
        },

        async deleteCategory(uuid) {
            if (!confirm('Are you sure you want to delete this category?')) {
                return;
            }

            const url = `/design-event-categories/${uuid}`;
            const toasts = {
                success: 'Category deleted successfully',
                error: 'Failed to delete category'
            };

            await this.$http_request(
                this.$http.delete, // Using the delete method
                url,
                toasts,
            ).then(() => {
                this.fetchDesignEventCategories(); // Refresh the list after deletion
            }).catch(error => {
                console.error('Delete Category Error:', error);
                if (error.response && error.response.data && error.response.data.message) {
                    this.$notify({type: "error", text: error.response.data.message});
                } else {
                    this.$notify({type: "error", text: error.message || 'An error occurred while deleting the category.'});
                }
            });
        },

        resetForm() {
            this.form = {
                integration_friendly_name: '',
                start_date: '',
                end_date: '',
                target_engagements: '',
                event_id_05: '',
                campaign_uuid: '',
            };
        },

        formatNumber(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        formatToMMddYYYY(dateStr) {
            if (!dateStr) return '';

            // Identify if time part is included
            const hasTimePart = dateStr.includes(' ');

            // Parse the date string in UTC mode
            const formatString = hasTimePart ? 'YYYY-MM-DD HH-mm' : 'YYYY-MM-DD';
            const date = moment.utc(dateStr, formatString);

            // Format the date string
            const formattedDate = date.format('MM-DD-YYYY');

            // Append time part if present
            const formattedDateTime = hasTimePart ? date.format('MM-DD-YYYY HH:mm') : formattedDate;

            return formattedDateTime;
        },

        async getAllCampaigns() {
            await this.$http.get('/campaigns/get/all').then( ({data}) => {
                this.allCampaigns = data
                this.allCampaigns.unshift({name: 'No Linked ND Campaign', uuid: null})
            })
        },

        setCurrentCampaign(campaign) {
            this.form.campaign_uuid = campaign.uuid
            console.log('form after setting campaign uuid', this.form)
        },

        integrationCampaignName(integration){
            const defaultMessage = 'No Linked ND Campaign'
            if(!integration.campaign_uuid) return defaultMessage
            const campaign_uuid = integration.campaign_uuid
            return this.allCampaigns.find(c => c.uuid === campaign_uuid)?.name || defaultMessage
        }
    }
}
</script>

<style scoped>

</style>