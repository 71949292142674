<template>
    <div class="bg-white overflow-hidden shadow rounded-lg p-7">
        <form class="space-y-8 divide-y divide-gray-200" v-on:submit.prevent="uploadData">
            <div class="sm:col-span-6">

                <h3 class="text-lg leading-6 font-medium text-gray-900 mb-3">Upload a Video Preview *</h3>
                <UploadFile accept="image/gif, image/png, image/jpeg, image/jpg"/>

                <div class="sm:col-span-6 mt-5">
                    <h3 class="text-lg leading-6 font-medium text-gray-900 mt-4">Enter The Video Name </h3>
                    <div class="flex text-sm text-gray-600 mt-3">
                        <input type="text" name="gif-name" id="gif-name" maxlength="50"
                            required autocomplete="off" v-model="user_defined_name" class="block w-full shadow-sm focus:ring-indigo-500
                            focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" /> 
                    </div>
                </div>

                <div class="sm:col-span-6 mt-5">
                    <h3 class="text-lg leading-6 font-medium text-gray-900 mt-4">Enter The Asset ID</h3>
                    <div class="flex text-sm text-gray-600 mt-3">
                        <input type="text" name="gif-name" id="gif-name" maxlength="50" placeholder="rbxassetid://12345"
                            required autocomplete="off" v-model="asset_id" class="block w-full shadow-sm focus:ring-indigo-500
                            focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" />     
                    </div>
                </div>

                <button v-if="!loading" v-on:submit.prevent="uploadData" class="btn-primary indigo mr-auto mt-4">Submit</button>
                <LoadingComponent v-else />     
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: "CreateVideo",
    components: {},
    props: {},
    data() {
        return {
            loading: false,
            asset_id: '',
            user_defined_name: '',
        }
    },
    computed: {
        creatives(){
            const creatives = this.$store.getters['getAllCreatives']
            if (!creatives) return []
            return creatives
        },
        existingFilenames() {
            const exisiting_filenames = []
            this.creatives.forEach((element) => {
                exisiting_filenames.push(element.user_defined_name+element.img_type)
            })
            return exisiting_filenames
        },
        stagedFile(){
            const staged_files = this.$store.getters['file_upload_module/getAllFiles']
            return staged_files[0]
        },
    },
    watch: {},
    created() {
        this.$store.commit('file_upload_module/clearAllFiles')
    },
    mounted() {},
    methods: {
        uploadData() {            
            if(!confirm('Are you sure you want to upload this video?'))
                return

            this.loading = true 
            let form_data = new FormData()

            form_data.append('image', this.stagedFile.file_object)
            form_data.append('user_defined_name', this.user_defined_name)
            form_data.append('asset_id', this.asset_id)

            const toasts = {'error': 'Error Uploading Video Data', 'success': 'Video Data Uploaded'}
            this.$http_request(this.$http.post, '/videos', toasts, form_data, { headers: { 'Content-Type': 'multipart/form-data'} })
                .then(() => {
                    this.loading = false
                    this.asset_id = ''
                    this.user_defined_name = ''
                    this.$store.commit('file_upload_module/clearAllFiles')
                }).catch( (error)=> {
                    this.$notify({type: 'error', text: error.response.data.message})
                    this.loading = false
                })
        },
    }
}
</script>

<style scoped>

</style>