<template>
    <div>
        <div v-show="!show_info" :class="active_color" @mouseover="show_info=true"  class="block w-9 h-9 rounded-full m-2"> </div>
        <div v-show="show_info" class="min-w-min min-h-min p-2 rounded-md cursor-pointer m-2 text-center text-sm font-semibold" :class="active_color" @click="showMessage" @mouseleave="show_info=false"> 
            {{active_color=='red' ? 'Not Running' : 'Running' }} 
        </div> 
    </div>
</template>

<style lang="scss" scoped>
.green {
    color: black;
    background-image: linear-gradient(45deg, #11f800, #4cff16, #6aff3c);
}

.red {
    color: white;
    background-image: linear-gradient(45deg, #ff7b71, #ff5e46, #ff5e2d);
}

</style>

<script>
export default {
    props: {
        active_color: {
            type: String,
            required: true,
            validator: function(color) {
                return['red', 'green'].includes(color)
            }
        },
        inactive_reason: {
            type: String,
            default: '',
        }
    },
    data(){
        return {
            show_info: false
        }
    },
    methods: {
        showMessage() {
            if(this.inactive_reason)
                alert(this.inactive_reason)
            else
                alert('Running normally')
        }
    }
}
</script>