<template>
    <div class="flex flex-col gap-2">

        <div class="flex w-full justify-center bg-white rounded-md p-4">
            <button 
                @click="setShowArchive(false)"
                class="options-picker border-l rounded-l-md" 
                :class="{'options-picker-active' : !this.show_archived_items}" 
                style="border-left-width: 1px"> 
                Archive An Item 
            </button>
            <button 
            @click="setShowArchive(true)"
                class="options-picker rounded-r-md" 
                :class="{'options-picker-active' : this.show_archived_items}"> 
                View Archived Items
            </button>
        </div>

        <div class="flex flex-row items-center justify-center gap-4 p-2 bg-white rounded-md">
            <DropdownComponent
            selected="Select An Item Type"  
            :items="['campaign', 'creative', 'placement']" 
            @user_select="getItemsFromType"
            class="lg:max-w-lg sm:max-w-xs w-full" />

            <button class="btn-primary green sm:text-sm text-xs" @click="editArchive" v-if="show_archived_items == false"> Archive Selected Items </button>
            <button class="btn-primary red sm:text-sm text-xs" @click="editArchive" v-if="show_archived_items == true"> UnArchive Selected Items </button>
        </div>

        <div class="flex flex-row gap-4 justify-center text-2xl font-medium mt-6" v-if="item_type && !items.length">
            No result found!
        </div>

        <div class="flex flex-row flex-wrap sm:gap-4 gap-2 justify-center" v-else>
            <CardComponent
                v-for="item in items" :key="item"
                :src="item.img_url" 
                :title="item.user_defined_name ? item.user_defined_name : item.name"
                :hover_effects="true"
                :class="{'checked-card': item['checked']}"
                @click="item['checked'] = !item['checked']">
                <template v-slot:thirdRow>
                    <a :href="routeToItem(item)">
                        <CogIcon class="cogicon-dimensions"/>
                    </a>
                </template>
            </CardComponent> 
        </div>
        
    </div>
</template>

<script>
import CardComponent from '@/components/UtilityComponents/CardComponent.vue'
import { CogIcon } from '@heroicons/vue/outline'

export default {
    name: "ArchiveItem",
    components: {
        CardComponent,
        CogIcon
    },
    props: {},
    data() {
        return {
            items: [],
            item_type: '',
            show_archived_items: false,
        }
    },
    computed: {
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {
        async getItemsFromType(item_type) {
            this.item_type = item_type
            await this.$http_request(this.$http.get, `/archive/${this.show_archived_items}/${item_type}`)
                .then( ({data}) => {
                    this.items = data
                })
        },
        async editArchive() {
            // get all the items that have checked = true
            const new_items = this.items.filter(item => item['checked'] == true)
            
            // need an api that makes a check for these items to see if they're active
            if(await this.areItemsActive(new_items)) 
                return this.$notify({type: 'error', text: 'Active Items Can Not Be Archived!'})

            const toasts = {'success': 'Archived Items Successfully', 'error': 'Error Archiving Items'}  
            const request = {
                items: new_items,
                item_type: this.item_type,
                archive_items_bool: !this.show_archived_items,
            }
            await this.$http_request(this.$http.post, `/edit-archive`, toasts, request)
                    .then( ()=> {
                        this.getItemsFromType(this.item_type)
                    })
        },
        setShowArchive(bool) {
            this.show_archived_items = bool
            
            if(this.item_type)
                this.getItemsFromType(this.item_type)
        },
        async areItemsActive(items) {
            const request = {
                items: items,
                item_type: this.item_type,
                archive_items_bool: !this.show_archived_items,
            }
            return await this.$http.post(`/check-active-items`, request)
                .then( (response)=> {
                    return response.data
                })
        },
        routeToItem(item) {
            if (this.item_type == 'campaign')
                return `manage/campaigns/${item.uuid}`
            
            return `manage-item/${this.item_type}/${item.uuid}`
        }
    }
}
</script>
