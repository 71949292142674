<template>
    <div class="bg-white rounded-lg shadow p-4 flex flex-col gap-2 items-center justify-center">
        <div class="flex flex-col gap-2 justify-center items-center h-full" v-if="loading">
            <h2> 
                Fetching data... This might take a few minutes. 
            </h2>
            <LoadingComponent />
        </div>
        <div class="flex flex-row flex-wrap justify-around w-full p-2" v-if="!loading">
            <img :src="creative_data.thumbnail_url" class="w-1/2 object-contain" alt="Thumbnail" />
            <div class="flex flex-col justify-center gap-2">
                <div class="lg:text-xl flex lg:flex-row flex-col gap-1" v-for="(meta, i) in creative_data.meta_data" :key="i">
                    <span class="lg:text-lg text-sm font-bold">{{ meta.label }}:</span>
                    <span class="lg:text-lg text-sm ">{{ meta.value ? meta.value.toLocaleString() : 0 }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    name: "VideoReportingInfo",
    props: {
        creative_data: {
            type: Object,
            required: true
        },
    },
    computed: {
        loading() {
            if(!this.creative_data?.meta_data) return true
            return false
        }
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {}
}
</script>

<style>
</style>