<template>
  <div>
    <div class="w-full flex flex-col gap-2">
      <div class="sm:p-8 p-4 bg-blue-100 rounded-md sm:grid sm:grid-cols-1 max-sm:flex max-sm:flex-col gap-x-8 gap-y-4">
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <!-- Creatives Dropdown - Shows when there is a current campaign -->
          <div>
            <label class="font-medium block">Creative:</label>
            <DropdownComponent 
              v-show="current_campaign"
              :items='campaign_creatives'
              display="user_defined_name"
              :selected="this.current_creative ? this.current_creative.user_defined_name : 'Select a Creative'"
              @user_select="setCurrentCreative($event)"
              :key="creative_dropdown_refresher"
              class="w-full"/>
          </div>
          <!-- Country Dropdown - Shows when there is a current creative -->
          <div>
            <label class="font-medium block">Country:</label>
            <DropdownComponent
              v-show="current_creative"
              class="w-full"
              selected="Select a Country (optional)" 
              :items="all_countries" 
              display="name"
              @user_select="setCountryLimit"
              :key="dropdown_refresher"/>
          </div>
        </div>

        <!-- Action Buttons -->
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <button 
            v-if="current_creative" 
            class="btn-primary green w-full"
            @click="getVideoData"> Apply Filters 
          </button>
          <button 
            v-if="current_creative" 
            class="btn-primary red w-full"
            @click="selected_country = null; dropdown_refresher++"> Reset Country 
          </button>
        </div>
      </div>

      <div class="p-4 px-8 bg-white rounded-md text-xl font-medium text-gray-700 text-center flex flex-row items-center lg:justify-between justify-center gap-2 flex-wrap" v-if="current_campaign">
          <img src="@/assets/GamefamLogo.png" class="w-40 h-9 object-cover" alt="gamefam-logo"> 
          {{headerName}} 
          <span class="text-sm text-gray-500">{{headerDate}}</span>
      </div>

      <span v-if="loading"> Please wait this might take a few minutes... </span>
      <LoadingComponent class="mt-4 self-center" v-if="loading" />
      <!-- Reporting Info -->
      <div class="w-full flex flex-col gap-4">
        <VideoReportingInfo :creative_data="creative_data"/>

        <div class="bg-white rounded-lg shadow p-4 flex flex-col gap-2 items-center justify-center" v-if="show_unique_chart & !loading">
          <TabButtons v-if="video_length >= 500" class="w-full" :buttons="panelSwitchingButtons" :active_button="user_unique_graph_choice" :panel-changed-method="panelChanged" />
          <highcharts class="w-full" :options="unique_impressions_per_choice" />
        </div>

        <div class="bg-white rounded-lg shadow p-4 flex flex-col gap-2 items-center justify-center" v-if="show_total_chart & !loading">
          <highcharts class="w-full" :options="impressions_vs_percentage_viewed_chart" />
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import VideoReportingInfo from '@/components/VideoReportingInfo.vue';
import {Chart} from 'highcharts-vue'
import TabButtons from '@/components/TabButtons.vue'
import moment from 'moment';

const defaultCreativeData = {
    thumbnail_url: '',
    meta_data: [],
    views_over_time: [],
};

const impressions_vs_percentage_viewed_chart = {
    chart: {
        type: "line",
        marginRight: 90
    },
    title: {
        text: "Total Impressions Per Percentage Watched",
    },
    xAxis: {
        title: {
        text: "Percentage Watched",
        },
        labels: {
            overflow: 'justify',
        }
    },
    yAxis: {
      title: {
        text: "Impressions",
      },
      labels: {
            overflow: 'justify',
            formatter: function() {
              return this.value >= 1000000 ? this.value / 1000000 + 'M' : this.axis.defaultLabelFormatter.call(this);
            }
      } 
    },
    tooltip: {
        formatter: function () {
            return `Percentage Watched: <b>+${this.x}</b><br/>${this.series.name}: <b>${this.y.toLocaleString()}</b>`;
        },
    },
    series: [
        {
            name: "Impressions",
            data: [],
        },
    ],
    credits: {
        enabled: false,
    },
    exporting: {},
};

const unique_impressions_per_choice = {
    chart: {
        type: "line",
        marginRight: 90
    },
    title: {
        text: "Unique Impressions Per Interval",
    },
    xAxis: {
        title: {
            text: "Seconds",
        },
        labels: {
            overflow: 'justify',
        }
    },
    yAxis: {
        title: {
            text: "Impressions",
        },
        labels: {
            overflow: 'justify',
            formatter: function() {
              return this.value >= 1000000 ? this.value / 1000000 + 'M' : this.axis.defaultLabelFormatter.call(this);
            }
        }
    },
    tooltip: {
      formatter: function () {
        const xAxisTitle = this.series.xAxis.axisTitle.textStr;
        return `${xAxisTitle} Watched: <b>+${this.x}</b><br/>${this.series.name}: <b>${this.y.toLocaleString()}</b>`;
      },
    },
    series: [
        {
            name: "Impressions",
            data: [],
        },
    ],
    credits: {
        enabled: false,
    },
    exporting: {},
};

export default {
    name: "VideoImpressionsReporting",

    components: {
      VideoReportingInfo,
      TabButtons,
      highcharts: Chart,
    },

    props: {
      current_campaign: {
        type: Object,
        default: null
      },
      userSelectionRange: {
        type: Array,
        default: null
      }
    },

    data() {
        return {
            loading: true,
            all_countries: [],
            all_campaigns: [],
            campaign_creatives: [],
            views_per_interval: null,
            creative_data: defaultCreativeData,
            current_creative: null,
            dropdown_refresher: 0,
            creative_dropdown_refresher: 0,
            show_unique_chart: false,
            show_total_chart: false,
            video_length: 0,
            overviewTotalVideoImpressions: 0,
            user_unique_graph_choice: 'percentage_chart',
            last_updated: '',
            unique_impressions_per_choice: unique_impressions_per_choice,
            impressions_vs_percentage_viewed_chart: impressions_vs_percentage_viewed_chart,
            panelSwitchingButtons: [
                {
                    name: 'percentage_chart',
                    text: 'Percentage Chart'
                },
                {
                    name: 'time_interval',
                    text: 'Time Chart'
                },
            ],
        }
    },

    computed: {
      canFetchVideoData() {
        if(!this.current_creative) return false
        if (this.current_campaign && this.current_creative) {
          return this.all_campaigns.some(campaign => campaign.uuid === this.current_campaign.uuid);
        }
        return false;
      },
      headerName() {
          if(!this.current_campaign) return 'N/A'
          return this.current_campaign.name
      },

      headerDate() {
          if(!this.current_campaign) return 'N/A'
          return ' From ' + this.headerStartDateString + ' To ' + this.headerEndDateString
      },

      campaignStartDateString() {
          if(!this.current_campaign.start_date) return 'N/A'
          return moment(this.current_campaign.start_date).format('MMMM D, YYYY');
      },

      campaignEndDateString() {
          if(!this.current_campaign.end_date) return 'N/A'
          return moment(this.current_campaign.end_date).format('MMMM D, YYYY');
      },

      formattedDateRange() {
        const [startDate, endDate] = this.userSelectionRange;
        
        return {
          start_date: startDate ? this.formatDate(startDate) : null,
          end_date: endDate ? this.formatDate(endDate) : null,
        };
      },

      headerStartDateString() {
          if(!this.userSelectionRange?.[0]) return this.campaignStartDateString
          return moment(this.userSelectionRange[0]).format('MMMM D, YYYY');
      },

      headerEndDateString() {
          if(!this.userSelectionRange?.[1]) return this.campaignEndDateString
          return moment(this.userSelectionRange[1]).format('MMMM D, YYYY');
      },
    },

    watch: {
      current_campaign: {
        handler: async function() {
          this.campaign_creatives = []
          this.current_creative = null
          await this.fetchCampaignCreatives()
          this.creative_data = null
          this.show_unique_chart = false
          this.show_total_chart = false
          this.last_updated = ''
          this.creative_dropdown_refresher++
          if(this.canFetchVideoData) this.getVideoData()
        },
        deep: true
      },

      user_unique_graph_choice: {
        handler: function() {
          this.show_unique_chart = false
          this.fetchUniquesChart()
        },
      },

      userSelectionRange() {
        if(!this.current_campaign) return;
        if(this.canFetchVideoData) this.getVideoData()
      }
    },

    created() {},

    async mounted() {
      this.loading = true
      await this.fetchAllCampaigns()
      await this.getCountries()
      this.loading = false
    },
    
    methods: {
      async getCountries(){
        return this.$http_request(this.$http.get, '/countries')
            .then(({data}) => {
                this.all_countries = data
            })
            .catch((error) => {
                console.error(error.message)
            })
      },

      async fetchAllCampaigns() {
          await this.$http.get(`/campaigns/get/all-video`).then(({data}) => {
              this.all_campaigns = data
          })
      },

      async fetchCampaignCreatives() {
          const toasts = { success: 'Fetched campaign creatives', error: 'Failed to fetch campaign creatives' };
          try {
              const { data } = await this.$http_request(this.$http.get, `/campaign-creatives/video/${this.current_campaign.uuid}`, toasts);
              const isObjectContainingObjects = !Array.isArray(data) && typeof data === 'object';
              const creatives = isObjectContainingObjects ? Object.values(data) : data;

              if (creatives.length) {
                  this.campaign_creatives = creatives;
                  this.current_creative = creatives[0];
                  this.creative_dropdown_refresher++;
              }
          } catch (error) {
              console.error(error);
          }
      },

      getVideoData() {
        this.$emit('isActiveFlag', 'VideoImpressionsReporting', true);
        this.loading = true
        this.creative_data = defaultCreativeData
        this.show_unique_chart = false
        this.show_total_chart = false
        this.last_updated = ''
        //gets creative data (thumbnail_url & views_over_time)
        this.fetchCreativeVideoData()
        //adds meta_data to creative_data
        this.fetchCreativeMetaData(this.current_creative.uuid, this.current_campaign.uuid, this.selected_country?.alpha2, this.formattedDateRange.start_date, this.formattedDateRange.end_date)
        this.fetchUniquesChart()
        this.fetchLastUpdated()
        this.getTotalVideoImpressions()
      },

      async getTotalVideoImpressions() {
          const campaignUuid = this.current_campaign.uuid;
          const startDateString = this.formattedDateRange.start_date;
          const endDateString = this.formattedDateRange.end_date;
          const endpoint = `/video-impressions/campaign-total-impressions/${campaignUuid}/${startDateString}/${endDateString}`;

          return this.$http_request(this.$http.get, endpoint)
              .then(({ data }) => {
                  this.overviewTotalVideoImpressions = data;
                  this.$emit('total-video-impressions', data);
              })
              .catch((error) => {
                  console.error(error.message);
              });
      },

      async fetchCreativeVideoData() {
          let api = `/video-impressions-view-by-creative/${this.current_campaign.uuid}/${this.current_creative.uuid}/${this.selected_country?.alpha2}/${this.formattedDateRange.start_date}/${this.formattedDateRange.end_date}`

          await this.$http_request(this.$http.get, api).then(({data}) => {
            this.creative_data = data
            this.prepareTotalsChart()
          })
          .catch((error) => {
            console.error(error)
          })
      },

      async fetchUniquesChart() {
        let request = {
          creative_uuid: this.current_creative.uuid,
          campaign_uuid: this.current_campaign.uuid,
          country: this.selected_country ? this.selected_country.alpha2 : null,
          graph_choice: this.user_unique_graph_choice,
          start_date: this.formattedDateRange.start_date,
          end_date: this.formattedDateRange.end_date
        }

        const toasts = {'error': `Error Getting Data`, 'success': 'Data Retreived'}
        this.$http_request(this.$http.post, '/video-impressions/by-interval', toasts, request)
          .then( ({data})=> {
            this.views_per_interval = data['chart_data']
            this.unique_impressions_per_choice.xAxis.title.text = this.user_unique_graph_choice == 'time_interval' ? data['interval_time'] : 'Percentage';
            this.unique_impressions_per_choice.title.text = this.video_length > 500 ? "Unique Impressions Per Interval Watched" : "Unique Impressions Per Percentage Watched";
            this.prepareUniquesChart()
          })
          .catch((error)=>{
              console.error(error.message)
          })
      },

      async fetchCreativeMetaData(creative_uuid, campaign_uuid, country_alpha_2, start_date, end_date) {
        const baseUrl = `/api/video-impressions/creative/${creative_uuid}/${campaign_uuid}`;
        const queryParams = new URLSearchParams({
          country_alpha_2, start_date, end_date
        }).toString();

        const urls = [
          `${baseUrl}/name`, // No query parameters needed
          `${baseUrl}/total-impressions?${queryParams}`,
          `${baseUrl}/unique-impressions?${queryParams}`,
          `${baseUrl}/unique-completion-rate?${queryParams}`,
          `${baseUrl}/video-length`, // No query parameters needed
          `${baseUrl}/average-view-time?${queryParams}`,
          `${baseUrl}/average-listen-time?${queryParams}`,
          `${baseUrl}/percentage-listened?${queryParams}`,
        ];

        try {
          const fetchPromises = urls.map(url => fetch(url).then(res => res.json()));
          const results = await Promise.all(fetchPromises);

          const labels = [
            'Creative Name',
            'Total Impressions',
            'Unique Impressions',
            'Completion Rate',
            'Video Length',
            'Average View Time',
            'Average Listen Time',
            'Percentage Of Players Who Listened'
          ];

          // Combine labels with results into the format [{ label: ..., value: ... }]
          this.creative_data.meta_data = results.map((result, index) => ({
            label: labels[index],
            value: result.value
          }));

          this.video_length = parseFloat(this.creative_data.meta_data[4].value.replace('s', ''))

          this.$notify({type: 'success', text: 'Video Impressions Data Fetched'});

        } catch (error) {
          console.error('Error fetching creative meta data:', error);
        }
      },

      async fetchLastUpdated() {
        const country = this.selected_country ? this.selected_country.alpha2 : null

        await this.$http.get(`/video-impressions/last-updated/${this.current_creative.uuid}/${this.current_campaign.uuid}/${country}`)
          .then( ({data}) => {
              this.last_updated = data
          })
      },

      async resetCachedValues() {
        const country = this.selected_country ? this.selected_country.alpha2 : null

        await this.$http.get(`/video-impressions/uncache-values/${this.current_creative.uuid}/${this.current_campaign.uuid}/${country}`)
          .then( () => {
            this.getVideoData()
          })
      },

      setCurrentCreative(creative) {
        this.current_creative = creative
      },

      prepareTotalsChart() {
        const graph_data =  this.creative_data.views_over_time
        let impressions_vs_percentage = [{x: 0, y: 0}]
        for (let i = 0; i < graph_data.length; i++) {
            const item = graph_data[i];
            impressions_vs_percentage.push({
                x: item.percentage,
                y: item.user_count,
            })
        }   

        this.impressions_vs_percentage_viewed_chart.series[0].data = impressions_vs_percentage

        this.show_total_chart = true
        this.loading = false
      },
      prepareUniquesChart() {
        const graph_data =  this.views_per_interval
        let graph_builder = [{x: 0, y: 0}]
        for (let i = 0; i < graph_data.length; i++) {
            const item = graph_data[i];
            graph_builder.push({
                x: item.yAxis,
                y: item.user_count,
            })
        } 

        this.unique_impressions_per_choice.series[0].data = graph_builder
        this.show_unique_chart = true
      },
      panelChanged(panelName){
          this.user_unique_graph_choice = panelName
      },
      setCountryLimit(country) {
        this.selected_country = country
      },

      formatDate(date) {
        return date.toISOString().split('T')[0]; // Converts Date object to 'YYYY-MM-DD' format
      },
  }
}
</script>

<style scoped>

</style>