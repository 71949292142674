const state = {
    table_view: false,
    relation: 'placement',
    item_type: 'game',
}

const getters = {
    getTableView(state) {
        return state.table_view
    },
    getRelation(state){
        return state.relation
    },
    getItemType(state){
        return state.item_type
    },
}

const mutations = {
    setTableView(state, table_view) {
        state.table_view = table_view
    },
    setRelation(state, relation){
        state.relation = relation
    },
    setItemType(state, item_type){
        if(item_type == 'placement') state.relation = 'ad set'
        if(item_type == 'creative' || item_type == 'game') state.relation = 'campaign'
        state.item_type = item_type
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}