<template>
    <div>
        <div class="sm:p-8 p-4 bg-blue-100 rounded-md sm:grid sm:grid-cols-2 max-sm:flex max-sm:flex-col gap-x-8 gap-y-4">
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div> 
                    <label class="block font-medium sm:col-start-1 sm:col-end-3  text-md font-medium text-gray-700">
                        Select Campaign
                    </label>
                    <DropdownComponent 
                        :items='combinedAllCampaignsAndAllIntegrations'
                        display="name"
                        :selected="this.currentlySelectedSuperCampaign ? this.currentlySelectedSuperCampaign.name : 'Select a Campaign'"
                        :key="combinedCampaignSelectorRefresher"
                        @user_select="setCurrentSuperCampaign($event)"
                    />
                </div>

                <div v-show="currentlySelectedSuperCampaign">
                    <label class="block font-medium sm:col-start-1 sm:col-end-3  text-md font-medium text-gray-700">Date Range</label>
                    <VueDatePicker
                        class="pt-1"
                        v-model="interval.userSelectionRange"
                        range
                        :min-date="intervalMinMax.minMax.min"
                        :max-date="intervalMinMax.minMax.max"
                        :enable-time-picker="false"
                        prevent-min-max-navigation
                        :preset-ranges="presetRanges"
                        :clearable="false">
                        <template #yearly="{ label, range, presetDateRange }">
                            <span @click="presetDateRange(range)">{{ label }}</span>
                        </template>
                    </VueDatePicker>
                </div>
            </div>

            <div class="grid-container gap-2" v-if="showOverviewPanel" >
                <div class="card-container" v-if="overviewTotalEngagements > 0">
                    <div class="inner-card">
                        <label> Total Engagements: </label>
                        <span> {{ overviewTotalEngagementsFormatted }} </span>
                    </div>
                </div>
                <div class="card-container" v-if="overviewTotalImpressions > 0">
                    <div class="inner-card" >
                        <label> Total Impressions </label>
                        <span> {{ formatNumber(overviewTotalImpressions) }} </span>
                    </div>
                </div>
                <div class="card-container" v-if="overviewTotalVideoImpressions > 0">
                    <div class="inner-card">
                        <label> Total Video Impressions </label>
                        <span> {{ formatNumber(overviewTotalVideoImpressions) }} </span>
                    </div>
                </div>
            </div>
        </div>
        <TabButtons v-if="currentlySelectedSuperCampaign" :buttons="panelSwitchingButtons" :active_button="currentPanel" :panel-changed-method="panelChanged"></TabButtons>
        <keep-alive>
            <div v-show="currentPanel == 'NativeDisplayReporting'">
                <keep-alive>
                    <NativeDisplayReporting
                        v-show="currentlySelectedCampaign"
                        :current_campaign="currentlySelectedCampaign"
                        :userSelectionRange="interval.userSelectionRange"
                        @isActiveFlag="updatePanelIsActive"
                        @min-max-range-array="setNativeDisplayReportIntervalMinMaxArray"
                        @impression-total="overviewTotalImpressions = $event"
                        @is-reward-video="updateIsRewardVideoFlag"
                    ></NativeDisplayReporting>
                </keep-alive>
            </div>
        </keep-alive>
        <keep-alive>
            <div v-show="currentPanel == 'IntegrationsReporting'">
                <keep-alive>
                    <IntegrationsReporting
                        v-show="currentlySelectedIntegration"
                        :all_integrations="allIntegrations"
                        :current_campaign="currentlySelectedCampaign"
                        :current_integration="currentlySelectedIntegration"
                        :userSelectionRangeProp="interval.userSelectionRange"
                        @isActiveFlag="updatePanelIsActive"
                        @min-max-range-array="setIntegrationsReportIntervalMinMaxArray"
                        @total-engagements="overviewTotalEngagements = $event"
                    ></IntegrationsReporting>
                </keep-alive>
            </div> 
        </keep-alive>
        <keep-alive>
            <div v-show="currentPanel == 'VideoImpressionsReporting'">
                <keep-alive>
                    <VideoImpressionsReporting
                        v-show="currentlySelectedCampaign"
                        :current_campaign="currentlySelectedCampaign"
                        :userSelectionRange="interval.userSelectionRange"
                        @isActiveFlag="updatePanelIsActive"
                        @total-video-impressions="overviewTotalVideoImpressions = $event">
                    </VideoImpressionsReporting>
                </keep-alive>
            </div>
        </keep-alive>
    </div>
</template>

<script>
import NativeDisplayReporting from '@/components/CombinedReporting/NativeDisplayReportingComponent.vue'
import IntegrationsReporting from '@/components/CombinedReporting/IntegrationsReportingComponent.vue'
import VideoImpressionsReporting from '@/components/CombinedReporting/VideoImpressionsReportingComponent.vue'
import TabButtons from '@/components/TabButtons.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { subDays } from 'date-fns'

export default {
    name: "CombinedReporting",
    components: {
        NativeDisplayReporting,
        IntegrationsReporting,
        VideoImpressionsReporting,
        TabButtons, 
        VueDatePicker
    },
    props: {},
    data() {
        return {
            interval: {
                minMax: {
                    min: null,
                    max: null,
                },
                userSelectionRange: [
                    null,
                    null,
                ],
            },
            nativeDisplayIsRewardVideo: false,
            currentPanel: 'IntegrationsReporting',
            allCampaigns: [],
            allIntegrations: [],
            integrationsReportIntervalMinMaxArray: [],
            nativeDisplayReportIntervalMinMaxArray: [],
            overviewTotalImpressions: 0,
            overviewTotalVideoImpressions: 0,
            overviewTotalEngagements: 0,
            currentlySelectedCampaign: null,
            currentlySelectedIntegration: null,
            campaignSelectorRefresher: 0,
            integrationSelectorRefresher: 0,
            panelSwitchingButtonsData: [
                {
                    name: 'IntegrationsReporting',
                    text: 'Integrations Reporting',
                    panelIsActive: false,
                },
                {
                    name: 'NativeDisplayReporting',
                    text: 'Native Display Reporting',
                    panelIsActive: false,
                },
                {
                    name: 'VideoImpressionsReporting',
                    text: 'Video Impressions Reporting',
                    panelIsActive: false,
                },
            ]
        }
    },
    computed: {
        panelSwitchingButtons() {
            return this.panelSwitchingButtonsData
            .filter(panel => panel.panelIsActive)
            .map(panel => {
                if (panel.name === 'NativeDisplayReporting') {
                return {
                    ...panel,
                    text: this.nativeDisplayIsRewardVideo 
                    ? 'Reward Video Reporting' 
                    : 'Native Display Reporting'
                };
                }
                return panel;
            });
        },

        combinedAllCampaignsAndAllIntegrations() {
            // Step 1: Map over allIntegrations and rename the key
            const modifiedIntegrations = this.allIntegrations.map(integration => {
                const { integration_friendly_name, ...rest } = integration;
                return { name: integration_friendly_name, ...rest };
            });

            // Step 2: Combine the two arrays
            let combined = [...this.allCampaigns, ...modifiedIntegrations];

            // Step 3: Remove integrations linked to campaigns
            combined = combined.filter(item => {
                return !(item.campaign_uuid && this.allCampaigns.some(campaign => campaign.uuid === item.campaign_uuid));
            });

            // Step 4: Sort by start_date
            combined.sort((a, b) => {
                const dateA = new Date(a.start_date);
                const dateB = new Date(b.start_date);
                return dateB - dateA;
            });

            return combined;
        },

        intervalMinMax() {
            // Define default min and max values
            const defaultMinMax = {
                min: new Date('2000-01-01'), // Example default min date
                max: new Date('2100-01-01'), // Example default max date
            };

            // Gather all dates from both arrays
            const allDates = [
                ...this.integrationsReportIntervalMinMaxArray,
                ...this.nativeDisplayReportIntervalMinMaxArray
            ].filter(date => date instanceof Date);

            // If no dates are found, return the default min and max
            if (allDates.length === 0) {
                return { minMax: defaultMinMax };
            }

            // Calculate the minimum and maximum dates from the available dates
            const minDate = new Date(Math.min(...allDates.map(date => date.getTime())));
            const maxDate = new Date(Math.max(...allDates.map(date => date.getTime())));

            return {
                minMax: {
                    min: minDate,
                    max: maxDate,
                },
            };
        },

        presetRanges() {
            return [
                {
                    label: 'Last 30 days',
                    range: [subDays(new Date(), 31), subDays(new Date(), 1)],
                },
                {
                    label: 'Last 60 days',
                    range: [subDays(new Date(), 61), subDays(new Date(), 1)],
                },
                {
                    label: 'Last 90 days',
                    range: [subDays(new Date(), 91), subDays(new Date(), 1)],
                },
                {
                    label: 'All time',
                    range: [
                        this.intervalMinMax.minMax.min,
                        this.intervalMinMax.minMax.max,
                    ],
                },
            ]
        },

        overviewTotalEngagementsFormatted() {
            if(!this.overviewTotalEngagements) {
                return 0
            }
            return this.formatNumber(this.overviewTotalEngagements);
        },

        showOverviewPanel() {
            return this.overviewTotalImpressions > 0 || this.overviewTotalVideoImpressions > 0 || this.overviewTotalEngagements > 0;
        }
    },
    watch: {
        panelSwitchingButtons() {
            if (this.panelSwitchingButtons.length === 1) {
                this.currentPanel = this.panelSwitchingButtons[0].name;
            } else if (this.panelSwitchingButtons.length > 1) {
                const integrationsReportingButton = this.panelSwitchingButtons.find(button => button.name === 'IntegrationsReporting' && button.panelIsActive);
                if (integrationsReportingButton) {
                    this.currentPanel = integrationsReportingButton.name;
                }
            }
        },

        intervalMinMax(intervalMinMax) {
            this.userSelectionRange = [
                intervalMinMax.minMax.min,
                intervalMinMax.minMax.max,
            ]
        }
    },
    created() {
        this.getAllCampaigns()
        this.getAllIntegrations()
    },
    mounted() {},
    methods: {
        updateIsRewardVideoFlag(isRewardVideo) {
            console.log('isRewardVideo', isRewardVideo);
            this.nativeDisplayIsRewardVideo = isRewardVideo;
        },
        panelChanged(panelName){
            this.currentPanel = panelName
        },

        updatePanelIsActive(panelName, isActive) {
            const panel = this.panelSwitchingButtonsData.find(p => p.name === panelName);
            if (panel) {
                panel.panelIsActive = isActive;
            }
        },

        resetAllPanelsHaveData() {
            this.panelSwitchingButtonsData.forEach(panel => {
                panel.panelIsActive = false;
            });
        },

        setCurrentSuperCampaign(campaignOrIntegration) {
            this.integrationsReportIntervalMinMaxArray = []
            this.nativeDisplayReportIntervalMinMaxArray = []
            this.interval.userSelectionRange = [
                null,
                null,
            ]
            this.interval.minmax = {
                min: null,
                max: null,
            }
            this.currentlySelectedCampaign = null;
            this.currentlySelectedIntegration = null;
            // Determine if the object is a campaign or an integration
            if ('name' in campaignOrIntegration && 'uuid' in campaignOrIntegration) {
                // It's a campaign
                this.setCurrentCampaign(campaignOrIntegration);
            } else if ('event_id_05' in campaignOrIntegration) {
                // It's an integration
                this.setCurrentIntegration(campaignOrIntegration);
            }
            // Set currentlySelectedSuperCampaign
            this.currentlySelectedSuperCampaign = campaignOrIntegration;

            this.resetAllPanelsHaveData();
            this.resetOverviewTotals();
            this.nativeDisplayIsRewardVideo = false;
        },

        resetOverviewTotals() {
            this.overviewTotalImpressions = 0;
            this.overviewTotalVideoImpressions = 0;
            this.overviewTotalEngagements = 0;
        },

        setCurrentCampaign(campaign) {
            this.currentlySelectedCampaign = campaign;
            const matchingIntegration = this.allIntegrations.find(integration => 
                integration.campaign_uuid === this.currentlySelectedCampaign.uuid);

            if (matchingIntegration && this.currentlySelectedIntegration?.event_id_05 !== matchingIntegration.event_id_05) {
                this.setCurrentIntegration(matchingIntegration);
            }
            else {
                this.currentlySelectedIntegration = null;
            }

            this.campaignSelectorRefresher++
            this.combinedCampaignSelectorRefresher++
        },

        setCurrentIntegration(integration) {
            this.currentlySelectedIntegration = integration;

            const matchingCampaign = this.allCampaigns.find(campaign => 
                campaign.uuid === this.currentlySelectedIntegration.campaign_uuid);

            if (matchingCampaign && this.currentlySelectedCampaign?.uuid !== matchingCampaign.uuid) {
                this.setCurrentCampaign(matchingCampaign);
            }

            this.integrationSelectorRefresher++
        },

        async getAllCampaigns() {
            await this.$http.get('/campaigns/get/all').then( ({data}) => {
                this.allCampaigns = data
            })
        },

        async getAllIntegrations() {
            await this.$http.get('/integrations').then( ({data}) => {
                this.allIntegrations = data.data
            })
        },

        setIntegrationsReportIntervalMinMaxArray(minMaxArray) {
            //Javascript dates in an array min first max second
            this.integrationsReportIntervalMinMaxArray = minMaxArray

            this.interval.userSelectionRange = [
                this.intervalMinMax.minMax.min,
                this.intervalMinMax.minMax.max
            ]
        },

        setNativeDisplayReportIntervalMinMaxArray(minMaxArray) {
            //Javascript dates in an array min first max second
            this.nativeDisplayReportIntervalMinMaxArray = minMaxArray

            this.interval.userSelectionRange = [
                this.intervalMinMax.minMax.min,
                this.intervalMinMax.minMax.max
            ]
        },

        formatNumber(value, locales = 'en-US', options = {}) {
            // Default options
            const defaultOptions = {
                maximumFractionDigits: 2,
                ...options
            };

            // Using Intl.NumberFormat for formatting
            return new Intl.NumberFormat(locales, defaultOptions).format(value);
        }
    }
}
</script>

<style  lang="postcss" scoped>
:deep .dp__action_select {
    color: white; 
    background-color: green; 
    opacity: 1 !important; 
    border: 1px solid black;
    padding: 0.8em 0.4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

:deep .dp__action_cancel {
    color: white; 
    background-color: red; 
    opacity: 1 !important; 
    border: 1px solid black;
    padding: 0.8em 0.4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

:deep(.dp__action_button:hover) {
    color: white; 
    background-color: blue; 
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.card-container {
    @apply bg-white rounded-md flex flex-col items-center justify-center p-4 gap-4;
}

.date-picker {
    @apply lg:max-w-lg sm:max-w-xs w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md;
}

.inner-card {
    @apply flex flex-col gap-2 items-center;
}

.card-container span {
    @apply text-3xl font-bold;
}
</style>