<template>
    <div>
        <div class="sm:p-8 p-4 bg-blue-100 rounded-md sm:grid sm:grid-cols-2 max-sm:flex max-sm:flex-col gap-x-8 gap-y-4">
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div> 
                    <label class="block font-medium sm:col-start-1 sm:col-end-3  text-md font-medium text-gray-700">
                        Select Integration
                    </label>
                    <DropdownComponent 
                        :items='integrations'
                        display="integration_friendly_name"
                        :selected="this.selectedIntegration ? this.selectedIntegration.integration_friendly_name : 'Select Integration'"
                        :key="integrationSelectorRefresher"
                        @user_select="setSelectedIntegration($event)"
                    />
                </div>
            </div>
        </div>

        <div v-if="selectedEventGroups.length > 0" class="p-4 border-t mt-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="bulk-category-uuid">
                New Category for Selected Events
            </label>
            <select id="bulk-category-uuid" v-model="bulkCategoryUuid" class="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                <option v-for="category in categories" :key="category.uuid" :value="category.uuid">
                    {{ category.category_name }}
                </option>
            </select>
            <button @click="updateSelectedEventGroupsCategory" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4">
                Update Categories
            </button>
        </div>

        <button v-if="selectedIntegration" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4 mb-4" @click="showNewEventGroupForm">Add New Group</button>

        <div v-if="showForm" class="mb-4">
            <form @submit.prevent="submitEventGroupForm" class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <!-- Assuming event_id_05 is preselected for new records based on selectedIntegration -->
                <input type="hidden" v-model="form.event_id_05" :value="selectedIntegration.event_id_05" />

                <div class="mb-4" v-if="!this.form.uuid">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="event-id-01">
                        Event ID 01
                    </label>
                    <input
                        id="event-id-01"
                        type="text"
                        v-model="form.event_id_01"
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        :readonly="form.uuid"
                        placeholder="Event ID 01">
                </div>

                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="category-uuid">
                        Category
                    </label>
                    <select id="category-uuid" v-model="form.category_uuid" class="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                        <option v-for="category in categories" :key="category.uuid" :value="category.uuid">
                            {{ category.category_name }}
                        </option>
                    </select>
                </div>

                <div class="flex items-center justify-between">
                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Save
                    </button>
                </div>
            </form>
        </div>
        <table class="table-auto w-full mb-4">
            <thead>
                <tr class="bg-gray-200">
                    <th class="px-4 py-2">Event ID One</th>
                    <th class="px-4 py-2">Category</th>
                    <th class="px-4 py-2">Actions</th>
                    <th class="px-4 py-2">Batch Update</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="eventGroup in integrationEventGroups" :key="eventGroup.uuid" :class="{ 'bg-blue-100': eventGroup.uuid === activeRowId }" class="border-b">
                    <td class="px-4 py-2">{{ eventGroup.event_id_01 }}</td>
                    <td class="px-4 py-2">{{ eventGroup.category.category_name }}</td>
                    <td class="px-4 py-2 flex flex-col items-start">
                        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mb-2" @click="editEventGroup(eventGroup)">Edit</button>
                    </td>
                    <td class="px-4 py-2">
                        <input type="checkbox" v-model="selectedEventGroups" :value="eventGroup.uuid">
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "ManageIntegrationGroups",
    components: {},
    props: {},
    data() {
        return {
            integrations: [],
            integrationEventGroups: [],
            categories: [], // Populate this from your APIww
            selectedEventGroups: [],
            selectedIntegration: null,
            bulkCategoryUuid: '',
            showForm: false,
            form: {}, // Reset this as needed
            activeRowId: null,
            integrationSelectorRefresher: 0
        }
    },
    computed: {},
    watch: {
        selectedIntegration: function () {
            this.fetchIntegrationEventGroups();
        }
    },
    created() {
        this.fetchIntegrations();
        this.fetchCategories();
    },
    mounted() {},
    methods: {
        async fetchIntegrations() {
            const url = '/integrations';
            const toasts = {
                success: 'Integrations fetched successfully',
                error: 'Failed to fetch integrations'
            };

            await this.$http_request(
                this.$http.get,
                url,
                toasts
            ).then(response => {
                this.integrations = response.data.data;
            }).catch(error => {
                console.error('Fetch Integrations Error:', error);
            });
        },

        async fetchIntegrationEventGroups() {
            // Construct the URL with the integration UUID
            const integrationUuid = this.selectedIntegration.id;
            const url = `/integration-event-groups/by-integration/${integrationUuid}`;
            const toasts = {
                success: 'Integration Event Groups fetched successfully',
                error: 'Failed to fetch Integration Event Groups'
            };

            await this.$http_request(
                this.$http.get,
                url,
                toasts
            ).then(response => {
                // Assuming you want to store the fetched event groups in a data property
                this.integrationEventGroups = response.data.data;
            }).catch(error => {
                console.error('Fetch Integration Event Groups Error:', error);
            });
        },

        async fetchCategories() {
            const url = '/design-event-categories';
            const toasts = {
                success: 'Event Categories fetched successfully',
                error: 'Failed to fetch event categories'
            };

            await this.$http_request(
                this.$http.get,
                url,
                toasts
            ).then(response => {
                this.categories = response.data.data;
            }).catch(error => {
                console.error('Fetch DesignEventCategories Error:', error);
            });
        },

        setSelectedIntegration(integration) {
            this.selectedIntegration = integration;
            this.integrationSelectorRefresher++;
        },

        editEventGroup(eventGroup) {
            this.activeRowId = eventGroup.uuid; // Highlight the row
            this.form = {
                uuid: eventGroup.uuid,
                event_id_01: eventGroup.event_id_01,
                category_uuid: eventGroup.category.uuid, // Assuming the eventGroup object has a category object
                integration_uuid: eventGroup.integration.id
                // Include any other fields you need to edit
            };
            this.showForm = true; // Show the form
        },

        showNewEventGroupForm() {
            this.form = { // Reset the form, ensure it doesn't carry over data from the last edit
                event_id_01: '',
                category_uuid: '',
                integration_uuid: this.selectedIntegration ? this.selectedIntegration.id : '',
            };
            this.activeRowId = null; // No row is active, as we're adding a new record
            this.showForm = true;
        },

        async submitEventGroupForm() {
            const url = this.form.uuid ? `/integration-event-groups/${this.form.uuid}` : '/integration-event-groups'; // Determine the URL based on add or edit mode
            const method = this.form.uuid ? 'put' : 'post'; // Use PUT for edit, POST for add

            try {
                await this.$http[method](url, this.form);
                // On success, fetch the updated list of event groups or close the form as needed
                this.showForm = false; 
                this.activeRowId = null;
                this.fetchIntegrationEventGroups(); 
            } catch (error) {
                this.activeRowId = null;
                console.error('Error submitting event group form:', error);
            }
        },

        async updateSelectedEventGroupsCategory() {
            console.log('Bulk update selected event groups:', this.selectedEventGroups, 'to category:', this.bulkCategoryUuid)
            for (const eventGroupId of this.selectedEventGroups) {
                const url = `/integration-event-groups/${eventGroupId}`;
                const formData = { category_uuid: this.bulkCategoryUuid };
                try {
                    await this.$http.put(url, formData);
                    // handle success for each event group update
                } catch (error) {
                    console.error('Bulk update error for event group:', eventGroupId, error);
                    // handle error
                }
            }
            this.fetchIntegrationEventGroups(); // Refresh the list after updates
            this.selectedEventGroups = []; // Clear selection
            this.bulkCategoryUuid = ''; // Reset selected category
        },
    }
}
</script>

<style scoped>

</style>