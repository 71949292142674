<template>
    <div class="flex flex-col gap-4 bg-white p-4 rounded-md">
        <TimeZoneSettings />
        <ChangePassword />
        <!-- The below is just an example of what future updates could look like in this file -->
        <!-- <OtherUserSettingOptions /> -->
    </div>
</template>

<script>
import ChangePassword from '@/components/ChangePassword.vue'
import TimeZoneSettings from '@/components/TimeZoneSettings.vue'
export default {
    name: "UserSettings",
    components: {
        ChangePassword,
        TimeZoneSettings
    },
    props: {},
    data() {
        return {

        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        
    }
}
</script>

<style scoped>

</style>