export default class Graph {
  constructor({id}) {
    this.id = id;
    this.nodes = [];
  }

  getId(){
    return this.id
  }

  getNode(id){
    return this.nodes.find(node => node.id === id)
  }

  removeEdge(id){
    this.nodes.forEach(node => node.removeEdge(id))
  }

  removeNodeAndConnections(id){
    this.removeEdgesTo(id)
    this.removeNode(id)
  }

  removeNode(id){
    this.nodes = this.nodes.filter(node => node.id !== id)
  }

  removeEdgesTo(id){
    this.nodes.forEach(node => node.removeEdgesTo(id))
  }
  
  addNode(node) {
    this.nodes.push(node);
  }

  getAsJSON(){
    return JSON.stringify(this.nodes)
  }
}