<template>
    <div class="w-full flex flex-row flex-wrap justify-center content-center gap-1 m-1 image-picker-wrapper">
    
        <div v-for="image in images" :key="image['uuid']">
            <ImagePickerGameImage
                v-if="image_types.games"
                @selected="imageSelected"
                :img_src="image[src_name]"
                :img_uuid="image['uuid']"
                :img_position="image['img_position']"
                :game_name="image['friendly_name']">
            </ImagePickerGameImage>

            <ImagePickerCreativeImage
                v-if="image_types.creatives"
                @selected="imageSelected"
                :img_src="image['thumbnail_url']"
                :uuid="image['uuid']"
                :img_dimensions="image['dimensions']"
                :img_type="image['img_type']"
                :img_position="image['img_position']"
                :user_defined_name="image['user_defined_name']"
                :under_moderation="image['under_moderation']"
                :on_placement="hasOnPlacement(image)"
                :on_run="hasOnRun(image)">
            </ImagePickerCreativeImage>

            <ImagePickerPlacementImage
                v-if="image_types.placements"
                :additional_details="additional_details"
                @emitToNavigate="emitToNavigate"
                :placement_uuid="image['uuid']"
                @selected="imageSelected"
                :img_src="image['thumbnail_url']"
                :img_position="image['img_position']"
                :name="image['name']"
                :creative_count="image['creative_count']"
                :impressions="imageImpressions(image)"
                :show_lifetime_impressions="showLifetimeImpressions">
            </ImagePickerPlacementImage>
        </div>
    </div>
</template>

<script>
import ImagePickerCreativeImage from '../ImagePickers/ImagePickerCreativeImage.vue'
import ImagePickerGameImage from '../ImagePickers/ImagePickerGameImage.vue'
import ImagePickerPlacementImage from '../ImagePickers/ImagePickerPlacementImage.vue'

export default {
    name: 'ImagePicker',
    components: {
        ImagePickerCreativeImage,
        ImagePickerGameImage,
        ImagePickerPlacementImage,
    },
    props: {
        additional_details: {
            default: false,
            type: Boolean,
        },
        getter_string: {
            default: 'get',
            type: String,
        },
        fetcher_string: {
            default: 'fetch',
            type: String,
        },
        image_type: {
            default: 'basic',
            type: String,
        },
        images_api: {
            default: null,
            type: String,
        },
        src_name: {
            //defines the object key to retrieve the image src.
            default: 'src',
            type: String,
        },
        picker_type: {
            default: 'adder',
            type: String,
        },
    },
    data() {
        return {
            image_types: {
                creatives: false,
                games: false,
                placements: false,
                basic: false,
            },
            returned_images: {},
            image_selection: {
                first_value: {
                    checked: false,
                    uuid: null,
                    img_name: null,
                    img_position: null,
                },
                last_value: {
                    checked: false,
                    uuid: null,
                    img_name: null,
                    img_position: null,
                },
                shift_pressed: false,
            },
            image_selection_clear: {
                ctrl_pressed: false,
                z_pressed: false,
            },
        }
    },
    methods: {
        imageSelected(image) {
            this.dispatchItemSelected(image)
            this.handleMultipleImageSelection(image)
        },
        handleMultipleImageSelection(image) {
            if (this.determineStage(image) != 'last') return

            this.addMultiSelectImagesToSelected()

            if (this.image_selection.last_value.uuid)
                this.imageSelectionReset()
        },
        determineStage(image) {
            if (!this.image_selection.shift_pressed) {
                this.image_selection.first_value = image
                return 'first'
            }
            if (this.image_selection.first_value.uuid === null) {
                this.image_selection.first_value = image
                return 'first'
            } else {
                if (!this.image_selection.shift_pressed) return 'first'
                this.image_selection.last_value = image
                return 'last'
            }
        },
        addMultiSelectImagesToSelected() {
            if (this.isBackwardSelection()) this.setSelectedBackwards()
            else this.setSelectedForwards()
        },
        isBackwardSelection() {
            if ( this.image_selection.first_value.img_position > this.image_selection.last_value.img_position )
                return true
            return false
        },
        setSelectedBackwards() {
            this.images.forEach((image_element) => {
                if (image_element.img_position < this.image_selection.first_value.img_position &&
                    image_element.img_position > this.image_selection.last_value.img_position ) 
                    this.dispatchItemSelected(image_element, true)             
            })
        },
        setSelectedForwards() {
            this.images.forEach((image_element) => {
                if ( image_element.img_position > this.image_selection.first_value.img_position &&
                    image_element.img_position < this.image_selection.last_value.img_position )
                    this.dispatchItemSelected(image_element, true)
            })
        },
        dispatchItemSelected(image, multi_select = false) {
            const selected_request = {
                item: image,
                item_type: this.image_type,
                picker_type: this.picker_type,
            }
            if (multi_select) {
                this.$store.dispatch('picker_module/addMultiSelectItem', selected_request)
            } else {
                this.$store.dispatch('picker_module/itemSelected',selected_request)
            }
        },
        imageSelectionReset() {
            this.image_selection = {
                shift_pressed: this.image_selection.shift_pressed,
                first_value: {
                    checked: false,
                    uuid: null,
                    img_name: null,
                    img_position: null,
                },
                last_value: {
                    checked: false,
                    uuid: null,
                    img_name: null,
                    img_position: null,
                },
            }
        },
        setImageType() {
            for (const [key] of Object.entries(this.image_types)) {
                if (key != this.image_type) {
                    this.image_types[key] = false
                } else if (key == this.image_type) {
                    this.image_types[key] = true
                }
            }
        },
        handleMultipleImageSelectionMode(event_type) {
            if (event_type == 'keydown')
                this.image_selection.shift_pressed = true
            if (event_type == 'keyup')
                this.image_selection.shift_pressed = false
            if (!this.image_selection.shift_pressed && this.image_selection.last_value.uuid === null)
                this.imageSelectionReset()
        },
        handleClearImageSelectionMode(event_type, key) {
            if (event_type == 'keydown' && key == 'Control')
                this.image_selection_clear.ctrl_pressed = true

            if (event_type == 'keyup' && key == 'Control')
                this.image_selection_clear.ctrl_pressed = false

            if (event_type == 'keydown' && key == 'KeyZ')
                this.image_selection_clear.z_pressed = true

            if (event_type == 'keyup' && key == 'KeyZ')
                this.image_selection_clear.z_pressed = false

            if ( this.image_selection_clear.ctrl_pressed && event_type == 'click')
                this.clearSelectedCreatives()

            if ( this.image_selection_clear.ctrl_pressed && this.image_selection_clear.z_pressed )
                this.restoreSelectedItems()
        },
        clearSelectedCreatives() {
            this.$store.commit('restore_point_module/setRestorationState',this.$store.getters['picker_module/getCurrentState'])
            this.$store.commit('picker_module/clearAllSelected')
        },
        restoreSelectedItems() {
            this.$store.commit('picker_module/restorePreviousState',this.$store.getters['restore_point_module/getRestorationState'])
        },
        addEventListeners() {
            window.addEventListener('keydown', (e) => {
                const event_type = 'keydown'

                if (e.key == 'Shift')
                    this.handleMultipleImageSelectionMode(event_type)
                
                if (e.key == 'Control')
                    this.handleClearImageSelectionMode(event_type, e.key)
                
                if (e.code == 'KeyZ')
                    this.handleClearImageSelectionMode(event_type, e.code)
            })
            
            window.addEventListener('keyup', (e) => {
                const event_type = 'keyup'

                if (e.key == 'Shift')
                    this.handleMultipleImageSelectionMode(event_type)

                if (e.key == 'Control')
                    this.handleClearImageSelectionMode(event_type, e.key)

                if (e.code == 'KeyZ')
                    this.handleClearImageSelectionMode(event_type, e.code)
            })

            window.addEventListener('click', () => {
                const event_type = 'click'
                this.handleClearImageSelectionMode(event_type)
            })
        },
        fetchImages() {
            this.$store.dispatch(this.fetcher_string, {
                api_url: this.images_api,
                image_type: this.image_type,
            })
        },
        getImages() {
            return this.$store.getters[this.getter_string]
        },
        applyPositionToImages(images) {
            var count = 1
            images.forEach((image) => {
                image.img_position = count
                count++
            })
            return images
        },
        emitToNavigate(placement_uuid){
            this.$emit('emitToNavigate', placement_uuid)
        },
        hasOnPlacement(image){
            if('on_placement' in image) return image['on_placement']
            else return true
        },
        hasOnRun(image){
            if('on_run' in image) return image['on_run']
            else return true
        },
        imageImpressions(image){
            return image?.impressions
        },
    },
    computed: {
        images() {
            var images = this.getImages()
            
            images = this.applyPositionToImages(images)
            
            return images
        },
        showLifetimeImpressions(){
            if(this.picker_type == 'adder') return true
            else return false
        }
    },
    created() {
        this.addEventListeners()
        this.$store.commit('picker_module/clearAllSelected')
    },
    mounted() {
        this.fetchImages()
        this.setImageType()
    },
}
</script>
