<template>
    <div class="flex flex-col gap-4">
        <!-- FILTER AREA -->
        <div class="bg-white rounded-md p-4 flex flex-col gap-2">

            <div class="sm:grid sm:grid-cols-2 sm:col-start-1 sm:col-end-3 flex flex-col gap-x-4">
                <label class="font-medium sm:col-start-1 sm:col-end-3"> Choose a Game *: </label>
                <DropdownComponent 
                selected="Select a Game" 
                :items="admonitor_games"
                display="friendly_name"
                @user_select="addGameToFilter"
                class="sm:col-start-1 sm:col-end-2 lg:max-w-lg sm:max-w-xs w-full"/>
            </div>

            <div class="sm:grid sm:grid-cols-2 sm:col-start-1 sm:col-end-3 flex flex-col gap-x-4">
                <label class="font-medium sm:col-start-1 sm:col-end-3"> Choose Grouping: </label>
                <DropdownComponent 
                :key="grouping_dropdown_refresher"
                selected="Select Grouping Options" 
                :items="grouping_options"
                display="name"
                @user_select="addGroupingOptionToFilter"
                class="sm:col-start-1 sm:col-end-2 lg:max-w-lg sm:max-w-xs w-full"/>
                <div v-if="selected_groupings.length" class="sm:col-start-1 sm:col-end-3 flex flex-row flex-wrap gap-1 mt-1">
                    <label v-for="grouping in selected_groupings" :key="grouping" class="bg-blue-200 rounded-md px-2 py-1 cursor-pointer hover:bg-blue-400" @click="removeGroupingOptionFromFilter(grouping)">{{grouping.name}}</label>
                </div>
            </div>

            <div class="sm:grid sm:grid-cols-2 sm:col-start-1 sm:col-end-3 flex flex-col gap-x-4">
                <label class="font-medium sm:col-start-1 sm:col-end-3"> Country: </label>
                <DropdownComponent
                :key="country_dropdown_refresher"
                :items='all_countries'
                display="name"
                selected="All Countries"
                @user_select="addCountryToFilter"
                class="sm:col-start-1 sm:col-end-2 lg:max-w-lg sm:max-w-xs w-full"/>
                <div v-if="selected_countries.length" class="sm:col-start-1 sm:col-end-3 flex flex-row flex-wrap gap-1 mt-1">
                    <label v-for="country in selected_countries" :key="country" class="bg-blue-200 rounded-md px-2 py-1 cursor-pointer hover:bg-blue-400" @click="removeCountryFromFilter(country)">{{country.name}}</label>
                </div>
            </div>

            <div class="sm:grid sm:grid-cols-2 sm:col-start-1 sm:col-end-3 flex flex-col gap-x-4">
                <div class="sm:col-start-1 sm:col-end-3 flex flex-row items-center gap-2">
                    <input type="checkbox" v-model="user_change_dates" class="w-4 h-4 text-blue-600 border-gray-300 rounded">
                    <label class="font-medium"> Specify Dates </label>
                    <span class="text-sm"> If not specified, all time data will be gathered </span>
                </div>
                <div class="sm:col-start-1 sm:col-end-2 flex flex-row gap-2 items-center">
                    <label for="start-date">
                        From:
                    </label>
                    <input type="date" name="start-date" id="start-date" min="2023-03-04" :max="dateYesterday" v-model="start_date_filter" class="date-picker" :class="{'bg-gray-500 text-white' : !user_change_dates}" :disabled="!user_change_dates"  />
                </div>

                <div class="sm:col-start-2 sm:col-end-3 flex flex-row gap-2 items-center">
                    <label for="end-date">
                        To:
                    </label>
                    <input type="date" name="end-date" id="end-date" :max="dateYesterday" v-model="end_date_filter" class="date-picker" :class="{'bg-gray-500 text-white' : !user_change_dates}" :disabled="!user_change_dates" />
                </div>
            </div>

            <div class="sm:col-start-1 sm:col-end-3 flex flex-row flex-wrap items-center gap-8">
                <div class="sm:col-start-1 sm:col-end-3 flex flex-row items-center gap-2">
                    <input id="avg-time" type="checkbox" v-model="show_avg_view_time" class="w-4 h-4 text-blue-600 border-gray-300 rounded">
                    <label for="avg-time" class="font-medium"> Show Avg. View Time </label>
                </div>

                <div class="sm:col-start-1 sm:col-end-3 flex flex-row items-center gap-2">
                    <input id="mod-version" type="checkbox" v-model="show_module_version_updates" class="w-4 h-4 text-blue-600 border-gray-300 rounded">
                    <label for="mod-version" class="font-medium"> ONLY Show Module Version Updates </label>
                </div>
            </div>

            <button class="btn-primary green" @click="getFilteredData"> Apply Filters </button>
        </div>

        <div class="flex flex-col gap-2" v-if="!loading && table_data">
            <highcharts :options="imp_over_time_chart_options" />
            <div class="bg-white rounded-md p-2">
                <h1 class="text-center border-b-2 mb-2 p-4 text-xl font-bold"> {{ table_title }} </h1>
                <TableComponent :show_average_row="false" :show_total_row="false" :table_data="table_data" :exported_file_name="this.selected_game.friendly_name+' Daily Reporting'" />
            </div>
        </div>
        <LoadingComponent v-if="loading" />
    </div>
</template>

<script>
import TableComponent from '../components/TableComponent.vue';
import {Chart} from 'highcharts-vue'

// Mapping of human-readable names to field names
const groupMapping = {
    'Module Version': 'moduleVersion',
    'Platform': 'platform',
    'Placement': 'placement',
    'Place ID': 'place_id'
};

const chartOptions = {
    time: {
        timezoneOffset: 0,
    },
    chart: {
        zoomType: 'x',
    },
    title: {
        text: 'Impressions Over Time',
    },
    plotOptions: {
        line: {
            connectNulls: false,
        },
    },
    xAxis: {
        type: 'datetime',
        labels: {
        format: '{value: %b %e}',
        },
    },
    yAxis: {
        title: {
            text: 'Impressions',
        },
        labels: {
            overflow: 'justify',
            formatter: function() {
                return this.value >= 1000000 ? this.value / 1000000 + 'M' : this.axis.defaultLabelFormatter.call(this);
            }
        },
    },
    series: [],
    credits: {
        enabled: false,
    },
    exporting: {},
}

const grouping_options = [
    { name: 'Module Version', database_column: 'module_version' },
    { name: 'Platform', database_column: 'platform' },
    { name: 'Place ID', database_column: 'place_id' },
    { name: 'Placement', database_column: 'placement_uuid' }
]
export default {
    name: "DailyGameReporting",
    components: { 
        TableComponent,
        highcharts: Chart,
    },
    props: {},
    data() {
        return {
            imp_over_time_chart_options: chartOptions,
            country_dropdown_refresher: 0,
            grouping_dropdown_refresher: 0,
            admonitor_games: [],
            all_countries: [],
            selected_groupings: [],
            selected_countries: [],
            table_title: '',
            selected_game: null,
            start_date_filter: null,
            end_date_filter: null,
            table_data: null,
            loading: false,
            show_avg_view_time: false,
            show_module_version_updates: false,
            user_change_dates: false,
            grouping_options: grouping_options,
        }
    },
    computed: {
        dateYesterday() {
            const date = new Date();
            date.setDate(date.getDate() - 1);
            return date.toISOString().split('T')[0];
        },
        groupedFields() {
            return this.selected_groupings.map(grouping => groupMapping[grouping.name]);
        },
        chartData() {
            if (!this.table_data) return null;

            return this.table_data.map(item => {
                const dataItem = {
                    date: new Date(item.date).getTime(),
                    impressions: parseInt(item.Impressions.replace(/,/g, '')),
                    uniqueImpressions: parseInt(item['Unique Impressions'].replace(/,/g, '')),
                };

                // Loop through the groupedFields and add the appropriate fields to dataItem
                this.groupedFields.forEach(field => {
                    const humanReadableName = Object.keys(groupMapping).find(key => groupMapping[key] === field);
                    dataItem[field] = item[humanReadableName];
                });

                return dataItem;
            });
        },
    },
    watch: {},
    created() {},
    mounted() {
        this.getAdMonitorGames()
        this.getCountries()
    },
    methods: {
        async getAdMonitorGames() {
            await this.$http_request(this.$http.get, '/games')
                .then((response) => { 
                    this.admonitor_games =  response.data 
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },
        async getCountries(){
            return this.$http_request(this.$http.get, '/countries')
                .then(({data}) => {
                    this.all_countries = data
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },
        async getFilteredData() {
            if(!this.selected_game || this.selected_game.length == 0)
                return this.$notify({type: 'error', text: 'Please Select A Game!'})

            if(this.user_change_dates && (this.start_date_filter == null || this.end_date_filter == null))
                return this.$notify({type: 'error', text: 'Please Specify Both Dates Or Uncheck The Specify Dates Checkbox'})

            const request = {
                game: this.selected_game,
                groupings: this.selected_groupings,
                countries: this.selected_countries,
                user_change_dates: this.user_change_dates,
                start_date: this.start_date_filter,
                end_date: this.end_date_filter,
                show_avg_view_time: this.show_avg_view_time,
                show_module_version_updates: this.show_module_version_updates,
            }

            this.loading = true
            await this.$http.post('/daily-game-reporting', request)
                .then( ({data}) => {
                    this.table_data = data
                    this.setChartData()
                    this.table_title = this.selected_game.friendly_name
                    this.loading = false
                })
                .catch( (error) => {
                    console.error('error', error)
                    this.loading = false
                })
        },
        setChartData() {
            if (this.groupedFields.length === 0) {
                return this.setDefaultChartData();
            }

            return this.groupChartDataByFields(this.groupedFields);
        },

        groupChartDataByFields(fields) {
            this.imp_over_time_chart_options.series = [];
            const seriesData = {};

            this.chartData.forEach(item => {
                // Build the key by concatenating the values of the specified fields
                const key = fields.map(field => item[field]).join('_');

                if (!seriesData[key]) {
                    seriesData[key] = [];
                }

                seriesData[key].push([item.date, item.impressions]);
            });

            this.imp_over_time_chart_options.series = Object.entries(seriesData).map(([key, data]) => {
                const names = key.split('_');
                // Build the name by concatenating the field values with a separator
                const name = names.join(' - ');

                return {
                    name,
                    data,
                };
            });
        },

        setDefaultChartData() {
            this.imp_over_time_chart_options.series = [ 
                {
                    name: 'Impressions',
                    data: [],
                },
                {
                    name: 'Unique Impressions',
                    data: []
                }
            ]
            this.imp_over_time_chart_options.series[0].data = this.chartData.map(item => [item.date, item.impressions]);
            this.imp_over_time_chart_options.series[1].data = this.chartData.map(item => [item.date, item.uniqueImpressions]);
        },
        addGameToFilter(game) {
            this.selected_game = game
        },
        removeGameFromFilter() {
            this.selected_game = null
        },
        addGroupingOptionToFilter(grouping) {
            this.grouping_dropdown_refresher++
            this.selected_groupings.push(grouping)
            this.grouping_options = this.grouping_options.filter( el => el.name !== grouping.name)
        },
        removeGroupingOptionFromFilter(grouping) {
            this.grouping_dropdown_refresher++
            this.selected_groupings = this.selected_groupings.filter( el => el.name !== grouping.name)
            this.grouping_options.push(grouping)
            this.grouping_options.sort((a, b) => a.name.localeCompare(b.name))
        },
        addCountryToFilter(country) {
            this.country_dropdown_refresher++
            this.selected_countries.push(country)
            this.all_countries = this.all_countries.filter( el => el.uuid !== country.uuid)
        },
        removeCountryFromFilter(country) {
            this.country_dropdown_refresher++
            this.selected_countries = this.selected_countries.filter( el => el !== country);
            this.all_countries.push(country);
            this.all_countries.sort((a, b) => {
                if (a.name === 'United States of America') return -1;
                if (b.name === 'United States of America') return 1;
                return a.name.localeCompare(b.name);
            });
        },
    }
}
</script>

<style scoped lang="postcss">
.date-picker {
    @apply lg:max-w-lg sm:max-w-xs w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md;
}
</style>