<template>
    <div class="bg-white rounded-md p-5 flex flex-col gap-5 min-w-fit capitalize">

        <div class="flex flex-col gap-3 w-full">
            <div v-for="slot in slots" :key="slot.name" class="flex sm:flex-row flex-wrap sm:flex-nowrap gap-2 sm:items-center w-full">
                <h5 v-if="show_blame && slot.additional_information" class="font-semibold text-2xl sm:border-t sm:border-gray-200" > {{slot.additional_information.label}} </h5>
                <h5 v-if="!show_blame" class="font-semibold text-2xl sm:border-t sm:border-gray-200">{{slot.label}}</h5>
                <slot :name="slot.name" class="font-semibold text-2xl"> </slot>
            </div>
        </div>

        <div class="flex flex-row flex-wrap gap-2"> 
            <slot name="toggleEditCard" v-if="!show_blame"></slot>
            <button v-if="show_info_button && !edit_info_card" class="btn-primary indigo" @click="toggleBlame"> History </button>
        </div>
    </div>
</template>

<script>
export default {
    props : {
        data: undefined,
        slots: {
            required: true
        },
        edit_info_card : {
            default: false,
            type: Boolean,
        },
        show_info_button : {
            default: true,
            type: Boolean,
        },
        show_blame: {
            default: false,
            type: Boolean
        }
    },
    methods: {
        toggleBlame () {
            this.$emit('showBlame')
        }
    },
}
</script>