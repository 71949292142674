import Graph from "./Graph";

export default class ConversationGraph extends Graph{
    constructor({id}, {characterIds, conversationName, runs}) {
        super({id});

        if(!Array.isArray(runs)) runs = []

        this.characterIds = characterIds;
        this.conversationName = conversationName
        this.runs = runs
    }

    removeRun(id){
        this.runs = this.runs.filter(runUuid => runUuid !== id)
    }

    getNodeConversationId(id){
        return this.getNode(id).conversationId
    }

    getAsJSON(){
        return JSON.stringify({
            conversationName: this.conversationName, 
            conversationId: this.id, 
            nodes: this.nodes, 
            characterIds: this.characterIds, 
            runs: this.runs
        })
    }
}