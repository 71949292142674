<template>
    <div class="flex flex-col gap-2 w-full justify-center items-center h-60">
        <h2 class="sm:text-lg text-sm"> Add a Platform For The {{ capitalizedAdvertType }}</h2> 
        <DropdownComponent 
            class="lg:max-w-lg sm:max-w-xs w-full"
            :selected="`Select a Platform`"
            :items="all_platforms"
            @user_select="addPlatformLimit"/>

        <div class="flex flex-col justify-center items-center w-full gap-4">
            <h2 class="sm:text-lg text-sm"> Current Platforms: </h2>
            <h2 class="sm:text-lg text-sm" v-if="advert_platforms.length === 0">
                All Platforms <span v-if="advert_type == 'run'">Selected On The Campaign </span> Will Be Applied To This {{ capitalizedAdvertType }} Unless You Specify Otherwise.
            </h2> 
            <div v-else class="flex flex-row gap-1 flex-wrap">
                <div v-for="platform in advert_platforms" :key="platform" class="text-white bg-indigo-500 rounded-md px-4 py-2 cursor-pointer hover:bg-blue-300 hover:text-black" @click="removePlatform(platform)">
                    {{platform}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        advert_uuid: {
            default: null,
            type: String,
        },
        advert_type: {
            default: 'campaign',
            type: String,
        }
    },
    data () {
        return {
            all_platforms: [],
            advert_platforms: [],
        }
    },
    watch: {
        advert_uuid: {
            handler: async function() {
                await this.getAllPlatforms()
                await this.getAdvertPlatforms()
            },
        }
    },
    methods: {
        async getAllPlatforms() {
            if(this.advert_type == 'campaign') {
                this.all_platforms = ['pc', 'mobile', 'console']
                return
            }

            this.$http.get(`all-run-platforms/${this.advert_uuid}`)
                .then( ({data}) => {
                    this.all_platforms = data
                })
        },
        async getAdvertPlatforms() {
            this.$http.get(`${this.advert_type}-platforms/${this.advert_uuid}`)
                .then ( ({data}) => {
                    this.advert_platforms = data
                    this.advert_platforms.forEach(platform => {
                        this.all_platforms = this.all_platforms.filter( el => el !== platform)
                    });
                })
        },
        addPlatformLimit(platform) {
            let request = {
                platform: platform,
                advert_uuid: this.advert_uuid,
            }

            const toasts = {'error': `Error Adding Platform`, 'success': 'Added Platform'}
            this.$http_request(this.$http.post, `${this.advert_type}-platform`, toasts, request)
                .then( ()=> {
                    this.advert_platforms.push(platform)
                    this.all_platforms = this.all_platforms.filter( el => el !== platform)
                })
                .catch((error)=>{
                    console.error(error.message)
                })
        },
        removePlatform(platform) {
            let request = {
                platform: platform,
                advert_uuid: this.advert_uuid,
            }

            const toasts = {'error': `Error Removing Platform`, 'success': 'Removed Platform', 'confirm': `Are You Sure You Want To Remove The Following Platform?`}
            this.$http_request(this.$http.post, `/${this.advert_type}-platform/delete`, toasts, request)
                .then( () => {
                    this.advert_platforms = this.advert_platforms.filter( el => el !== platform)
                    this.all_platforms.push(platform)
                })
                .catch((error)=>{
                    console.error(error)
                })
        },
    },
    computed: {
        capitalizedAdvertType() {
            return this.$capitalizeFirstLetter(this.advert_type)
        }
    },
    async created(){
        await this.getAllPlatforms()
        await this.getAdvertPlatforms()
    }
}
</script>

