<template>
    <div class="flex flex-col justify-center items-center gap-4 w-full">
        <div class="flex flex-row justify-center items-center gap-2">
            <input id="table-view" name="table-view" v-model="enable_frequency" @change="saveAndSubmit" type="checkbox" class="focus:ring-indigo-500 h-5 w-5 text-indigo-600 border-gray-300 rounded"/>
            <label for="table-view" class="ml-3 text-lg font-medium text-gray-700">Enable Frequency Cap</label>
        </div>

        <div>
            <div class="text-sm sm:text-lg text-center flex flex-row flex-wrap justify-center items-center gap-2">
                <!-- Full sentence will be: A USER SHOULD ONLY SEE THIS Ad Set X AMOUNT OF TIMES OVER Y MINUTES/HOURS -->
                A User Should Only See This Ad Set 
                <input type="number" v-model="frequency_cap" @change="saveAndSubmit" min="1" step="1" class="text-center sm:w-16 sm:text-lg text-sm w-12 rounded-md"> 
                Times Over 
                <input type="number" :disabled="disable_frequency_cap_expiry" v-show="!disable_frequency_cap_expiry" v-model="frequency_cap_expiry" @change="saveAndSubmit" min="1" step="1" class="text-center sm:w-16 sm:text-lg text-sm w-12 rounded-md"> 
                <DropdownComponent 
                :items='["Hours", "Days", "Ad Set Length (Days)"]'
                :selected="this.$capitalizeFirstLetter(run_info.frequency_time_unit)"
                @user_select="changeFrequencyTimeUnitAndSubmit"
                class="w-32 h-full"/>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    props: {
        run_info: {
            type: String,
            required: true,
        }
    },
    data(){
        return {
            disable_frequency_cap_expiry: false,
            frequency_cap: 1,
            frequency_cap_expiry: 1 ,
            frequency_time_unit: '',
            enable_frequency: false,
        }
    },
    methods: {

        getFrequencyForRun() {

            let expiryValue = 0;
            //Convert from Seconds in the Database to the unit the user has chosen.
            if (this.run_info.frequency_time_unit.toLowerCase() === "hours") {
                expiryValue = this.run_info.frequency_cap_expiry / 3600;  // Convert seconds to hours
            } else if (this.run_info.frequency_time_unit.toLowerCase() === "days") {
                expiryValue = this.run_info.frequency_cap_expiry / (3600 * 24);  // Convert seconds to days
            } else if (this.run_info.frequency_time_unit.toLowerCase() === "ad set length (days)") {
                expiryValue = this.run_info.frequency_cap_expiry / (3600 * 24);  // Convert seconds to days
            }

            this.enable_frequency = this.run_info.enable_frequency == 1 ? true : false
            this.frequency_cap_expiry = expiryValue
            this.frequency_time_unit = this.run_info.frequency_time_unit
            this.frequency_cap = this.run_info.frequency_cap

            //optionally disable the ability to choose the frequcny cap expiry if the user has chosen ad set length
            if(this.run_info.frequency_time_unit.toLowerCase() === "ad set length (days)") {
                this.disable_frequency_cap_expiry = true;
            } else {
                this.disable_frequency_cap_expiry = false;
            }

        },

        changeFrequencyTimeUnitAndSubmit(unit){
            this.frequency_time_unit = unit
            this.saveAndSubmit();
        },

        async saveAndSubmit() {
            // Values less than 0 are not allowed!!!! 
            if(this.enable_frequency && (this.frequency_cap <= 0 || this.frequency_cap_expiry <= 0)) {
                this.enable_frequency = false
                return this.$notify({type: "error", text: "Please Enter A Value Above 0 Or Turn Off Frequency Cap!"})
            }

            const request = {
                run_uuid: this.run_info.uuid,
                enable_frequency: this.enable_frequency,
                frequency_cap: this.frequency_cap,
                frequency_cap_expiry: this.frequency_cap_expiry,
                frequency_time_unit: this.frequency_time_unit
            }

            const toasts = {'success': 'Frequency Settings Changed', 'error': 'Error Changing Frequency Settings'}
            await this.$http_request(this.$http.post, '/run/update-frequency-info', toasts, request)
            this.$emit('refreshRun', this.run_info)
        },

    },

    created(){
        this.getFrequencyForRun()
    },

}
</script>

<style scoped>
</style>