import storeFunctions from '@/plugins/StorePlugin.js'
export default {
    install(Vue) {
        Vue.config.globalProperties.$removeLastLetter = function(string) {
            return storeFunctions.removeLastLetter(string)
        }

        Vue.config.globalProperties.$capitalizeFirstLetter = function(string) {
            return storeFunctions.capitalizeFirstLetter(string)
        }

        Vue.config.globalProperties.$dateTodayFormatted = function() {
            return storeFunctions.dateTodayFormatted()
        }

        Vue.config.globalProperties.$formatDate = function(date) {
            return storeFunctions.formatDate(date)
        }

        Vue.config.globalProperties.$http_request = function(http_method, url, toasts={'success': undefined, 'error': undefined, 'confirm': undefined}, data=undefined, header=undefined){
            return storeFunctions.http_request(http_method, url, toasts, data, header)
        }
    },
}

