<template>
    <div class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div class="max-w-md w-full space-y-8">
            <div class="grid place-items-center">
                <img class="h-24" src="../assets/admonitor-full-logo.png" alt="admonitor-logo" loading="lazy"/>
                <h2 class="text-center text-3xl font-extrabold text-gray-900">
                    Sign in to your account
                </h2>
                <p class="mt-2 text-center text-sm text-gray-600"> Or {{ ' ' }}
                    <a href="/sign-up" class="font-medium text-indigo-600 hover:text-indigo-500">
                        Sign Up Here!
                    </a>
                </p>
            </div>
        <div v-show="forgotPasswordClicked" class="mt-2">
            <input id="forgot-password-email" name="email" type="email" autocomplete="email" required v-model="forgotPasswordEmail"
                class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300
                    placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500
                    focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Enter your email to reset password" />
            <button class="mt-2 group relative w-full flex justify-center py-2 px-4 border border-transparent
                    text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none
                    focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click.prevent="resetPassword">
                Reset Password
            </button>
        </div>
            <form v-if="!forgotPasswordClicked" class="mt-8 space-y-6" v-on:submit.prevent="login">
                <input type="hidden" name="remember" value="true" />
                <div class="rounded-md shadow-sm -space-y-px">
                    <div>
                        <label for="email-address" class="sr-only">Email address</label>
                        <input id="email-address" name="email" type="email" autocomplete="email" v-model="user.email" required
                            class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300
                                placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500
                                focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email address" />
                    </div>
                    <div>
                        <label for="password" class="sr-only">Password</label>
                        <input id="password" name="password" type="password" autocomplete="current-password" required v-model="user.password"
                            class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 
                            text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
                            placeholder="Password" />
                    </div>
                </div>

                <div class="flex items-center justify-between">
                    <div class="flex items-center">
                        <input id="remember-me" name="remember-me" type="checkbox"
                            class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                        <label for="remember-me" class="ml-2 block text-sm text-gray-900"> Remember me </label>
                    </div>
                </div>

                <div v-show="!loading">
                    <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent
                            text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none
                            focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" v-on:submit.prevent="login">
                        <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                            <LockClosedIcon class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                        </span>
                        Sign in
                    </button>
                </div>

                <div class="group w-full relative flex justify-center" v-show="loading">
                    <LoadingComponent />
                </div>
            </form>
            <div class="text-center">
                <button class="text-indigo-600 hover:text-indigo-500" @click.prevent="forgotPasswordClicked=!forgotPasswordClicked">
                    {{ forgotPasswordButtonString }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { LockClosedIcon } from '@heroicons/vue/solid'
export default {
    name: 'LoginPage',
    data() {
        return {
            forgotPasswordClicked : false,
            forgotPasswordEmail: '',
            user: {
                email: '',
                password: '',
            },
            loading: false,
        }
    },

    components: {
        LockClosedIcon,
    },

    computed: {
        forgotPasswordButtonString(){
            if(this.forgotPasswordClicked) return 'Return to Login'
            else return 'Forgot Password?'
        }
    },

    methods: {
        async login() {
            this.loading = true
            var user_exists = await this.checkUserExists()

            if(user_exists) {
                this.$http_request(this.$http.get, '/refresh-csrf-token')
                    .then(() => {
                        this.$store.dispatch('login_module/login', this.user)
                            .then( async () => { 
                                this.loading = false 
                                this.$router.push(this.$route.query.redirect ? this.$route.query.redirect : '/') 
                            }).catch( () => { 
                                this.loading = false
                            })
                    })
            }
        },
        async checkUserExists() {
            return this.$store.dispatch('login_module/isUserVerified', this.user)
                .then( () => { 
                    return true 
                })
                .catch(() => {
                    this.loading = false 
                    this.user.email = this.user.password = ''
                    return false
                })
        },
        async isUserLoggedIn() {
            var user = await this.$store.dispatch('login_module/getUsernameFromSession');
            if(user) {
                this.$router.push('/')
            }
        },

        async resetPassword() {
            this.loading = true
            const toasts = {success: 'Sending new password to your email', error: 'Error sending new password to your email'} 
            await this.$http_request(this.$http.post, '/reset-password', toasts, { email: this.forgotPasswordEmail })
                .then(() => {
                    this.loading = false
                    this.$toast.success('New password sent to your email')
                    this.forgotPasswordClicked = false
                }).catch((error) => {
                    this.loading = false
                    this.$toast.error(error.response.data.message)
                })
        }
    },
    mounted() {
        this.isUserLoggedIn()
    }
}
</script>

