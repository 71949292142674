import Item from '@/classes/Item/Item.js'
import Creative from '@/classes/Item/Creative.js'
import Game from '@/classes/Item/Game.js'
import Placement from '@/classes/Item/Placement.js'
import Gif from '@/classes/Item/Gif.js'
import GifFrame from '@/classes/Item/GifFrame.js'

export default class ItemFactory extends Object{
    items = []
    constructor(item_type, item_params) {
        super()
        item_params = this.toArray(item_params)
        item_params.forEach((item_param) => {
            switch(item_type){
                case 'creative':
                case 'Creative':
                    this.items.push(new Creative(item_param))
                    break
                case 'game':
                case 'Game':
                    this.items.push(new Game(item_param))
                    break
                case 'Placement':
                case 'placement':
                    this.items.push(new Placement(item_param))
                    break
                case 'gif':
                case 'Gif':
                    this.items.push(new Gif(item_param)) 
                    break
                case 'gifFrame':
                case 'gif_frame':
                case 'GifFrame':
                case 'Gif_Frame':
                    this.items.push(new GifFrame(item_param))
                    break
                default:
                    this.items.push(new Item(item_param))
                    break 
            }
        })
    }  
    toArray(data){
        if(Array.isArray(data)) return data 
        else return [data]
    }
}