import storeFunctions from '@/plugins/StorePlugin.js'

const actions = {
    async uploadCreatives({ dispatch }, selected_files) {
        let form_data = new FormData()
        selected_files.forEach((element) => {
            form_data.append(element.dimensions, element, element.name) 
        })

        return await dispatch('file_upload_module/sendHttpRequest', {api_url: 'creatives', form_data: form_data}, {root: true})
        .then( async ()=> { 
            const params = {
                api_url: 'creatives',
                image_type: 'creatives',
            }
            await dispatch('fetchAllImages', params, { root: true })
            return
        })
        .catch((error) => { 
            return Promise.reject(error)
        })
    },
    deleteCreatives({ dispatch }, selected_creatives) {
        const toasts = {'success': 'Creative(s) Deleted', 'error': 'Error Deleting Creative(s)'}
        return storeFunctions.http_request(this.$http.post, 'creatives/delete', toasts, selected_creatives)
            .then(() => {
                const params = {
                    api_url: 'creatives',
                    image_type: 'creatives',
                }
                dispatch('fetchAllImages', params, { root: true })
            })
            .catch((error) => {
                return Promise.reject(error)
            })
    },
    updateCreativeName({ dispatch, commit}, request) {
        const toasts = {'success': 'Creative Name Updated', 'error': 'Error Updating Creative Name'}
        return storeFunctions.http_request(this.$http.post, '/creatives/update-creative-name', toasts, request)
            .then(() => {
                const params = {
                    api_url: 'creatives',
                    image_type: 'creatives',
                }
                dispatch('fetchAllImages', params, { root: true })
                
                const update_name_params = {
                    uuid: request.uuid,
                    user_defined_name: request.user_defined_name
                }

                commit('updateCreativeNameClientSide', update_name_params, {root:true})
            })
            .catch((error) => {
                return Promise.reject(error)
            })
    },
}

export default {
    namespaced: true,
    actions,
}
