<template>
    <div>
        <!-- SETTINGS TAB -->
        <div class="my-5 bg-white flex sm:flex-row sm:flex-wrap sm:gap-8 flex-col gap-2 rounded-md p-4 items-center justify-center xl:justify-start relative">

            <!-- Sort by newest/oldest -->
            <div class="flex flex-col items-center">
                <span class="font-medium">Sort By: </span>
                <DropdownComponent
                class="w-36"
                :selected="user_sort_option" 
                :items="sort_options" 
                @user_select="setSortOption"/>
            </div>

            <!-- get blame for day, week, month or all time -->
            <div class="flex flex-col items-center">
                <span class="font-medium">Timespan Settings: </span>
                <DropdownComponent
                class="w-36"
                :selected="user_timespan_option" 
                :items="timespan_options" 
                @user_select="setTimespanOption"/>
            </div>

            <!-- Sort by item t ype -->
            <div class="flex flex-col items-center">
                <span class="font-medium">Item Type:</span>
                <DropdownComponent
                class="w-36"
                :selected="user_item_option" 
                :items="item_options" 
                @user_select="setItemTypeOption"/>
            </div>

            <button class="btn-primary indigo" @click="getData"> Submit </button>

            <!-- Refresh and Download Buttons -->
            <div class="xl:absolute xl:right-0 flex flex-row gap-2 sm:mr-4">
                <div class="flex flex-col items-center hover:bg-gray-400 hover:text-white cursor-pointer w-20 rounded-md duration-200" @click="download">
                    <DownloadIcon class="w-10 h-9" />
                    <span class="text-sm">download</span>
                </div>
            </div>
        </div>

        <!-- IF GETTING DATA -->
        <LoadingComponent v-show="this.loading" />

        <!-- IF NO DATA AVAILABLE -->
        <div v-show="!this.table_data.length && !this.loading">
            Oops! Nothing To See Here!
        </div>

        <!-- IF DATA LOADED -->
        <div v-show="this.table_data.length && !this.loading">

            <!-- TABLE -->
            <div class="flex flex-col">
                <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-300">
                                <!-- Table Field -->
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6" v-for="field in table_fields" :key="field">
                                            {{field}}
                                        </th>
                                    </tr>
                                </thead>
                                <!-- Table Data -->
                                <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr v-for="blame in table_data" :key="blame">

                                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {{ blame['User'] }} 
                                        </td>

                                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {{ blame['Date & Time Modified'] }}
                                        </td>

                                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {{ blame['Modification'] }}
                                        </td>

                                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            <span 
                                            class="font-medium" 
                                            :class="blame['Item Name'] == 'Item Deleted!' ? 'text-red-400' : 'text-blue-500 hover:text-blue-700 underline underline-offset-2 cursor-pointer'"
                                            @click="routeToItem(blame)">
                                                {{ blame['Item Name'] }}
                                            </span>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <PaginationComponent :total_pages="total_pages" :current_page="current_page" @changePage="getData" />
        </div>
    </div>
</template>

<script>
import PaginationComponent from '@/components/PaginationComponent.vue'

import { DownloadIcon } from '@heroicons/vue/solid'

import * as XLSX from 'xlsx/xlsx.mjs';

export default {
    components: {
        DownloadIcon,
        PaginationComponent
    },
    data() {
        return {
            table_data: [],
            table_fields: ["User", "Date & Time Modified", "Modification", "Item Name"],
            timespan_options: ['Day', 'Week', 'Month', 'All'],
            sort_options: ['Oldest First', 'Newest First'],
            item_options: ['All', 'Campaign', 'Ad Set','Placement', 'Creative'],
            user_timespan_option: 'All',
            user_sort_option: 'Newest First',
            user_item_option: 'All',
            loading: true,
            current_page: 1,
            total_pages: 0,
            items_per_page: 20,
        }
    },
    methods: {
        async getData(page_back) {
            this.loading = true
            
            if(page_back == true)
                this.current_page--
            else if (page_back == false)
                this.current_page++
            // if page_back is undefined, we stay on the same page

            if(this.current_page <= 1)
                this.current_page = 1

            let item_option = this.user_item_option
            if(this.user_item_option == 'Ad Set')
                item_option = 'Run'

            await this.$http.get(`/change-history/${this.user_timespan_option}/${item_option}/${this.user_sort_option}/${this.items_per_page}?page=`+this.current_page)
                .then( (response) => {

                    this.table_data = response.data[0]
                    this.total_pages = response.data[1]
                })
                .catch( (error)=> {
                    console.error(error.message)
                })

            this.loading = false
        },
        routeToItem(blame_item) {
            // if item isn't deleted
            if(blame_item['ItemLink'])
                this.$router.push(blame_item['ItemLink'])
        },
        setSortOption(sort_option) {
            this.user_sort_option = sort_option
        },
        setTimespanOption(timespan_option) {
            this.user_timespan_option = timespan_option
        },
        setItemTypeOption(item_option) {
            this.user_item_option = item_option
        },
        download() {
            // save the file as filename + today's date
            let d = new Date();
            var datestring = d.getDate()  + "-" + (d.getMonth()+1) + "-" + d.getFullYear()

            // remove itemlink from table_data to not be displayed in the downloaded file.
            let download_data = this.table_data
            download_data.forEach(object => {

                if(object['ItemLink'])
                    delete object['ItemLink'];
            });

            const workbook = XLSX.utils.book_new();
            const sheet = XLSX.utils.json_to_sheet(download_data);
            XLSX.utils.book_append_sheet(workbook, sheet, 'Roblox Asset IDs');
            XLSX.writeFile(workbook, `AdMonitor Blame ${datestring}.xlsx`);
        },
    },
    async created() {
        await this.getData()
    }
};
</script>

<style>

</style>