import ItemProperty from '@/classes/Item/ItemProperty.js'

export default class Item extends Object {
    item_type = 'item'
    id = new ItemProperty({
        params: {
            name: 'id',
            friendly_name: 'ID',
        },
        default_type: 'not_displayed'
        })
    uuid = new ItemProperty({
        params: {
            name: 'uuid',
            friendly_name: 'UUID',
        },
        default_type: 'not_displayed'
    })
    img_url = new ItemProperty({
        params: {
            name: 'img_url',
            friendly_name: 'Image Url',
        },
        default_type: 'not_displayed'
    })
    displayed_properties = {}
    user_modifiable_properties = {}
    item_form = {}
    relations = []

    constructor(params){
        super()
        this.unpack(params)
    }
    unpack(params){
        params = this.unpackParams(params)
        return params
    }
    unpackParams(params){
        for(const [key, val] of Object.entries(params)){
            if(key in this){ 
                this[key].value = val
            }
        }
    }
    getDisplayedProperties(){
        for(const [key, val] of Object.entries(this)){
            if(this[key]?.display == true){
                this.displayed_properties[key] = val
            }
        }
        return this.displayed_properties
    }
    getUserModifiableProperties(){
        for(const [key, val] of Object.entries(this)){
            if(this[key]?.user_modifiable == true){
                this.user_modifiable_properties[key] = val
            }
        }
        return this.user_modifiable_properties
    }
    //this form will contain data that the user has inputed to change
    getItemForm(){
        const modifiable_properties = this.getUserModifiableProperties()
        for(const [key, val] of Object.entries(modifiable_properties)){
            
            if(val.input_component != undefined){
                this.item_form[key] = val.input_component.value
            }
            else if(val.html_input != undefined){
                this.item_form[key] = val.html_input.value
            }
        }
        return this.item_form
    }
}