<template>
    <div class="py-4">
        <DynamicForm 
            v-if="revisionHistory && iabRequirementsFormCreation"
            :formFields="iabRequirementsFormCreation" 
            :tableData="revisionHistory" 
            :defaultFormData="this.defaultFormData" 
            :formData="this.formData" 
            api="/update-iab-requirements"
            @setFormData="setFormData"
            :key="component_refresher"
        />
    </div>
</template>

<script>
import DynamicForm from '../components/DynamicForm.vue'

export default {
    name: "IabRequirements",
    
    components: {DynamicForm},

    props: {},

    data() {
        return {
            iabRequirementsFormCreation: [],
            formData: {},
            defaultFormData: {},
            revisionHistory: [],
            component_refresher: 0,
        }
    },

    computed: {},

    created() {},

    async mounted() {
        await this.fetchIabForm()
        await this.getRevisionHistory()
        this.setFormData()

    },

    methods: {
        async fetchIabForm() {
            const toasts = {success: 'Fetched IAB Form', error: 'Failed to fetch IAB Form'}
            return this.$http_request(this.$http.get, `/iab-requirments-form`, toasts)
                .then(({data}) => {
                    this.iabRequirementsFormCreation = data.data
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },

        setFormData(form_fields = null){
            if(!form_fields)
                form_fields = this.iabRequirementsFormCreation

            this.formData = form_fields.reduce((acc, curr) => {
                acc[curr.name] = curr.value;
                return acc;
            }, {});

            this.defaultFormData = JSON.parse(JSON.stringify(this.formData));
            this.component_refresher++
        },

        async getRevisionHistory(){
            const toasts = {success: 'Fetched revision history', error: 'Failed to fetch revision history'}
            return this.$http_request(this.$http.get, `/iab-requirements-get-revision-history`, toasts)
                .then(({data}) => {
                    this.revisionHistory = data.data
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },

    }
}
</script>

<style scoped>

</style>