<template>
    <div>
        <TransitionRoot as="template" :show="sidebarOpen">
            <Dialog as="div" class="fixed inset-0 flex z-40 lg:hidden" @close="sidebarOpen = false">

                <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0"
                    enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">

                    <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />

                </TransitionChild>

                <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full"
                    enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">

                    <div class="relative flex-1 flex flex-col max-w-xs w-full pb-4 bg-gray-800">

                        <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                            leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">

                            <div class="absolute top-0 right-0 -mr-12 pt-2">

                                <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 
                                focus:ring-inset focus:ring-white" @click="sidebarOpen = false">

                                    <span class="sr-only">Close sidebar</span>

                                    <XIcon class="h-6 w-6 text-white" aria-hidden="true" />

                                </button>

                            </div>

                        </TransitionChild>

                        <div class="flex-shrink-0 flex items-center justify-center px-4" :class="environmentClass">
                            <img v-if="environment == 'production'" class="h-20 w-64 pr-2 image-scale" src='../assets/admonitor-full-logo.png' alt="admonitor-logo" loading="lazy"/>
                            <span class="text-3xl font-extrabold p-4" v-else-if="environment == 'staging'"> STAGING </span>
                            <span class="text-3xl font-extrabold p-4" v-else-if="environment == 'local'"> LOCAL </span>
                        </div>

                        <div class="mt-5 flex-1 h-0 overflow-y-auto">
                            <nav class="px-2 space-y-1">
                                <SidebarNavigation />
                            </nav>
                        </div>

                    </div>

                </TransitionChild>
                <div class="flex-shrink-0 w-14" aria-hidden="true">
                    <!-- Dummy element to force sidebar to shrink to fit close icon -->
                </div>
            </Dialog>
        </TransitionRoot>

        <!-- Static sidebar for desktop -->
        <div class="hidden lg:flex lg:w-72 lg:flex-col lg:fixed lg:inset-y-0 break-all absolute left-0 z-50">
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex flex-col flex-grow border-r border-gray-200 bg-gray-800 overflow-y-auto pb-8">
                <div class="flex justify-center pt-2" :class="environmentClass">
                    <img v-if="environment == 'production'" class="h-20 w-64 pr-2 image-scale" src="../assets/admonitor-full-logo.png" alt="admonitor-logo" loading="lazy"/>
                    <span class="text-3xl font-extrabold p-4" v-else-if="environment == 'staging'"> STAGING </span>
                    <span class="text-3xl font-extrabold p-4" v-else-if="environment == 'local'"> LOCAL </span>
                </div>
                <div class="py-5 px-2 h-full">
                    <nav class="flex flex-col gap-1 justify-center" aria-label="Sidebar">
                        <SidebarNavigation />
                    </nav>
                </div>
            </div>
        </div>

        <div class="lg:pl-72 flex flex-col">
            <div class="lg:hidden sticky top-0 z-10 flex-shrink-0 flex">

                <button type="button" class="bg-gray-800 px-2 text-white focus:outline-none focus:ring-2
                        focus:ring-inset focus:ring-indigo-500 h-12" @click="sidebarOpen = true">
                        
                <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />

                </button>
            </div>

            <!-- MAIN PAGE LAYOUT -->
            <main class="flex-1">
                <div class="py-6">
                    <div class="w-11/12 mx-auto px-4 sm:px-6 lg:px-8">
                        <h1 class="text-2xl font-semibold text-gray-900">
                            {{ $route.name }}
                        </h1>
                    </div>
                    <div class="w-11/12 mx-auto px-4 sm:px-6 lg:px-8 mt-3">
                        <!-- Replace with your content -->
                        <router-view :key="$route.path">
                            
                        </router-view>
                        <!-- /End replace -->
                    </div>
                </div>
            </main>

        </div>
    </div>
</template>

<script>
import { ref } from 'vue'

import { Dialog, DialogOverlay, TransitionChild,
        TransitionRoot } from '@headlessui/vue'

import { MenuAlt2Icon, XIcon } from '@heroicons/vue/outline'

import SidebarNavigation from '../components/SidebarNavigation.vue'

export default {
    name: 'default-container',

    components: {
        Dialog,
        DialogOverlay,
        TransitionChild,
        TransitionRoot,
        SidebarNavigation,
        XIcon,
        MenuAlt2Icon
    },
    data() {
        return {
            environment: 'production'
        }
    },
    created() {
        this.$http.get('/environment')
            .then( ({data}) => {
                this.environment = data
            })
    },
    computed: {
        environmentClass() {
            switch (this.environment) {
                case 'production':
                    return 'bg-gray-900';
                case 'staging':
                    return 'bg-blue-300';
                default:
                    return 'bg-yellow-300';
            }
        },
    },
    setup() {
        const sidebarOpen = ref(false)

        return {
            sidebarOpen,
        }
    },
    
}
</script>

<style scoped>
::-webkit-scrollbar {
    background: rgb(58, 58, 58);
    width: 0.5rem;
}

::-webkit-scrollbar-thumb {
    background: rgb(126, 126, 126);
    background-clip: padding-box;
    border-radius: 7px;
    width: 0.25rem;
}

.image-scale {
    scale: 0.85;
}

</style>

<style lang="postcss">
    .checked-card {
        cursor: pointer;
        color: white;
        background-image: linear-gradient(45deg, #49d5ff, #2acdff, #04c2fc, #49a7ff, #4989ff, #4983ff, #4964ff, #8077ff, #8f96ff, #b397ff);
        background-size: 400%;
        -webkit-animation: Animation 15s ease-in-out infinite alternate;
        -moz-animation: Animation 15s ease-in-out infinite alternate;
        animation: Animation 15s ease-in-out infinite alternate;
    }
    .checked-card:hover {
        background-image: linear-gradient(45deg, #31b3ff,#85e3ff, #1bcaff, #76dfff);
    }

    .disabled-card {
        color: white;
        cursor:pointer;
        background-image: linear-gradient(45deg, #3f3f3f, #3d3d3d,#555555,#5e5e5e,#747474, #adadad, #a7a7a7);
        background-size: 300%;
        -webkit-animation: Animation 15s ease-in-out infinite alternate;
        -moz-animation: Animation 15s ease-in-out infinite alternate;
        animation: Animation 15s ease-in-out infinite alternate;
    }

    .disabled-card:hover {
        color: white;
        background-image: linear-gradient(45deg, #5e5e5e, #7a7a7a,#777777,#666666);
    }
    
    @-webkit-keyframes Animation {
        0%{background-position:10% 0%}
        50%{background-position:91% 100%}
        100%{background-position:10% 0%}
    }
    @-moz-keyframes Animation {
        0%{background-position:10% 0%}
        50%{background-position:91% 100%}
        100%{background-position:10% 0%}
    }
    @keyframes Animation { 
        0%{background-position:10% 0%}
        50%{background-position:91% 100%}
        100%{background-position:10% 0%}
    }

    /* TAILWIND */
    .options-picker {
        @apply text-sm sm:text-base border border-gray-600 inline-block w-32 sm:w-1/2 p-2 bg-white hover:bg-blue-300 hover:cursor-pointer duration-300 text-center;
    }
    .options-picker:not(:first-child) {
        @apply border-l-0;
    }

    .options-picker-active {
        @apply text-white bg-indigo-500 hover:text-black;
    }

    .btn-primary {
        word-break: break-word;
        @apply inline-block justify-center py-2 px-4 border border-transparent duration-150 shadow-sm sm:text-sm text-xs font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2;
    }
    .green {
        @apply bg-green-500 hover:bg-green-700 focus:ring-green-500
    }
    .red {
        @apply bg-red-500 hover:bg-red-700 focus:ring-red-500
    }
    .indigo {
        @apply bg-indigo-500 hover:bg-indigo-700 focus:ring-indigo-500
    }
    .yellow {
        @apply bg-yellow-500 hover:bg-yellow-700 focus:ring-yellow-500
    }
    .gray {
        @apply bg-gray-700 hover:bg-gray-800 focus:ring-gray-500
    }
    .light-blue {
        @apply bg-blue-400 hover:bg-blue-500 focus:ring-blue-500
    }
    .light-gray {
        @apply bg-gray-400 hover:bg-gray-500 focus:ring-gray-500 border-gray-400
    }
    .lighter-gray {
        @apply bg-gray-200 hover:bg-gray-300 focus:ring-gray-300 border-gray-300
    }
    .card-dimensions {
        @apply /* 2xl:h-96 2xl:w-64 sm:h-72 sm:w-56 */ md:h-72 md:w-56 h-48 w-36
    }
    .card-settings {
        @apply col-span-1 flex flex-col md:gap-2 text-center bg-white rounded-lg shadow divide-y divide-gray-200 p-2 md:p-8 justify-center items-center
    }
    .cogicon-dimensions {
        @apply w-8 h-8 md:w-12 md:h-12 p-1 mt-1 lighter-gray duration-150 rounded-md text-black
    }
</style>