<template>
    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 pt-5">
        <label for="test-user" class="block text-md font-medium text-gray-700 sm:mt-px pt-2">
            Add a Test User
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-2 max-w-lg sm:max-w-xs">
            <DropdownComponent
                selected="Select a Test User"  
                :items="dropdown_test_users" 
                @user_select="addTestUser"
                display="name"/>
        </div>
        <label for="test-user" class="block text-md font-medium text-gray-700 sm:mt-px pt-2">
            Current Test Users
        </label>
        <h2 v-if="this.selected_test_users.length == 0"> None </h2> 
        <div v-else class="flex flex-row gap-1 flex-wrap"> 
            <div v-for="test_user in selected_test_users" :key="test_user" class="flex flex-row gap-2 justify-center items-center bg-gray-300 rounded-md p-2">
                <h4 class="text-lg"> {{getUsernameFromUuid(test_user)}} </h4>
                <button class="btn-primary red text-lg" @click="removeSelectedTestUser(test_user)"> x </button>
            </div>
        </div> 
    </div>
</template>

<script>
export default {
    props: {
        placement_uuid: {
            default: null,
            required: true,
            type: String,
        }
    },
    data () {
        return {
            all_test_users: [],
            dropdown_test_users: [],
            selected_test_users: [],
        }
    },
    methods: {
        async getAllPossibleTestUsers() {
            return this.$http_request(this.$http.get, 'test-users/all')
                .then(({data}) => {
                    this.all_test_users = data
                    this.refreshDropDownTestUsers();
                })
        },
        async getCurrentTestUsers(){
            return this.$http_request(this.$http.get, `placements/test-users/${this.placement_uuid}`)
                .then(({data}) => {
                    this.selected_test_users = data
                })
        },
        addTestUser(selected_user) {
            this.selected_test_users.push(selected_user.uuid)
            this.$emit('added-test-user', selected_user.uuid)
            this.refreshDropDownTestUsers();
        },
        removeSelectedTestUser(test_user_to_remove) {
            var index = this.selected_test_users.indexOf(test_user_to_remove);
            if (index != -1) this.selected_test_users.splice(index, 1);
            this.$emit('removed-test-user', test_user_to_remove)

            this.refreshDropDownTestUsers();
        },
        refreshDropDownTestUsers() {
            this.dropdown_test_users = this.all_test_users.filter(x => !this.selected_test_users.includes(x.uuid))
        },
        getUsernameFromUuid(uuid){
            for(const test_user of this.all_test_users){
                if(test_user.uuid == uuid) return test_user.name
            }
        }
    },
    async created(){
        await this.getAllPossibleTestUsers()
        await this.getCurrentTestUsers()
        this.refreshDropDownTestUsers()
    }
}
</script>

<style lang="scss" scoped>

.test-user-dropdown{
    width: 400px;
    height: 30px;
    text-align: center;
    border: 2px solid black;
    border-radius: 5px;
    margin: auto;
    margin-top: 6px;
    margin-bottom: 6px;
    transition: 0.2s;
}
.test-user-dropdown:focus {
    border: 2px solid blue;
}
.selected-test-users{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 0.75em;
}
.container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    gap: 0.5em;
    background-color: blue;
    border-radius: 5px;
    padding: 0.75em;
}
.test-user-name{
    color: white;
    margin: 0;
    padding: 0;
}
.x-button {
    font-size: 24px;
    color: yellow;
    cursor: pointer;
    transition: 0.2s;
}
.x-button:hover {
    color: red;
}
</style>