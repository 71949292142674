<template>
    <CardComponent 
    @click="cardSelected"
    :src="img_src" 
    :title="user_defined_name+img_type" 
    :hover_effects="this.checked || !on_placement || !on_run ? false : true"
    :class="{
    'checked-card': this.checked, 
    'disabled-card': ((!on_placement && !this.checked) || (!on_run && !this.checked)) }"> 
        <template v-slot:thirdRow>
            <a :href="`/manage-item/creative/${this.uuid}`">
                <CogIcon class="cogicon-dimensions"/>
            </a>
        </template>
    </CardComponent>
</template>

<script>
import { CogIcon } from '@heroicons/vue/outline'
import CardComponent from '@/components/UtilityComponents/CardComponent.vue'

export default {
    components: {
        CardComponent,
        CogIcon,
    },
    props: {
        img_src: undefined,
        uuid: {
            default: 'default',
            type: String,
        },
        img_type:{
            default: 'default',
            type: String,
        },
        img_dimensions: {
            default: 'default',
            type: String,
        },
        img_position: {
            default: 0,
            type: Number,
        },
        under_moderation: {
            default: 1,
            type: Number,
        },
        user_defined_name: {
            default: 'default',
            type: String,
        },
        on_placement: {
            default: true,
            type: Boolean,
        },
        on_run: {
            default:true,
            type: Boolean,
        }
    },
    methods: {
        cardSelected() {
            const image = {
                uuid: this.uuid,
                checked: this.checked,
                img_position: this.img_position,
            }
            this.$emit('selected', image)
        },
    },
    computed: {
        checked() {
            return this.$store.getters['picker_module/getCheckedStatus']({
                item_uuid: this.uuid,
                item_type: 'creatives',
            })
        },
    },
}
</script>
