<template>
    <div>
        <div v-for="item in navigation" :key="item.name">
            <div v-if="!item.children">
                <router-link :to="item.href" :class="[$route.fullPath === item.href ? 'item-current' : 'not-item-current', 'group grouped-classes']">
                    <component :is="item.icon" :class="[$route.fullPath === item.href ? 'text-gray-800' : 'inactive-span', 'component-settings']" aria-hidden="true" />
                    {{ item.name }}
                </router-link>
            </div>

            <!-- IF MAIN ITEM HAS SUB CHILDREN -->
            <Disclosure as="div" v-else v-slot="{ open }">

                <!-- SHOW MAIN ITEM NAME AND ICON -->
                <DisclosureButton :class="[item.href == $route.fullPath || hasActiveSubChild(item) ? 'item-current' : 'not-item-current', 'group grouped-classes closure-button']">
                    <component :is="item.icon" :class="[item.href == $route.fullPath || hasActiveSubChild(item) ? 'text-gray-800' : 'inactive-span', 'component-settings']" aria-hidden="true" />

                    <!-- if the main item name has an href, make it navigatable  -->
                    <router-link v-if="item.href" :to="item.href" :class="[item.href == $route.fullPath || hasActiveSubChild(item) ? 'item-current' : 'not-item-current', 'group grouped-classes h-8 truncate']">
                        <span class="flex-1 truncate">
                            {{ item.name }}
                        </span>
                    </router-link>
                    
                    <!-- else just show the name -->
                    <span class="flex-1" v-else>
                        {{ item.name }}
                    </span>

                    <svg :class="[open ? 'text-gray-300 rotate-90' : 'text-white', 'arrow-class']" viewBox="0 0 20 20" aria-hidden="true">
                        <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                    </svg>

                </DisclosureButton>

                <!-- SHOW CHILDREN -->
                <DisclosurePanel class="mt-1">
                    <div v-for="subItem in item.children" :key="subItem.name">

                        <router-link v-if="!subItem.subChildren" :to="subItem.href" :class="[subItem.href == $route.fullPath ? 'item-current' : 'not-item-current', 'group grouped-classes h-10']">
                            <span :class="[subItem.href == $route.fullPath ? 'text-white' : 'inactive-span', 'component-settings']" aria-hidden="true" />
                            {{ subItem.name }}
                        </router-link>

                    </div>
                </DisclosurePanel>

            </Disclosure>
        </div>
        <!-- desktop logout button -->
        <button class="mb-4 w-full text-sm text-gray-300 hover:bg-gray-50 hover:text-gray-800 group flex items-center px-2 py-2 font-medium rounded-md h-12" @click="logout()">
            <LogoutIcon class="component-settings"/>
            Log Out
        </button>
    </div>
</template>

<script>
import {  Disclosure, DisclosureButton,  DisclosurePanel } from '@headlessui/vue'
import { HomeIcon, UserAddIcon, TicketIcon, ReceiptTaxIcon,
        PuzzleIcon, PlayIcon, PhotographIcon, DocumentSearchIcon, LogoutIcon, SpeakerphoneIcon,
        ClipboardIcon, ArchiveIcon, BookOpenIcon, TerminalIcon, UserIcon } from '@heroicons/vue/outline'
        
export default {
    name: "SidebarNavigation",
    components: {
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        HomeIcon,
        UserAddIcon,
        TicketIcon,
        ReceiptTaxIcon,
        PuzzleIcon,
        PlayIcon,
        PhotographIcon, 
        DocumentSearchIcon,
        ArchiveIcon,
        ClipboardIcon,
        SpeakerphoneIcon,
        TerminalIcon,
        LogoutIcon,
        BookOpenIcon,
        UserIcon,
    },
    props: {},
    data() {
        return {
            refresh_counter: 0,
            campaigns: [],
            navigation: [],
        }
    },
    computed: {},
    watch: {},
    mounted() {
        this.emitter.on('refreshCampaignNavbar', async () => {
            await this.getSidebarCampaigns()
            this.buildCampaignNodesForSidebar()
        })
    },
    async created(){
        await this.getAllSidebarPages()
        await this.getSidebarCampaigns()
        this.buildCampaignNodesForSidebar()
    },
    methods: {
        async logout() {
            await this.$store.dispatch('resetState')
            await this.$store.dispatch(['login_module/logout'])
            await this.$http.get('/logout')
            this.$router.push('/login')
        },
        async getSidebarCampaigns() {
            await this.$http.get('/campaigns/get/sidebar').then( ({data}) => {
                this.campaigns = data
            })
        },

        async buildCampaignNodesForSidebar() {
            // Find the nav item for manage campaigns
            var manage_campaign_sidebar = this.navigation.find(obj => obj.name === 'Campaigns');
            
            // Update the children only if the 'Campaigns' object exists in the navigation
            if (manage_campaign_sidebar) {

                // Preserve existing children if they exist
                const existingChildren = manage_campaign_sidebar.children || [];

                // Update the children with existing ones first, followed by this.campaigns
                manage_campaign_sidebar.children = [...existingChildren, ...this.campaigns];
            }
        },

        async getAllSidebarPages() {
            this.$http.get('/pages/sidebar').then( ({data}) => {
                this.navigation = data
            })
        },
        hasActiveSubChild(parentItem) {
            if (!parentItem) return false;

            if (parentItem.href === this.$route.fullPath) return true;

            const subChildren = parentItem.subChildren || parentItem.children;
            if (subChildren) {
                for (const child of subChildren) {
                    if (child && child.href && this.$route.fullPath === child.href) {
                        return true;
                    } else if (child && child.subChildren && this.hasActiveSubChild(child)) {
                        return true;
                    }
                }
            }

            return false;
        },
    },
}
</script>

<style lang="postcss" scoped>
.item-current {
    @apply bg-gray-100 text-gray-800 
}
.not-item-current {
    @apply bg-gray-800 text-gray-100 hover:bg-gray-50 hover:text-gray-800
}
.grouped-classes {
    @apply p-2 h-12 w-full flex items-center text-sm font-medium rounded-md
}
.arrow-class {
    @apply ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-200 transition-colors ease-in-out duration-150
}
.inactive-span {
    @apply text-gray-200 group-hover:text-gray-500
}
.component-settings {
    @apply mr-2 flex-shrink-0 h-6 w-4
}
.closure-button {
    @apply text-left focus:outline-none focus:ring-2 focus:ring-indigo-500
}
</style>