<template>
    <div class="py-4">
        <DynamicForm 
            v-if="revisionHistory && dynamicParametersFormCreation"
            :formFields="dynamicParametersFormCreation" 
            :tableData="revisionHistory" 
            :defaultFormData="defaultFormData" 
            :formData="formData" 
            api="/update-dynamic-parameters"
            @setFormData="setFormData"
            :key="component_refresher"
        />
    </div>
</template>

<script>
import DynamicForm from '@/components/DynamicForm.vue'

export default {
    name: "DynamicParameters",
    
    components: {DynamicForm},

    data() {
        return {
            dynamicParametersFormCreation: [],
            formData: {},
            defaultFormData: {},
            revisionHistory: [],
            component_refresher: 0,
        }
    },

    async mounted() {
        await this.fetchDynamicParametersForm()
        await this.getRevisionHistory()
        this.setFormData()
    },

    methods: {
        async fetchDynamicParametersForm() {
            const toasts = {success: 'Fetched Dynamic Parameters Form', error: 'Failed to fetch Dynamic Parameters Form'}
            return this.$http_request(this.$http.get, `/dynamic-parameters-form`, toasts)
                .then(({data}) => {
                    this.dynamicParametersFormCreation = data.data
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },

        setFormData(form_fields = null){
            if(!form_fields)
                form_fields = this.dynamicParametersFormCreation

            this.formData = form_fields.reduce((acc, curr) => {
                acc[curr.name] = curr.value;
                return acc;
            }, {});

            this.defaultFormData = JSON.parse(JSON.stringify(this.formData));
            this.component_refresher++
        },

        async getRevisionHistory(){
            const toasts = {success: 'Fetched revision history', error: 'Failed to fetch revision history'}
            return this.$http_request(this.$http.get, `/dynamic-parameters-revision-history`, toasts)
                .then(({data}) => {
                    this.revisionHistory = data.data
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },
    }
}
</script>

<style scoped>

</style>
