<template>
    <div class="flex flex-col gap-6 bg-white rounded-md items-center justify-center p-4">
        <button class="btn-primary light-blue" @click="getRunImpressionsForecast"> Forecast Impressions </button>
        <!-- Data -->
        <ul class="sm:grid sm:grid-cols-2 flex flex-wrap gap-1" v-if="forecast_data.length > 0">
            <li class="flex text-gray-700 mb-2" v-for="forecast_item in forecast_data" :key="forecast_item.label"> 
                <span class=""> {{ forecast_item.label }}:</span> <span class="font-medium text-black">&nbsp;{{ forecast_item.value }} </span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "AdvertForecasting",
    components: {},
    props: {
        advert: {
            type: Object,
            required: true,
        },
        advert_type: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            forecast_data: [],
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        getRunImpressionsForecast(){
            const toasts = {'success': 'Forecasted Successfully', 'error': 'Sorry there was an error forecasting'}    
            const request = {
                uuid : this.advert.uuid,
            }   
            this.$http_request(this.$http.post, `/forecasting/${this.advert_type}-forecast`, toasts, request)
                .then((response) => {
                    this.forecast_data = response.data
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }
}
</script>

<style scoped>

</style>