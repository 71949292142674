export default class GraphNode {
    constructor({id, metadata}) {
        this.id = id;
        if(metadata) this.metadata = metadata;
        this.edges = [];
    }

    getId(){
        return this.id
    }

    addEdge(edge) {
        this.edges.push(edge)
    }

    removeEdgesTo(id){
        this.edges = this.edges.filter(edge => edge.getDestination() !== id)
    }

    removeEdge(id){
        this.edges = this.edges.filter(edge => edge.getId() !== id)
    }

    getEdges(){
        return this.edges
    }
}