<template>
    <div class="border-b border-gray-300 pb-2"> 
        <h2 class="text-lg font-medium mb-3"> Change Password </h2>
        <form class="flex flex-col gap-4" autocomplete="off" v-on:submit.prevent="updatePassword">
            <div class="flex flex-col gap-1">
                <label for="email" class="label-format">Your email</label>
                <input type="email" v-model="email" name="email" id="email" class="input-format" autocomplete="off" placeholder="name@company.com" required>
            </div>
            <div class="flex flex-col gap-1">
                <label for="password" class="label-format">Old Password</label>
                <input type="password" v-model="password" name="password" id="password" placeholder="••••••••" autocomplete="off" class="input-format" required>
            </div>
            <div class="flex flex-col gap-1">
                <label for="new-password" class="label-format">New Password</label>
                <input type="password" v-model="new_password" name="new-password" id="new-password" placeholder="••••••••" autocomplete="off" class="input-format" required>
            </div>
            <div class="flex flex-col gap-1">
                <label for="confirm-password" class="label-format">Confirm password</label>
                <input type="password" v-model="confirm_password" name="confirm-password" id="confirm-password" placeholder="••••••••" autocomplete="off" class="input-format" required>
            </div>
            <button class="btn-primary green self-start" v-on:submit.prevent="updatePassword"> Change Password </button>
        </form>
    </div>
</template>

<script>
export default {
    name: "ChangePassword",
    components: {},
    props: {},
    data() {
        return {
            email: '',
            password: '',
            new_password: '',
            confirm_password: '',
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        async updatePassword() {
            const request = {
                email: this.email,
                password: this.password,
                new_password: this.new_password,
                confirm_password: this.confirm_password
            }

            const toasts = {'success': 'Successfully Updated Password', 'error': 'Error Updating Password'}
            await this.$http_request(this.$http.post, '/user/update-password', toasts, request)
                .catch( () => {
                    this.$notify({type: 'error', text: 'Error! Please Make Sure All The Details Are Correct!'});
                })
            this.resetAllFields()
        },
        resetAllFields() {
            this.email = ''
            this.password = ''
            this.new_password = ''
            this.confirm_password = ''
        }
    }
}
</script>

<style scoped lang="postcss">
.label-format {
    @apply block mb-2 font-medium
}

.input-format {
    @apply max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md
}
</style>