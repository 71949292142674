<template>
  <div>
    <div class="flex sm:flex-row sm:gap-12 sm:flex-wrap flex-col gap-4 mt-8 mb-4 sm:items-center justify-center bg-white rounded-md p-2">

      <div class="inner-flex-container">
        <label for="sort-component" class="text-sm font-medium text-gray-700 sm:text-lg">Sort By</label>
        <DropdownComponent
          class="text-sm sm:text-lg"
          style="width: 180px !important"
          id="sort-component"
          selected="Newest First" 
          :items="['Newest First', 'Oldest First']" 
          @user_select="setSortCampaigns"/>
      </div>

      <div class="inner-flex-container">
        <input id="table-view" v-model="show_expired" name="table-view" type="checkbox" class="focus:ring-indigo-500 h-5 w-5 text-indigo-600 border-gray-300 rounded" />
        <label for="expired-component" class="text-sm font-medium text-gray-700 sm:text-lg">Show Expired</label>
      </div>

      <button class="btn-primary indigo" @click="getCampaigns"> Submit </button>

      <div class="inner-flex-container">
        <input id="table-view" v-model="table_view" name="table-view" type="checkbox" class="focus:ring-indigo-500 h-5 w-5 text-indigo-600 border-gray-300 rounded" />
        <label for="table-view" class="text-sm font-medium text-gray-700 sm:text-lg">Table View</label>
      </div>

      <button class="btn-primary green" @click="this.$router.push('/create-item/campaign')"> Create a Campaign </button>

    </div>

    <ul role="list" class="flex flex-wrap gap-4 justify-center" v-if="campaigns.length && !table_view">
      <li v-for="campaign in campaigns" :key="campaign.uuid">
        <CardComponent v-if="campaign.img_url" :src="campaign.img_url" :title="campaign.name" :hover_effects="true" @click="routetoCampaign(campaign)" class="sm:h-80 sm:w-64">
          <template v-slot:thirdRow>
            <dl class="flex flex-1 flex-col w-full">
              <ProgressBar title="Time To Completion" :percentage="getCampaignTimePercentage(campaign)" />
            </dl>
          </template>
        </CardComponent>
      </li>
    </ul>

    <div class="mt-8 flex flex-col" v-if="table_view">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 uppercase" v-for="field in campaignsTable.fields" :key="field">
                      {{field}}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="item in campaignsTable.items" :key="item.id">

                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-lg font-medium text-gray-900 sm:pl-6">
                    <span @click="routetoCampaign(item)" class="text-blue-500 underline underline-offset-2 font-medium cursor-pointer hover:text-blue-700">
                        {{ item.name }} 
                    </span>
                  </td>

                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {{ item.start_date.split('T')[0] }}
                  </td>

                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {{ item.end_date.split('T')[0] }}
                  </td>

                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {{ item.impressions_sent.toLocaleString() }}
                  </td>

                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

		<PaginationComponent :total_pages="total_pages" :current_page="current_page" @changePage="getCampaigns" />

  </div>
</template>

<style lang="postcss" scoped>
.inner-flex-container {
  @apply flex flex-row sm:justify-center items-center gap-2 sm:pl-0 pl-2
}
</style>

<script>

import CardComponent from '@/components/UtilityComponents/CardComponent.vue'
import ProgressBar from '@/components/UtilityComponents/ProgressBar.vue'
import PaginationComponent from '../../components/PaginationComponent.vue'
export default {
  components: {
    CardComponent,
    ProgressBar,
    PaginationComponent,
  },
  data() {
      return {
          user_timezone: null,
          all_data: [],
          campaigns: [],
          sort_by: 'Newest First',
          table_view: false,
          follow_roblox_policy: false,
          show_expired: true,
          table_fields: ['Name','Start Date','End Date','Impressions Sent'],
					current_page: 1,
					total_pages: 0,
					items_per_page: 10,
      }
  },
  methods: {
      setSortCampaigns(sort_by) {
        this.sort_by = sort_by
      },
      async getCampaigns(page_back) 
      {
        if(page_back == true)
            this.current_page--
        else if (page_back == false)
            this.current_page++
        // if page_back is undefined, we stay on the same page

        if(this.current_page <= 1)
            this.current_page = 1

          return await this.$http_request(this.$http.get, `/campaigns/get/all/meta/${this.items_per_page}?page=${this.current_page}&show_expired=${this.show_expired}&sort_by=${this.sort_by}`)
              .then(({ data }) => {
								this.total_pages = data.last_page
                /* if the campaign doesnt have an image, give it the "no creative image" */
                data.data.forEach(campaign => {
                  if(!campaign['img_url']) {
                    campaign['img_url'] = require('../../assets/no_creatives_associated.png')
                  }
                });
                
                this.campaigns = this.all_data = data.data
              })
              .catch((error) => {
                  console.error(error.message)
              })
      },
      routetoCampaign(campaign) {
        this.$router.push('/manage/campaigns/'+campaign.uuid)
      },
      getFollowRobloxPolicy() {
        this.$http.get('/get-roblox-policy')
          .then( ({data}) => {
            this.follow_roblox_policy = data
          })
      },
      getCampaignTimePercentage(campaign) {
            if(!campaign)
                return 0

            return this.$advertDateGapPercentage(campaign, "campaigns", this.user_timezone)
        },
  },
  computed: {
      campaignsTable(){
          return {
              items: this.campaigns,
              fields: this.table_fields,
          }
      }
  },
  async mounted() {
    this.user_timezone = await this.$store.dispatch('login_module/getUserTimezone')
    await this.getCampaigns()
    this.getFollowRobloxPolicy()
  },
}
</script>
