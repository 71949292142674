import ErrorInterceptor from '@/classes/ErrorInterceptor.js'

async function responseInterceptor(response){
  return response
}

async function errorInterceptor(error) {
    const error_interceptor = new ErrorInterceptor(error)
    return error_interceptor.handleError()
}

export default {
  errorInterceptor,
  responseInterceptor
}