<template>
    <div class="bg-white overflow-hidden shadow rounded-lg">
        <form class="space-y-6 divide-y divide-gray-200 p-5" v-on:submit.prevent="uploadPlacementData">
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-tsm:border-gray-200 sm:pt-5">
                <label for="placement-name" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Placement Name *
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <input type="text" name="placement-name" id="placement-name" autocomplete="off"
                        v-model="user_defined_name" maxlength="50" required
                        class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500
                            sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="placement-uuid" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                    In-Experience Placement UUID * 
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <input type="text" name="placement-uuid" id="placement-uuid" v-model="uuid" 
                        required autocomplete="off"
                        class="max-w-lg sm:max-w-xs block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500
                            sm:text-sm border-gray-300 rounded-md" />
                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="game" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Choose The Corresponding Game *
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2 max-w-lg sm:max-w-xs">
                    <DropdownComponent 
                        selected="Select a Game" 
                        :items="admonitor_games" 
                        display="name"
                        @user_select="setGame" />
                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="cover-photo" class=" block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Placement Image *
                </label>
                <UploadFile class="w-full"/>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="game" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Select a Test Creative
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2 max-w-lg sm:max-w-xs">
                    <DropdownComponent
                        selected="Select a Test Creative"  
                        :items="all_creatives" 
                        @user_select="setTestCreative"
                        display="user_defined_name"/>
                </div>
            </div>
            <TestUserSelector @added-test-user="addTestUser" @removed-test-user="removeTestUser"></TestUserSelector>
            <div class="pt-5">
                <div class="flex justify-end" v-show="!loading">
                    <button v-on:submit="uploadPlacementData"
                        class="btn-primary indigo">
                        Create Placement
                    </button>
                </div>
                <LoadingComponent v-show="loading" />
            </div>
        </form>
        <div v-if="placement_exists" class="text-red-400 p-1 rounded-sm text-lg flex flex-col items-center gap-4">
            <label>This Placement UUID Already Exists</label>
            <label> <span class="font-medium">Name: </span> {{existing_placement.name}}</label>
            <label> <span class="font-medium">Game Name: </span> {{existing_placement.game.name}}</label>
            <label> <span class="font-medium">Place ID: </span> {{existing_placement.place_id}}</label>
            <button @click="goToManagePlacement(existing_placement.uuid)" class="btn-primary green text-lg"> Go To Placement</button>
        </div>
    </div>
</template>

<script>
import TestUserSelector from '@/components/TestUserSelector.vue'

export default {
    components: {
        TestUserSelector,
    },
    data() {
        return {
            admonitor_games: [],
            selected_game: null,
            user_defined_name: null,
            uuid: null,
            placement_exists: false,
            form_errors: [],
            existing_placement: {},
            test_users: {},
            all_creatives: [],
            selected_creative: {},
        }
    },
    methods: {
        //this function should be in vuex
        async getAdMonitorGames() {
            await this.$http_request(this.$http.get, '/games')
                .then((response) => { 
                    this.admonitor_games =  response.data 
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },
        setGame(game) {
            this.selected_game = game.place_id
        },
        setTestCreative(creative) {
            this.selected_creative = creative
        },
        async getAllCreatives(){
            return this.$http_request(this.$http.get, 'creatives')
                .then(({data}) => { this.all_creatives = data })
        },
        uploadPlacementData() {
            if(!this.isValidForm()) return
            this.placement_exists = false
            const form_data = new FormData()
            form_data.append('image', this.stagedFile.file_object)
            form_data.append('place_id', this.selected_game)
            form_data.append('user_defined_name', this.user_defined_name)
            form_data.append('uuid', this.uuid)

            const test_users_array = this.convertTestUsersObjectToArray()
            form_data.append('test_user_uuids', test_users_array)

            if(this.selected_creative.uuid == undefined) this.selected_creative.uuid = null
            form_data.append('test_creative_uuid', this.selected_creative.uuid)

            this.$store.dispatch('file_upload_module/sendHttpRequest', {api_url: 'placements', form_data: form_data})
                    .then( ()=> { 
                        this.resetForm() 
                    })
                    .catch((error) => {
                        if(error.response.data.message == 'Placement Already Exists'){
                            alert('PLACEMENT ALREADY EXISTS!')
                            this.placement_exists = true
                            this.existing_placement = error.response.data.placement
                        }else console.error(error.message)
                    })
        },
        goToManagePlacement(uuid) {
            this.$router.push(`/manage-item/placement/${uuid}`)
        },
        convertTestUsersObjectToArray(){
            return Object.keys(this.test_users).map((key) => {
                return this.test_users[key]
            })
        },
        isValidForm(){
            this.form_errors = []
            if(!this.hasSelectedGame()){
                alert('No Game Selected')
                return false
            }
            else if(!this.isValidUuid(this.uuid)) {
                alert('Not A Valid UUID.v4 For Placement UUID')
                return false
            }
            else return true
        },
        isValidUuid(string){
            const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
            return regexExp.test(string)
        },
        trimWhitespaceOnFormVals(){
            this.user_defined_name = this.user_defined_name.trim()
            this.uuid = this.uuid.trim()
        },
        resetForm() {
            this.selected_game = ''
            this.user_defined_name = ''
            this.uuid = ''
            this.selected_creative = {}
        },
        addTestUser(test_user){
            this.test_users[test_user] = test_user
        },
        removeTestUser(test_user){
            delete this.test_users[test_user]
        },
        hasSelectedGame() {
            if (this.selected_game) {
                return true;
            } else {
                this.$notify({type: "error", text:'Select The Associated Game!'})
                return false
            }
        },
    },
    computed: {
        stagedFile(){
            const staged_files = this.$store.getters['file_upload_module/getAllFiles']
            if(!staged_files) return []
            return staged_files[0]
        },
        loading() {
            return this.$store.getters['getImageUploadLoadingState']
        }
    },
    async mounted () {
        if(!this.loading)
            this.$store.commit('file_upload_module/clearAllFiles')
        await this.getAdMonitorGames()
        await this.getAllCreatives()
    }
}
</script>

