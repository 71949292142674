<template>
    <div class="w-full flex flex-col gap-2 sm:text-base text-sm">
        <div class="bg-white rounded-md w-full p-4">
            Impressions Left: <span class="font-medium">{{ impressions_left.toLocaleString() }}</span>
        </div>
        <div class="overflow-y-auto h-96 flex flex-col gap-2">
            <div v-for="run_placement in run_placements" :key="run_placement" class="grid grid-cols-4 bg-white rounded-md gap-1 p-2 items-center">
                <span class="flex flex-col gap-1 items-center justify-center"> 
                    Name: 
                    <span class="font-medium">{{ run_placement.name }}</span> 
                </span>
                <span class="flex flex-col gap-1 items-center justify-center"> 
                    Impressions Received: 
                    <span class="font-medium">{{ run_placement.impressions.toLocaleString() }}</span> 
                </span>
                <span v-if="run_placement.target_impressions != null" class="flex flex-col gap-1 items-center justify-center"> 
                    Target Impressions: 
                    <span class="font-medium">{{ run_placement.target_impressions.toLocaleString() }}</span> 
                </span>
                <span v-else class="flex flex-col gap-1 items-center justify-center"> 
                    Target Impressions: 
                    <input type="number" v-model="run_placement.new_impressions_constraint" class="w-24 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-xs border-gray-300 rounded-md"/>
                </span>
                <div class="flex flex-row gap-2">
                    <button class="btn-primary green sm:text-sm text-xs w-full h-full" v-if="run_placement.target_impressions == null" @click="createPlacementConstraint(run_placement)"> Submit </button>
                    <button class="btn-primary green sm:text-sm text-xs w-1/2" v-if="run_placement.target_impressions != null" @click="run_placement.new_impressions_constraint = run_placement.target_impressions, run_placement.target_impressions = null"> Edit </button>
                    <button class="btn-primary red sm:text-sm text-xs w-1/2" v-if="run_placement.target_impressions != null" @click="clearPlacementConstraint(run_placement)"> Clear </button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "PlacementsConstraints",
    components: {},
    props: {
        run_info: {
            required: true
        }
    },
    data() {
        return {
            run_placements: [],
            impressions_left: 0,
        }
    },
    computed: {},
    watch: {},
    async created() {
        await this.getRunPlacementsImpressionsConstraints()
    },
    mounted() {},
    methods: {
        async getRunPlacementsImpressionsConstraints() {
            await this.$http.get('/run-placements-impression-constraint/' + this.run_info.uuid)
                .then( (response) => {
                    this.run_placements = response.data;
                    this.getImpressionsLeft()
                })
        },
        createPlacementConstraint(run_placement) {
            if(!this.ImpressionValidationSuccessfull(run_placement))
                return

            const toasts = {'success': 'Success', 'error': 'Error'}
            this.$http_request(this.$http.post, '/run-placements/edit-placement-constraint', toasts, run_placement)
                .then( ()=> {
                    this.getRunPlacementsImpressionsConstraints()
                })
        },
        clearPlacementConstraint(run_placement) {
            run_placement.new_impressions_constraint = null

            const toasts = {'success': 'Success', 'error': 'Error'}
            this.$http_request(this.$http.post, '/run-placements/edit-placement-constraint', toasts, run_placement)
                .then( ()=> {
                    this.getRunPlacementsImpressionsConstraints()
                })
        },
        ImpressionValidationSuccessfull(new_run_placement) {
            let sum = 0
            
            this.run_placements.forEach( run_placement => {
                if(run_placement.target_impressions != null) {
                    sum += run_placement.target_impressions
                }
            })

            sum += new_run_placement.new_impressions_constraint

            if(sum > this.run_info.target_impressions) {
                this.$notify({type: 'error', text: 'Total Impressions Is Greater Than ' + this.run_info.target_impressions.toLocaleString()})
                return false
            }

            return true;
        },
        getImpressionsLeft() {
            let sum = 0
            this.run_placements.forEach( run_placement => {
                if(run_placement.target_impressions != null) {
                    sum += run_placement.target_impressions
                }
            })

            this.impressions_left = this.run_info.target_impressions - sum
        }
    }
}
</script>

<style scoped>

</style>