<template>
    <div>
        <div class="flex flex-row flex-shrink gap-5 mt-5" v-if="unverified_users.length">
            <CardComponent v-for="user in unverified_users" :key="user" :top_info="user.name" :title="user.email">
                <template v-slot:thirdRow>
                    <button class="btn-primary green" @click="approveUser(user)"> 
                        Approve User
                    </button>
                    <button class="btn-primary red" @click="deleteUser(user)"> 
                        Reject User
                    </button>
                </template>
            </CardComponent>
        </div>
        <p v-else class="m-auto text-2xl">No Unverified Users At The Moment</p>
    </div>
</template>

<script>
import CardComponent from '@/components/UtilityComponents/CardComponent.vue'


export default {
    name: 'UserModeration',
    components: {
        CardComponent,
    },
    data() {
        return {
            unverified_users: [],
        }
    },
    methods: {
        async getUnverifiedUsers() {
            await this.$http.get('/users/unverified')
                .then((response) => {
                    this.unverified_users = response.data
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },
        approveUser(user) {
            const toasts = {'success': 'User Approved', 'error': 'Error Approving User', 'confirm': 'Are You Sure You Want To Approve '+user.name}
            this.$http_request(this.$http.post, '/user/verify-user', toasts, user)
                .then((response) => {
                    this.unverified_users = response.data
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },
        deleteUser(user) {
            const toasts = {'success': 'User Deleted', 'error': 'Error Deleting User', 'confirm': 'Are You Sure You Want To Reject '+user.name}
            this.$http_request(this.$http.post, '/user/delete', toasts, user)
                .then((response) => {
                    this.unverified_users = response.data
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },
    },
    async created() {
        await this.getUnverifiedUsers()
    },
}
</script>
