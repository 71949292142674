<template>
    <div class="flex flex-col space-y-8 p-4">
        <!-- Loading spinner -->
        <LoadingComponent class="mt-4 self-center" v-if="fetchingIntegrationData" />

        <!-- Dropdown and table components -->
        <div v-if="!fetchingIntegrationData" class="space-y-6">
            
            <!-- Dropdown section -->
            <div class="flex flex-wrap space-x-4"> 
                
                <!-- Dropdown for Integrations -->
                <div class="inline-block min-w-min min-w-[25ch]"> 
                    <DropdownComponent
                        :items="integrations"
                        display="name"
                        :selected="selectedIntegration ? selectedIntegration.name : 'Select Integration'"
                        @user_select="selectIntegration"
                        :key="dropdown_integrations_refresher"
                        class="w-auto min-w-[25ch]"
                    />
                </div>
                
                <!-- Dropdown for Intervals (only visible when integration is selected) -->
                <div class="inline-block min-w-min" v-if="selectedIntegration">
                    <DropdownComponent
                        :items="intervals"
                        display="interval"
                        :selected="(selectedStartDate && selectedEndDate) ? `${displayedStartDateMDY} - ${displayedEndDateMDY}` : 'Select Interval'"
                        @user_select="selectInterval"
                        :key="dropdown_intervals_refresher"
                        class="w-auto min-w-[25ch]"
                    />
                </div>
            </div>

            <!-- Table component for data -->
            <div class="mt-8"> 
                <TableComponent 
                    v-if="integrationData.length > 0"
                    :table_data="integrationData" 
                    total_column_heading_name="Event Name" 
                    :disallowed_averages="['Island Name']" 
                    :disallowed_totals="['Island Name']" 
                    :exported_file_name="exportedFilename"
                />
            </div>
        </div>
    </div>
</template>

<script>
import TableComponent from '@/components/TableComponent.vue';

export default {
    name: "FortniteIntegrationReporting",
    components: {
        TableComponent,
    },
    data() {
        return {
            integrationsWithIntervals: [],
            integrationData: [],
            selectedIntegration: null, // The selected integration
            dropdown_integrations_refresher: 0,
            dropdown_intervals_refresher: 0,
            selectedStartDate: null,
            selectedEndDate: null,
            fetchingIntegrationData: false,
        };
    },
    created() {
        this.fetchIntegrationsWithIntervals();
    },
    methods: {
        async fetchIntegrationsWithIntervals() {
            const url = '/fortnite-integrations-with-intervals';
            const toasts = {
                success: "Integrations with intervals loaded successfully",
                error: "Error fetching integrations with intervals",
            };

            try {
                const response = await this.$http_request(this.$http.get, url, toasts);
                this.integrationsWithIntervals = response.data.data;
                this.dropdown_integrations_refresher += 1; // Refresh the integrations dropdown
            } catch (error) {
                console.error('Fetch Integrations Error:', error);
            }
        },

        async fetchIntegrationData() {
            // Check if the required selections are made
            if (!this.selectedIntegration || !this.selectedStartDate || !this.selectedEndDate) {
                this.$notify({ type: 'error', text: 'Please select an integration and a valid date range' });
                return;
            }

            this.fetchingIntegrationData = true; // Start loading

            const uuid = this.selectedIntegration.uuid;
            const startDate = this.selectedStartDate;
            const endDate = this.selectedEndDate;
            const url = `/fortnite-integrations/${uuid}/data?startDate=${startDate}&endDate=${endDate}`;
            const toasts = {
                success: 'Data loaded successfully',
                error: 'Failed to fetch integration data',
            };

            try {
                const response = await this.$http_request(this.$http.get, url, toasts);
                this.integrationData = response.data;  // Store the integration data
            } catch (error) {
                console.error('Error fetching integration data:', error);
            } finally {
                this.fetchingIntegrationData = false; // Stop loading
            }
        },

        selectIntegration(item) {
            this.selectedIntegration = item; // Set selected integration
            this.selectedStartDate = null;   // Reset selected interval
            this.selectedEndDate = null;     // Reset selected interval
            this.integrationData = [];       // Clear the integration data
            this.dropdown_intervals_refresher += 1; // Force re-render of intervals dropdown
        },

        selectInterval(selectedInterval) {
            // Assuming selectedInterval is in the format "MM-DD-YYYY - MM-DD-YYYY"
            const [startDateMDY, endDateMDY] = selectedInterval.interval.split(' - ');

            // Convert the selected interval to YMD format
            const startDateYMD = new Date(startDateMDY).toISOString().split('T')[0]; // YYYY-MM-DD
            const endDateYMD = new Date(endDateMDY).toISOString().split('T')[0]; // YYYY-MM-DD

            // Set the selected dates
            this.selectedStartDate = startDateYMD;
            this.selectedEndDate = endDateYMD;

            // Fetch the integration data for the new interval
            this.fetchIntegrationData();
        },

        formatToMMddYYYY(dateStr) {
            if (!dateStr) return '';
            
            const date = new Date(dateStr);

            // Get the month, day, and year in UTC
            const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
            const day = String(date.getUTCDate()).padStart(2, '0');
            const year = date.getUTCFullYear();

            // Return the date in MM-DD-YYYY format
            return `${month}-${day}-${year}`;
        },
    },
    computed: {
        // List of integrations for the integrations dropdown
        integrations() {
            return this.integrationsWithIntervals.map(integration => ({
                name: integration.name,
                uuid: integration.uuid,
            }));
        },
        // List of intervals for the selected integration
        intervals() {
            if (!this.selectedIntegration) return [];

            const selected = this.integrationsWithIntervals.find(integration => integration.uuid === this.selectedIntegration.uuid);
            if (!selected || !selected.intervals) return [];

            return selected.intervals
                .sort((a, b) => new Date(b.start_date) - new Date(a.start_date)) // Sort by start_date in descending order
                .map(interval => {
                    const startDate = this.formatToMMddYYYY(interval.start_date);
                    const endDate = this.formatToMMddYYYY(interval.end_date);

                    return {
                        interval: `${startDate} - ${endDate}`,
                    };
                });
        },

        displayedStartDateMDY() {
            return this.selectedStartDate ? this.formatToMMddYYYY(this.selectedStartDate) : '';
        },
        displayedEndDateMDY() {
            return this.selectedEndDate ? this.formatToMMddYYYY(this.selectedEndDate) : '';
        },

        exportedFilename() {
            if(!this.selectedIntegration) return '';
            if(!this.selectedStartDate || !this.selectedEndDate) return '';
            return `fortnite-integration-data-${this.selectedIntegration.name}_${this.selectedStartDate}_${this.selectedEndDate}`;
        },
    }
};
</script>

<style scoped>
/* Add any relevant styles */
</style>
