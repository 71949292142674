export default class GraphEdge {
    constructor({id, sourceId, destinationId, metadata}) {
        this.id = id;
        this.sourceId = sourceId;
        this.destinationId = destinationId;
        if(metadata) this.metadata = metadata;
    }

    getId(){
        return this.id
    }

    getSource(){
        return this.sourceId
    }

    getDestination(){
        return this.destinationId
    }
}