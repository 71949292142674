import GraphEdge from "./GraphEdge";

export default class ConversationEdge extends GraphEdge {
    constructor({id, sourceId, destinationId, metadata}, {text, singleChoice}) {
        super({id, sourceId, destinationId, metadata})

        if(singleChoice === null || singleChoice === undefined) singleChoice = false;

        this.text = text;
        this.singleChoice = singleChoice;
    }
}