const state = () => ({
    current_campaign: null,
    country_filter: [],
    include_countries: true,
    run_filter: [],
    include_runs: true,
    game_filter: [],
    include_games: true,
    start_date: '',
    end_date: '',
    impression_grouping: 'Daily',
})

const getters = {
    getCurrentCampaign(state) {
        return state.current_campaign
    },
    getSelectedCountries(state) {
        return state.country_filter
    },
    getIncludeCountriesBool(state) {
        return state.include_countries
    },
    getSelectedRuns(state) {
        return state.run_filter
    },
    getIncludeRunsBool(state) {
        return state.include_runs
    },
    getSelectedGames(state) {
        return state.game_filter
    },
    getIncludeGamesBool(state) {
        return state.include_games
    },
    getStartDate(state) {
        return state.start_date
    },
    getEndDate(state) {
        return state.end_date
    },
    getImpressionGrouping(state) {
        return state.impression_grouping
    },
}

const mutations = {
    setCurrentCampaign(state, current_campaign) {
        state.current_campaign = current_campaign
    },
    setSelectedCountries(state, countries) {
        state.country_filter = countries
    },
    setIncludeCountriesBool(state, include_countries) {
        state.include_countries = include_countries
    },
    setSelectedRuns(state, runs) {
        state.run_filter = runs
    },
    setIncludeRunsBool(state, include_runs) {
        state.include_runs = include_runs
    },
    setSelectedGames(state, games) {
        state.game_filter = games
    },
    setIncludeGamesBool(state, include_games) {
        state.include_games = include_games
    },
    setStartDate(state, start_date) {
        state.start_date = start_date
    },
    setEndDate(state, end_date) {
        state.end_date = end_date
    },
    setImpressionGrouping(state, impression_grouping) {
        state.impression_grouping = impression_grouping
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
}