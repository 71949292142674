<template>
    <div class="container mx-auto p-4">
        <!-- Fortnite Integration List -->
        <table class="table-auto w-full mb-4">
            <thead>
                <tr class="bg-gray-200">
                    <th class="px-4 py-2">Name</th>
                    <th class="px-4 py-2">Event Name Contains</th>
                    <th class="px-4 py-2">Event Name Does Not Contain</th>
                    <th class="px-4 py-2">Actions</th>
                    <th class="px-4 py-2">Updated At</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="integration in integrations" :key="integration.uuid" :class="{ 'bg-blue-100': integration.uuid === activeRowId }" class="border-b">
                    <td class="px-4 py-2">{{ integration.name }}</td>
                    <td class="px-4 py-2">{{ integration.event_name_contains_csv || 'N/A' }}</td>
                    <td class="px-4 py-2">{{ integration.event_name_does_not_contain_csv || 'N/A' }}</td>
                    <td class="px-4 py-2 flex flex-col items-start">
                        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mb-2" @click="editIntegration(integration)">Edit</button>
                        <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded" @click="deleteIntegration(integration.uuid)">Delete</button>
                    </td>
                    <td class="px-4 py-2">{{ formatToMMddYYYY(integration.updated_at) }}</td>
                </tr>
            </tbody>
        </table>

        <!-- Add/Edit Fortnite Integration Form -->
        <div v-if="showForm" class="mb-4">
            <form @submit.prevent="submitForm" class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="name">
                        Name
                    </label>
                    <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" v-model="form.name" placeholder="Name">
                </div>

                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="event-name-contains">
                        Event Name Contains CSV
                    </label>
                    <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="event-name-contains" type="text" v-model="form.event_name_contains_csv" placeholder="SomeName_2024,Anotherversion2024">
                </div>

                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="event-name-does-not-contain">
                        Event Name Does Not Contain CSV
                    </label>
                    <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="event-name-does-not-contain" type="text" v-model="form.event_name_does_not_contain_csv" placeholder="UnwantedName_2024,anotherunwantedevent2024">
                </div>

                <div class="flex items-center justify-between">
                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Save
                    </button>
                </div>
            </form>
        </div>

        <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" @click="addIntegration">Add Integration</button>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: "ManageFortniteIntegrations",
    data() {
        return {
            integrations: [],
            showForm: false,
            isEditMode: false,
            activeRowId: null,
            form: {
                name: '',
                event_name_contains_csv: '',
                event_name_does_not_contain_csv: ''
            }
        }
    },
    created() {
        this.fetchIntegrations();
    },
    methods: {
        async fetchIntegrations() {
            const url = '/fortnite-integrations';
            try {
                const response = await this.$http.get(url);
                this.integrations = response.data.data;
            } catch (error) {
                console.error('Fetch Integrations Error:', error);
            }
        },

        addIntegration() {
            this.resetForm();
            this.showForm = true;
            this.isEditMode = false;
            this.activeRowId = null;
        },

        editIntegration(integration) {
            this.form = { ...integration };
            this.showForm = true;
            this.isEditMode = true;
            this.activeRowId = integration.uuid;
        },

        async submitForm() {
            const url = this.isEditMode ? `/fortnite-integrations/${this.activeRowId}` : '/fortnite-integrations'
            const method = this.isEditMode ? this.$http.put : this.$http.post;
            try {
                await method(url, this.form);
                this.showForm = false;
                this.resetForm();
                this.fetchIntegrations();
            } catch (error) {
                console.error('Submit Form Error:', error);
            }
        },

        async deleteIntegration(uuid) {
            if (confirm("Are you sure you want to delete this integration?")) {
                const url = `/fortnite-integrations/${uuid}`;
                try {
                    await this.$http.delete(url);
                    this.fetchIntegrations();
                } catch (error) {
                    console.error('Delete Integration Error:', error);
                }
            }
        },

        resetForm() {
            this.form = {
                name: '',
                event_name_contains_csv: '',
                event_name_does_not_contain_csv: ''
            };
        },

        formatToMMddYYYY(dateStr) {
            return dateStr ? moment(dateStr).format('MM-DD-YYYY') : '';
        }
    }
}
</script>

<style scoped>
</style>
