import MainState from '@/classes/MainState.js'
import storeFunctions from '@/plugins/StorePlugin.js'

const main_state = new MainState()
const state = () => (main_state.state)
// getters
//    someFuncGet: (state, getters, rootState) => {},
const getters = {
    getCreative: (state) => (creative_uuid) => {
        for(const creative of state.all.creatives){
            if(creative.uuid == creative_uuid) return creative
        }
        return {}
    },
    getAllCreatives(state) {
        return state.all.creatives
    },
    getAllGames(state) {
        return state.all.games
    },
    getAllCampaignCreatives(state) {
        return state.all.campaign.creatives
    },
    getAllRunCreatives(state) {
        return state.all.run.creatives
    },
    getAllCampaignGames(state) {
        return state.all.campaign.games
    },
    getAllRunGames(state) {
        return state.all.run.games
    },
    getAllRunPlacements(state){
        return state.all.run.placements
    },
    getFilteredCreatives(state) {
        const filtered_creatives = state.all.creatives.filter((creative) => {
            const currently_viewed = state.taker.creatives.some(
                (el) => el.uuid == creative.uuid
            )
            return !currently_viewed
        })
        return filtered_creatives
    },
    getTakerCreatives(state) {
        return state.taker.creatives
    },
    getFilteredGames(state) {
        const filtered_games = state.all.games.filter((game) => {
            const currently_viewed = state.taker.games.some(
                (el) => el.uuid == game.uuid
            )
            return !currently_viewed
        })
        return filtered_games
    },
    getTakerGames(state) {
        return state.taker.games
    },
    getFilteredCampaignGames(state) {
        const filtered_games = state.all.campaign.games.filter((game) => {
            const currently_viewed = state.taker.games.some(
                (el) => el.uuid == game.uuid
            )
            return !currently_viewed
        })
        return filtered_games
    },
    getFilteredCampaignCreatives(state) {
        const filtered_creatives = state.all.campaign.creatives.filter(
            (creative) => {
                const currently_viewed = state.taker.creatives.some(
                    (el) => el.uuid == creative.uuid
                )
                return !currently_viewed
            }
        )
        return filtered_creatives
    },
    getFilteredRunCreatives(state) {
        const filtered_creatives = state.all.run.creatives.filter(
            (creative) => {
                const currently_viewed = state.taker.creatives.some(
                    (el) => el.uuid == creative.uuid
                )
                return !currently_viewed
            }
        )
        return filtered_creatives
    },
    getFilteredRunPlacements(state) {
        const filtered_placements = state.all.run.placements.filter(
            (placement) => {
                const currently_viewed = state.taker.placements.some(
                    (el) => el.uuid == placement.uuid
                )
                return !currently_viewed
            }
        )
        return filtered_placements
    },
    getTakerPlacements(state){
        return state.taker.placements
    },
    getImageUploadLoadingState(state) {
        return state.image_upload_loading_state
    },
    getImageDeleteLoadingState(state) {
        return state.image_delete_loading_state
    },
    getCsrfTokenRetryCount(state){
        return state.request_csrf_count
    },
    getUserPermissions(state) {
        return state.user
    },
    getPossibleTimezones(state){
        return state.timezones
    },
    getCampaignCategories(state){
        return state.campaign_categories
    }
}

// actions
//    someFuncAction({ state, commit }, product) {},
const actions = {
    fetchAllImages({ commit }, params) {
        const api_url = params.api_url
        const image_type = storeFunctions.capitalizeFirstLetter(params.image_type)
        const commit_string = `setAll${image_type}`
        this.$http.get(api_url).then((response) => {
            commit(commit_string, response.data)
        })
    },
    fetchCampaignImages({ commit }, params) {
        const api_url = params.api_url
        const image_type = storeFunctions.capitalizeFirstLetter(params.image_type)
        const commit_string = `setAllCampaign${image_type}`
        this.$http.get(api_url).then((response) => {
            commit(commit_string, response.data)
        })
    },
    fetchRunImages({ commit }, params) {
        const api_url = params.api_url
        const image_type = storeFunctions.capitalizeFirstLetter(params.image_type)
        const commit_string = `setAllRun${image_type}`
        this.$http.get(api_url).then((response) => {
            commit(commit_string, response.data)
        })
    },
    fetchTakerImages({ commit }, { api_url, image_type }) {
        const image_type_string = storeFunctions.capitalizeFirstLetter(image_type)
        const commit_string = `setTaker${image_type_string}`
        this.$http.get(api_url).then((response) => {
            commit(commit_string, response.data)
        })
    },
    async fetchUserPermissions({commit}, request) {
        const toasts = {'error': 'Error Getting Permissions'}
        await storeFunctions.http_request(this.$http.post, '/specific-user-page-perm', toasts, request)
            .then ( ({data}) => {
                commit('setUserPermissions', Object.values(data)[0])
            })
    },
    // eslint-disable-next-line no-unused-vars
    getBlameDetails( {commit}, uuid) {
        return this.$http.get(`/blame/${uuid}`).then((response)=> {
            return response.data
        })
    },
    resetState({ commit }) {
        commit('resetState')
    },
}

// mutations
const mutations = {
    setImageUploadLoadingState(state, loading_state){
        state.image_upload_loading_state = loading_state
    },
    setImageDeleteLoadingState(state, loading_state) {
        state.image_delete_loading_state = loading_state
    },
    setCurrentlyViewedCreatives(state, creatives) {
        state.viewing.creatives = creatives
    },
    setCurrentlyViewedGames(state, games) {
        state.viewing.games = games
    },
    setAllCreatives(state, creatives) {
        state.all.creatives = creatives
    },
    setAllGames(state, games) {
        state.all.games = games
    },
    //this method (setAllCampaignCreatives) is for the image pickers. All refers to all of the images that can be selected from
    // given the context
    setAllCampaignCreatives(state, creatives) {
        state.all.campaign.creatives = creatives
    },
    setAllCampaignGames(state, games) {
        state.all.campaign.games = games
    },
    setAllRunPlacements(state, placements){
        state.all.run.placements = placements;
    },
    setAllRunCreatives(state, creatives){
        state.all.run.creatives = creatives;
    },
    setTakerCreatives(state, creatives) {
        state.taker.creatives = creatives
    },
    setTakerGames(state, games) {
        state.taker.games = games
    },
    setTakerPlacements(state, placements){
        state.taker.placements = placements
    },
    setUserPermissions(state, user_permissions) {
        state.user = user_permissions
    },
    updateCreativeNameClientSide(state, creative){
        state.taker.creatives.forEach((element, index) => {
            if(element.uuid == creative.uuid){
                element.user_defined_name = creative.user_defined_name
                state.taker.creatives[index][element]
            }
        })
    },
    incrementCsrfTokenCount(state){
        state.request_csrf_count += 1
    },
    resetCsrfTokenCount(state){
        state.request_csrf_count = 0
    },
    resetState(state) {
        // eslint-disable-next-line no-unused-vars
        return state = new MainState()
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
