import Item from '@/classes/Item/Item.js'
import ItemProperty from '@/classes/Item/ItemProperty.js'

export default class Placement extends Item {
    constructor(params){
        super(params)
        this.unpack(params)
    }
    item_type = 'placement'
    dimensions = new ItemProperty({
        params: {
            name: 'dimensions',
            friendly_name: 'Dimensions',
        },
        default_type: 'not_displayed'
    })
    name = new ItemProperty({
        params: {
            name: 'name',
            friendly_name: 'Name',
            html_input: {
                type: 'text',
                value: ''
            },
        },
        default_type: 'modifiable'
    })
    place_id = new ItemProperty({
        params: {
            name: 'place_id',
            friendly_name: 'Place ID',
        },
        default_type: 'displayed_not_modifiable'
    })
    img_url = new ItemProperty({
        params: {
            name: 'img_url',
            friendly_name: 'Image Url',
            user_modifiable: true,
        },
        default_type: 'not_displayed'
    })
    archived = new ItemProperty({
        params: {
            name: 'archived',
            friendly_name: 'Archived',
            user_modifiable: false,
        },
        default_type: 'not_displayed'
    })
    test_creative_uuid = new ItemProperty({
        params: {
            name: 'test_creative_uuid',
            friendly_name: 'Test Creative UUID',
        },
        default_type: 'not_displayed'
    })
    impressions = new ItemProperty({
        params: {
            name: 'impressions',
            friendly_name: 'Lifetime Impressions',
        },
        default_type: 'displayed_not_modifiable'
    })
    game_name = new ItemProperty({
        params: {
            name: 'game_name',
            friendly_name: 'Game Name',
        },
        default_type: 'displayed_not_modifiable'
    })
    allow_roblox_ads = new ItemProperty({
        params: {
            name: 'allow_roblox_ads',
            friendly_name: 'Allow Roblox Ads',
            user_modifiable: true,
            html_input: {
                type: 'checkbox',
                value: ''
            },
        },
        default_type: 'modifiable'
    })
    relations = [
        {
            value: 'run',
            friendly_name: 'Run'
        },
        {
            value: 'creative',
            friendly_name: 'Creatives'
        }
    ]
}