<template>
  <div class="flex flex-col gap-4">
      <div class="bg-white w-full rounded-md p-6 flex flex-col justify-center items-center gap-4">
        <div class="flex w-full justify-center">
          <button 
              @click="this.is_admonitor = true" 
              class="options-picker border-l rounded-l-md" 
              :class="{'options-picker-active' : this.is_admonitor}" 
              style="border-left-width: 1px"> 
              AdMonitor Games 
          </button>
          <button 
              @click="this.is_admonitor = false" 
              class="options-picker rounded-r-md" 
              :class="{'options-picker-active' : !this.is_admonitor}"> 
              Gamefam Games 
          </button>
        </div>
        <div class="flex flex-row flex-wrap gap-4 w-full justify-center items-center">
          <input type="text" class="max-w-lg sm:max-w-xs block w-full shadow-sm focus:ring-indigo-500 
          focus:border-indigo-500 sm:text-sm border-gray-400 rounded-md " v-if="is_admonitor" v-model="admonitor_search_string" placeholder="Search" maxlength="50"/>
          <input type="text" class="max-w-lg sm:max-w-xs block w-full shadow-sm focus:ring-indigo-500 
          focus:border-indigo-500 sm:text-sm border-gray-400 rounded-md " v-else v-model="gamefam_search_string" placeholder="Search" maxlength="50"/>
          <button @click="refreshGames" class="btn-primary light-blue"> 
            Refresh All Games
          </button>
        </div>
        <button class="btn-primary indigo" @click="getSubPlacesForAdtechGames"> 
            Get All Sub Place IDs for Current AdTech Games 
        </button>
      </div>
      <ul role="list" class="flex flex-row justify-center items-center flex-wrap gap-2" v-show="is_admonitor">
        <li v-for="game in filteredAdmonitorGames" :key="game">
          <CardComponent :src="game.img_url" :title="game.name">
            <template v-slot:thirdRow>
              <a :href="`/manage-item/game/${game.uuid}`">
                <CogIcon class="cogicon-dimensions cursor-pointer"/>
              </a>
            </template>
            <template v-slot:fourthRow>
              <p class="text-xs text-gray-500"> {{ game.sdk_version }} </p>
            </template>
          </CardComponent>
        </li>
      </ul>
      <ul role="list" class="flex flex-row justify-center items-center flex-wrap gap-2" v-show="!is_admonitor && !loading">
        <li v-for="game in filteredGamefamGames" :key="game">
          <CardComponent :src="game.img_url" :title="game.name">
            <template v-slot:thirdRow>
              <button @click="addGame(game)" class="btn-primary green sm:text-sm text-xs"> 
                Add to AdMonitor
              </button>
            </template>
          </CardComponent>
        </li>
      </ul>
      <LoadingComponent v-show="loading && !is_admonitor" style="margin-left: 50%; margin-top: 25%"/>
  </div>
</template>

<script>
import CardComponent from '@/components/UtilityComponents/CardComponent.vue'
import { CogIcon } from '@heroicons/vue/outline'
export default {
    components: {
      CardComponent,
      CogIcon,
    },
    data() {
        return {
            is_admonitor: true,
            gamefam_games: [],
            admonitor_games: [],
            admonitor_search_string: '',
            gamefam_search_string: '',
            loading: true,
        }
    },
    methods: {
        async getGamefamGames() {
            this.loading = true

            this.$http.get('/gamefam-games')
              .then(({data}) => {
                  this.gamefam_games = data
              })
              .catch((error) => {
                  console.error(error.message)
              })

            this.loading = false
        },
        async getAdMonitorGames() {
            await this.$http_request(this.$http.get, '/games/all')
                .then(({data}) => {
                    this.admonitor_games = data
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },
        async refreshGames () {
            await this.$http.get('/games/update-gamefam-games')
                .then( async ()=> { 
                    this.getGamefamGames()
                    await this.getAdMonitorGames()
                })
                .catch( (error)=> {
                    this.$notify({type: "error", text: 'Something Went Wrong When Loading Game Icons!'})
                    console.error(error)
                    this.loading = false;
                })
        },
        addGame(game) {
          const toasts = {'success': 'Game(s) Added to Admonitor', 'error': 'Error Adding Games to Admonitor'}
          this.$http_request(this.$http.post, 'games', toasts, game)
              .then(() => {
                  this.getAdMonitorGames()
                  this.getGamefamGames()
              })
              .catch((error) => {
                  console.error(error.message)
              })
        },
        async getSubPlacesForAdtechGames() {
          const toasts = {'success': 'Success', 'error': 'Error Adding Sub Place IDs'}
          await this.$http_request(this.$http.get, '/update-sub-places', toasts)
            .catch((error) => {
                console.error(error.message)
            })
        }
    },
    async created() {
        this.getGamefamGames()
        await this.getAdMonitorGames()
    },
    computed: {
      filteredGamefamGames() {
        if (!this.gamefam_search_string) return this.gamefam_games;
        return this.gamefam_games.filter(game =>
          game.name.toLowerCase().includes(this.gamefam_search_string.toLowerCase())
        );
      },
      filteredAdmonitorGames() {
        if (!this.admonitor_search_string) return this.admonitor_games;
        return this.admonitor_games.filter(game =>
          game.friendly_name.toLowerCase().includes(this.admonitor_search_string.toLowerCase())
        );
      }
    },
}
</script>
