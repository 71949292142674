<template>
    <div class="flex flex-col gap-4">

        <div class="flex flex-row gap-6 flex-wrap bg-white p-4 rounded-md justify-around">
            <div class="flex flex-row items-center gap-2">
                <label for="item_type_selector" class="text-lg font-medium">Select Item Type</label>
                <DropdownComponent 
                    class="w-44"
                    id="item_type_selector"
                    :selected="itemType"
                    :items="item_types"
                    @user_select="setItemType"/>
            </div>

            <div class="flex flex-row items-center gap-2">
                <label for="relation_type_selector" class="text-lg font-medium">Select Relation</label>
                <DropdownComponent
                    class="w-44"
                    id="relation_type_selector" 
                    :selected="relation"
                    :items="relations"
                    @user_select="setRelation"
                    :key="componentKey"/>
            </div>
        </div>

        <LoadingComponent v-if="loading" />

        <div class="flex flex-row gap-4 flex-wrap bg-white p-4 rounded-md justify-around" v-if="!loading">
            <div class="flex flex-row justify-center items-center gap-2">
                <input v-gtag="{ category: 'Switch', action: 'Toggled Table View', label: 'table view toggle in manage all items'}" id="table-view" v-model="tableView" name="table-view" type="checkbox" class="focus:ring-indigo-500 h-5 w-5 text-indigo-600 border-gray-300 rounded" />
                <label for="table-view" class="ml-3 text-lg font-medium text-gray-700">Table View</label>
            </div>

            <input type="text" v-model="item_search_string" :placeholder="`Search ${$capitalizeFirstLetter(this.itemType)}s`" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-1/2 md:w-1/4 sm:text-sm border-gray-300 rounded-md" maxlength="50"/>
            <input type="text" v-model="relation_search_string" :placeholder="`Search ${$capitalizeFirstLetter(this.relation)}s`" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-1/2 md:w-1/4 sm:text-sm border-gray-300 rounded-md" maxlength="50"/>
        </div>

        <div v-if="!tableView && !loading" class="flex flex-col gap-4">
            <div v-for="item in filteredItems" :key="item.uuid" class="bg-white p-4 rounded-md flex flex-col gap-4">
                <div class="flex flex-row gap-8 items-center border-b border-black p-2">
                    <img :src="item.img_url" alt="game-image" class="w-24 h-24 md:w-32 md:h-32 object-contain bg-transparent overflow-hidden" />
                    <a class="text-2xl font-medium text-blue-400 hover:text-blue-600" :href="`/manage-item/${this.itemType}/${item.uuid}`"> {{ item.user_defined_name ? item.user_defined_name : item.name }} </a>
                </div>
                <div class="flex flex-row flex-wrap gap-2 justify-center" v-if="relation == 'placement'">
                    <CardComponent
                        v-for="relation in item.relations" :key="relation.uuid"
                        :src="relation.img_url" 
                        :title="relation.user_defined_name ? relation.user_defined_name : relation.name">
                        <template v-slot:thirdRow>
                            <CogIcon @click.stop="navigateToManageRelation(relation)" class="cogicon-dimensions cursor-pointer"/>
                            <button class="btn-primary red" @click.stop="deletePlacement(relation)"> Delete </button>
                        </template>
                    </CardComponent>
                </div>
                <div class="flex flex-row flex-wrap gap-2 justify-center" v-else>
                    <div v-for="relation in item.relations" :key="relation.uuid" class="bg-gray-200 p-4 rounded-md">
                        <div>
                            <span class="font-medium"> Name: </span>
                            <span @click="navigateToManageRelation(relation)" class="text-blue-500 underline underline-offset-2 font-medium cursor-pointer hover:text-blue-700">
                                {{ relation.name }} 
                            </span>
                        </div>

                        <div>
                            <span class="font-medium"> Start Date: </span>
                            <span>
                                {{ relation.start_date.split('T')[0] }}
                            </span>
                        </div>

                        <div>
                            <span class="font-medium"> End Date: </span>
                            <span>
                                {{ relation.end_date.split('T')[0] }}
                            </span>
                        </div>

                        <div>
                            <span class="font-medium"> Target Impressions: </span>
                            <span>
                                {{ relation.target_impressions.toLocaleString() }}
                            </span>
                        </div>

                        <div>
                            <span class="font-medium"> Impressions Received: </span>
                            <span>
                                {{ relation.impressions_sent.toLocaleString() }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-8 flex flex-col" v-if="tableView && !loading">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6" v-for="field in itemsTable.fields" :key="field">
                                        {{field}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr v-for="item in itemsTable.items" :key="item.id">

                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        <span @click="this.$router.push(`/manage-item/${this.itemType}/${item.uuid}`)" class="text-blue-500 underline underline-offset-2 font-medium cursor-pointer hover:text-blue-700">
                                            {{ item.user_defined_name ? item.user_defined_name : item.name }} 
                                        </span>
                                    </td>

                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        <img :src="item.img_url" class="w-24 h-24 object-contain" alt="item-image" loading="lazy">
                                    </td>

                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {{ item.img_type ? item.img_type : item.place_id }}
                                    </td>

                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" v-if="itemType=='placement'">
                                        {{ item.impressions ? item.impressions.toLocaleString() : 0 }}
                                    </td>

                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {{ item.created_at ? item.created_at.split('T')[0] : '' }}
                                    </td>

                                    <table class="min-w-full divide-y divide-gray-300" v-if="item.relations">
                                        <thead class="bg-gray-50">
                                            <tr>
                                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6" v-for="field in relationTableFields" :key="field">
                                                    {{field}}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="item in item.relations" :key="item.id">

                                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    <span @click="navigateToManageRelation(item)" class="text-blue-500 underline underline-offset-2 font-medium cursor-pointer hover:text-blue-700">
                                                        {{ item.name }} 
                                                    </span>
                                                </td>

                                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" v-if="item.img_url">
                                                    <img :src="item.img_url" class="w-24 h-24 object-contain" alt="item-image" loading="lazy">
                                                </td>

                                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" v-if="item.place_id">
                                                    {{item.place_id}}
                                                </td>

                                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" v-if="!item.place_id">
                                                    {{ item.start_date.split('T')[0] }}
                                                </td>

                                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" v-if="!item.place_id">
                                                    {{ item.end_date.split('T')[0] }}
                                                </td>

                                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" v-if="!item.place_id">
                                                    {{ item.target_impressions.toLocaleString() }}
                                                </td>

                                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" v-if="!item.place_id">
                                                    {{ item.impressions_sent.toLocaleString() }}
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CogIcon } from '@heroicons/vue/outline'
import CardComponent from '@/components/UtilityComponents/CardComponent.vue'
export default {
    components: {
        CardComponent,
        CogIcon,
    },
    data() {
        return {
            loading: true,
            componentKey: 0,
            item_types: ['creative', 'game', 'placement'],
            item_search_string: '',
            relation_search_string: '',
            searchable_items: [],
            items_nested_relations: [],
            item_table_fields: {
                'creative':['Name', 'Image', 'Image Type', 'Created At', 'Relation'],
                'game': ['Name','Image','Place ID','Created At','Relation'],
                'placement': ['Name', 'Image','Place ID','Total Impressions','Created At','Relation']
            },
            relation_table_fields: {
                'campaign': ['Name','Start Date','End Date','Target Impressions','Impressions Sent'],
                'ad set': ['Name','Start Date','End Date', 'Target Impressions','Impressions Sent'],
                'game': ['Name','Image', 'Place ID',]
            }
        }
    },
    methods: {
        setItemType(item_type) {
            this.itemType = item_type
            this.componentKey++
        },
        setRelation(relation) {
            this.relation = relation
        },
        async getAllItems() {
            this.loading = true
            const toasts = {'error': 'Sorry there was an error getting the items'}

            await this.$http_request(this.$http.get, `/all-items-nested/${this.itemType}/${this.relation}`, toasts)
                .then((response) => {
                    this.items_nested_relations = response.data
                })
                .catch((error) => {
                    console.error(error.message)
                })

            this.loading = false
        },
        deletePlacement(item) {
            if(confirm('Are You Sure You Want To Delete This Placement?')) {       
                const toasts = {'success': 'Deleted Successfully!', 'error': 'Error Deleting Item!'}       
                this.$http_request(this.$http.post, `item/delete/placement/${item.uuid}`, toasts, item)
                    .then (()=> {
                        return this.getAllItems()
                    })
                    .catch((error) => {
                        console.error(error.message)
                    })
            }
        },
        getItemName(item){
            if(this.itemType == 'creative') return item.user_defined_name
            else return item.name
        },
        friendlyDateString(date){
            const date_object = new Date(date)
            return date_object
        },
        navigateToManageRelation(item_relation){
            this.$router.push(this.getRelationLink(item_relation))
        },
        getRelationLink(item_relation){
            if(this.relation == 'ad set' || this.relation == 'campaign'){
                if(item_relation?.campaign_uuid) return `/manage/${this.relation}s/${item_relation.campaign_uuid}/${item_relation.uuid}`
                else return `/manage/${this.relation}s/${item_relation.uuid}`
            }
            else {
                return `/manage-item/${this.relation}/${item_relation.uuid}`
            }

        },
        tableRelationLink(item){
            if(this.relation == 'ad set') return `/manage/ad_sets/${item.campaign_uuid}/${item.uuid}`
            if(this.relation == 'game') return `/manage-item/${this.relation}/${item.uuid}`
            else return `/manage/campaigns/${item.uuid}`
        },
    },
    computed: {
        relations(){
            if(this.itemType == 'placement') return ['ad set']
            else if(this.itemType == 'game') return ['campaign', 'ad set', 'placement']
            else return ['campaign', 'ad set']
        },
        switchToViewLabel(){
            if(this.tableView) return 'Cards View'
            else return 'Table View'
        },
        itemsTable(){
            return {
                items: this.filteredItems,
                fields: this.tableFields,
            }
        },
        tableFields(){
            return this.item_table_fields[this.itemType]
        },
        relationTableFields(){
            return this.relation_table_fields[this.relation]
        },
        relation : {
            get: function() {
                return this.$store.getters['manage_all_items_module/getRelation']
            },
            set: function(relation){
                this.$store.commit('manage_all_items_module/setRelation', relation)
            }
        },
        itemType : {
            get: function() {
                return this.$store.getters['manage_all_items_module/getItemType']
            },
            set: function(item_type){
                this.$store.commit('manage_all_items_module/setItemType', item_type)
            }
        },
        tableView: {
            get: function() {
                return this.$store.getters['manage_all_items_module/getTableView']
            },
            set: function(table_view){
                this.$store.commit('manage_all_items_module/setTableView', table_view)
            }
        },
        filteredItems() {
            let filtered = this.items_nested_relations;
            if (this.relation_search_string) {
                filtered = filtered.filter(item => {
                    return item.relations.some(relation => {
                        let name = relation.user_defined_name ? relation.user_defined_name : relation.name
                        return name.toLowerCase().includes(this.relation_search_string.toLowerCase());
                    });
                });
            }
            if (this.item_search_string) {
                filtered = filtered.filter(item => item.name.toLowerCase().includes(this.item_search_string.toLowerCase()));
            }
            return filtered;
        },
    },
    mounted() {
        this.getAllItems()
    },
    watch: {
        itemType(new_item_type){
            if(new_item_type == 'placement') this.relation = 'ad set'
            if(new_item_type == 'creative' || new_item_type == 'game') this.relation = 'campaign'
            this.getAllItems()
        },
        relation(){
            this.getAllItems()
        }
    },
}
</script>
