<template>
    <div 
        class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md min-h-fit" 
        @dragover.prevent="dragOver" 
        @dragleave.prevent="dragLeave"
        @drop.prevent="droptest">

        <!-- DEFAULT VIEW / NO STAGED IMAGES -->
        <div v-if="!stagedFiles.length" class="space-y-1 text-center">
            <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <div class="flex flex-col text-sm text-gray-600">
                <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                    <div>
                        Upload an image 
                        <span class="text-black font-normal">or drag and drop</span>
                    </div>
                    <input id="file-upload" required name="file-upload" class="sr-only" type="file" :accept="accept" :multiple="multiple" @change="stageFile"/>
                </label> 
                <p class="text-xs"> It Might Take A Few Seconds When Uploading Several Images </p>
            </div>
        </div>

        <!-- STAGED IMAGES  -->
        <div v-if="stagedFiles.length" class="flex flex-row flex-wrap gap-2 w-full min-h-fit">    
            <div v-for="(file, index) in stagedFiles" :key="file" class="relative" id="parent-div">

                <!-- IMAGE -->
                <img :src="file.meta_data.source" class="w-24 h-24 object-cover rounded-sm" alt="staged-image" loading="lazy">

                <!-- X BUTTON TOP RIGHT -->
                <span id="hidden-x" 
                class=" font-semibold absolute top-0 right-0 w-5 h-5 m-1 text-sm rounded-full bg-gray-300 cursor-pointer flex justify-center items-center"
                @click="$store.commit('file_upload_module/removeFileByIndex', index)"> x</span>

            </div>
            <!-- + BUTTON TO ADD MORE IMAGES -->
            <label for="image_upload" class="w-24 h-24 rounded-sm text-2xl flex justify-center items-center bg-indigo-400 cursor-pointer hover:bg-indigo-600 duration-300" :title="multiple ? 'Add Items' : 'Change Item'"> 
                <span v-if="multiple" class="text-white text-4xl"> + </span>
                <SwitchHorizontalIcon class="text-white w-1/2" v-else />
            </label>
            <input id="image_upload" type="file" class="sr-only" :accept="accept" :multiple="multiple" @change="stageFile" style="display: none">
        </div>
        <p class="text-xs text-red-500" v-if="isTooManyFiles"> {{noOfFilesToRemoveMessage}} </p> <br>
        <p class="text-xs text-red-500" v-if="isPayloadTooLarge"> {{amountOfDataToRemoveMessage}} </p> <br>
    </div>
</template>

<script>
import { SwitchHorizontalIcon } from '@heroicons/vue/outline'

export default {
    components: {
        SwitchHorizontalIcon
    },
    props: {
        accept: {
            type: String,
            default: 'image/png, image/jpeg, image/jpg',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        check_file_name_existence: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            MAX_NO_OF_FILES: 25,
            MAX_PAYLOAD_SIZE_MB: 75,
        }
    },
    methods: {
        async droptest(event){
            for(const file of Object.values(event.dataTransfer.files)) {
                if (this.check_file_name_existence) {
                    this.filenameConflicts(file.name)
                }
                if(!this.accept.includes(file.type)) {
                    this.$notify({type: "error", text: `${file.type} Files Are Not Allowed On This Page!`})
                    continue
                } else if(await this.isNameTooLong(file.name)) {
                    continue
                } else if(!this.multiple) {
                    this.$store.commit('file_upload_module/clearAllFiles')
                    this.$store.dispatch('file_upload_module/stageFiles',{'files': [file], 'optional_file_names': undefined})
                    this.$notify({type: "error", text: 'You Can Only Upload One Image On This Page!'})
                    break
                } else {
                    await this.$store.dispatch('file_upload_module/stageFiles',{'files': [file], 'optional_file_names': this.existingFilenames})
                }
            }
        },
        async stageFile(event){
            if(!this.multiple)
                await this.$store.commit('file_upload_module/clearAllFiles')

            await this.$store.dispatch('file_upload_module/stageFiles',{'files': event.target.files, 'optional_file_names': this.existingFilenames})
        },
        async isNameTooLong(filename){
            if(await this.$store.dispatch('file_upload_module/isNameTooLong', filename)) {
                this.$notify({type: "error", text: `Unable To Stage "${filename}" Because The File Name Is Too Long!`})
                return true
            }
            return false
        },
        fileOrFiles(noToRemove){
            if(noToRemove == 1) return 'file'
            else return 'files'
        },
        filenameConflicts(file_name) {
            if(this.check_file_name_existence) {
                if (this.existingFilenames.includes(file_name)) {
                    return this.$notify({type: 'warn', text: `${file_name} Already Exists, Please Remove If This Is Unintentional`})
                }
            }
        },
    },
    computed: {
        stagedFiles(){
            const staged_files = this.$store.getters['file_upload_module/getAllFiles']
            if(!staged_files) return []
            return staged_files
        },
        existingFilenames() {
            if(this.check_file_name_existence) {
                const exisiting_filenames = []
                this.creatives.forEach((element) => {
                    exisiting_filenames.push(element.user_defined_name+element.img_type)
                })
                return exisiting_filenames
            } else return null
        },
        noOfStagedFiles(){
            return this.stagedFiles.length
        },
        isTooManyFiles(){
            if(this.noOfStagedFiles > this.MAX_NO_OF_FILES) return true
            else return false
        },
        noOfFilesToRemoveMessage(){
            const noToRemove = this.noOfStagedFiles - this.MAX_NO_OF_FILES
            const fileOrFiles = this.fileOrFiles(noToRemove)
            return `Sorry, files can only be uploaded in batches of ${this.MAX_NO_OF_FILES} or less, please remove ${noToRemove} ${fileOrFiles}`
        },
        amountOfDataToRemoveMessage(){
            const amountToRemoveMb = this.totalPayloadSizeMb - this.MAX_PAYLOAD_SIZE_MB
            return `Sorry, files can only be uploaded in batches of ${this.MAX_PAYLOAD_SIZE_MB}mb or less, please remove ${amountToRemoveMb}mb`
        },
        totalPayloadSizeMb(){
            return this.$store.getters['file_upload_module/getTotalPayloadSizeMb']
        },
        isPayloadTooLarge(){
            if(this.totalPayloadSizeMb > this.MAX_PAYLOAD_SIZE_MB) return true
            else return false
        },
        creatives(){
            if(this.check_file_name_existence) {
                const creatives = this.$store.getters['getAllCreatives']
                if (!creatives) return []
                return creatives
            } else return []
        },
    },
}
</script>


<style scoped>
/* to display and hide the x button */
#parent-div #hidden-x {
    display: none;
}
#parent-div:hover #hidden-x{
    display:flex;
}
</style>