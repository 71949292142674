<template>
    <div class="flex flex-col sm:text-base text-xs">
        <div v-if="hasTableData" class="inline-block min-w-full">
            <!-- Container with overflow-x-auto for horizontal scrolling -->
            <div class="flex flex-row items-start bg-white overflow-x-auto">
                <!-- Table wrapped in a div to control overflow -->
                <div class="flex-grow rounded-t-md">
                    <table class="min-w-full">
                        <thead class="bg-white border-b sticky top-0">
                            <tr>
                                <th v-for="heading in tableHeadings" :key="heading" scope="col"
                                    @click="setSortBy(heading)"
                                    class="text-gray-900 sm:px-6 py-5 px-1 text-center hover:cursor-pointer hover:text-gray-500 duration-300">
                                    <div class="flex flex-row items-center justify-center w-full">
                                        <span>{{ heading }}</span>
                                        <ArrowUpIcon class="w-5 h-5" v-if="sort_by.column === heading && !sort_by.sort_desc" />
                                        <ArrowDownIcon class="w-6 h-6" v-if="sort_by.column === heading && sort_by.sort_desc" />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in finalTableBody" :key="index"
                                class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                <td v-for="(value, columnHeading) in row" :key="columnHeading"
                                    class="text-gray-900 sm:px-6 sm:py-4 px-1 py-1 whitespace-nowrap text-center duration-150">
                                    {{ value !== null && value !== undefined ? value.toLocaleString() : value }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Download Button -->
                <div class="ml-4 pr-2 py-2 flex flex-col justify-start" v-if="show_export_button">
                    <button @click="exportTable" class="bg-gray-200 hover:bg-gray-400 duration-300 rounded-md p-1">
                        <DownloadIcon class="h-4 w-4 sm:h-5 sm:w-5" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DownloadIcon from '@heroicons/vue/outline/DownloadIcon'
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/vue/solid'
import chroma from 'chroma-js'

export default {
    name: "TableComponent",

    components: {
        ArrowDownIcon,
        ArrowUpIcon,
        DownloadIcon,
    },

    props: {
        table_data: {
            type: Array,
            required: true,
            default: () => []
        },
        default_sort_by: {
            type: String,
            required: false,
            default: ''
        },
        default_sort_desc: {
            type: Boolean,
            required: false,
            default: true
        },
        show_total_row: {
            type: Boolean,
            required: false,
            default: true
        },
        show_average_row: {
            type: Boolean,
            required: false,
            default: true
        },
        show_export_button: {
            type: Boolean,
            required: false,
            default: true
        },
        exported_file_name: {
            type: String,
            required: false,
            default: 'table'
        },
        //is the name of the column heading that will have the word 'Total' in it
        total_column_heading_name: {
            type: String,
            required: false,
            default: 'Experience',
        },
        //number of colours in the gradient scale
        num_colors: {
            type: Number,
            required: false,
            default: 100,
        },
        // headings in these arrays will get '-' as values in thier total or average rows
        disallowed_averages: {
            type: Array,
            required: false,
            default: () => []
        },
        disallowed_totals: {
            type: Array,
            required: false,
            default: () => []
        },
        calculate_weighted_average: {
            type: Boolean,
            default: false,
        },
        weighted_average_columns: {
            type: Array,
            required: false,
        }, 
        weighting_factor: {
            type: String,
            required: false,
        }
    },

    data() {
        return {
            sort_by: {
                column: '',
                sort_desc: true,
            },
        }
    },

    computed: {
        hasTableData() {
            if (!Array.isArray(this.table_data)) return false;
            if (this.table_data.length <= 0) return false;
            return true;
        },
        
        tableHeadings(){
            if(!this.hasTableData) return []
            return Object.keys(this.table_data[0])
        },

        sortedTableBody() {
            if (!this.hasTableData) return [];
            let table_data = [...this.table_data];

            if (this.sort_by.column === '') {
                return table_data;
            }

            const sort_by_column = this.sort_by.column;
            const sort_desc = this.sort_by.sort_desc;

            return table_data.sort((a, b) => {
                let aVal = this.parseValue(a[sort_by_column], true); // true indicates date parsing
                let bVal = this.parseValue(b[sort_by_column], true);

                // Directly compare dates or numbers
                if (!isNaN(aVal) && !isNaN(bVal)) {
                    return sort_desc ? bVal - aVal : aVal - bVal;
                }

                // For two strings, compare them alphabetically
                let compare_result = String(aVal).localeCompare(String(bVal), undefined, {numeric: true, sensitivity: 'base'});
                return sort_desc ? -compare_result : compare_result;
            });
        },

        finalTableBody(){
            if(!this.hasTableData) return []
            let table_data = [...this.sortedTableBody]
            table_data = this.processSummaryRows(table_data)
            return table_data
        }, 

    },

    watch: {},

    created() {},

    mounted() {
        this.sort_by.column = this.default_sort_by
        this.sort_by.sort_desc = this.default_sort_desc
    },

    methods: {
        setSortBy: function(column) {
            if(column === this.sort_by.column){
                this.sort_by.sort_desc = !this.sort_by.sort_desc
            } else {
                this.sort_by.column = column
                this.sort_by.sort_desc = this.default_sort_desc
            }
        },

        parseValue(value, parseDate = false) {
            if (typeof value === 'string') {
                // If parsing dates, attempt to normalize and parse date strings
                if (parseDate && this.isDateString(value)) {
                    let date = this.normalizeDateString(value);
                    return new Date(date).getTime(); // Convert to timestamp for comparison
                }

                // Attempt to parse a number with commas or decimal points
                let parsedValue = parseFloat(value.replace(/,/g, ''));
                if (!isNaN(parsedValue)) {
                    return parsedValue;
                }
            }
            // Return the original value if it's not a numeric string or if date parsing isn't requested
            return value;
        },

        // Checks if a string is a date based on the presence of dashes and length
        isDateString(value) {
            return /\d{2}-\d{2}-\d{4}|\d{4}-\d{2}-\d{2}/.test(value);
        },

        // Converts various date formats to ISO 8601 (YYYY-MM-DD)
        normalizeDateString(value) {
            let parts = value.split('-');
            // Assuming the date comes in YYYY-mm-dd, mm-dd-YYYY or dd-mm-YYYY formats
            if (parts[0].length === 4) { // YYYY-mm-dd
                return value; // No change needed
            } else if (parts[2].length === 4) { // dd-mm-YYYY or mm-dd-YYYY
                // Heuristic: If the first part (day or month) is greater than 12, assume dd-mm-YYYY
                if (parseInt(parts[0]) > 12) {
                    return `${parts[2]}-${parts[1]}-${parts[0]}`; // Convert dd-mm-YYYY to YYYY-mm-dd
                } else {
                    return `${parts[2]}-${parts[0]}-${parts[1]}`; // Convert mm-dd-YYYY to YYYY-mm-dd
                }
            }
            return value; // Fallback to original value if format is unrecognized
        },

        processSummaryRows(tableData) {
            let baseTableData = [...tableData];
            let finalTableData = [...tableData];

            if (this.show_average_row) {
                finalTableData = this.addAverageRow(baseTableData, finalTableData);
            }

            if (this.show_total_row) {
                finalTableData = this.addTotalRow(baseTableData, finalTableData);
            }

            return finalTableData;
        },

        addTotalRow(baseTableData, finalTableData) {
            if (!this.show_total_row) return finalTableData;
            const totalRow = {};

            const columns = this.tableHeadings

            // Loop through the columns
            columns.forEach(column => {
                const { total } = this.calculateRowValues(baseTableData, column);

                // Check if the column is disallowed for totaling
                if (this.disallowed_totals.includes(column)) {
                    totalRow[column] = '-';
                } else {
                // Add the total value to the totalRow object
                if (column === this.total_column_heading_name) {
                    totalRow[column] = 'Total'
                } else {
                    totalRow[column] = total;
                }
                }
            });

            // Add the totalRow object to the tableData array
            finalTableData.push(totalRow);

            return finalTableData;
        },

        addAverageRow(baseTableData, finalTableData) {
            const averageRow = {};
            const columns = this.tableHeadings;
            let totalVisits = 0;

            if (this.calculate_weighted_average) {
                // Calculate total visits for all rows
                baseTableData.forEach(row => {
                    const totalVisitsValue = String(row['Total Visits']);
                    totalVisits += parseInt(totalVisitsValue.replaceAll(',', ''));
                });
            }

            columns.forEach((column) => {
                if (this.disallowed_averages.includes(column)) {
                    averageRow[column] = '-';
                } else if (column === this.total_column_heading_name) {
                    averageRow[column] = 'Average';
                } else {
                    if (this.calculate_weighted_average && this.weighted_average_columns.includes(column)) {
                        let weightedTotal = 0;
                        for (const row of baseTableData) {
                            if (!row[column]) continue;
                            let percentageValue = parseFloat(row[column].replace('%', '')) / 100;
                            let visitsForRow = parseInt(row[this.weighting_factor].replaceAll(',', '')); // Renamed variable
                            weightedTotal += percentageValue * visitsForRow;
                        }
                        const weightedAverage = totalVisits > 0 ? (weightedTotal / totalVisits) * 100 : 'N/A';
                        averageRow[column] = typeof weightedAverage === 'number' ? Math.round(weightedAverage) : 'N/A';
                    } else {
                        const { total, count } = this.calculateRowValues(baseTableData, column);
                        const averageValue = count > 0 ? total / count : 'N/A';
                        averageRow[column] = typeof averageValue === 'number' ? Math.round(averageValue) : 'N/A';
                    }
                }
            });

            finalTableData.push(averageRow);
            return finalTableData;
        },

        calculateRowValues(baseTableData, column) {
            let total = 0;
            let count = 0;

            for (let row of baseTableData) {
                let value = row[column];

                if (value === null || value === undefined) continue;
                if (value === 'N/A') continue;

                if (typeof value === 'string') {
                    value = value.replace(/%/g, '');
                    value = value.replace(/,/g, '');
                }

                value = parseFloat(value);

                    if (!isNaN(value)) {
                    total += value;
                    count++;
                }
            }

            return { total, count };
        },

        exportTable() {
            let filename = this.exported_file_name
            if(!this.exported_file_name) filename = 'table'
            filename += '.csv'

            let csvData = this.convertToCSV(this.finalTableBody)
            let link = document.createElement('a')
            link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData))
            link.setAttribute('download', filename)
            link.click()
        },

        convertToCSV(data) {
            let csv = ''
            let headings = Object.keys(data[0]).join(',')
            csv += headings + '\n'
            data.forEach((row) => {
            let rowData = Object.values(row).map(cell => {
                if(typeof cell === 'string') {
                    return `"${cell.replace(/"/g, '""')}"`
                }
                else {
                    return cell;
                }
            })
                csv += rowData.join(',') + '\n'
            })
            return csv
        },

        sanitizeNumber(input) {
            if (typeof input === "number" && !isNaN(input)) {
                return input;
            }

            if (typeof input !== "string") {
                return NaN;
            }

            let sanitizedInput = input.trim().replace(/,/g, "");
            if (sanitizedInput.endsWith("%")) {
                sanitizedInput = sanitizedInput.slice(0, -1);
                sanitizedInput /= 100;
            }

            const parsedNumber = Number(sanitizedInput);
            return isNaN(parsedNumber) ? NaN : parsedNumber;
        },

        getRowKey(row) {
            return JSON.stringify(row);
        },

        generateColors(num_colors) {
            const scale = chroma.scale(["#ffffcc", "#800026"]);
            return scale.colors(num_colors);
        },
    }
}
</script>

<style scoped>

</style>