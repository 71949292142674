<template>
    <div class="flex flex-col gap-2">
        <div class="flex flex-row flex-wrap p-4 bg-white rounded-md items-center justify-center gap-4">
            <div class="flex sm:flex-row sm:flex-wrap flex-col gap-2"> 
                <label class="grid place-items-center text-md font-medium text-gray-700">Campaign:</label> 
                <DropdownComponent 
                :items='all_campaigns'
                display="name"
                :selected="this.current_campaign ? this.current_campaign.name : 'Select a Campaign'"
                @user_select="setCurrentCampaign($event)"
                :key="campaign_selector_refresher"
                class="sm:w-96 w-72"/>
            </div>

            <div class="flex flex-row flex-wrap p-4 bg-white rounded-md items-center justify-center gap-2" v-if="this.current_campaign && !loading">
                <button class="btn-primary light-blue" @click="this.show_filter_options = !this.show_filter_options"> Show Filter Options </button>
                <button class="btn-primary light-blue" @click="this.show_download_options = !this.show_download_options"> Show Download Options </button>
                <button class="btn-primary indigo" @click="refreshData"> Refresh </button>
                <div title="Schedule Report" @click="openModal">
                    <ClockIcon class="cursor-pointer w-10 h-10 duration-200 rounded-md p-2 border border-gray-600 hover:bg-gray-300" @click="openModal"> </ClockIcon>
                </div>

            </div>
        </div>

        <ScheduledReportModal v-if="this.current_campaign && !loading" :campaign="this.current_campaign" ref="modalComponent" />

        <div class="p-4 bg-blue-200 rounded-md" v-if="this.current_campaign && !loading && show_download_options">
            <div class="flex flex-row justify-center items-center flex-wrap gap-4">
                <button class="btn-primary indigo" @click="downloadPageAsPNG"> Download PNG </button>
                <button class="btn-primary indigo" @click="downloadPageAsPDF"> Download PDF </button>
                <button class="btn-primary indigo" @click="downloadPageAsExcel"> Download Excel </button>
            </div>
        </div>

        <!-- FILTER SECTION -->
        <div class="sm:p-8 p-4 bg-blue-100 rounded-md sm:grid sm:grid-cols-2 max-sm:flex max-sm:flex-col gap-x-8 gap-y-4" v-if="this.current_campaign && !loading && show_filter_options">
            <div class="sm:grid sm:grid-cols-2 sm:col-start-1 sm:col-end-3 max-sm:flex max-sm:flex-col gap-y-2 gap-x-4">
                <label class="font-medium sm:col-start-1 sm:col-end-3">Ad Set:</label> 
                <DropdownComponent 
                :items='all_runs'
                display="name"
                selected="All Ad Sets"
                @user_select="addRunToFilter($event)"
                :key="dropdown_refresher"
                class="sm:col-start-1 sm:col-end-2 lg:max-w-lg sm:max-w-xs w-full"/>
                <div class="self-center justify-self-center sm:col-start-2 sm:col-end-3 w-full flex justify-center">
                    <span @click="this.include_runs = true" class="options-picker border-l rounded-l-md" :class="{'options-picker-active' : this.include_runs == true}"> Include </span>
                    <span @click="this.include_runs = false" class="options-picker rounded-r-md" :class="{'options-picker-active' : this.include_runs == false}"> Exclude </span>
                </div>
                <div class="sm:col-start-1 sm:col-end-3 flex flex-row flex-wrap gap-1">
                    <label v-for="run in run_filter_array" :key="run" class="text-white bg-indigo-500 rounded-md px-2 py-1 cursor-pointer hover:bg-blue-300 hover:text-black" @click="removeRunFromFilter(run)">{{run.name}}</label>
                </div>
            </div>

            <div class="sm:grid sm:grid-cols-2 sm:col-start-1 sm:col-end-3 flex flex-col gap-y-2 gap-x-4">
                <label class="font-medium sm:col-start-1 sm:col-end-3"> Game: </label>
                <DropdownComponent 
                :items='all_campaign_games'
                display="name"
                selected="All Games"
                @user_select="addGameToFilter"
                class="sm:col-start-1 sm:col-end-2 lg:max-w-lg sm:max-w-xs w-full"/>
                <div class="self-center justify-self-center sm:col-start-2 sm:col-end-3 w-full flex justify-center">
                    <span @click="this.include_games = true" class="options-picker border-l rounded-l-md" :class="{'options-picker-active' : this.include_games == true}"> Include </span>
                    <span @click="this.include_games = false" class="options-picker rounded-r-md" :class="{'options-picker-active' : this.include_games == false}"> Exclude </span>
                </div>
                <div class="sm:col-start-1 sm:col-end-3 flex flex-row flex-wrap gap-1">
                    <label v-for="game in game_filter_array" :key="game" class="bg-white rounded-md px-2 py-1 cursor-pointer hover:bg-blue-300" @click="removeGameFromFilter(game)">{{game.friendly_name}}</label>
                </div>
            </div>

            <div class="sm:grid sm:grid-cols-2 sm:col-start-1 sm:col-end-3 flex flex-col gap-y-2 gap-x-4">
                <label class="font-medium sm:col-start-1 sm:col-end-3"> Country: </label>
                <DropdownComponent 
                :items='all_countries'
                display="name"
                selected="All Countries"
                @user_select="addCountryToFilter"
                class="sm:col-start-1 sm:col-end-2 lg:max-w-lg sm:max-w-xs w-full"/>
                <div class="self-center justify-self-center sm:col-start-2 sm:col-end-3 w-full flex justify-center">
                    <span @click="this.include_countries = true" class="options-picker border-l rounded-l-md" :class="{'options-picker-active' : this.include_countries == true}"> Include </span>
                    <span @click="this.include_countries = false" class="options-picker rounded-r-md" :class="{'options-picker-active' : this.include_countries == false}"> Exclude </span>
                </div>
                <div class="sm:col-start-1 sm:col-end-3 flex flex-row flex-wrap gap-1">
                    <label v-for="country in country_filter_array" :key="country" class="bg-white rounded-md px-2 py-1 cursor-pointer hover:bg-blue-300" @click="removeCountryFromFilter(country)">{{country.name}}</label>
                </div>
            </div>
            
            <div class="sm:grid sm:grid-cols-2 sm:col-start-1 sm:col-end-3 flex flex-col gap-y-2 gap-x-4">
                <div class="flex flex-col gap-2 font-medium sm:col-start-1 sm:col-end-2">
                    <label> Start Date: </label>
                    <input type="date" v-model="start_date" :min="current_campaign.start_date" :max="current_campaign.end_date" class="date-picker"> 
                </div>
                <div class="flex flex-col gap-2 font-medium sm:col-start-2 sm:col-end-3">
                    <label> End Date: </label>
                    <input type="date" v-model="end_date" class="date-picker" :min="current_campaign.start_date" :max="current_campaign.end_date">
                </div>
            </div>

            <div class="sm:grid sm:grid-cols-2 sm:col-start-1 sm:col-end-3 flex flex-col gap-y-2 gap-x-4">
                <label class="font-medium sm:col-start-1 sm:col-end-3"> Impressions Over Time Graph: </label>
                <div class="w-full flex sm:flex-row max-sm:flex-wrap max-sm:gap-1 max-sm:justify-center sm:col-start-1 sm:col-end-3">
                    <span class="options-picker border-l rounded-l-md" :class="{'options-picker-active' : this.impression_grouping == 'Daily'}" @click="this.impression_grouping = 'Daily'"> Daily Impressions </span>
                    <span class="options-picker" :class="{'options-picker-active' : this.impression_grouping == 'Hourly'}" @click="this.impression_grouping = 'Hourly'"> Hourly Impressions </span>
                    <span class="options-picker" :class="{'options-picker-active' : this.impression_grouping == 'TenMinute'}" @click="this.impression_grouping = 'TenMinute'"> Ten Minute Impressions </span>
                    <span class="options-picker rounded-r-md" :class="{'options-picker-active' : this.impression_grouping == 'Minute'}" @click="this.impression_grouping = 'Minute'"> Minute Impressions </span>
                </div>
            </div>

            <div class="col-start-1 col-end-3 grid grid-flow-col gap-4">
                <button class="btn-primary green" @click="getAllKeyFunctions" v-if="this.current_campaign && !loading"> Apply Filters </button>
                <button class="btn-primary red" @click="setCurrentCampaign(this.current_campaign)" v-if="this.current_campaign && !loading"> Reset Filters </button>
            </div>
        </div>

        <section id="element-to-print" class="flex flex-col gap-2">
            <div class="p-4 px-8 bg-white rounded-md text-xl font-medium text-gray-700 text-center flex flex-row items-center lg:justify-between justify-center gap-2 flex-wrap" v-if="current_campaign && !loading">
                <img src="../assets/GamefamLogo.png" class="w-40 h-9 object-cover" alt="gamefam-logo"> 
                {{header_name}} 
                <span class="text-sm text-gray-500">{{header_date}}</span>
                <span class="text-sm text-gray-500"> Last Updated: {{last_updated}}</span>
            </div>

            <div class="flex flex-col gap-2 justify-center items-center h-full" v-if="loading">
                <h2> 
                    Fetching data... This might take a few minutes. 
                </h2>
                <LoadingComponent />
            </div>

            <div class="flex flex-col gap-2" v-if="this.current_campaign && !loading">
                <!-- Cards Section -->
                <div class="grid-container gap-2">
                    <div class="card-container">
                        <div class="inner-card">
                            <label> Impressions Delivered: </label>
                            <span> {{this.impressions_delivered.toLocaleString()}} </span>
                        </div>
                        <div class="inner-card">
                            <label>Target Impressions:</label>
                            <span> {{total_run_target_impressions > 0 ? total_run_target_impressions.toLocaleString() : this.current_campaign.target_impressions.toLocaleString()}} </span>
                        </div>
                    </div>
                    <div class="card-container">
                        <div class="inner-card">
                            <label> Reach </label>
                            <span> {{total_reach.toLocaleString()}} </span>
                        </div>
                        <div class="inner-card">
                            <label> Target Reach </label>
                            <span> {{Math.ceil(this.current_campaign.target_impressions / target_frequency ).toLocaleString()}} </span>
                        </div>
                    </div>
                    <div class="card-container">
                        <div class="inner-card">
                            <label> Avg. Frequency </label>
                            <span> {{ average_frequency }} </span>
                        </div>
                        <div class="inner-card">
                            <label> Target Frequency </label>
                            <span> {{ Math.round(target_frequency, 2).toLocaleString() }} </span>
                        </div>
                    </div>
                    <div class="card-container">
                        <div class="inner-card">
                            <label> Campaign Average View Time: </label>
                            <span> {{avg_view_time}} </span>
                        </div>
                    </div>
                </div>
                
                <div class="p-4 bg-white rounded-md flex flex-col gap-4">
                    <ProgressBar title="Current Pacing" :percentage="impressionsPercentage"/>
                    <ProgressBar title="Target Pacing" :percentage="dateGapPercentage"/>
                </div>

                <div class="bg-white rounded-md p-2">
                    <highcharts :options="imp_over_time_chart_options"></highcharts>
                </div>

                <!-- impressions per experience bar chart  -->
                <div class="bg-white rounded-md p-2">
                    <highcharts :options="imp_per_exp_chart_options"></highcharts>
                </div>

                <!-- Key Campaign Stats -->
                <div class="bg-white rounded-md p-2">
                    <h1 class="text-center border-b-2 mb-2 p-4 text-lg font-bold"> Key Campaign Stats </h1>
                    <TableComponent :table_data="key_advert_stats" :exported_file_name="current_campaign.name+'-key-campaign-stats'" :show_total_row="false" :show_average_row="false"/>
                </div>

                <!-- Experience / Device Breakdown -->
                <div class="bg-white rounded-md p-2">
                    <h1 class="text-center border-b-2 mb-2 p-4 text-lg font-bold"> Experience / Device Breakdown </h1>
                    <TableComponent :table_data="experience_device_breakdown" :exported_file_name="current_campaign.name+'-experience-device-breakdown'" :show_total_row="false" :show_average_row="false"/>
                </div>

                <!-- Impressions by device -->
                <div class="bg-white rounded-md p-2">
                    <h1 class="text-center border-b-2 mb-2 p-4 text-lg font-bold"> Impressions By Device </h1>
                    <TableComponent :table_data="impressions_by_device" :exported_file_name="current_campaign.name+'-impressions-by-device'" :show_total_row="false" :show_average_row="false"/>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ProgressBar from '@/components/UtilityComponents/ProgressBar.vue'
import ScheduledReportModal from '@/components/ScheduledReportModal.vue'
import TableComponent from '@/components/TableComponent.vue'
import {Chart} from 'highcharts-vue'
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs'
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx/xlsx.mjs';
import html2canvas from 'html2canvas';
import { ClockIcon } from "@heroicons/vue/outline"
// eslint-disable-next-line no-unused-vars
import Highcharts from 'highcharts'
import moment from 'moment';

const imp_per_exp_chart_options = {
    chart: {
        type: 'column',
    },
    title: {
        text: 'Impressions Per Experience',
    },
    xAxis: {
        categories: ['Experiences'],
    },
    yAxis: {
        title: {
            text: 'Impressions',
        },
        labels: {
            overflow: 'justify',
            formatter: function() {
                return this.value >= 1000000 ? this.value / 1000000 + 'M' : this.axis.defaultLabelFormatter.call(this);
            }
        },
    },
    credits: {
        enabled: false,
    },
    series: [],
}

const imp_over_time_chart_options = {
    chart: {
        zoomType: 'x'
    },
    title: {
        text: 'Impressions Over Time',
    },
    subtitle: {
        text: 'Timezone: ',
    },
    plotOptions: {
        line: {
            connectNulls: false
        }
    },
    xAxis: {
        type: 'datetime',
        labels: {
            format: '{value: %b %e}'
        },
    },
    yAxis: {
        title: {
            text: 'Impressions',
            formatter: function() {
                return this.value >= 1000000 ? this.value / 1000000 + 'M' : this.axis.defaultLabelFormatter.call(this);
            }
        }
    },
    series: [{
        name: 'Impressions',
        data: []
    },
    {
        name: 'Unique Impressions',
        data: []
    }],
    credits: {
        enabled: false,
    },
    impression_grouping: 'Daily',
    tooltip: {
        formatter: function () {
            if(this.series.chart.options.impression_grouping != 'Daily')
                return Highcharts.time.dateFormat('%A, %B %e, %l:%M%P', this.x) + '<br>' +
                    this.series.name + ': <b>' + this.y.toLocaleString() + '</b>';

            return Highcharts.time.dateFormat('%A, %B %e', this.x) + '<br>' +
            this.series.name + ': <b>' + this.y.toLocaleString() + '</b>';
        }
    },
    exporting: {
    }
}

export default {
    data() {
        return {
            start_date: null,
            end_date: null,
            header_name: '',
            header_date: '',
            last_updated: '',
            key_advert_stats: [],
            experience_device_breakdown: [],
            impressions_by_device: [],
            all_campaigns: [],
            all_campaign_games: [],
            all_runs: [],
            all_countries: [],
            run_filter_array: [],
            country_filter_array: [],
            game_filter_array: [],
            imp_per_exp_chart_options: imp_per_exp_chart_options,
            imp_over_time_chart_options: imp_over_time_chart_options,
            current_campaign: null,
            user_timezone: null,
            average_frequency: 0,
            avg_view_time: 0,
            total_reach: 0,
            impressions_delivered: 0,
            total_run_target_impressions: 0,
            dropdown_refresher: 0,
            campaign_selector_refresher: 0,
            target_frequency: 0,
            impression_grouping: 'Daily',
            include_games: true,
            include_countries: true,
            include_runs: true,
            show_filter_options: false,
            show_download_options: false,
            loading: false,
        }
    },
    components: {
        ProgressBar,
        ScheduledReportModal,
        TableComponent,
        ClockIcon,
        highcharts: Chart,
    },
    watch: {
        header_name() {
            this.imp_over_time_chart_options.exporting.filename = this.header_name + " - Impressions Over Time - " + this.simplified_timezone_string
        },
    },
    methods: {
        async getAllCampaigns() {
            await this.$http.get('/campaigns/get/all').then( ({data}) => {
                this.all_campaigns = data
            })
        },
        async getCampaignRuns() {
            await this.$http.get(`/runs/get-campaign-runs/${this.current_campaign.uuid}`).then( ({data}) => {
                this.all_runs = data
            })
        },
        async getCampaignGames() {
            await this.$http.get('/campaign-games/' + this.current_campaign.uuid)
                .then( ({data}) => {
                    this.all_campaign_games = data
                })
        },
        async getCardStats() {
            await this.$http.post('nd-reporting/card-stats', this.request)
                .then( (response) => {
                    this.impressions_delivered = response.data['Impressions']
                    this.avg_view_time = response.data['Avg. View Time']
                    this.target_frequency = response.data['Target Frequency']
                }).catch( (error) => {
                    console.error(error)
                    this.loading = false
                })
        },
        async getKeyAdvertStats() {
            await this.$http.post('nd-reporting/key-advert-stats', this.request)
            .then( (response) => {
                this.key_advert_stats = response.data
                this.filterKeyData()
            }).catch( (error) => {
                console.error(error)
                this.loading = false
            })
        },
        async getImpressionsOverTime() {
            await this.$http.post('nd-reporting/imp-over-time', this.request)
                .then( (response) => {
                    const imp_over_time = response.data
                    this.imp_over_time_chart_options.series[0].data = []
                    this.imp_over_time_chart_options.series[1].data = []
                    this.imp_over_time_chart_options.subtitle.text = 'Timezone: ' + this.simplified_timezone_string
                    this.imp_over_time_chart_options.impression_grouping = this.impression_grouping
                    imp_over_time.forEach(row => {
                        const unix_time = moment.tz(row['Time'], 'Africa/Abidjan').valueOf();
                        this.imp_over_time_chart_options.series[0].data.push([unix_time, row['Impressions']]);
                        this.imp_over_time_chart_options.series[1].data.push([unix_time, row['Unique Impressions']]);
                    })
                }).catch( (error) => {
                    console.error(error)
                    this.loading = false
                })
        },

        async getRendersOverTime() {
            // Remove existing 'Renders' series
            this.imp_over_time_chart_options.series = this.imp_over_time_chart_options.series.filter(series => series.name !== 'Renders');

            await this.$http.post('nd-reporting/ren-over-time', this.request)
                .then((response) => {
                    const ren_over_time = response.data;
                    const renderData = [];
                    ren_over_time.forEach(row => {
                        const unix_time = moment.tz(row['Time'], 'Africa/Abidjan').valueOf();
                        renderData.push([unix_time, row.Renders]);
                    });
                    this.imp_over_time_chart_options.series.push({name: 'Renders', data: renderData});
                }).catch((error) => {
                    console.error(error);
                    this.loading = false;
                });
        },
        async getExperienceDeviceBreakdown() {
            await this.$http.post('nd-reporting/exp-device-breakdown', this.request)
                .then( (response) => {
                    this.experience_device_breakdown = response.data
                }).catch( (error) => {
                    console.error(error)
                    this.loading = false
                })
        },
        async getImpressionsByDevice() {
            await this.$http.post('nd-reporting/imp-by-device', this.request)
                .then( (response) => {
                    this.impressions_by_device = response.data
                }).catch( (error) => {
                    console.error(error)
                    this.loading = false
                })
        },
        async refreshData() {
            await this.$http.post('nd-reporting/forget-stats', this.request)
                .catch( (error) => {
                    console.error(error)
                })
            await this.getAllKeyFunctions()
        },
        openModal() {
            this.$refs.modalComponent.openModal();
        },
        filterKeyData() {
            this.imp_per_exp_chart_options.series = []
            this.total_reach = 0
            this.average_frequency = 0

            let total_frequency = 0
            let frequency_count = 0

            this.key_advert_stats.forEach(stat => {
                // sets the data for the chart
                this.imp_per_exp_chart_options.series.push({name: stat.Experience, data: [stat.Impressions]})

                // calculates the total reach
                total_frequency += parseFloat(stat.Frequency)
                frequency_count = frequency_count + 1

                // calculates the total frequency
                this.total_reach += stat.Reach
            });

            this.average_frequency = total_frequency / frequency_count
            this.average_frequency = this.average_frequency.toFixed(2)
        },
        async setCurrentCampaign(advert) {
            this.loading = true

            this.run_filter_array = []
            this.total_run_target_impressions = 0
            this.country_filter_array = []
            this.getCountries()
            this.game_filter_array = []
            this.current_campaign = advert
            this.start_date = this.current_campaign.start_date
            this.end_date = this.current_campaign.end_date
            this.impression_grouping = 'Daily'

            await this.getCampaignRuns()
            await this.getCampaignGames()
            await this.getAllKeyFunctions()

            this.loading = false
        },
        async getAllKeyFunctions() {
            this.loading = true
            this.setStoreVariables()
            await this.getCardStats()
            await this.getKeyAdvertStats()
            await this.getExperienceDeviceBreakdown()
            await this.getImpressionsByDevice()
            await this.getImpressionsOverTime()
            await this.getRendersOverTime()

            this.buildHeaderName()
            await this.getLastUpdatedTime()
            this.loading = false
        },
        setStoreVariables() {
            this.$store.commit('ndr_module/setCurrentCampaign', this.current_campaign);
            this.$store.commit('ndr_module/setSelectedCountries', this.country_filter_array);
            this.$store.commit('ndr_module/setIncludeCountriesBool', this.include_countries);
            this.$store.commit('ndr_module/setSelectedRuns', this.run_filter_array);
            this.$store.commit('ndr_module/setIncludeRunsBool', this.include_runs);
            this.$store.commit('ndr_module/setSelectedGames', this.game_filter_array);
            this.$store.commit('ndr_module/setIncludeGamesBool', this.include_games);
            this.$store.commit('ndr_module/setStartDate', this.start_date);
            this.$store.commit('ndr_module/setEndDate', this.end_date);
            this.$store.commit('ndr_module/setImpressionGrouping', this.impression_grouping);
        },
        async getLastUpdatedTime() {
            await this.$http.post('nd-reporting/last-updated', this.request)
                .then( ({data}) => {
                    this.last_updated = data
                }).catch( (error) => {
                    console.error(error)
                    this.loading = false
                })
        },
        async getCountries(){
            return this.$http_request(this.$http.get, '/countries')
                .then(({data}) => {
                    this.all_countries = data
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },
        buildHeaderName() {
            let name = this.current_campaign.name

            if(this.start_date && this.end_date) {
                this.header_date = ' From ' + this.advertStartDateString + ' To ' + this.advertEndDateString
            }
                
            this.header_name = name
        },
        downloadPageAsPNG() {
            var node = document.getElementById('element-to-print');
            htmlToImage.toPng(node)
            .then((dataUrl) => {
                download(dataUrl, this.current_campaign.name);
            })
        },
        downloadPageAsPDF() {
            var node = document.getElementById('element-to-print');
            html2canvas(node, {backgroundColor:'#E3E3E3'}).then(function (canvas) {

                var imgWidth = 210; 
                var pageHeight = 295;  
                var imgHeight = canvas.height * imgWidth / canvas.width;
                var heightLeft = imgHeight;

                var doc = new jsPDF('p', 'mm');
                var position = 0;

                doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }
                doc.save( 'file.pdf');
            })
        },
        downloadPageAsExcel() {
            const dataSets = [
                {
                    data: this.key_advert_stats,
                    heading: `Campaign Summary`,
                    columnNames: Object.keys(this.key_advert_stats[0])
                },
                {
                    data: this.imp_over_time_chart_options.series[0].data.map(dataItem => ({
                        Time: moment.tz(dataItem[0], 'Africa/Abidjan').format('YYYY-MM-DD HH:mm:ss'),
                        Impressions: dataItem[1]
                    })),
                    heading: `Impressions Over Time`,
                    columnNames: ['Time', 'Impressions', 'Timezone: ' + this.simplified_timezone_string]
                },
                {
                    data: this.experience_device_breakdown,
                    heading: `Experience-Device Breakdown`,
                    columnNames: Object.keys(this.experience_device_breakdown[0])
                },
                {
                    data: this.impressions_by_device,
                    heading: `Impressions By Device`,
                    columnNames: Object.keys(this.impressions_by_device[0])
                },
            ];

            const workbook = XLSX.utils.book_new();

            dataSets.forEach(({ data, heading, columnNames }) => {
                const sheet = XLSX.utils.json_to_sheet(data, { header: columnNames });
                XLSX.utils.book_append_sheet(workbook, sheet, heading);
            });

            XLSX.writeFile(workbook, `${this.current_campaign.name}.xlsx`);
        },
        addRunToFilter(run) {
            this.run_filter_array.push(run)
            this.all_runs = this.all_runs.filter( el => el.uuid !== run.uuid)
        },
        removeRunFromFilter(run) {
            this.run_filter_array = this.run_filter_array.filter( el => el.uuid !== run.uuid)
            this.all_runs.push(run)
            this.all_runs.sort((a, b) => a.name.localeCompare(b.name))

            if(this.run_filter_array.length == 0)
                this.total_run_target_impressions = 0
        },
        addCountryToFilter(country) {
            this.country_filter_array.push(country)
            this.all_countries = this.all_countries.filter( el => el.uuid !== country.uuid)
        },
        removeCountryFromFilter(country) {
            this.country_filter_array = this.country_filter_array.filter( el => el.uuid !== country.uuid)
            this.all_countries.push(country);
            this.all_countries.sort((a, b) => {
                if (a.name === 'United States of America') return -1;
                if (b.name === 'United States of America') return 1;
                return a.name.localeCompare(b.name);
            });
        },
        addGameToFilter(game) {
            this.game_filter_array.push(game)
            this.all_campaign_games = this.all_campaign_games.filter( el => el.uuid !== game.uuid)
        },
        removeGameFromFilter(game) {
            this.game_filter_array = this.game_filter_array.filter( el => el.uuid !== game.uuid)
            this.all_campaign_games.push(game)
            this.all_campaign_games.sort((a, b) => a.name.localeCompare(b.name))
        },
        calculateRunTotalTargetImpressions() {
            this.total_run_target_impressions = 0

            this.run_filter_array.forEach(run => {
                this.total_run_target_impressions += run['target_impressions']
            })
        },
        getSimplifiedTimezoneString() {
            const all_timezones = this.$store.getters['getPossibleTimezones']
            const timezone = all_timezones.find(tz => tz.value === this.user_timezone);

            return timezone ? timezone.name : null;
        },
        formatDate(dateString) {
            return moment(dateString).format('YYYY-MM-DD');
        },
        getStoreVariables() {
            this.current_campaign = this.$store.getters['ndr_module/getCurrentCampaign'];
            this.campaign_selector_refresher++
            this.country_filter_array = this.$store.getters['ndr_module/getSelectedCountries'];
            this.include_countries = this.$store.getters['ndr_module/getIncludeCountriesBool'];
            this.run_filter_array = this.$store.getters['ndr_module/getSelectedRuns'];
            this.include_runs = this.$store.getters['ndr_module/getIncludeRunsBool'];
            this.game_filter_array = this.$store.getters['ndr_module/getSelectedGames'];
            this.include_games = this.$store.getters['ndr_module/getIncludeGamesBool'];
            this.start_date = this.$store.getters['ndr_module/getStartDate'];
            this.end_date = this.$store.getters['ndr_module/getEndDate']
            this.impression_grouping = this.$store.getters['ndr_module/getImpressionGrouping'];

            if(this.current_campaign != null) {
                this.getCampaignRuns()
                this.getCampaignGames()
                this.getAllKeyFunctions()
            }
        }
    },
    computed: {
        request() {
            return {
                campaign_uuid: this.current_campaign ? this.current_campaign.uuid : [],
                country_filter: this.country_filter_array ? this.country_filter_array : [],
                include_countries: this.include_countries,
                run_filter: this.run_filter_array ? this.run_filter_array : [],
                include_runs: this.include_runs,
                game_filter: this.game_filter_array ? this.game_filter_array : [],
                include_games: this.include_games,
                start_date: this.start_date,
                end_date: this.end_date,
                impression_grouping: this.impression_grouping,
            }
        },
        impressionsPercentage() {
            if(!this.current_campaign || typeof(this.current_campaign) !='object')
                return 0

            if(!this.current_campaign['target_impressions'])
                return 0

            if(this.run_filter_array.length == 0)
                return Math.floor(this.impressions_delivered / this.current_campaign['target_impressions'] * 100)
            
            this.calculateRunTotalTargetImpressions()

            return Math.floor(this.impressions_delivered / this.total_run_target_impressions * 100)
        },
        dateGapPercentage() {
            if(!this.current_campaign)
                return 0

            const advert = {
                start_date: this.start_date,
                end_date: this.end_date
            }

            return this.$advertDateGapPercentage(advert, "campaigns", this.user_timezone)
        },
        advertStartDateString() {
            return moment(this.start_date).format('MMMM D, YYYY');
        },
        advertEndDateString() {
            return moment(this.end_date).format('MMMM D, YYYY');
        }
    },
    async created(){
        this.getAllCampaigns()
        this.getCountries()
        this.user_timezone = await this.$store.dispatch('login_module/getUserTimezone')
        this.simplified_timezone_string = this.getSimplifiedTimezoneString()
        this.getStoreVariables()
    }
}
</script>

<style lang="postcss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');


.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.card-container {
    @apply bg-white rounded-md flex flex-col items-center justify-center p-4 gap-4;
}

.date-picker {
    @apply lg:max-w-lg sm:max-w-xs w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md;
}

.inner-card {
    @apply flex flex-col gap-2 items-center;
}

.card-container span {
    @apply text-3xl font-bold;
}

:deep .dp__action_button {
    color: black; 
    background-color: white; 
    opacity: 1 !important; 
    border: 1px solid black;
    padding: 0.8em 0.4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

:deep(.dp__action_button:hover) {
    color: white; 
    background-color: blue; 
}

</style>