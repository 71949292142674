<template>
    <div class=" min-h-screen flex">
        <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div class="mx-auto w-full max-w-sm lg:w-96">

                <div>
                    <img class="h-24" src="../assets/admonitor-full-logo.png" alt="admonitor-logo" loading="lazy"/>
                    <h2 class="mt-6 text-3xl font-extrabold text-gray-900">Sign Up By Completing This Form!</h2>
                </div>

                <div class="mt-8">
                    <div>
                        <div class="mt-6">
                            <form class="space-y-6" v-on:submit.prevent="createAccount">
                                <div>
                                    <label for="username" class="block text-sm font-medium text-gray-700"> Username </label>
                                    <div class="mt-1">
                                        <input id="username" name="username" type="text" autocomplete="off" v-model="user.name" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                    </div>
                                </div>

                                <div>
                                    <label for="email" class="block text-sm font-medium text-gray-700"> Email address </label>
                                    <div class="mt-1">
                                        <input id="email" name="email" type="email" autocomplete="off" v-model="user.email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                    </div>
                                </div>

                                <div class="space-y-1">
                                    <label for="password" class="block text-sm font-medium text-gray-700"> Password </label>
                                    <div class="mt-1">
                                        <input id="password" name="password" type="password" v-model="user.password" autocomplete="off" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                    </div>
                                </div>

                                <div class="space-y-1">
                                    <label for="password" class="block text-sm font-medium text-gray-700"> Confirm Password </label>
                                    <div class="mt-1">
                                        <input id="confirm-password" v-model="confirm_password" name="confirm-password" type="password" autocomplete="off" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                                    </div>
                                </div>

                                <div>
                                    <button type="submit" v-on:submit.prevent="createAccount" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Sign Up</button>
                                </div>

                                <a href="/login" class="underline text-indigo-400 hover:text-indigo-600"> Already have an account? Click here to login</a>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="hidden lg:block relative w-0 flex-1">
            <img class="absolute inset-0 h-full w-full object-cover" src="https://images.unsplash.com/photo-1460533078824-f51edbff2726?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80" alt="" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'SignUp',
    data() {
        return {
            user: {
                name: '',
                email: '',
                password: '',
                moderated: false,
            },  
            confirm_password: '', 
        }
    },
    methods: {
        createAccount() {
            // Validate Text Fields
            if (!this.validateTextFields())
                return

            // Register User
            this.$store.dispatch('login_module/registerUser', this.user)
                .then( () => {
                    this.$notify({type: "success", text: 'Account Created, Please Wait For Approval'})
                    this.$router.push('/login')
                }).catch( () => {
                    this.$notify({type: "error", text: "Username / Email Is Already In Use!"})
                    this.user.name = this.user.email = this.user.password = this.confirm_password = ''
                })
        },
        validateTextFields() {
            if ( this.user.name.trim() == '' || this.user.email.trim() == '' || this.user.password.trim() == '' || this.confirm_password.trim() == '' ) {
                this.$notify({type: "error", text: 'Missing Fields Detected!'})
                this.user.name = this.user.email = this.user.password = this.confirm_password = ''
                return false
            }
            if ( this.user.password != this.confirm_password ) {
                this.$notify({type: "error", text: 'Passwords Do Not Match!'})
                this.confirm_password = this.user.password = ''
                return false
            }
            if (!this.validateEmail(this.user.email)) {
                this.$notify({type: "error", text: 'Invalid Email Format'})
                this.user.email = ''
                return false
            }

            return true
        },
        validateEmail(email) {
            return String(email).toLowerCase().match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
        },
    },
}
</script>