<template>
    <div class="flex flex-col gap-2">
        <form @submit.prevent="submitUpdate">
            <div v-for="field in form_fields" :key="field.name" class="mb-4">
                <label class="block font-bold mb-2">{{ field.label }}</label>
                <div class="text-sm text-gray-500 mb-2">
                    {{ field.rules.required ? 'Required' : 'Optional' }}
                    {{ field.rules.min ? ', Min: ' + field.rules.min : '' }}
                    {{ field.rules.max ? ', Max: ' + field.rules.max : '' }}
                    {{ field.rules.boolean ? ', Boolean ' : ''}}
                </div>
                <input v-if="field.type === 'number'" type="number" v-model.number="form_data[field.name]" 
                    :min="field.rules.min" 
                    :max="field.rules.max" 
                    :required="field.rules.required === 'required'"
                    :step="field.rules.boolean ? '1' : 'any'"
                    :class="isValueChanged(field.name) ? 'w-full py-2 px-3 border border-blue-500 rounded-md focus:outline-none focus:ring focus:ring-indigo-200 focus:border-indigo-500' : 'w-full py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200 focus:border-indigo-500'">
                <input v-else type="text" v-model="form_data[field.name]" :class="isValueChanged(field.name) ? 'w-full py-2 px-3 border border-blue-500 rounded-md focus:outline-none focus:ring focus:ring-indigo-200 focus:border-indigo-500' : 'w-full py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200 focus:border-indigo-500'">
            </div>
            <div class="flex flex-row gap-2">
                <button @submit.prevent="submitUpdate" class="btn-primary green">Submit</button>
                <button @click.prevent="resetForm" class="btn-primary red">Reset</button>
            </div>
        </form>

        <!-- Revision History -->
        <TableComponent v-if="history_data" :table_data="history_data" :default_sort_by="'Id'" :show_total_row="false"></TableComponent>
    </div>
</template>

<script>
import TableComponent from '@/components/TableComponent.vue'

export default {
    name: "DynamicForm",
    components: {
        TableComponent,
    },
    props: {
        formFields: {
            required: true,
        },
        formData: {
            required: true,
        },
        tableData: {
            required: true
        },
        defaultFormData: {
            required: true,
        },
        api: {
            required: true,
        }
    },
    data() {
        return {
            form_fields: this.formFields,
            form_data: this.formData,
            history_data: this.tableData,
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        submitUpdate(){
            if(!this.anyValueChanged()) {
                this.$notify({type: 'error', text: 'No values have changed'}) 
                return
            }

            if(!confirm('Are you sure you want to submit?')) return
            const toasts = {success: 'Updated Successfully', error: 'Failed To Update'}
            return this.$http_request(this.$http.post, this.api, toasts, this.form_data)
                .then(({data}) => {
                    this.form_fields = data.form
                    this.history_data = data.revisionHistory
                    this.setFormData()
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },

        resetForm(){
            if(!this.anyValueChanged()) {
                this.$notify({type: 'error', text: 'No values have changed'}) 
                return
            }
            if(!confirm('Are you sure you want to reset all values to their initial state?')) return
            this.form_data = JSON.parse(JSON.stringify(this.defaultFormData));
        },

        anyValueChanged() {
            for (const fieldName in this.form_data) {
                if (this.isValueChanged(fieldName)) {
                    return true;
                }
            }
            return false;
        },

        isValueChanged(fieldName) {
            return this.form_data[fieldName] !== this.defaultFormData[fieldName];
        },

        setFormData() {
            this.$emit('setFormData', this.form_fields)
        }
    }
}
</script>

<style scoped>

</style>