<template>
<div class="">
    <div class="p-4 h-90 flex flex-col">
        <textarea v-model="jsonInput" class="h-80 w-full h-32 border rounded p-2"></textarea>
        <div class="flex justify-around my-3">
        </div>
        <p v-if="validJsonLength" class="text-green-500">Valid Length</p>
        <p v-else class="text-red-500">Invalid Length</p>
        <p v-if="validJson" class="text-green-500">Valid JSON</p>
        <p v-else class="text-red-500">Invalid JSON</p>
        <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full" @click="submit">Submit</button>
    </div>
    <div class="p-4 h-90 flex flex-col">
        <div class="bg-gray-200 p-4" v-for="dialog in dialogs" :key="dialog.uuid">
            <textarea class="large-text-field textarea-non-editable" v-model="dialog.dialog_json"></textarea>
            <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full" @click="deleteDialog(dialog.uuid)">Delete</button>
        </div>
    </div>
</div>


</template>

<script>
import {v4 as uuidv4} from 'uuid';

const MAX_VARCHAR_LENGTH = 65535
export default {
data() {
    return {
        jsonInput: '',
        dialogs: [],
    }
},

mounted(){
    this.getExisitngDialogs()
},

methods: {
    submit(){
        if(!this.validJson || !this.validJsonLength) {
            this.$notify({type: 'error', text: 'Invalid JSON or Length'})
            return
        }
        const toasts = {success: 'Dialog added successfully', error: 'Failed to add dialog'}
        const request = {
            conversationName: 'Untitled',
            conversationId: uuidv4(),
            dialogJson: this.jsonInput,
        }
        this.$http_request(this.$http.post, `/add-dialog`, toasts, request)
            .then(() => {
                this.jsonInput = ''
                this.getExisitngDialogs()
            })
            .catch((error) => {
                console.error(error.message)
            })
    },

    getExisitngDialogs(){
        const toasts = {success: 'Dialogs fetched successfully', error: 'Failed to fetch dialogs'}
        this.$http_request(this.$http.get, `/get-dialogs`, toasts)
            .then((response) => {
                this.dialogs = response.data
            })
            .catch((error) => {
                console.error(error.message)
            })
    },

    deleteDialog(uuid) {
        if(!confirm('Are You Sure You Want To Delete This Dialog?')) return;
        const toasts = {success: 'Dialog deleted successfully', error: 'Failed to delete dialog'}
        this.$http_request(this.$http.post, `/delete-dialog/${uuid}`, toasts)
            .then(() => {
                this.dialogs = this.dialogs.filter(dialog => dialog.uuid !== uuid)
            })
            .catch(error => {
                console.error(error)
            })
    }
},

computed: {
    validJson() {
        try {
            JSON.parse(this.jsonInput)
            return true
            } catch (e) {
                return false
        }   
    },
    validJsonLength() {
        if(this.jsonInput.length === 0) return false;
        if(this.jsonInput.length >= MAX_VARCHAR_LENGTH) return false;
        return true;
    }
}
}
</script>

<style scoped>
.h-80 {
    height: 80vh;
}
.h-90 {
    height: 90vh;
}
.h-100 {
    height: 100vh;
}
.large-text-field {
    width: 100%;
    height: 80vh;
}
.textarea-non-editable{
    pointer-events: none;
}
</style>