import storeFunctions from '@/plugins/StorePlugin.js'
import axios from 'axios'

export default class Advert {
    advert_type = 'adverts'
    advert_type_capitalised = 'Adverts'
    advert_type_singular = 'advert'
    advert_type_singular_capitalised = 'Advert'

    fetchAdverts(){
        return axios.get(this.advert_type)
    }
    updateAdvert(advert_form){
        const toasts = {'success': `${this.advert_type_singular_capitalised} Updated`, 'error': `Error Updating ${this.advert_type_singular_capitalised}`}
        return storeFunctions.http_request(axios.post, `${this.advert_type}/update`, toasts, advert_form)
    }
    deleteAdvert(uuid){
        const toasts = {'success': `${this.advert_type_singular_capitalised} Deleted`, 'error': `Error Deleting ${this.advert_type_singular_capitalised}`}
        return storeFunctions.http_request(axios.delete, `${this.advert_type}/${uuid}`, toasts)
    }
    addItemsToAdvert({uuid, item_uuids, item_type}){
        const cap_item_type = storeFunctions.capitalizeFirstLetter(item_type)
        let request = {
            uuids: item_uuids,
            uuid: uuid,
        }
        const toasts = {'success': `${cap_item_type} Added to ${this.advert_type_singular_capitalised}`, 'error': `Error Adding ${cap_item_type} to ${this.advert_type_singular_capitalised}`}
        return storeFunctions.http_request(axios.post, `/${this.advert_type_singular}-${item_type}`, toasts, request)            
    }
    removeItemsFromAdvert({uuid, item_uuids, item_type}){
        const cap_item_type = storeFunctions.capitalizeFirstLetter(item_type)
        const request = {
            uuid: uuid,
            uuids: item_uuids,
        }
        const api_endpoint = `${this.advert_type_singular}-${item_type}/delete`
        const toasts = {'success': `${cap_item_type} Removed From ${this.advert_type_singular_capitalised}`, 'error': `Error Removing ${cap_item_type} from ${this.advert_type_singular_capitalised}`}
        return storeFunctions.http_request(axios.post, api_endpoint, toasts, request)
    }
    copyAdvert({uuid}){
        const api_endpoint = `${this.advert_type}/copy/${uuid}`
        const toasts = {'success': `${this.advert_type_singular_capitalised} Copied`, 'error': `Error Copying ${this.advert_type_singular_capitalised}`}
        return storeFunctions.http_request(axios.post, api_endpoint, toasts)
    }
}