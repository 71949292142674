<template>
    <div class="bg-white rounded-md p-4 w-full text-xl">
        <div v-if="!is_editing_advert" class="flex flex-col gap-2">
            <span> <b>Name:</b> <span class="capitalize">{{ current_advert.name }}</span></span>
            <span> <b>Ad Set Start:</b> {{ current_advert.start_date }} {{ convertTo12Hour(current_advert.start_time.split(':').slice(0, 2).join(':')) }}</span>
            <span> <b>Ad Set End:</b> {{ current_advert.end_date }} {{ convertTo12Hour(current_advert.end_time.split(':').slice(0, 2).join(':')) }}</span>
            <span> <b>Impressions Received:</b> {{ current_advert.impressions_sent.toLocaleString() }}</span>
            <span> <b>Target Impressions: </b>{{ current_advert.target_impressions.toLocaleString() }}</span>
            <span> <b>Is Rate Limited: </b>{{ current_advert.is_rate_limited == 0 ? 'False' : 'True' }}</span>
            <button v-if="!is_editing_advert" @click="is_editing_advert = true" class="btn-primary green self-center w-72"> Edit </button>
        </div>
        <div v-else class="flex flex-col gap-2">
            Name: <input type='text' v-model="advert_edit.name" maxlength="50">
            Ad Set Start: <input type="date" v-model="advert_edit.start_date"> <input type="time" v-model="advert_edit.start_time">
            Ad Set End: <input type="date" v-model="advert_edit.end_date"> <input type="time" v-model="advert_edit.end_time">
            Target Impressions: <input type='number' v-model="advert_edit.target_impressions">
            <div class="flex flex-row flex-wrap items-center justify-center gap-2">
                <button v-if="is_editing_advert" @click="cancelEdit" class="btn-primary red w-72"> Cancel </button>
                <button v-if="is_editing_advert" @click="submitAdvertEdit" class="btn-primary indigo w-72"> Submit </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AdsetInfoEditor",
    components: {},
    props: {
        advert: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            is_editing_advert: false,
            current_advert: [],
            advert_edit: [],
        }
    },
    computed: {},
    watch: {
        advert: {
            handler: function() {
                this.current_advert = JSON.parse(JSON.stringify(this.advert));
                this.advert_edit = JSON.parse(JSON.stringify(this.advert));
            },
        }
    },
    created() {
        this.current_advert = JSON.parse(JSON.stringify(this.advert));
        this.advert_edit = JSON.parse(JSON.stringify(this.advert));
    },
    mounted() {
    },
    methods: {
        submitAdvertEdit() {
            const toasts = {'success': 'Ad set updated successfully', 'error': 'Sorry there was an error updating the ad set'}    
            this.$http_request(this.$http.post, '/runs/update', toasts, this.advert_edit)
                .then( ({data})=> {
                    this.is_editing_advert = false
                    this.current_advert = data
                    this.advert_edit = data
                    this.$emit('refreshRunInfo')
                });
        },
        cancelEdit() {
            this.advert_edit = this.advert
            this.is_editing_advert = false
        },

        convertTo12Hour(time) {
            let [hour, minute] = time.split(':');
            hour = parseInt(hour);
            const ampm = hour >= 12 ? 'PM' : 'AM';
            hour = hour % 12;
            hour = hour ? hour : 12; // the hour '0' should be '12'
            minute = minute.length === 1 ? `0${minute}` : minute; // ensuring minute is two digits
            return `${hour}:${minute} ${ampm}`;
        }
    }
}
</script>

<style scoped>

</style>