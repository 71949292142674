<template>
    <div class="w-full flex flex-row gap-2 items-center justify-center mt-4">
        <button class="btn-primary indigo" v-if="this.current_page > 1" @click="emitClickFunction(true)"> Back </button>
        <span> Page {{current_page}} of {{total_pages}} </span>
        <button class="btn-primary indigo" v-if="this.total_pages > this.current_page" @click="emitClickFunction(false)"> Next </button>
    </div>
</template>

<script>
export default {
    name: "PaginationComponent",
    props: {
        total_pages: {
            default: 0,
            required: true,
            type: Number
        },
        current_page: {
            default: 0,
            required: true,
            type: Number
        },
    },
    methods: {
        emitClickFunction(page_back) {
            this.$emit('changePage', page_back)
        }
    }
}
</script>

<style scoped>

</style>