import storeFunctions from '@/plugins/StorePlugin.js'
import ItemFactory from '@/classes/Item/ItemFactory.js'

const state = {
    items: {},
    relations: {}
}

const getters = {
    getItem: (state) => ({item_type, uuid}) => {
        return state.items?.[item_type]?.[uuid] ?? {}
    },
    getItemRelation: (state) => ({item_type, uuid, relation}) => {
        return state.relations?.[item_type]?.[relation]?.[uuid] ?? {}
    }
}

const actions = {
    async fetchItem({commit}, {item_type, uuid}){
        const api = `item/${item_type}/${uuid}`
        return storeFunctions.http_request(this.$http.get, api)
            .then(({data}) => {
                const all_item_data = {...data}
                let item = new ItemFactory(item_type, all_item_data).items.pop()
                commit('setItem',{item_type, uuid, item})
            })
            .catch((error) => {
                console.error(error.message)
            })
    },
    updateItem({dispatch}, {item_type, uuid, form}){
        const toasts = {'success': 'Item Updated', 'error': 'Error Updating Item'}
        storeFunctions.http_request(this.$http.patch, `/item/${item_type}/${uuid}`, toasts, form)
            .then(() => {
                dispatch('fetchItem', {item_type, uuid})
            })
    },
    fetchRelation({commit}, {item_type, uuid, relation}){
        const api = `/item/relation/${relation}/${item_type}/${uuid}`
        storeFunctions.http_request(this.$http.get, api)
            .then(({data}) => {
                commit('setRelation', {item_type, uuid, relation, 'relations':data})
            })
    }
}

const mutations = {
    setItem(state, {item_type, uuid, item}){
        if(!('items' in state)){
            state.items = {}
        }
        if(!(item_type in state.items)) {
            state.items[item_type] = {}
        }
        state.items[item_type][uuid] = item
    },
    setRelation(state, {item_type, uuid, relation, relations}){
        if(!('relations' in state)){
            state.relations = {}
        }
        if(!(item_type in state.relations)){
            state.relations[item_type] = {}
        }
        if(!(relation in state.relations[item_type])){
            state.relations[item_type][relation] = {}
        }

        state.relations[item_type][relation][uuid] =relations
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}