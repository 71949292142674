import Item from '@/classes/Item/Item.js'
import ItemProperty from '@/classes/Item/ItemProperty.js'
export default class Game extends Item {
    constructor(params){
        super(params)
        this.unpack(params)
    }
    item_type = 'game'
    name = new ItemProperty({
        params: {
            name: 'name',
            friendly_name: 'Name',
        },
        default_type: 'displayed_not_modifiable'
    })
    place_id = new ItemProperty({
        params: {
            name: 'place_id',
            friendly_name: 'Place ID',
        },
        default_type: 'displayed_not_modifiable'
    })
    ga_id = new ItemProperty({
        params: {
            name: 'ga_id',
            friendly_name: 'GA ID',
        },
        default_type: 'modifiable'
    })
    relations = [
        {
            value: 'run', 
            friendly_name: 'Runs',
        },
        {
            value: 'campaign',
            friendly_name: 'Campaigns'
        }
    ]
}