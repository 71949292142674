export default class ItemProperty extends Object{
    value = undefined
    display = undefined
    user_modifiable = undefined
    name = undefined
    friendly_name = undefined
    input_component = undefined
    output_component = undefined
    html_input = {
        type: undefined,
        value: undefined,
        optional_attributes: undefined
    }
    constructor({params, default_type}){
        super()
        let default_type_params = {}
        switch(default_type){
            case 'not_displayed':
                default_type_params = this.notDisplayed()
                break
            case 'displayed_not_modifiable':
                default_type_params = this.displayedNotModifiable()
                break
            case 'modifiable':
                default_type_params = this.modifiable()
        }
        //set the selected defaults
        for(const [key, value] of Object.entries(default_type_params)){
            this[key] = value
        }
        //potentially override these with passed in params
        for(const [key, value] of Object.entries(params)){
            this[key] = value
        }
        return this
    }
    notDisplayed(){
        return {
            display: false,
            user_modifiable: false,
        }
    }
    displayedNotModifiable(){
        return {
            display: true,
            user_modifiable: false,
        }
    }
    modifiable(){
        return {
            display: true,
            user_modifiable: true,
        }
    }
}