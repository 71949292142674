import storeFunctions from '@/plugins/StorePlugin.js'

const state = {
    files: [
        {
            file_object: {},
            meta_data: {}
        }
    ]
}

const getters = {
    getAllFiles(state){
        return state.files
    },
    getAllFileObjects(state){
        const file_objects = []
        state.files.forEach(file => {
            file_objects.push(file.file_object)
        })
        return file_objects
    },
    getTotalPayloadSizeMb(state){
        const BYTES_IN_MB = 1000000
        var payload_total_bytes = 0;
        if(state.files.length === 0) return payload_total_bytes
        state.files.forEach(file => {
            payload_total_bytes += file.file_object.size 
        })
        const payload_total_mb = payload_total_bytes/BYTES_IN_MB
        return payload_total_mb
    }
}

const actions = {
    async stageFiles({dispatch},{files, optional_file_names=undefined}) {
        Array.from(files).forEach(file => {
            dispatch('readFileIntoStore', {file, optional_file_names})
        })
    },
    async readFileIntoStore({dispatch, commit},{file, optional_file_names=undefined}) {
        let is_file_name_conflict = false
        if(optional_file_names){ is_file_name_conflict = await dispatch('isFileNameConflict', {'file_name':file.name, optional_file_names})}
        const is_name_too_long = await dispatch('isNameTooLong', file.name)
        const img = await dispatch('createImageObject',URL.createObjectURL(file))
        img.onload = async () => {
            file = await dispatch('appendDimensionsToFileObject', {'file':file, 'img':img})
            let file_meta_data = {
                name: file.name,
                width: img.width,
                height: img.height,
                dimensions: file.dimensions,
                name_conflict: is_file_name_conflict,
                name_too_long: is_name_too_long,
                source: img.src,
            }   

            const all_file_data = {
                file_object: file,
                meta_data: file_meta_data
            }

            commit('addFile', all_file_data)
        }
    },
    async sendHttpRequest({commit}, {api_url, form_data}) {

        commit('setImageUploadLoadingState', true, {root: true})

        const toasts = {'success': 'Upload Successful!', 'error': 'Error Uploading Image!'}

        return storeFunctions.http_request(this.$http.post, api_url, toasts, form_data, { headers: { 'Content-Type': 'multipart/form-data' } }, 120)
        .then((response) => { 
            commit('clearAllFiles');
            commit('setImageUploadLoadingState', false, { root: true });
            return Promise.resolve(response.data);
        })
        .catch((error) => { 
            commit('setImageUploadLoadingState', false, { root: true });
            console.error(error);
            return Promise.reject(error);
        });
    },
    // eslint-disable-next-line no-empty-pattern
    isFileNameConflict({}, {file_name, optional_file_names}){
        if(optional_file_names.includes(file_name)) return true
        else return false
    },
    // eslint-disable-next-line no-empty-pattern
    isNameTooLong({}, file_name){
        const MAX_NAME_LENGTH = 50
        if(file_name.length > MAX_NAME_LENGTH) return true
        else return false 
    },
    // eslint-disable-next-line no-empty-pattern
    createImageObject({}, object_url){
        var img = new Image
        img.src = object_url
        return img
    },
    // eslint-disable-next-line no-empty-pattern
    appendDimensionsToFileObject({}, {file, img}){
        file.dimensions = img.width + 'x' + img.height
        return file
    }
}

const mutations = {
    addFile(state,{file_object, meta_data}){
        const file = {
            file_object: file_object,
            meta_data: meta_data,
        }
        state.files.push(file)
    },
    clearAllFiles(state){
        state.files = []
    },
    removeFileByIndex(state, index){
        state.files.splice(index, 1)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}