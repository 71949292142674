<template>
    <Listbox as="div" v-model="selector" class="min-w-min" tabindex="0" @keyup.enter="selector='Select An Item'"> 
        <div class="mt-1 relative">
            <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left text-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-md">
                <span class="block truncate">{{selector}}</span>
                <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
            </ListboxButton>
            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-sm ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-md">
                    <div class="relative w-full">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <SearchIcon class="w-4 h-4" />
                        </div>
                        <input type="text" id="search" autocomplete="off" v-model="search" placeholder="Search" @click.stop @keydown.space.stop class="border border-gray-300 text-black text-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 p-2.5">
                    </div>
                    <ListboxOption as="template" class="cursor-pointer" v-for="item in filteredItems" :key="item" :value="display ? item[display] : item" v-slot="{ active, selector }">
                        <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'select-none relative py-2 pl-3 pr-9']" @click="emitItem(item)">
                            <span :class="[selector ? 'font-semibold' : 'font-normal', 'block truncate']">
                                {{ display ? item[display] : item }}
                            </span>

                            <span v-if="selector" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </transition>
        </div>
    </Listbox>
</template>

<script>
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, SelectorIcon, SearchIcon } from '@heroicons/vue/solid'
export default {
    components: {
        Listbox,
        ListboxButton,
        ListboxOption,
        ListboxOptions,
        CheckIcon,
        SelectorIcon,
        SearchIcon,
    },
    props: {
        /* selected is what the dropdown shows by default */
        selected: {
            default: 'Select An Item',
            type: String,
        },
        /* items to loop over */
        items: {
            default: null,
            type: undefined,
        },
        /* display is the item key you want to display */
        display: {
            default: null,
            type: String,
        }
    },
    data() {
        return {
            selector: this.selected,
            search: '',
        }
    },
    methods: {
        emitItem(item) {
            /* display to the user the value in the dropdown */
            this.selector = item[this.display]
            this.$emit('user_select', item)
        },
    },
    computed: {
        filteredItems() {
            if (!this.search.length)
                return this.items;

            return this.items.filter((item) =>
                this.display ? item[this.display].toLowerCase().includes(this.search.toLowerCase()) : item.toLowerCase().includes(this.search.toLowerCase())
            );
        }
    }
}
</script>

<style>

</style>