<template>
    <div class="border-b border-gray-300 pb-2"> 
        <h2 class="text-lg font-medium mb-3"> Change Time Zone </h2>
        <form class="flex flex-col gap-2" v-on:submit.prevent="updateTimeZone">
            <div class="flex gap-2">
                <label for="timezone" class="label-format">Your Time Zone: </label>
                <span id="timezone"> {{ userSimplifiedTimezone }} </span>
            </div>
            <div class="flex gap-2">
                <label for="current-time" class="label-format"> Current Time:</label>
                <span id="current-time">  {{ userDateTime }} </span>
            </div>
            <div class="flex gap-2 items-center">
                <label class="label-format"> New Time Zone: </label>
                <DropdownComponent 
                :items='timezones'
                display="name"
                selected="Select a New Time Zone"
                @user_select="setNewTimeZone" 
                class="w-64"/>
            </div>
            <button class="btn-primary green self-start" v-on:submit.prevent="updateTimeZone"> Change Time Zone </button>
        </form>
    </div>
</template>

<script>
export default {
    name: "TimeZoneSettings",
    components: {},
    props: {},
    data() {
        return {
            timezones: [],
            current_timezone: null,
            new_timezone: null
        }
    },
    computed: {
        userDateTime() {
            if(!this.current_timezone)
                return null;
            
            const date = new Date();

            // Get the options for formatting the date and time
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                timeZone: this.current_timezone,
            };

            // Get the date and time in the desired format for the specified time zone
            const date_time_string = date.toLocaleString('en-US', options);

            return date_time_string;
        },
        userSimplifiedTimezone() {
            const timezone = this.timezones.find(tz => tz.value === this.current_timezone);

            return timezone ? timezone.name : null;
        }
    },
    watch: {},
    async created() {
        await this.getUserTimeZone()
        this.timezones = this.$store.getters['getPossibleTimezones']
    },
    mounted() {},
    methods: {
        async updateTimeZone() {
            if (!this.new_timezone) 
                return this.$notify({type: 'error', text: 'Select A New Time Zone First!'})

            await this.$http.post('/user/new-timezone', {timezone: this.new_timezone})
                .then( async () => {
                    await this.getUserTimeZone()
                    await this.$store.dispatch('login_module/refreshTimezone')
                    this.$notify({type: 'success', text: 'Time Zone Changed Successfully'})
                })
        },
        setNewTimeZone(timezone) {
            this.new_timezone = timezone.value
        },
        async getUserTimeZone() {
            await this.$http.get('/user/timezone')
                .then( ({data}) => {
                    this.current_timezone = data
                })
        }
    }
}
</script>

<style scoped lang="postcss">
.label-format {
    @apply block font-medium
}
</style>