<template>
    <div class="card-dimensions overflow-hidden col-span-1 flex flex-col gap-2 text-center 
        bg-white rounded-lg shadow divide-y divide-gray-200 p-2 md:p-4 justify-center 
            items-center break-all" :class="{ 'hover-effects cursor-pointer duration-150' : hover_effects}" ref="imageContainer">
        <img v-if="src && isVisible" class="w-36 h-36 md:w-56 md:h-56 object-contain bg-transparent overflow-hidden" :src="src" alt="image" draggable="false" loading="lazy"/>
        <span v-else-if="top_info" class="md:text-sm max-md:text-xs font-medium m-2 first-letter:capitalize "> {{top_info}}</span>
        <span class="max-md:text-xs font-medium break-all first-letter:capitalize">{{title}}</span>
        <slot name="thirdRow"></slot> 
        <slot name="fourthRow"></slot> 
    </div>
</template>

<style scoped>
.hover-effects:hover {
    background-position: 400%;
    background-image: linear-gradient(45deg, #ffffff,#d9efff,#b1e0ff,#97d5ff, #7ccbff);
}
</style>

<script>
export default {
    props: {
        hover_effects: {
            default: false,
            type: Boolean,
        },
        src: {
            default: '',
            type: String,
        },
        title: {
            default: '',
            type: String,
        },
        top_info: {
            default: '',
            type: String,
        }
    },
    data() {
        return {
            isVisible: false,
            observer: null
        }
    },
    mounted() {
        this.observer = new IntersectionObserver(this.handleIntersection, { threshold: 0 })
        this.observer.observe(this.$refs.imageContainer)
    },
    methods: {
        handleIntersection(entries) {
            const entry = entries[0]
            if (entry.isIntersecting) {
                this.isVisible = true
                this.observer.disconnect()
            }
        }
    }
}
</script>