<template>
    <div class="bg-white rounded-md py-4 px-2 flex flex-col gap-2">
        <h1 class="p-1 text-lg font-medium"> Specify When The Ad Set Should Always Be On: </h1>
        <div class="sm:border-t sm:border-gray-200 p-1 w-full grid grid-cols-2 gap-3 justify-center">
            <label for="disableRateLimitStart" class="block text-lg font-medium text-gray-700 col-span-1" style="grid-column: 1/2">
                From:
            </label>
            <input id="disableRateLimitStart" type="datetime-local" v-model="disable_rate_limit_start" class="col-span-1">
        </div>

        <div class="sm:border-t sm:border-gray-200 p-1 w-full grid grid-cols-2 gap-3 justify-center">
            <label for="disableRateLimitEnd" class="block text-lg font-medium text-gray-700 col-span-1" style="grid-column: 1/2">
                To:
            </label>
            <input id="disableRateLimitEnd" type="datetime-local" v-model="disable_rate_limit_end" class="col-span-1">
        </div>

        <div class="sm:border-t sm:border-gray-200 p-1 w-full flex justify-center">
            <button class="btn-primary indigo h-fit" @click="updateDisableRateLimit">Submit</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "DisableRateLimit",
    components: {},
    props: {
        run_info: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            disable_rate_limit_start: null,
            disable_rate_limit_end: null,
        }
    },
    computed: {},
    watch: {},
    created() {
        this.prePopulateDisableRateLimitForm()
    },
    mounted() {},
    methods: {
        prePopulateDisableRateLimitForm(){
            this.disable_rate_limit_start = this.run_info.disable_rate_limit_start
            this.disable_rate_limit_end = this.run_info.disable_rate_limit_end
        },
        updateDisableRateLimit() {
            if(!confirm(`Are You Sure You Want To Update the disable rate limit?`)) return
            const toasts = {'success': 'Disable Rate Limit Updated', 'error': 'Error Updating Disable Rate Limit'}
            this.$http_request(this.$http.post, `/run/update-disable-rate-limit/${this.run_info.uuid}`, toasts, {
                disable_rate_limit_start: this.disable_rate_limit_start,
                disable_rate_limit_end: this.disable_rate_limit_end
            })
            .catch(error => {
                console.error(error);
            });
        },
    }
}
</script>

<style scoped>

</style>