<template>
    <CardComponent
        @click="emitImageDataOnSelected"        
        :src="img_src" 
        :title="game_name"
        :hover_effects="this.checked ? false : true"
        :class="{ 'checked-card': this.checked }">
        <template v-slot:thirdRow>
            <a :href="`/manage-item/game/${this.img_uuid}`">
                <CogIcon class="cogicon-dimensions"/>
            </a>
        </template>
    </CardComponent>
</template>

<script>
import { CogIcon } from '@heroicons/vue/outline'
import CardComponent from '@/components/UtilityComponents/CardComponent.vue'
export default {
    name: 'ImagePickerGameImage',
    components: {
        CardComponent,
        CogIcon,
    },
    props: {
        img_src: undefined,
        img_uuid: undefined,
        game_name: undefined,
        img_position: {
            default: 0,
            type: Number,
        },
    },
    computed: {
        checked(){
            return this.$store.getters['picker_module/getCheckedStatus']({item_uuid: this.img_uuid, item_type: 'games'})
        }
    },
    methods: {
        emitImageDataOnSelected(){
            const image = { uuid: this.img_uuid, checked: this.checked, img_position: this.img_position }
            this.$emit('selected', image)
        },
    },
}
</script>
