<template>
    <div class="flex flex-col gap-4 w-full justify-center items-center">
        <div class="flex w-full justify-center" v-show="item_type=='Countries' && advert_items.length > 0">
            <span @click="setIncludeCountries(true)" class="options-picker border-l rounded-l-md" :class="{'options-picker-active' : this.include_country == true}"> Include </span>
            <span @click="setIncludeCountries(false)" class="options-picker rounded-r-md" :class="{'options-picker-active' : this.include_country == false}"> Exclude </span>
        </div>
        <div class="flex flex-col items-center w-full gap-1">
            <h2 class="sm:text-lg text-sm"> <span class="font-semibold"> Add </span> <strong>{{item_type}}</strong> </h2> 
            <DropdownComponent 
                class="lg:max-w-lg sm:max-w-xs w-full"
                :selected="`Add ${this.item_type}`"
                :items="all_items"
                display="name"
                @user_select="addItem"/>
        </div>
        <div class="flex flex-col justify-center items-center w-full gap-4">
            <h2 class="sm:text-lg text-sm" v-if="advert_items.length === 0 && advert_type == 'run'">
                All {{ item_type }} Selected On The Campaign Will Be Applied To This Run Unless You Specify Otherwise.
            </h2> 
            <div class="flex flex-col items-center justify-center" v-if="advert_items.length === 0">
                <h2 class="sm:text-lg text-sm"> Current Selected {{item_type}}: </h2>
                <h2 class="flex flex-row gap-2 justify-center items-center bg-white rounded-md py-2 px-4 border border-black duration-150"> Global </h2> 
            </div>
            <div class="flex flex-col items-center justify-center" v-else-if="advert_items.length > 0">
                <h2 class="sm:text-lg text-sm"> Current <strong>{{includeString}}</strong> {{item_type}}: </h2>
                <div class="flex flex-row gap-1 flex-wrap w-fit">
                    <div v-for="item in advert_items" :key="item" class="flex flex-row border border-black gap-2 justify-center items-center bg-white rounded-md p-2 cursor-pointer hover:bg-indigo-300 duration-150" @click="removeItem(item)">
                        <h4 class="sm:text-lg text-sm"> {{ item.name }} </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item_type: {
            default: null,
            type: String
        },
        advert_uuid: {
            default: null,
            type: String,
        },
        advert_type: {
            default: 'run',
            type: String
        }
    },
    data () {
        return {
            all_items: [],
            advert_items: [],
            include_country: true,
        }
    },
    watch: {
        advert_uuid: {
            handler: async function() {
                if(this.item_type == 'Countries')
                    await this.areAdvertCountriesIncluded()

                await this.getAllPossibleItems()

                await this.getAdvertItems()
            },
        }
    },
    methods: {
        async getAllPossibleItems() {
            if(this.advert_type == 'run')
                this.$http.get(`/${this.advert_type}-${this.lowercasePluralItemType}/all/${this.advert_uuid}`)
                    .then ( ({data}) => {
                            this.all_items = data
                        })
            else 
                this.$http.get(`/${this.lowercasePluralItemType}`)
                    .then ( ({data}) => {
                        this.all_items = data
                    })
        },
        async getAdvertItems() {
            //run-languages, run-countries. for Test user im thinking of a computed prop isRun that checks if it's test user returns null else returns 'run-'
            await this.$http_request(this.$http.get, `/${this.advert_type}-${this.lowercasePluralItemType}/${this.advert_uuid}`)
                .then((response)=>{
                    this.advert_items = response.data
                })
                .catch((error)=>{
                    console.error(error)
                })
        },
        async areAdvertCountriesIncluded() {
            this.$http_request(this.$http.get, `/${this.advert_type}-countries/included/${this.advert_uuid}`)
                .then( (response)=> {
                    if(response.data[0]==0)
                        this.include_country = false
                })
        },
        addItem(item_to_add) {
            if(!item_to_add) return;

            let request = {
                item: item_to_add,
                advert_uuid: this.advert_uuid,
            }
            if (this.item_type == 'Countries') request['include_countries'] = this.include_country

            //run-language, run-country
            this.$http.post(`${this.advert_type}-${this.lowercaseSingularItemType}`, request)
                .then( ()=> {
                    this.advert_items.push(item_to_add)
                })
                .catch((error)=>{
                    if(error.response.status == 400)
                        this.$notify({type: 'error', text: `${this.UppercaseSingularItemType} Already Exists In The Ad Set!`})
                    else 
                        this.$notify({type: 'error', text: `Error Adding ${this.item_type}, Please Refresh The Page And Try Again`})
                    console.error(error.message)
                })
        },
        removeItem(item_to_remove) {
            let request = {
                item: item_to_remove,
                advert_uuid: this.advert_uuid,
            }
            if (this.item_type == 'Countries') 
                request['include_countries'] = this.include_country

            //update in backend
            const toasts = {'error': `Error Removing ${this.item_type}`, 'confirm': `Are You Sure You Want To Remove The Following ${this.UppercaseSingularItemType}?`}
            //run-language or run-country
            this.$http_request(this.$http.post, `/${this.advert_type}-${this.lowercaseSingularItemType}/delete`, toasts, request)
                .then( () => {
                    //update in frontend
                    var index = this.advert_items.indexOf(item_to_remove);
                    if (index != -1) this.advert_items.splice(index, 1);
                })
                .catch((error)=>{
                    console.error(error)
                })
        },
        setIncludeCountries(bool) {
            let request = {
                advert_uuid: this.advert_uuid,
                include_countries: bool
            }
            //update all runcountries in database to be included/excluded, 
            const toasts = {'error': `Error Switching ${this.includeString} ${this.item_type}`, 'confirm': `This Will Switch All The Current ${this.item_type}' Inclusion Type. Are You Sure You Want To Do This?`}
            this.$http_request(this.$http.post, `${this.advert_type}-country/update-inclusion`, toasts, request)
                .then( ()=> {
                    this.include_country = bool
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },
    },
    computed: {
        lowercaseSingularItemType() {
            if(this.item_type == 'Countries')
                return 'country'
            else if (this.item_type == 'Languages')
                return 'language'
            else return 'TestUser'
        },
        lowercasePluralItemType() {
            if(this.item_type == 'Countries')
                return 'countries'
            else if (this.item_type == 'Languages')
                return 'languages'
            else return 'TestUser'
        },
        UppercaseSingularItemType() {
            if(this.item_type == 'Countries')
                return 'Country'
            else if (this.item_type == 'Languages')
                return 'Language'
            else return 'TestUser'
        },
        includeString(){
            if (this.include_country) return 'Included'
            else return 'Excluded' 
        }
    },
    async created(){
        if(this.item_type == 'Countries')
            await this.areAdvertCountriesIncluded()

        await this.getAllPossibleItems()

        await this.getAdvertItems()
    }
}
</script>

