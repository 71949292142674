<template>
    <div class="w-full flex flex-col gap-2 sm:text-base text-sm">
        <div class="bg-white rounded-md w-full p-4">
            Percentage Left: <span class="font-medium">{{ percentage_left }}%</span>
        </div>
        <div v-for="run_game in run_games" :key="run_game.target_impressions_percentage" class="grid grid-cols-3 bg-white rounded-md gap-2 p-4 items-center">
            <span> 
                Name: 
                <span class="font-medium">{{ run_game.friendly_name }}</span> 
            </span>
            <span v-if="run_game.target_impressions_percentage != null"> 
                Impression Percentage: 
                <span class="font-medium">{{ run_game.target_impressions_percentage }}%</span> 
            </span>
            <span v-if="run_game.target_impressions_percentage == null"> 
                Impression Percentage: 
                <span class="flex flex-row items-center gap-2">
                    <input type="number" v-model="run_game.new_impression_percentage" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-20 sm:text-sm text-xs border-gray-300 rounded-md" min="0" max="100"/>
                    <span class="sm:text-lg font-medium">%</span>
                </span>
            </span>
            <div class="flex flex-row gap-2">
                <button class="btn-primary green sm:text-sm text-xs w-full h-full" v-if="run_game.target_impressions_percentage == null" @click="createGameConstraint(run_game)"> Submit </button>
                <button class="btn-primary green sm:text-sm text-xs w-1/2" v-if="run_game.target_impressions_percentage != null" @click="run_game.new_impression_percentage = run_game.target_impressions_percentage, run_game.target_impressions_percentage = null"> Edit </button>
                <button class="btn-primary red sm:text-sm text-xs w-1/2" v-if="run_game.target_impressions_percentage != null" @click="clearGameConstraint(run_game)"> Clear </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GamesConstrains",
    components: {},
    props: {
        run_info: {
            required: true
        }
    },
    data() {
        return {
            run_games: [],
            percentage_left: 100,
        }
    },
    computed: {},
    watch: {},
    async created() {
        await this.getRunGamesImpressionsPercentageConstraints()
    },
    mounted() {},
    methods: {
        async getRunGamesImpressionsPercentageConstraints() {
            await this.$http.get('/run-games-impression-constraint/' + this.run_info.uuid)
                .then( (response) => {
                    this.run_games = response.data;
                    this.getPercentageLeft()
                })
        },
        createGameConstraint(run_game) {
            if(run_game.new_impression_percentage == 0)
                return this.$notify({type: 'error', text: 'Can Not Set Percentage To 0%'})

            /* making sure they don't input 100% on a single game when they have more than one game */
            if(this.run_games.length > 1 && run_game.new_impression_percentage == 100)
                return this.$notify({type: 'error', text: 'Can Not Set Percentage To 100% When There Are More Than 1 Game'})

            if(!this.percentageValidationSuccessfull(run_game))
                return

            const toasts = {'success': 'Success', 'error': 'Error'}
            this.$http_request(this.$http.post, '/run-games/edit-game-constraint', toasts, run_game)
                .then( ()=> {
                    this.getRunGamesImpressionsPercentageConstraints()
                })
        },
        clearGameConstraint(run_game) {
            run_game.new_impression_percentage = null

            const toasts = {'success': 'Success', 'error': 'Error'}
            this.$http_request(this.$http.post, '/run-games/edit-game-constraint', toasts, run_game)
                .then( ()=> {
                    this.getRunGamesImpressionsPercentageConstraints()
                })
        },
        percentageValidationSuccessfull(new_run_game) {
            let sum = 0
            let percentages_set_length = 1
            /* the new_run_game will always have 0 as target_impressions_percentage */
            this.run_games.forEach( run_game => {
                if(run_game.target_impressions_percentage != null) {
                    sum += run_game.target_impressions_percentage
                    percentages_set_length++
                }
            })

            sum += new_run_game.new_impression_percentage

            /* making sure the percentages don't go over 100%. */
            if(sum > 100) {
                this.$notify({type: 'error', text: 'Total Percentage Is Greater Than 100%!'})
                return false
            }

            // if they have set percentages for all games, make sure they add up to 100
            if(percentages_set_length == this.run_games.length && sum < 100) {
                this.$notify({type: 'error', text: 'All Game Percentages Do Not Add Up to 100!'})
                return false
            }
                

            return true;
        },
        getPercentageLeft() {
            let sum = 0
            this.run_games.forEach( run_game => {
                if(run_game.target_impressions_percentage != null) {
                    sum += run_game.target_impressions_percentage
                }
            })

            this.percentage_left = Math.round(100 - sum)
        }
    }
}
</script>

<style scoped>

</style>