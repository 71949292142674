<template>
    <div class="flex flex-col gap-6 w-full items-center overflow-auto min-h-101 max-h-110 p-1">
        <div class="w-full flex flex-row flex-wrap items-center justify-center gap-2">
            <DropdownComponent
                class="lg:max-w-lg sm:max-w-xs w-full"
                :selected="'Add ' + capitalizedItemType" 
                :items="adder_items" 
                :display="dropdownDisplay"
                @user_select="addItemsToAdvert"
                :key="dropdown_refresher" />
            <input type="text" v-model="searchText" placeholder="Search" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md mt-1" maxlength="50">
        </div>

        <div class="flex flex-row gap-1 items-center justify-center flex-wrap" draggable="false">
            <div v-for="item in filteredItems" :key="item" class="bg-white relative flex flex-col items-center justify-center border border-gray-600 p-2 rounded-md break-word break-all md:h-60 md:w-48 h-48 w-36">
                <span @click="removeItemFromAdvert(item)" class="p-3 font-semibold absolute top-0 right-0 w-5 h-5 m-1 text-sm rounded-full bg-gray-300 hover:bg-red-500 hover:text-white duration-200 cursor-pointer flex justify-center items-center border border-gray-600">X</span>
                <img v-if="item_type != 'games'" class="w-24 h-24 md:w-40 md:h-40 object-contain bg-transparent overflow-hidden" :src="item.thumbnail_url" alt="image" draggable="false"/>
                <img v-else class="w-24 h-24 md:w-40 md:h-40 object-contain bg-transparent overflow-hidden" :src="item.img_url" alt="image" draggable="false"/>
                <span class="max-md:text-xs font-medium break-word first-letter:capitalize text-center" v-if="item_type == 'creatives'"> {{item.user_defined_name + item.img_type}} </span>
                <span class="max-md:text-xs font-medium break-word first-letter:capitalize text-center" v-else> {{item.friendly_name}} </span>
                <a :href="`/manage-item/${this.singularItemType}/${item.uuid}`">
                    <CogIcon class="w-6 h-6 md:w-8 md:h-8 p-1 mt-1 lighter-gray duration-150 rounded-md text-black"/>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { CogIcon } from '@heroicons/vue/outline'

export default {
    name: "AdvertImagePicker",
    components: {
        CogIcon
    },
    props: {
        advert_type: {
            type: String,
            required: true,
        },
        advert: {
            required: true,
        },
        item_type: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            dropdown_refresher: 0,
            taker_items: [],
            adder_items: [],
            searchText: "",
        }
    },
    computed: {
        capitalizedItemType() {
            return this.$capitalizeFirstLetter(this.item_type)
        },
        capitalizedAvertType() {
            return this.$capitalizeFirstLetter(this.advert_type)
        },
        singularItemType() {
            return this.item_type.slice(0, -1)
        },
        singularCapitalizedItemType() {
            return this.$capitalizeFirstLetter(this.singularItemType)
        },
        dropdownDisplay() {
            if(this.item_type == 'creatives')
                return "user_defined_name"
                
            return "friendly_name"
        },
        filteredItems() {
            return this.taker_items.filter(item => {
                if (this.searchText === "") return true;

                let itemName = "";
                if (this.item_type === "creatives") {
                    itemName = item.user_defined_name
                } else {
                    itemName = item.friendly_name
                }

                return itemName.toLowerCase().includes(this.searchText.toLowerCase());
            });
        },
        newAdvertType() {
            if(this.advert_type == 'run')
                return 'ad set'

            return 'campaign'
        }
    },
    watch: {
        advert: {
            handler: async function() {
                await this.getAdderItems()
                await this.getTakerItems()
                this.filterItems()
            },
        }
    },
    async created() {
        await this.getAdderItems()
        await this.getTakerItems()
        this.filterItems()
    },
    mounted() {},
    methods: {
        async getAdderItems() {
            let api = `/${this. item_type}`
            
            if(this.item_type == 'placements' && this.advert_type == 'campaign')
                api = `/campaign-placements/all/${this.advert.uuid}`

            if(this.advert_type == 'run')
                api = `/campaign-${this.item_type}/${this.advert.campaign_uuid}`
                
            await this.$http_request(this.$http.get, api)
                .then(({data}) => {
                    this.adder_items = data
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },

        async getTakerItems() {
            await this.$http_request(this.$http.get, `/${this.advert_type}-${this.item_type}/${this.advert.uuid}`)
                .then(({data}) => {
                    this.taker_items = data
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },

        filterItems() {
            const takerUuids = this.taker_items.map(item => item.uuid);

            this.adder_items = this.adder_items.filter(item => 
                !takerUuids.includes(item.uuid)
            );

            const adderUuids = this.adder_items.map(item => item.uuid);

            this.taker_items = this.taker_items.filter(item => 
                !adderUuids.includes(item.uuid)
            );
        },

        async addItemsToAdvert(item) {
            const request = {
                uuids: [item.uuid],
                uuid: this.advert.uuid
            }

            const toasts = {'success': `${this.singularCapitalizedItemType} added to ${this.newAdvertType}`, 'error': `Error adding ${this.singularItemType} to ${this.newAdvertType}`}
            await this.$http_request(this.$http.post, `/${this.advert_type}-${this.item_type}`, toasts, request)
                .then( async () => {
                    this.addItemToTakerItems(item)
                    this.dropdown_refresher++
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },

        async removeItemFromAdvert(item) {
            const confirm_msg = this.buildConfirmMsg()

            if(!confirm(confirm_msg)) return
            const request = {
                uuids: [item.uuid],
                uuid: this.advert.uuid
            }
            const toasts = {'success': `${this.singularCapitalizedItemType} removed from ${this.newAdvertType}`, 'error': `Error removing ${this.singularItemType} from ${this.newAdvertType}`}
            await this.$http_request(this.$http.post, `/${this.advert_type}-${this.item_type}/delete`, toasts, request)
                .then( async () => {
                    this.removeItemFromTakerItems(item)
                    this.dropdown_refresher++
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },

        buildConfirmMsg() {
            let advert = this.advert_type
            if (advert == 'run')
                advert = 'ad set'
            
            let msg = `Are you sure you want to remove this ${this.singularItemType} from the ${advert}?`
            if(this.advert_type == 'campaign')
                msg += ' This will also remove it from all the ad sets!'

            return msg
        },

        addItemToTakerItems(item) {
            this.taker_items.push(item)
            this.adder_items = this.adder_items.filter(el => el.uuid !== item.uuid)
            if(this.item_type == 'games')
                this.taker_items.sort((a, b) => a.friendly_name.localeCompare(b.friendly_name))
            else if (this.item_type == 'creatives')
                this.taker_items.sort((a, b) => b.created_at.localeCompare(a.created_at))
            else
                this.taker_items.sort((a, b) => a.place_id - b.place_id)
        },

        removeItemFromTakerItems(item) {
            this.taker_items = this.taker_items.filter( el => el.uuid !== item.uuid)
            this.adder_items.push(item)
            if(this.item_type == 'games')
                this.adder_items.sort((a, b) => a.friendly_name.localeCompare(b.friendly_name))
            else if (this.item_type == 'creatives')
                this.adder_items.sort((a, b) => b.created_at.localeCompare(a.created_at))
            else
                this.taker_items.sort((a, b) => b.place_id - a.place_id)
        },

    }
}
</script>

<style scoped>

</style>