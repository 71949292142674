import moment from 'moment';
import 'moment-timezone';

var progress_bar_functions = {

    advertDateGapPercentage(advert, advert_type, timezone) {
        let start_date = null
        let end_date = null

        if (advert_type == "campaigns") {
            start_date = this.getCampaignStartDate(advert.start_date, advert.start_time, timezone)
            end_date = this.getCampaignEndDate(advert.end_date, advert.end_time, timezone)
        } else {
            start_date = this.getAdvertDateTime(advert.start_date, advert.start_time, timezone)
            end_date = this.getAdvertDateTime(advert.end_date, advert.end_time, timezone)
        }

        return this.getPercentage(start_date, end_date);
    },

    getCampaignStartDate(date, time, timezone) {
        let start_time = time
        if (start_time == null)
            start_time = '00:00:01'
        
        return this.getAdvertDateTime(date, start_time, timezone)
    },

    getCampaignEndDate(date, time, timezone) {
        let end_time = time
        if (end_time == null)
            end_time = '23:59:59'
        
        return this.getAdvertDateTime(date, end_time, timezone)
    },

    getAdvertDateTime(date, time, timezone) {
        const userDateTimeString = date + " " + time;
        const dateObj = moment.tz(userDateTimeString, 'YYYY-MM-DD HH:mm', timezone);
        const utcDateObj = dateObj.clone().tz("Africa/Abidjan");

        return Date.UTC(
            new Date(utcDateObj).getUTCFullYear(),
            new Date(utcDateObj).getUTCMonth(),
            new Date(utcDateObj).getUTCDate(),
            new Date(utcDateObj).getUTCHours(),
            new Date(utcDateObj).getUTCMinutes(),
            new Date(utcDateObj).getUTCSeconds()
        );
    },

    getPercentage(start_date, end_date) {
        const current_date = Date.now()
        const total_duration = end_date - start_date;
        const elapsed_duration = current_date - start_date;

        let percentage = (elapsed_duration / total_duration) * 100;

        if (percentage <= 0)
            return 0

        // Limit percentage to a maximum of 100
        percentage = Math.min(percentage, 100);
        return Math.floor(percentage);
    }
}

export default {
    install(Vue) {
        Vue.config.globalProperties.$advertDateGapPercentage = function (advert, advert_type, timezone) {
            return progress_bar_functions.advertDateGapPercentage(advert, advert_type, timezone)
        }
    },
}
