<template>
    <div class="flex flex-col justify-center items-center w-full" tabindex="0" @keydown.delete="deleteCreatives">
        <div class="bg-white w-full rounded-md p-6 flex flex-col justify-center items-center" v-if="imageUploadLoadingState || imageDeleteLoadingState">
            <LoadingComponent />
        </div>
        <div v-else class="bg-white w-full rounded-md p-6 flex flex-col justify-center items-center">
            <div class="w-full flex flex-col gap-6 items-start" >
                <h2 class="font-medium"> Upload an Image </h2>
                <form class="space-y-8 divide-y divide-gray-200 relative w-full" v-on:submit.prevent="uploadCreatives">
                    <UploadFile class="w-full" :multiple="true" :check_file_name_existence="true"/>

                    <button v-if="!imageUploadLoadingState  && stagedFiles.length" @click="confirmAndClearStagedFiles" class="btn-primary light-blue">
                        Clear All Staged Files
                    </button>

                    <button v-if="!imageUploadLoadingState && stagedFiles.length" v-on:submit.prevent="uploadCreatives" class="btn-primary green absolute right-0">
                        Submit Staged Files
                    </button>

                </form>
                <h2 class="font-medium"> Or </h2>
                <button v-if="!imageDeleteLoadingState"  @click="deleteCreatives" class="btn-primary red">
                    Delete Selected Creatives
                </button>
            </div>
        </div>
        <ImagePicker
            class="mt-5"
            :class="{'pointer-events-none' : imageDeleteLoadingState }"
            src_name="storage_path"
            image_type="creatives"
            images_api="creatives"
            :use_single_path="false"
            picker_type="adder"
            getter_string="getAllCreatives"
            fetcher_string="fetchAllImages">
        </ImagePicker>
    </div>
</template>

<script>
import ImagePicker from '@/components/ImagePickers/ImagePicker.vue'

export default {
    components: {
        ImagePicker,
    },
    data() {
        return {
            file_input_value: undefined,
            isCheckboxed: false,
            use_creatives: true,
            show_image_upload: false,
        }
    },
    methods: {
        async uploadCreatives() {
            //check if there is a file selected
            if (!this.stagedFiles.length) {
                this.reset()
                return
            }
            if(confirm('Are you sure you want to upload these images?'))
                await this.$store.dispatch('create_creative_module/uploadCreatives', this.stagedFileObjects)
                    .then(() => {
                        this.reset()
                    })
                    .catch((error) => {
                        this.reset()
                        console.error(error.message)
                    })
        },
        deleteCreatives(e = null) {
            if (e.key === "Backspace")
                return; // Don't do anything 

            if (Object.keys(this.selectedCreatives).length == 0) {
                this.$notify({type: "error", text:'Select a Creative First!'})
                return
            }
            if(confirm('Are You Sure You Want To Delete The Selected Creatives?')) {
                this.$store.commit('setImageDeleteLoadingState', true)
                this.$store.dispatch('create_creative_module/deleteCreatives', this.selectedCreatives)
                .then(() => {
                    this.reset()
                })
                .catch((error) => {
                    this.reset()
                    console.error(error.message)
                })  
            }
        },
        reset(){
            this.$store.commit('setImageDeleteLoadingState', false)
            this.$store.commit('picker_module/clearSelectedItems',{picker_type: 'adder',item_type: 'creatives',})
        },
        confirmAndClearStagedFiles(){
            if(confirm('Are you sure you want to clear all staged files?')){
                this.$store.commit('file_upload_module/clearAllFiles')
                this.$notify({type: "success", text:'Cleared Staged Files'})
            }
        },
    },
    computed: {
        creatives(){
            const creatives = this.$store.getters['getAllCreatives']
            if (!creatives) return []
            return creatives
        },
        selectedCreatives() {
            const creatives = this.$store.getters['picker_module/getAdderSelectedCreatives']
            if (!creatives) return {}
            return creatives
        },
        stagedFiles(){
            const staged_files = this.$store.getters['file_upload_module/getAllFiles']
            if(!staged_files) return []
            return staged_files
        },
        stagedFileObjects(){
            const staged_file_objects = this.$store.getters['file_upload_module/getAllFileObjects']
            if(!staged_file_objects) return []
            return staged_file_objects
        },
        imageUploadLoadingState(){
            return this.$store.getters['getImageUploadLoadingState']
        },
        imageDeleteLoadingState(){
            return this.$store.getters['getImageDeleteLoadingState']
        },
    },
    mounted() {
        if(!this.imageUploadLoadingState)
            this.$store.commit('file_upload_module/clearAllFiles')
    },
}
</script>

<style scoped>
.items-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2em;
    align-items: center;
}
</style>