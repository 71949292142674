<template>
    <div class="w-full flex flex-col items-center justify-center gap-4">

        <div class="bg-white rounded-md p-4 sm:text-lg text-sm">
            <div v-if="missing_impression_dates.imp_left > 0" class="flex flex-col">
                <div class="flex flex-row gap-1 sm:justify-center"> 
                    <span class="font-medium"> {{ missing_impression_dates.imp_left?.toLocaleString() }} </span> 
                    <span>Impressions Will Be Distributed For The Following Dates:</span>
                </div>
                <div class="self-center justify-self-center"> {{ missing_impression_dates.range }} </div>
            </div>
            <div v-else>
                Constraints Have Been Set For All Dates and All Impressions
            </div>
        </div>

        <button class="btn-primary green" @click="this.show_create_form = !this.show_create_form"> Create Constraint </button>

        <!-- FORM  -->
        <div class="flex flex-col gap-2 bg-white p-4 rounded-md text-sm sm:text-base" v-show="show_create_form || edit_constraint">
            <div class="grid grid-flow-col grid-cols-2">
                <label class="col-span-1 font-medium"> Target Impressions </label>
                <input type="number" v-model="constraint_target_impressions" class="col-span-2 max-w-lg sm:max-w-xs block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" />
            </div>
            <div class="grid grid-flow-col grid-cols-2">
                <label class="col-span-1 font-medium"> Start Date <span class="text-xs text-gray-500">(Starts at 00:00:00 AM)</span> </label>
                <input type="date" v-model="constraint_start_date" class="date-picker col-span-2" :min="run_info.start_date" :max="run_info.end_date"/>
            </div>
            <div class="grid grid-flow-col grid-cols-2">
                <label class="col-span-1 font-medium"> End Date <span class="text-xs text-gray-500">(Ends at 23:59:59 PM)</span> </label>
                <input type="date" v-model="constraint_end_date" class="date-picker col-span-2" :max="run_info.end_date" :min="constraint_start_date ? constraint_start_date : run_info.start_date"/>
            </div>
            <div class="flex flex-row gap-2 items-center justify-center border-t border-gray-400 pt-2">
                <button v-show="!edit_constraint" class="btn-primary green" @click="createNewConstraint"> Create </button>
                <button v-show="edit_constraint" class="btn-primary green" @click="editImpressionConstraint"> Update </button>
                <button class="btn-primary red" @click="resetForm"> Cancel </button>
            </div>
        </div>

        <!-- ALL IMPRESSION CONSTRAINTS -->
        <div v-if="impression_constraints.length" class="flex flex-row flex-wrap gap-2 items-center justify-center">
            <div v-for="impression_constraint in impression_constraints" :key="impression_constraint.id" class="flex flex-col gap-4 bg-white py-4 px-6 rounded-md">
                <div class="grid grid-flow-row grid-rows-2">
                    <label class="row-span-1 font-medium justify-self-center"> Impressions Sent </label>
                    <span class="justify-self-center"> {{ impression_constraint.impressions_sent.toLocaleString() }}</span>
                </div>
                <div class="grid grid-flow-row grid-rows-2">
                    <label class="row-span-1 font-medium justify-self-center"> Target Impressions </label>
                    <span class="justify-self-center"> {{ impression_constraint.target_impressions.toLocaleString() }}</span>
                </div>
                <div class="grid grid-flow-row grid-rows-2">
                    <label class="row-span-1 font-medium justify-self-center"> Start Date </label>
                    <span class="justify-self-center"> {{ impression_constraint.start_date.split(" ")[0] }} </span>
                </div>
                <div class="grid grid-flow-row grid-rows-2">
                    <label class="row-span-1 font-medium justify-self-center"> End Date </label>
                    <span class="justify-self-center"> {{ impression_constraint.end_date.split(" ")[0] }}</span>
                </div>
                <div class="grid grid-flow-col grid-cols-2 gap-2">
                    <button class="btn-primary green" @click="toggleEditImpressionConstraint(impression_constraint)"> Edit </button>
                    <button class="btn-primary red" @click="deleteImpressionConstraint(impression_constraint)"> Delete </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DatesConstraints",
    components: {},
    props: {
        run_info: {
            required: true,
        }
    },
    data() {
        return {
            impression_constraints: [],
            missing_impression_dates: [],
            show_create_form: false,
            edit_constraint: false,
            edit_constraint_id : null,
            constraint_start_date: null,
            constraint_end_date: null,
            constraint_target_impressions: 0,
        }
    },
    computed: {},
    watch: {},
    async created() {
        await this.getAllImpressionConstraints()
        await this.findMissingImpressionDates()
    },
    mounted() {},
    methods: {
        async getAllImpressionConstraints() {
            await this.$http_request(this.$http.get, `/run-imp-date-constraint/${this.run_info.uuid}`)
                .then( ({data})=> {
                    this.impression_constraints = data
                })
        },
        async createNewConstraint() {
            if(this.constraint_target_impressions <= 0)
                return this.$notify({type: 'error', text: 'Target Impressions Can Not Be Less Than Or Equal To Zero'})

            if(!this.isTotalImpressionConstraintsValid())
                return

            if(!this.isDateConstraintsValid())
                return

            const request = {
                run_uuid: this.run_info['uuid'],
                target_impressions: this.constraint_target_impressions,
                start_date: this.constraint_start_date,
                end_date: this.constraint_end_date
            }

            await this.$http.post('/run-imp-date-constraint/create', request)
                .then( async ()=> {
                    await this.getAllImpressionConstraints()
                    this.findMissingImpressionDates()
                    this.resetForm()               
                })
                .catch( (error)=> {
                    this.$notify({type: 'error', text: error.response.data.message})
                })
        },
        async deleteImpressionConstraint(imp_constraint) {
            const toasts = {'error': `Error Deleting Constraint`, 'success': 'Successfully Deleted Constraint', 'confirm': `Are You Sure You Want To Delete The Following Constraint?`}
            await this.$http_request(this.$http.post, `/run-imp-date-constraint/delete`, toasts, imp_constraint)
                .then( async ()=> {
                    await this.getAllImpressionConstraints()
                    this.findMissingImpressionDates()
                })
        },
        toggleEditImpressionConstraint(imp_constraint) {
            this.edit_constraint = true
            this.edit_constraint_id = imp_constraint.id
            this.constraint_target_impressions = imp_constraint.target_impressions
            this.constraint_start_date = new Date(imp_constraint.start_date).toISOString().slice(0,10)
            this.constraint_end_date = new Date(imp_constraint.end_date).toISOString().slice(0,10)

            this.impression_constraints = this.impression_constraints.filter(constraint => constraint.id !== this.edit_constraint_id);
        },
        async editImpressionConstraint() {
            if(this.constraint_target_impressions <= 0)
                return this.$notify({type: 'error', text: 'Target Impressions Can Not Be Less Than Or Equal To Zero'})

            if(!this.isTotalImpressionConstraintsValid())
                return

            if(!this.isDateConstraintsValid())
                return
            
            const request = {
                run_uuid: this.run_info.uuid,
                constraint_id: this.edit_constraint_id,
                target_impressions: this.constraint_target_impressions,
                start_date: this.constraint_start_date,
                end_date: this.constraint_end_date
            }

            await this.$http.post('/run-imp-date-constraint/edit', request)
                .then( async ()=> {
                    await this.getAllImpressionConstraints()
                    this.findMissingImpressionDates()
                    this.resetForm()               
                })
                .catch( (error)=> {
                    this.$notify({type: 'error', text: error.response.data.message})
                })
        },
        async findMissingImpressionDates() {
            const request = {
                run_info: this.run_info,
                impression_constraints: this.impression_constraints
            }

            await this.$http.post(`/run-imp-date-constraint/missing-dates`, request)
                .then( ({data})=> {
                    this.missing_impression_dates = data
                })
        },
        isTotalImpressionConstraintsValid() {
            // compare total impression constraints to run target impressions
            var total_imp_constraints = this.constraint_target_impressions

            this.impression_constraints.forEach(function (item) {
                total_imp_constraints += item.target_impressions
            })

            if(total_imp_constraints > this.run_info.target_impressions) {
                this.constraint_target_impressions = 0
                this.$notify({type: 'error', text: 'Total Impression Constraints Is Larger Than Run Target Impressions!'})
                return false
            }

            return true
        },
        isDateConstraintsValid() {
            const arr = this.impression_constraints;
            const start_date = this.constraint_start_date;
            const end_date = this.constraint_end_date;

            if(start_date == null || end_date == null) {
                this.$notify({type: 'error', text: 'Dates Can Not Be Null!'})
                return false;
            }
            
            for (let i = 0; i < arr.length; i++) {
                if (start_date <= arr[i].end_date && end_date >= arr[i].start_date) {
                    this.$notify({type: 'error', text: 'Date Constraint Collision Found!'})
                    this.constraint_start_date = null
                    this.constraint_end_date = null
                    return false;
                }
            }
            return true;
        },
        resetForm() {
            this.getAllImpressionConstraints()
            this.show_create_form = false
            this.constraint_start_date = null
            this.constraint_end_date = null
            this.constraint_target_impressions = 0
            this.edit_constraint = false
            this.edit_constraint_id = null
        }
    }
}
</script>

<style lang="postcss" scoped>
.date-picker {
    @apply lg:max-w-lg sm:max-w-xs max-w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 text-xs sm:text-sm border-gray-300 rounded-md;
}
</style>