<template>
    <div class="bg-white rounded-md p-5 flex flex-col gap-5 justify-center text-2xl">
        <span class="text-3xl font-semibold"> <span class="underline underline-offset-2">{{friendlyItemType}}</span> Info</span>
        <!-- Label + Input View -->
        <div class="min-w-fit flex flex-col gap-2">
            <div v-for="property in displayedProperties" :key="property">
                <!-- only show label input value if there is a value to show. Don't show if empty unless it's audio id for a spritesheet -->
                <LabelInputValue
                    v-if="showProperty(property)"
                    :form_key="property"
                    :item_uuid="item.uuid.value"
                    :item_type="item.item_type"
                    :label="property.friendly_name" 
                    :value="property.value" 
                    :input="property.html_input"
                    :editable="property.user_modifiable" 
                    :edit_mode="edit_mode"> 
                </LabelInputValue>
            </div>
        </div>
        <div class="flex flex-row gap-2">

            <!-- Edit Button -->
            <button class="btn-primary green flex items-center" v-if="this.containsUserModifiableProperties" @click="switchEditMode">{{editButtonMessage}}</button>

            <!-- Delete Button -->
            <button class="btn-primary red flex items-center" @click="deleteItem">Delete</button>

            <!-- If item is a gif, give the option to download the item's roblox asset ids  -->
            <button v-if="!edit_mode && showDownloadButton" class="btn-primary green ml-auto" @click="downloadRobloxAssetIDs"> Download Roblox Asset IDs </button>
        </div>
    </div>
</template>

<script>
import LabelInputValue from '@/components/LabelValueInput.vue'

export default {
    components: {
        LabelInputValue
    },
    props: {
        friendlyItemType: {
            default: '',
            type: String,
        },
        item: {
            required: true,
        }
    },
    data() {
        return {
            edit_mode: false,
            show_info_button: true,
        }
    },
    methods: {
        switchEditMode(){
            this.edit_mode = !this.edit_mode
            this.show_info_button = !this.show_info_button
        },
        deleteItem(){
            this.$emit('delete-item', this.item)
        },
        showProperty(property) {
            /* shows audio id if the item is a spritesheet */
            if (property.name == "audio_id" && this.displayedProperties['spritesheet_wait']['value'])
                return true
                
            /* doesn't show properties with null values */
            if (property.value != null)
                return true
            
            return false
        },
        downloadRobloxAssetIDs() {
            // http request to get the asset ids for the gif
            this.$http.get('/gif-assets/'+this.item.uuid.value)
                .then( ({data}) => {
                    const roblox_ids = data.map(obj => obj.roblox_id).join(", ");

                    // Create a Blob object from the string
                    const blob = new Blob([roblox_ids], { type: "text/plain" });

                    // Create a link element that the user can click to download the file
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = `${this.item.user_defined_name.value}.txt`;

                    link.click();
                })
                .catch( (error) => {
                    console.error(error)
                })
        },
    },
    computed: {
        displayedProperties(){
            return this.item.getDisplayedProperties?.()
        },
        containsUserModifiableProperties(){
            const modifiable_properties = this.item.getUserModifiableProperties?.() ?? {}
            if(Object.keys(modifiable_properties).length <= 0) return false
            else return true
        },
        editButtonMessage(){
            if(this.edit_mode) return 'Back'
            else return 'Edit'
        },
        showDownloadButton() {
            if(this.item.img_type && this.item.img_type.value == ".gif")
                return true

            return false
        }
    },
}
</script>