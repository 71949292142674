<template>
    <form v-on:submit.prevent="emitRunForm" class="bg-blue-100 overflow-hidden shadow rounded-lg w-fit p-4 grid grid-flow-row">  
        <div class="sm:border-t sm:border-gray-400 w-full grid grid-cols-2 gap-1 items-center justify-center" v-for="input in form_inputs" :key="input.display">

            <label :for="input.display" class="block text-base font-medium text-gray-700" style="grid-column: 1/2">
                {{input.display}}:
            </label>

            <input 
                :type="input.type" 
                :id="input.display" 
                v-model="run_form[input.model]" 
                :min="input.display == 'End Date' ? this.run_form['start_date']: $dateTodayFormatted()"
                :max="campaign_end_date"
                autocomplete="off" 
                maxlength="50" 
                class="sm-input"
                style="grid-column: 2/3"
                required
            />

        </div>

        <button class="btn-primary indigo h-fit" v-on:submit.prevent="emitRunForm"> Submit </button>    

    </form>
</template>

<script>
import AdvertForm from '@/classes/AdvertForm.js'
export default {
    props: {
        campaign_end_date: {
            type: undefined,
            default: null,
        }
    },
    data() {
        return {
            run_form: new AdvertForm(),
            form_inputs: [
                {
                    'type': 'text',
                    'display': 'Name',
                    'model' : 'name'
                }, 
                {
                    'type': 'date',
                    'display': 'Start Date',
                    'model' : 'start_date'
                },
                {
                    'type': 'date',
                    'display': 'End Date',
                    'model' : 'end_date',
                },
                {
                    'type': 'time',
                    'display': 'Start Time',
                    'model' : 'start_time',
                },
                {
                    'type': 'time',
                    'display': 'End Date',
                    'model' : 'end_time',
                },
                {
                    'type': 'number',
                    'display': 'Target Impressions',
                    'model' : 'target_impressions',
                },
            ]
        }
    },
    methods: {
        emitRunForm() {
            this.$emit('submitForm', this.run_form)
            this.resetRunForm()
        },
        resetRunForm() {
            this.run_form= new AdvertForm()
        },
    },

}
</script>

<style lang="postcss" scoped>
    .sm-input {
        @apply max-w-lg block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md;
    }
    .my-grid {
        display: grid;
        grid-template-columns: 1fr 2fr;
    }
</style>