<template>
    <div id="app">
        <notifications classes="my-notification" position="bottom right"/>
        <component :is="layout">
            <router-view :key="$route.path"/>
        </component>
        
    </div>
</template>

<style lang="scss">
.my-notification {
  // styling
    margin: 5px;
    padding: 10px;
    color: white;
    font-size: 1.3em;
    // default (blue)
    background: #44a4fc;
    border-left: 5px solid #187fe7;

    .notification-title {
        font-weight: 700;
    }
  // types (green, amber, red)
    &.success {
        background: #34ca61;
        border-left-color: #42a85f;
    }

    &.warn {
        background: #ffb648;
        border-left-color: #f48a06;
    }

    &.error {
        background: #e54d42;
        border-left-color: #b82e24;
    }
    &.loader {
        background: #8042e5;
        border-left-color: #9b24b8;
    }
}
</style>

<script>

import BlankContainer from './layouts/Blank'
import DefaultContainer from './layouts/Default'

export default {
    data() {
        return {
            defaultLayout: 'blank-container',
        }
    },
    components: {
        BlankContainer,
        DefaultContainer,
    },
    computed: {
        layout() {
            return this.$route.meta.layout || this.defaultLayout
        },
    },
}
</script>
