import Advert from '@/classes/Advert.js'
import axios from 'axios'
export default class Run extends Advert {
    advert_type = 'runs'
    advert_type_capitalised = 'Runs'
    advert_type_singular = 'run'
    advert_type_singular_capitalised = 'Run'
    fetchAdverts(){
        return axios.get(`/${this.advert_type}/get/all`)
    }
    deleteAdvert(uuid){
        return axios.delete(`${this.advert_type}/${uuid}`)
    }
}
