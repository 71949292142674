<template>
    <div class="flex flex-col justify-center items-center mx-2 gap-1">
        <h5 class="text-gray-900 sm:text-sm text-xs font-medium">{{title}}</h5>
        <div class="w-full border-gray-500 rounded-full relative overflow-hidden break-normal progress-container">
            <span class="text-white text-sm font-medium relative z-10 w-full flex items-center justify-center">{{percentage}}%</span>
            <div class="bg-indigo-600 p-1 rounded-l-full absolute top-0 left-0 h-full" :style="`width: ${this.percentageWidth}%`"> </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            default: '',
            type: String,
        },
        percentage: {
            default: 0,
            type: Number,
        }
    },
    computed: {
        percentageWidth() {
            if (this.percentage > 100 )
                return 100
            
            return this.percentage
        }
    }
}
</script>

<style scoped>
.progress-container {
    padding: 2px; 
    background-color: rgb(188, 188, 188);
    border-width: 1px;
}
</style>