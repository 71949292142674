<template>
    <div>
        <LoadingComponent v-if="loading"/>
        <div class="flex flex-col gap-2" v-else>

            <div class="flex sm:flex-row sm:gap-12 sm:flex-wrap flex-col gap-4 mt-8 mb-4 sm:items-center justify-center bg-white rounded-md p-2">
                <div class="flex flex-row sm:justify-center items-center gap-2 sm:pl-0 pl-2">
                    <input id="table-view" v-model="table_view" name="table-view" type="checkbox" class="focus:ring-indigo-500 h-5 w-5 text-indigo-600 border-gray-300 rounded" />
                    <label for="expired-component" class="text-sm font-medium text-gray-700 sm:text-lg"> Table View </label>
                </div>

                <div class="self-center justify-self-center sm:col-start-2 sm:col-end-3 w-3/4 flex justify-center">
                    <span @click="this.is_invisible_data = true" class="options-picker border-l rounded-l-md" :class="{'options-picker-active' : this.is_invisible_data == true}"> Invisible Data </span>
                    <span @click="this.is_invisible_data = false" class="options-picker rounded-r-md" :class="{'options-picker-active' : this.is_invisible_data == false}"> Campaign Data </span>
                </div>
            </div>
            
            <div class="my-grid-container" v-if="!table_view">
                <div v-for="item in historic_data" :key="item" class="flex flex-col gap-1 bg-white p-4 rounded-md text-lg items-center justify-center">
                    <span class="font-medium text-2xl"> {{ item['Experience'] }} </span> 
                    <img class="w-24 h-24 md:w-36 md:h-36 object-contain bg-transparent overflow-hidden" :src="item.placement_thumbnail" alt="image" draggable="false" loading="lazy"/>
                    <span class="flex flex-col items-center justify-center"> 
                        <span class="font-medium">Placement Name:</span> 
                        {{ item['Placement Name'] }} 
                    </span>
                    <span class="flex flex-col items-center justify-center"> 
                        <span class="font-medium"> Total Impressions:</span> 
                        {{ item[this.totalImpressionsKey] }} 
                    </span>
                    <span class="flex flex-col items-center justify-center"> 
                        <span class="font-medium"> Avg. Impressions / Day:</span> 
                        {{ item['Avg Impressions Per Day'] }} 
                    </span>
                    <button class="btn-primary light-blue" @click="$router.push(`/manage-item/placement/${ item.placement_uuid }`)"> Go To Placement</button> 
                </div>
            </div>
            <TableComponent v-else :table_data="tableData" :show_total_row="false" :show_average_row="false" :exported_file_name="tableExportName" />
        </div>
    </div>
</template>

<script>
import TableComponent from '../components/TableComponent.vue'

export default {
    name: "HistoricDashboard",
    components: { TableComponent },
    props: {},
    data() {
        return {
            historic_data: null,
            table_view: true,
            is_invisible_data: true,
            loading: true,
        }
    },
    computed: {
        tableData() {
            return this.historic_data.map((data) => {
                let obj = {
                    'Experience':               data['Experience'],
                    'Placement Name':           data['Placement Name'],
                    'Total Impressions':        data[this.totalImpressionsKey],
                    'Avg. Impresions / Day':    data['Avg Impressions Per Day'],
                };
                
                if (!this.is_invisible_data) {
                    obj['Total Days Active'] = data['total_days_active'];
                } else {
                    obj['Avg. Uniques / Day'] = data['Avg Uniques Per Day']
                }

                return obj;
            });
        },
        totalImpressionsKey() {
            if(this.is_invisible_data)
                return "Total Invisible Impressions"

            return "Total Campaign Impressions"
        },
        tableExportName() {
            if(this.is_invisible_data)
                return "Invisible - Highest Placements Impressions"

            return "Visible - Highest Placements Impressions"
        }
    },
    watch: {
        is_invisible_data() {
            this.getHistoricData();
        }
    },
    created() {
        this.getHistoricData()
    },
    mounted() {},
    methods: {
        async getHistoricData() {
            this.loading = true
            await this.$http.get(`historic/highest-invis-placement/${this.is_invisible_data}`)
                .then( ({data}) => {
                    this.historic_data = data
                })
            this.loading = false
        }
    }
}
</script>

<style scoped>
.my-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
}
</style>