import storeFunctions from '@/plugins/StorePlugin.js'
const state = () => ({
    taker: {
        selected: {
            creatives: {},
            games: {},
            placements: {}
        }
    },
    adder: {
        selected: {
            creatives: {},
            games: {},
            placements: {}
        }
    }
})

const getters = {
    getCurrentState(state){
        return state
    },
    getTakerSelectedCreatives(state) {
        return state.taker.selected.creatives
    },
    getTakerSelectedGames(state) {
        return state.taker.selected.games
    },
    getTakerSelectedPlacements(state) {
        return state.taker.selected.placements
    },
    getAdderSelectedCreatives(state) {
        return state.adder.selected.creatives
    },
    getAdderSelectedGames(state) {
        return state.adder.selected.games
    },
    getAdderSelectedPlacements(state) {
        return state.adder.selected.placements
    },
    getCheckedStatus: (state) => ({item_uuid, item_type}) => {
        var comparison_taker = state.taker.selected[item_type]
        var comparison_adder = state.adder.selected[item_type]        
        if(!comparison_taker) comparison_taker = {}
        if(!comparison_adder) comparison_adder = {}
        if(item_uuid in comparison_taker || item_uuid in comparison_adder) return true
        else return false
    },
    addToSelected: (state) => ({item_uuid, item_type, picker_type}) => {
        const comparison_uuids = state[picker_type].selected[item_type]
        if(item_uuid in comparison_uuids) {return false}
        else return true
    },
     // eslint-disable-next-line no-unused-vars
    getSelectedItemsData: (state, _, __, rootGetters) => (item_type, picker_type) => {
        const cap_item_type = storeFunctions.capitalizeFirstLetter(item_type) 
        const selected_uuids = state[picker_type].selected[item_type]

        let all_items = rootGetters[`getAll${cap_item_type}`] 

        if(cap_item_type=='Placements')
            all_items = rootGetters[`getAllRunPlacements`] 

        let selected_item_data = {}
        for(const[key] of Object.entries(selected_uuids)){
            all_items.forEach((item) => {
                if(item.uuid == key){selected_item_data[key] = item}
            })
        }
        return selected_item_data
    }
}

const actions = {
    itemSelected({commit, getters},{item, item_type, picker_type}){
        const addToSelected = getters['addToSelected']({item_uuid: item.uuid, item_type: item_type, picker_type: picker_type})
        if (addToSelected) {
            commit('addSelectedItem', {item, item_type, picker_type})
        } else {
            commit('removeSelectedItem', {item, item_type, picker_type})
        }
    },
    addMultiSelectItem({commit}, {item, item_type, picker_type}){
        commit('addSelectedItem', {item, item_type, picker_type})
    }, 
}

const mutations = {
    //individual points in the state tree need to be updated using $set. 
    restorePreviousState(state, old_state){
        state.taker.selected.creatives = old_state.taker.selected.creatives
        state.taker.selected.games = old_state.taker.selected.games
        state.taker.selected.placements = old_state.taker.selected.placements
        state.adder.selected.creatives = old_state.adder.selected.creatives
        state.adder.selected.games = old_state.adder.selected.games
        state.adder.selected.placements = old_state.adder.selected.placements
    },
    clearAllSelected(state){
        state.taker.selected.creatives = {}
        state.taker.selected.games = {}
        state.taker.selected.placements = {}
        state.adder.selected.creatives = {}
        state.adder.selected.games = {}
        state.adder.selected.placements = {}
    },
    clearSelectedItems(state, {picker_type, item_type}){
        state[picker_type].selected[item_type] = {}
    },
    setTakerSelectedCreatives(state, creatives){
        state.taker.selected.creatives = creatives
    },
    setTakerSelectedGames(state, games){
        state.taker.selected.games = games
    },
    setTakerSelectedPlacements(state, placements){
        state.taker.selected.placements = placements
    },
    setAdderSelectedCreatives(state, creatives){
        state.adder.selected.creatives = creatives
    },
    setAdderSelectedGames(state, games){
        state.adder.selected.games = games
    },
    setAdderSelectedPlacements(state, placements){
        state.adder.selected.placements = placements
    },
    //Need to look into how data is passed to this point as during multi-select the whole original,
    //image object is passed, but in singular the image object is re-formatted in the Component
    addSelectedItem(state, {item, item_type, picker_type}){
        state[picker_type]['selected'][item_type][item.uuid] = item.uuid
    },
    removeSelectedItem(state, {item, item_type, picker_type}){
        delete(state[picker_type].selected[item_type][item.uuid ])
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}