<template>
    <div>
        <!-- Campaign Primers List -->
        <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mb-2" @click="addCampaignPrimer">Add Campaign</button>

        <!-- Add/Edit Campaign Primer Form -->
        <div v-if="showForm" class="mb-4 form-wrapper" :key="formRefresherKey">
            <form @submit.prevent="submitForm" class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="campaign-name">
                        Campaign Name
                    </label>
                    <input type="text" id="campaign-name" v-model="form.name" placeholder="Campaign Name"
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                </div>

                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="target-impressions">
                        Target Impressions
                    </label>
                    <input type="number" id="target-impressions" v-model="form.target_impressions" placeholder="0"
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                </div>

                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="category">
                        Countries
                    </label>
                    <DropdownComponent
                        class="lg:max-w-lg sm:max-w-xs w-full"
                        selected="All Countries" 
                        :items="allCountries" 
                        display="name"
                        @user_select="setCampaignCountry"
                        :key="countryDropdownRefresher"/>
                    <div class="flex flex-row gap-1 flex-wrap">
                        <div v-for="country in this.form.countries" :key="country" class="text-white bg-indigo-500 rounded-md px-2 py-1 cursor-pointer hover:bg-blue-300 hover:text-black" @click="removeCountry(country)">
                            {{country.name}}
                        </div>
                    </div>
                </div>

                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="category">
                        Gender
                    </label>
                    <DropdownComponent
                        class="lg:max-w-lg sm:max-w-xs w-full"
                        :selected="form.gender || 'All'"
                        :items="genderDropdownList" 
                        @user_select="setGender"
                        :key="genderDropdownRefresher"/>
                </div>

                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="flight-dates">
                        Flight Dates
                    </label>
                    <div v-for="(flight, index) in form.flight_information" :key="index" class="mb-4">
                        <div class="mb-2">
                            <input type="date" :id="'start-date-' + index" v-model="flight.start_date"
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            <label class="block text-gray-700 text-sm font-bold mb-2" :for="'start-date-' + index">
                                Start Date
                            </label>
                        </div>
                        <div class="mb-2">
                            <input type="date" :id="'end-date-' + index" v-model="flight.end_date"
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            <label class="block text-gray-700 text-sm font-bold mb-2" :for="'end-date-' + index">
                                End Date
                            </label>
                        </div>
                        <button type="button" @click="removeFlight(index)" class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded mt-1">
                            Remove
                        </button>
                    </div>
                    <button type="button" @click="addFlight" class="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded">
                        Add Flight Date
                    </button>
                </div>

                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="creatives-link">
                        Link To Creatives (dropbox or google drive)
                    </label>
                    <input type="text" id="creatives-link" v-model="form.creatives_link" placeholder="http://some.link.com"
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                </div>
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="notes">
                        Notes
                    </label>
                    <textarea id="notes" v-model="form.notes" placeholder="Notes" maxlength="250"
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"></textarea>
                </div>
                <div class="flex items-center justify-between">
                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Save
                    </button>
                </div>
            </form>
        </div>
        <div v-if="campaignPrimers.length > 0" class="inline-block min-w-full">
            <div class="flex flex-row items-start bg-white overflow-x-auto shadow-md">
                <table class="min-w-full">
                    <thead class="bg-gray-200 sticky top-0 z-10">
                        <tr>
                            <th class="px-4 py-2 text-left" style="min-width: 150px;">Campaign Name</th>
                            <th class="px-4 py-2 text-left" style="min-width: 120px;">Target Impressions</th>
                            <th class="px-4 py-2 text-left" style="min-width: 120px;">Countries</th>
                            <th class="px-4 py-2 text-left" style="min-width: 100px;">Gender</th>
                            <th class="px-4 py-2 text-left" style="min-width: 160px; width: 20%;">Flight Dates</th>
                            <th class="px-4 py-2 text-left" style="min-width: 120px; max-width: 15%;">Link To Creatives</th>
                            <th class="px-4 py-2 text-left" style="min-width: 120px;">Notes</th>
                            <th class="px-4 py-2 text-left" style="min-width: 100px;">Creator</th>
                            <th class="px-4 py-2 text-left" style="min-width: 80px;">Approved</th>
                            <th class="px-4 py-2 text-left" style="min-width: 140px;">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(primer) in campaignPrimers" :key="primer.uuid"
                            class="border-b transition duration-300 ease-in-out hover:bg-gray-100">
                            <td class="px-4 py-2">{{ primer.name }}</td>
                            <td class="px-4 py-2">{{ formatNumber(primer.target_impressions) }}</td>
                            <td class="px-4 py-2">
                                <ul>
                                    <li v-for="(country, i) in primer.countries" :key="i">{{ country.name }}</li>
                                </ul>
                            </td>
                            <td class="px-4 py-2">{{ primer.gender }}</td>
                            <td class="px-4 py-2">
                                <ul>
                                    <li v-for="(flight, i) in primer.flight_information" :key="i">
                                        {{ formatToMMddYYYY(flight.start_date) }} to {{ formatToMMddYYYY(flight.end_date) }}
                                    </li>
                                </ul>
                            </td>
                            <td class="px-4 py-2 link-cell">
                                <a :href="primer.creatives_link" target="_blank" style="color: blue; text-decoration: underline;">Link to Creatives</a>
                            </td>                       
                            <td class="px-4 py-2">{{ primer.notes }}</td>
                            <td class="px-4 py-2">{{ primer.created_by }}</td>
                            <td class="px-4 py-2">{{ primer.is_approved ? 'Yes' : 'No' }}</td>
                            <td class="px-4 py-2">
                                <button v-if="!primer.is_approved" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded" @click="editCampaignPrimer(primer)">Edit</button>
                                <button v-if="!primer.is_approved" class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded" @click="deleteCampaignPrimer(primer.uuid)">Delete</button>
                                <button v-if="!primer.is_approved && userInformation.type == 'admin'" class="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded" @click="approveCampaignPrimer(primer.uuid)">Approve</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

const defaultFlightDatePlaceholder = {
    start_date: '',
    end_date: ''
}

const defaultPrimerForm = {
        name: '',
        creatives_link: '',
        notes: '',
        gender: 'All',
        flight_information: [defaultFlightDatePlaceholder],
        target_impressions: 0,
        category: '',
        countries: [],
    }

const defaultUserInformation = {
    uuid: 'uuid',
    email: 'email@email.com',
    type: 'normal',
}

export default {
    name: "ManageCampaignPrimers",
    components: {},
    props: {},
    data() {
        return {
            campaignPrimers: [],
            showForm: false,
            isEditMode: false,
            form: defaultPrimerForm,
            formRefresherKey: 0,
            countryDropdownRefresher: 0,
            allCountries: [],
            userInformation: defaultUserInformation,
            genderDropdownList: [
                'All', 'Male', 'Female'
            ]
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.fetchCampaignPrimers()
        this.getCountries()
        this.getUserInformation()
    },
    methods: {
        async fetchCampaignPrimers() {
            const url = '/campaign-primers';
            const toasts = {
                success: 'Campaigns fetched successfully',
                error: 'Failed to fetch Campaigns'
            };

            await this.$http_request(
                this.$http.get,
                url,
                toasts
            ).then(response => {
                this.campaignPrimers = response.data.data;
                console.log('Campaigns:', this.campaignPrimers);
            }).catch(error => {
                console.error('Fetch Campaigns Error:', error);
            });
        },

        async getCountries(){
            return this.$http_request(this.$http.get, '/countries')
                .then(({data}) => {
                    this.allCountries = data
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },

        async getUserInformation() {
            return this.$http_request(this.$http.get, '/user/profile')
                .then(({data}) => {
                    this.userInformation = data.data;
                    console.log('User Information:', this.userInformation)
                })
                .catch((error) => {
                    console.error(error.message);
                })
        },

        addCampaignPrimer() {
            if (this.showForm && !this.isEditMode) {
                this.showForm = false;
                this.resetForm();
                return;
            }
            this.showForm = true;
            this.isEditMode = false;
            this.resetForm(); // Reset form to initial state for new entries
        },

        removeCountry(country) {
            this.form.countries = this.form.countries.filter( el => el !== country);
            this.allCountries.push(country);
            this.allCountries.sort((a, b) => {
                if (a.name === 'United States of America') return -1;
                if (b.name === 'United States of America') return 1;
                return a.name.localeCompare(b.name);
            });
        },

        editCampaignPrimer(primer) {
            if (this.showForm && this.activeRowId === primer.uuid) {
                this.showForm = false;
                this.isEditMode = false;
                this.resetForm();
                return;
            }

            this.activeRowId = primer.uuid;
            // Clone the primer and format dates for the date inputs
            this.form = JSON.parse(JSON.stringify(primer)); // Deep clone to ensure reactivity and avoid direct mutation

            // Ensure each flight date is in the correct format if not already
            if (this.form.flight_information && this.form.flight_information.length) {
                this.form.flight_information.forEach(flight => {
                    flight.start_date = this.formatDateForInput(flight.start_date);
                    flight.end_date = this.formatDateForInput(flight.end_date);
                });
            }

            this.countryDropdownRefresher += 1;
            this.genderDropdownRefresher += 1;
            this.showForm = true;
            this.isEditMode = true;

            console.log('Editing Campaign:', this.form);
        },

        // Helper method to ensure date is in 'YYYY-MM-DD' format
        formatDateForInput(dateStr) {
            if (!dateStr) return '';
            const date = new Date(dateStr);
            return date.toISOString().slice(0, 10); // Converts date to 'YYYY-MM-DD' format
        },

        deleteCampaignPrimer(primerUuid) {
            if(!confirm("Are you sure you want to delete this Campaign?")) return

            // URL for the DELETE request
            const url = `/campaign-primers/${primerUuid}`;
            const method = 'delete';  // HTTP method for deletion
            const toasts = {
                success: 'Campaign  deleted successfully',
                error: 'Failed to delete Campaign'
            };

            // Call the http_request with the DELETE method, URL, and no data needed
            this.$http_request(
                this.$http[method],
                url,
                toasts
            ).then(() => {
                this.showForm = false; // Optionally hide the form or update UI
                this.fetchCampaignPrimers(); // Refresh the list of campaign primers
            }).catch(error => {
                console.error('Error deleting Campaign:', error);
                if (error && error.response && error.response.status === 422) {
                    // Handle specific error feedback for validation or other server-side issues
                    console.log('Validation errors:', error.response.data);
                }
            });
        },

        resetForm() {            
            // Deep clone the default form to avoid references to the original object
            this.form = this.deepClone(defaultPrimerForm);
            
            this.activeRowId = null;
            this.formRefresherKey += 1;
        },

        addFlight() {
            this.form.flight_information.push({
                start_date: '',
                end_date: ''
            });
        },

        removeFlight(index) {
            this.form.flight_information.splice(index, 1);
        },

        setGender(gender){
            this.form.gender = gender
            this.genderDropdownRefresher += 1
        },

        submitForm() {
            const url = this.isEditMode ? `/campaign-primers/${this.activeRowId}` : '/campaign-primers';
            const method = this.isEditMode ? 'put' : 'post';
            const toasts = {
                success: this.isEditMode ? 'Updated ' : 'Created',
                error: 'Failed to save Campaign'
            };

            this.$http_request(
                this.$http[method],
                url,
                toasts,
                this.form
            ).then(() => {
                this.notifyHowManyDaysUntilGoingLive();
                this.showForm = false;
                this.isEditMode = false;
                this.resetForm();
                this.fetchCampaignPrimers();
            }).catch(error => {
                console.error('Error submitting Campaign form:', error);
                if (error && error.response && error.response.status === 422) {
                    // Log the entire response data to inspect the structure
                    console.log('Response data:', error.response.data);

                    // Directly use the error.response.data since it contains the error details
                    const validationErrors = error.response.data;
                    if (validationErrors) {
                        this.displayValidationErrors(validationErrors);
                    } else {
                        // If validationErrors is undefined or empty
                        console.log('No validation errors found in the response.');
                    }
                }
            });
        },

        approveCampaignPrimer(primerUuid) {
            if(!confirm("Are you sure you want to approve this Campaign?")) return
            const url = `/campaign-primers/${primerUuid}/approve`; // Setting the endpoint for approval
            const method = 'put'; // Method is PUT for updating/approving
            const toasts = {
                success: 'Campaign approved successfully',
                error: 'Failed to approve Campaign'
            };

            // Making the HTTP request
            this.$http_request(
                this.$http[method],
                url,
                toasts,
                this.form
            ).then(() => {
                this.fetchCampaignPrimers(); // Refetch the list of campaign primers
            }).catch(error => {
                console.error('Error during campaign primer approval:', error);
                // Optionally handle additional logic on error
            });
        },

        displayValidationErrors(errors) {
            if (!errors || typeof errors !== 'object') {
                console.error('No valid validation errors provided:', errors);
                return;
            }

            Object.keys(errors).forEach(field => {
                const fieldName = this.humanizeFieldName(field);  // Convert field names to a more readable format
                errors[field].forEach(error => {
                    this.$notify({
                        type: 'error',
                        title: 'Validation Error',
                        text: `${fieldName}: ${error}`
                    });
                });
            });
        },

        setCampaignCountry(country) {
            this.form.countries.push(country)
            this.allCountries = this.allCountries.filter( el => el !== country)
            console.log('form after adding country',this.form)
            this.countryDropdownRefresher += 1
        },
        
        humanizeFieldName(field) {
            // Convert field names like 'flight_information.0.start_date' to 'Flight Information Start Date'
            return field
                .replace(/_/g, ' ')  // Replace underscores with spaces
                .replace(/\d+/g, '')  // Remove numeric characters
                .replace(/\./g, ' ')  // Replace dots with spaces
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize the first letter of each word
                .join(' ');
        },

        formatToMMddYYYY(dateStr) {
            if (!dateStr) return '';

            // Identify if time part is included
            const hasTimePart = dateStr.includes(' ');

            // Parse the date string in UTC mode
            const formatString = hasTimePart ? 'YYYY-MM-DD HH-mm' : 'YYYY-MM-DD';
            const date = moment.utc(dateStr, formatString);

            // Format the date string
            const formattedDate = date.format('MM-DD-YYYY');

            // Append time part if present
            const formattedDateTime = hasTimePart ? date.format('MM-DD-YYYY HH:mm') : formattedDate;

            return formattedDateTime;
        },

        formatNumber(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        deepClone(obj) {
            return JSON.parse(JSON.stringify(obj));
        },

        notifyHowManyDaysUntilGoingLive() {
            if (!this.form || !this.form.flight_information || !this.form.flight_information.length) return;

            const today = moment();
            const startDates = this.form.flight_information.map(flight => moment(flight.start_date));
            const earliestStartDate = moment.min(startDates);
            
            // Calculate the difference in days, rounding up if there's a fractional part
            const daysUntilLive = Math.ceil(earliestStartDate.diff(today, 'days', true));

            let notificationText;
            if (daysUntilLive === 1) {
                notificationText = 'tomorrow';
            } else {
                notificationText = `in ${daysUntilLive} day(s)`;
            }

            const notificationMessage = `Your campaign once approved will go live ${notificationText}.`;

            this.$notify({
                type: 'success',
                title: 'Submission Successful',
                text: notificationMessage,
                duration: 10000 // duration in milliseconds (10 seconds)
            });
        }
    }
}
</script>

<style scoped>
/* Ensures table headers stay visible */
thead th {
    position: sticky;
    top: 0;
    background: #FFF;
    z-index: 10;
}

/* Hover effect for table rows */
tbody tr:hover {
    background-color: #f3f4f6; /* Light gray background on hover */
}

/* Overflow handling for the table container */
.overflow-x-auto {
    overflow-x: auto;
}

/* Additional styles for the link cell */
.link-cell {
    max-width: 150px; /* Set a max-width for the link column */
    white-space: normal; /* Overrides the default nowrap on table cells if present */
    word-wrap: break-word; /* Ensures words can be broken at any character */
    word-break: break-word; /* Specifically useful for unbroken text like URLs */
    overflow-wrap: break-word; /* Alternative to word-wrap for modern browsers */
}

.table-auto {
    width: 100%;
    display: block;
    overflow-x: auto;
}

.form-wrapper {
    width: 50%;
    margin: auto; /* Auto for all sides centers it also vertically if inside a sufficiently sized container */
    display: block; /* Ensures the div is treated as a block-level element */
    padding: 20px; /* Adds space inside the div around the form */
}

</style>