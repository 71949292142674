import AdvertFactory from '@/classes/AdvertFactory';
import storeFunctions from '@/plugins/StorePlugin.js'
import axios from 'axios'

const state = {
    advert_factory: undefined,
    advert_data: {}
}

const actions = {
    // eslint-disable-next-line no-unused-vars
    async fetchSingleAdvert({state, commit}, {advert_type, advert_uuid}){
        return await axios.get(advert_type + '/' + advert_uuid).then( ({data}) => {
            return data.data
        })
    },
    async fetchAdvertPickerItems({commit, dispatch}, {uuid, advert_type, item_type, picker_type}){
        const strings = await dispatch('buildStringsForFetchAdvertPickerItems', {picker_type, advert_type, item_type, uuid})
        return axios.get(strings.get_string)
            .then(({data}) => {
                commit(strings.commit_string, data, {root:true})
            })
    },
    //TODO: REFACTORING this method. The route for runs/games would be a starting point to reduce one conditional
    // eslint-disable-next-line no-unused-vars
    buildStringsForFetchAdvertPickerItems({commit},{picker_type, advert_type, item_type, uuid}){
        const advert_type_singular = storeFunctions.removeLastLetter(advert_type)
        const cap_item_type = storeFunctions.capitalizeFirstLetter(item_type)
        const cap_singular_advert_type = storeFunctions.removeLastLetter(storeFunctions.capitalizeFirstLetter(advert_type))
        let get_string = ``
        let commit_string = ``
        if(picker_type == 'Adder') {
            if(item_type != 'placements'){
                uuid = 'all'
            }
            commit_string = `setAll${cap_singular_advert_type}${cap_item_type}`
        } else {
            commit_string = `set${picker_type}${cap_item_type}`
        }
        if(item_type == 'placements' && picker_type == 'Adder'){
            get_string = `/run-placements/all/${uuid}`
        }
        else {
            get_string = `${advert_type_singular}-${item_type}/${uuid}`
        }
        return {
            'get_string': get_string,
            'commit_string': commit_string
        }  
    },
    updateAdvert({dispatch}, {advert_type, advert_form}){
        const advert = new AdvertFactory().getAdvert(advert_type)
        return advert.updateAdvert(advert_form)
            .then(() => {
                return dispatch('fetchSingleAdvert', {advert_type: advert_type, advert_uuid: advert_form.uuid})
            })
    },
    // eslint-disable-next-line no-unused-vars
    deleteAdvert({state}, {advert_type, uuid}){
        const advert = new AdvertFactory().getAdvert(advert_type)
        return advert.deleteAdvert(uuid)
    },
    // eslint-disable-next-line no-unused-vars
    copyAdvert({state}, {advert_type, uuid}){
        const advert = new AdvertFactory().getAdvert(advert_type)
        return advert.copyAdvert({uuid})
    },
    // eslint-disable-next-line no-unused-vars
    async addItemsToAdvert({dispatch, rootGetters}, {advert_type, uuid, item_type}){
        const advert = new AdvertFactory().getAdvert(advert_type)
        const capitalized_item_type = storeFunctions.capitalizeFirstLetter(item_type) 
        const item_uuids = rootGetters[`picker_module/getAdderSelected${capitalized_item_type}`]
        const request = { uuid, item_uuids, item_type }
        return await advert.addItemsToAdvert(request).then(() => {
            dispatch('clearSelected', {uuid, advert_type, item_type})
        })
    },
    // eslint-disable-next-line no-unused-vars
    async removeItemsFromAdvert({rootGetters, dispatch}, {advert_type, uuid, item_type}){
        const advert = new AdvertFactory().getAdvert(advert_type)
        const cap_item_type = storeFunctions.capitalizeFirstLetter(item_type)
        const item_uuids = rootGetters[`picker_module/getTakerSelected${cap_item_type}`] 
        const request = {uuid, item_uuids, item_type}
        return await advert.removeItemsFromAdvert(request)
            .then(() => {
                dispatch('clearSelected', {uuid, advert_type, item_type})
            })
    },
    clearSelected({commit, dispatch}, uuid, advert_type, item_type) {
        const clear_selected_request = {
            'picker_type': 'adder',
            'item_type': item_type
        }

        commit('picker_module/clearSelectedItems', clear_selected_request, {root: true})

        dispatch(`fetchAdvertPickerItems`, {uuid, advert_type, item_type, picker_type:'Taker'})
        dispatch(`fetchAdvertPickerItems`, {uuid, advert_type, item_type, picker_type:'Adder'})

        if(item_type == 'games'){
            dispatch(`fetchAdvertPickerItems`, {uuid, advert_type, 'item_type': 'placements', picker_type:'Taker'})
            dispatch(`fetchAdvertPickerItems`, {uuid, advert_type, 'item_type': 'placements', picker_type:'Adder'})
        }
    }
}

export default {
    namespaced: true,
    state,
    actions,
}