import storeFunctions from '@/plugins/StorePlugin.js'
import axios from 'axios'
const state = () => ({
    access_token: '',
    name: null,
    email: null,
    timezone: null,
})

const actions = {
    // eslint-disable-next-line no-empty-pattern
    isUserVerified({state}, credentials) {
        const toasts = {'error': 'Invalid Email/Password'}
        return storeFunctions.http_request(this.$http.post, 'user/checkVerified', toasts, credentials)
            .then( (response) => {

                if(response.data == "Invalid Email Format" || response.data == "Email Has Not Been Verified By Moderator Yet!") {
                    this.$notify({type: "error", text: response.data})
                    return Promise.reject()
                }

                state.name= response.data
            })
            .catch((error) => { 
                console.error(error)
                return Promise.reject(error)
            })
    },
    login({ state, dispatch }, credentials) {
        const toasts = {'error': 'Something Went Wrong!'}
        return storeFunctions.http_request(this.$http.post, '/login', toasts, credentials)
                .then( async ({data}) => {
                    state.access_token = data.access_token
                    state.email = data.email
                    state.timezone = data.timezone
                    // set auth header 
                    this.$http.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`
                    await dispatch('fetchUserPermissions', {email: data.email}, {root: true})
                })
                .catch((error) => { 
                    console.error(error)
                    return Promise.reject(error)
                })
    },
    logout({ state }) {
        state.access_token = null
        state.name = null
        state.timezone = null
        sessionStorage.clear()
        return 
    },
    // eslint-disable-next-line no-empty-pattern
    registerUser({}, credentials) {
        const toasts = {'error': 'Something Went Wrong!'}
        return storeFunctions.http_request(this.$http.post, 'registerUser', toasts, credentials)
        .catch((error) => { 
            console.error(error)
            return Promise.reject(error)
        })
    },
    getUsernameFromSession({state}) {
        if(state.name != null)
            return state.name

        const toasts = {'error': 'Something Went Wrong!'}

        return storeFunctions.http_request(this.$http.get, '/get-logged-in-username', toasts).then( (response)=> {
            
            if(response.data) {
                state.name = response.data
            }

            return state.name
        })
    },
    getEmailFromSession({state}) {
        if(state.email != null)
            return state.email

        const toasts = {'error': 'Something Went Wrong!'}

        return storeFunctions.http_request(this.$http.get, '/get-logged-in-email', toasts).then( (response)=> {
            if(response.data) {
                state.email = response.data
            }

            return state.email
        })
    },
    async getUserTimezone({state, dispatch}) {
        if (state.timezone)
            return state.timezone
        
        return await dispatch('refreshTimezone')
    },
    async refreshTimezone({commit}) {
        const timezone = await refreshUserTimeZone()
        commit('setTimezone', timezone)
        return timezone
    }
}

const mutations = {
    setTimezone(state, timezone) {
        state.timezone = timezone
    },
}

async function refreshUserTimeZone() {
    const toasts = {'error': 'Something Went Wrong!'}
    return await storeFunctions.http_request(axios.get, '/get-logged-in-timezone', toasts).then( ({data})=> {
        return data
    })
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}