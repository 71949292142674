import { notify } from "@kyvg/vue3-notification";

var storeFunctions = {
    removeLastLetter(string){
        return string.slice(0, -1)
    },

    capitalizeFirstLetter(string){
        return string.charAt(0).toUpperCase() + string.slice(1)
    },

    dateTodayFormatted(){
        return this.formatDate(new Date())
    },

    formatDate(date){
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    },

    async http_request(http_method, url, toasts={'success': undefined, 'error': undefined, 'confirm': undefined}, data=undefined, header=undefined, timeout = null){
        if(toasts.confirm && confirm(toasts.confirm) || !toasts.confirm) {

            const config = {
                headers: header,
                timeout: timeout ? timeout * 1000 : 0 // Convert to milliseconds
            };

            notify({id: 'loader', type:"loader", duration: 10000000, text: "Loading"})
            return http_method(url, data, config)
                .then((response)=>{
                    notify.close('loader');
                    if(toasts.success) notify({type: 'success', text: toasts.success})
                    return Promise.resolve(response)
                }) 
                .catch((error) => {
                    notify.close('loader');
                    if(error.response.status === 413) notify({type: 'error', text: 'Payload Is Too Large!'})
                    if(error.code === 'ECONNABORTED') notify({ type: 'error', text: 'The request timed out!' });
                    else if(toasts.error) notify({type: 'error', text: toasts.error})
                    return Promise.reject(error)
                })
            } else {
                notify.close('loader');
                return Promise.reject()
            }
    }
}

export default storeFunctions 
