<template>
    <div class="flex flex-col justify-center items-center rounded-md p-4 gap-2">

        <div v-for="item in label_info" :key="item" class="border-t border-gray-200 p-1 w-full grid grid-cols-2 gap-3 justify-center">

            <label :for="item.label" class="block sm:text-lg text-xs font-medium text-gray-700 col-span-1" style="grid-column: 1/2">
                {{item.label}}:
            </label>

            <span :id="item.label" class="block sm:text-xl text-sm text-gray-700 col-span-1 capitalize" style="grid-column: 2/3">
                {{run[item.value].toLocaleString()}}
            </span>

        </div>

        <ProgressBar title="Impressions Sent" :percentage="impressionsSentPercentage" class="w-full border-t border-gray-200"/>
        <ProgressBar title="Time Completion" :percentage="dateGapPercentage" class="w-full"/>
        
        <div class="flex flex-row justify-center items-center gap-1 border-t border-gray-200">
            <button class="btn-primary green h-fit" @click="toggleSelectedRun"> Toggle View</button>
            <button class="btn-primary red h-fit" @click="deleteRun"> Delete </button>
            <button class="btn-primary indigo h-fit" @click="copyRun"> Copy </button>
            <RGBLed v-if="ledColor=='red'" :inactive_reason="inactiveReasonsStringBuilder" :active_color="ledColor"></RGBLed>
            <RGBLed v-if="ledColor=='green'" :active_color="ledColor"></RGBLed>
        </div>
    </div>
</template>

<script>
import ProgressBar from '@/components/UtilityComponents/ProgressBar.vue'
import RGBLed from '@/components/RGBLed.vue'
export default {
    components: {
        RGBLed,
        ProgressBar,
    },
    props: {
        run: {
            default: null,
            type: Object,
        },
        campaign_uuid: {
            default: null,
            type: String,
        },
        is_active: {
            default: true,
            required: false,
            type: Boolean,
        },
        inactive_reasons: {
            default: [],
            required: false,
            type: () => {return Array},
        },
        user_timezone: {
            default: null,
            required: true,
        }
    },
    data() {
        return {
            label_info: [
                {
                    'label': 'Name',
                    'value': 'name',
                }, 
                {
                    'label': 'Start Date',
                    'value': 'start_date',
                },
                {
                    'label': 'End Date',
                    'value': 'end_date',
                },
                {
                    'label': 'Impressions Sent',
                    'value': 'impressions_sent',
                },
                {
                    'label': 'Target Impressions',
                    'value': 'target_impressions',
                },
                {
                    'label': 'Is Rate Limited',
                    'value': 'is_rate_limited',
                },
            ]
        }
    },
    methods: {
        toggleSelectedRun() {
            this.$emit('selected_run', this.run)
        },
        deleteRun() {
            if(confirm('Are You Sure You Want To Delete This Ad Set?')) {       
                const request = {
                    advert_type: 'runs',
                    uuid: this.run.uuid,
                } 
                this.$store.dispatch('adverts_module/deleteAdvert', request)
                    .then(() => {
                        this.$emit('deletedRunInfo')
                        this.emitter.emit("refreshCampaignNavbar");
                        this.$notify({type: "success", text: 'Ad Set Deleted Successfully'})
                    })
                    .catch(() => {
                        this.$notify({type: "error", text: 'Error Deleting Ad Set'})
                    })
            }
        },
        copyRun(){
            this.$store.dispatch('adverts_module/copyAdvert', {advert_type: 'runs', uuid: this.run.uuid})
            // emit to refresh campaign
            this.$emit('refreshCampaignRuns')
            this.emitter.emit("refreshCampaignNavbar");
        },
    },
    computed: {
        dateGapPercentage() {
            return this.$advertDateGapPercentage(this.run, 'runs', this.user_timezone)
        },
        ledColor(){
            if(this.is_active) return 'green'
            else return 'red'
        },
        inactiveReasonsStringBuilder(){
            let reason_string = ''
            this.inactive_reasons.forEach((reason_phrase) => {
                reason_string = reason_string + reason_phrase + '.\n'
            })
            return reason_string
        },
        impressionsSentPercentage() {
            if(this.run.target_impressions == 0)
                return 0
            return  Math.floor(this.run.impressions_sent / this.run.target_impressions * 100)
        },
    },
}
</script>
