<template>
    <div>
        <div
            class="flex flex-col gap-2 justify-center items-center h-full"
            v-if="loading">
            <h2>Fetching data... This might take a few minutes.</h2>
            <LoadingComponent />
        </div>
        <div class="flex flex-col gap-2">
            <!-- Filter Section -->
            <div class="sm:p-8 p-4 bg-blue-100 rounded-md sm:grid sm:grid-cols-1 max-sm:flex max-sm:flex-col gap-x-8 gap-y-4" v-show="!loading">
                <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <!-- Game Filter -->
                    <div>
                        <label class="font-medium block">Game:</label>
                        <DropdownComponent 
                            :items="gamesDropdown"
                            display="game_name"
                            :selected="selectedGameName ? this.selectedGame.game_name : 'Select a Game (required)'"
                            @user_select="changeSelectedGame"
                            :key="gameDropdownRefresher"
                            class="w-full"/>
                    </div>

                    <!-- Country Filter -->
                    <div>
                        <label class="font-medium block">Country:</label>
                        <DropdownComponent
                            :items="countryDropdownItems"
                            :selected="selectedCountry ?? 'All Countries'"
                            @user_select="changeSelectedCountry"
                            class="w-full"/>
                    </div>
                </div>

                <!-- Apply and Reset Filter Buttons -->
                <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <button class="btn-primary green" @click="fetchNewData" v-if="this.current_integration && !loading">Apply Filters</button>
                    <button class="btn-primary indigo" @click="downloadTablesAsExcel" v-if="showDownloadButton">Export Data</button>
                </div>
            </div>

            
            <div class="p-4 px-8 bg-white rounded-md text-xl font-medium text-gray-700 text-center flex flex-row items-center lg:justify-between justify-center gap-2 flex-wrap" v-show="current_integration">
                <img src="@/assets/GamefamLogo.png" class="w-40 h-9 object-cover" alt="gamefam-logo"> 
                {{headerName}} 
                <span class="text-sm text-gray-500"> Campaign Dates - {{integrationStartDateString}} - {{integrationEndDateString}}</span>
            </div>
            <div class="p-4 px-8 bg-white rounded-md text-xl font-medium text-gray-700 text-center flex flex-row items-center lg:justify-between justify-center gap-2 flex-wrap" v-show="current_integration">
                Full Campaign Overview    
            </div>
            <!-- Cards Section -->
            <div class="grid-container gap-2" v-if="current_integration && current_integration.integration_overview && ((showStat(current_integration.integration_overview?.total_unique_engagements) || showStat(current_integration.integration_overview?.mean_engagements_per_player)))">
                <div class="card-container" v-if="showStat(current_integration.integration_overview?.total_unique_engagements)">
                    <div class="inner-card">
                        <label> Total Unique Engagements: </label>
                        <span> {{ formatNumber(current_integration.integration_overview?.total_unique_engagements) }} </span>
                    </div>
                    <div class="inner-card" v-if="showStat(current_integration.integration_overview?.mean_engagements_per_player)">
                        <label>Average Engagements Per Player</label>
                        <span> {{ formatNumber(current_integration.integration_overview?.mean_engagements_per_player) }} </span>
                    </div>
                </div>
                <div class="card-container" v-if="showStat(current_integration.integration_overview?.total_visits) || showStat(current_integration.integration_overview?.mean_seconds_spent_per_player)">
                    <div class="inner-card" v-if="showStat(current_integration.integration_overview?.total_visits)">
                        <label> Total Visits </label>
                        <span> {{ formatNumber(current_integration.integration_overview?.total_visits) }} </span>
                    </div>
                    <div class="inner-card" v-if="showStat(current_integration.integration_overview?.mean_seconds_spent_per_player)">
                        <label> Mean Seconds Spent Per Player </label>
                        <span> {{ formatNumber(current_integration.integration_overview?.mean_seconds_spent_per_player) }} </span>
                    </div>
                </div>
                <div class="card-container" v-if="showStat(current_integration?.target_engagements) && showStat(current_integration?.delivered_engagements)">
                    <div class="inner-card" v-if="showStat(current_integration?.target_engagements)">
                        <label> Target Engagements </label>
                        <span> {{ formatNumber(current_integration?.target_engagements) }} </span>
                    </div>
                    <div class="inner-card" v-if="showStat(integrationTotalEngagements)">
                        <label> Total Engagements Delivered </label>
                        <span> {{ formatNumber(integrationTotalEngagements) }} </span>
                    </div>
                </div>
            </div>

            <div class="p-4 bg-white rounded-md flex flex-col gap-4" v-if="!fetchingStatus.ingegrationData">
                <ProgressBar title="Current Pacing" :percentage="engagementPercentageProgress"/>
                <ProgressBar title="Target Pacing" :percentage="engagementTimePercentageProgress"/>
            </div>

            <TabButtons :buttons="panelSwitchingButtons" :active_button="currentlyVisiblePanel" :panel-changed-method="panelChanged"></TabButtons>

            <chart v-if="currentlyVisiblePanel === 'event_table'" :options="gameTotalsChartOptions"></chart>

            <chart v-if="currentlyVisiblePanel === 'event_group_totals'" :options="chartOptions"></chart>

            <!-- <chart v-if="currentlyVisiblePanel === 'event_group_daily_table'" :options="dailyEventChartOptions"></chart> -->

            <div class="p-4 px-8 bg-white rounded-md text-xl font-medium text-gray-700 text-center flex flex-row items-center lg:justify-between justify-center gap-2 flex-wrap" v-show="current_integration">
                Table Data
            </div>

            <TableComponent v-if="currentlyVisiblePanel === 'event_table'" :table_data="eventTotalsData" total_column_heading_name="Event Name"  :show_average_row="false"></TableComponent>

            <TableComponent v-if="currentlyVisiblePanel === 'event_table'" :table_data="eventGroupDailyData" total_column_heading_name="Date" :show_average_row="false"></TableComponent>

            <TableComponent v-if="currentlyVisiblePanel === 'event_group_totals'" :table_data="eventGroupTotalsData" total_column_heading_name="Group" :show_average_row="false"></TableComponent>

            <template v-if="currentlyVisiblePanel === 'event_group_totals'">
                <div v-for="(categoryDetail, index) in eventGroupDetailsData" :key="index" class="category-detail">
                    <div class="p-2 px-4 bg-white rounded-md text-lg font-medium text-gray-600 text-center flex flex-row items-center justify-center gap-1 flex-wrap">
                        {{ categoryDetail?.Category }}
                    </div>
                    <table-component
                        :table_data="categoryDetail?.Details"
                        total_column_heading_name="Engagement"
                        :show_average_row="false">
                    </table-component>
                </div>
            </template>

        </div>
    </div>
</template>

<script>
import ProgressBar from '@/components/UtilityComponents/ProgressBar.vue'
import TableComponent from '@/components/TableComponent.vue';
import TabButtons from '@/components/TabButtons.vue'
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment';

import '@vuepic/vue-datepicker/dist/main.css'
import { Chart } from 'highcharts-vue'
import { subDays } from 'date-fns'

const noGrouping = 'All (No Grouping)';
const noneSelectedRequired = 'None Selected (Required)';

const overviewNoData = 'N/A'

const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
const currentDate = new Date();
const startDate = new Date(currentDate.getTime() - thirtyDaysInMilliseconds);
const endDate = new Date(currentDate.getTime());

const integrationDefault = {
    start_date: startDate.toISOString().split('T')[0], // Format as 'YYYY-MM-DD'
    end_date: endDate.toISOString().split('T')[0],    // Format as 'YYYY-MM-DD'
    target_engagements: overviewNoData,
    delivered_engagements: overviewNoData,
    integration_overview: {
        total_unique_engagements: overviewNoData,
        mean_engagements_per_player: overviewNoData,
        total_visits: overviewNoData,
        mean_seconds_spent_per_player: overviewNoData,
    }
};

const defaultGameDesignEventChartOptions = {
    chart: {
        type: 'column',
    },
    title: {
        text: 'Engagement Groups',
    },
    xAxis: {
        categories: [], // Will be filled dynamically
        title: {
            text: 'Group',
        },
    },
    yAxis: {
        title: {
            text: 'Engagement Count',
        },
        labels: {
            overflow: 'justify',
            formatter: function () {
                return this.value >= 1000000
                    ? this.value / 1000000 + 'M'
                    : this.axis.defaultLabelFormatter.call(this);
            },
        },
    },
    credits: {
        enabled: false,
    },
    series: [{
        name: 'Engagement Group Totals',
        data: [], // Will be filled dynamically
    }],
};

const defaultDailyEventChartOptions = {
    chart: {
        type: 'line'
    },
    title: {
        text: 'Daily Engagements Overview'
    },
    xAxis: {
        title: {
            text: 'Date'
        }
    },
    yAxis: {
        title: {
            text: 'Engagement Count'
        },
        labels: {
            overflow: 'justify',
            formatter: function () {
                return this.value >= 1000000
                    ? this.value / 1000000 + 'M'
                    : this.axis.defaultLabelFormatter.call(this);
            }
        }
    },
    credits: {
        enabled: false
    },
    series: []
};

const defaultGameTotalsChartOptions = {
    chart: {
        type: 'column',
    },
    title: {
        text: 'Game Totals',
    },
    xAxis: {
        categories: [], // Will be filled dynamically
        title: {
            text: 'Game',
        },
    },
    yAxis: {
        title: {
            text: 'Engagement Count',
        },
        labels: {
            overflow: 'justify',
            formatter: function () {
                return this.value >= 1000000
                    ? this.value / 1000000 + 'M'
                    : this.axis.defaultLabelFormatter.call(this);
            },
        },
    },
    credits: {
        enabled: false,
    },
    series: [{
        name: 'Game Totals',
        data: [], // Will be filled dynamically
    }],
};

export default {
    name: 'IntegrationsDashboard',
    components: {
        TabButtons,
        TableComponent,
        ProgressBar,
        Chart,
    },
    props: {
        userSelectionRangeProp: {
            type: Array,
            required: false,
            default: () => {
                return [new Date('2021-01-01'), new Date()]
            },
        },
        current_campaign: {
            type: Object,
            required: false,
        },
        current_integration: {
            type: Object,
            required: false,
            default: () => {return integrationDefault} ,
        },
        all_integrations: {
            type: Array,
            required: false,
            default: () => [],
        }
    },

    watch: {
        current_integration(newIntegration){
            if(!newIntegration) return

            if(newIntegration) this.$emit('isActiveFlag', 'IntegrationsReporting', true);
            this.selectedGame = {game_name: "Select a Game (required)", ga_id: null}
            if(newIntegration.games && newIntegration.games.length > 0) {
                this.selectedGame = newIntegration.games.find(game => game.game_name != null);
            }

            this.gameDropdownRefresher++
            this.datePickerRefresher++

            if(this.canFetchCampaignEngagementsDeliveredEngagementsTables) {
                this.fetchNewData()
            }  
            // Create date strings with local time designators to avoid timezone shifts
            const startDateString = `${newIntegration.start_date}T00:00:00`;
            const endDateString = `${newIntegration.end_date}T00:00:00`;

            // Convert the strings to Date objects to emit
            const startDate = new Date(startDateString);
            const endDate = new Date(endDateString);

            // Emit the Date objects, ensuring they represent the intended calendar days
            this.$emit('min-max-range-array', [startDate, endDate]);
        },

        userSelectionRangeProp(){            
            if(this.canFetchCampaignEngagementsDeliveredEngagementsTables) {
                this.fetchNewData()
            }
        }
    },

    data() {
        return {
            defaultGameDesignEventChartOptions: defaultGameDesignEventChartOptions,
            gameIdGameNameMap: {},
            selectedGame: {game_name: "Select a Game (required)", ga_id: null},
            eventIdOnesDropdownItems: [],
            eventGroupTotalsData: [],
            totalEventCountByGameData: [],
            eventGroupDetails: [],
            selectedEventIdOne: noGrouping,
            eventTotalsData: [],
            eventGroupDailyData: [],
            integrationTotalEngagements: 0,
            countryDropdownItems : ['US', 'All Countries'],
            selectedCountry: 'All Countries',
            currentlyVisiblePanel: 'event_table',
            chartOptions: defaultGameDesignEventChartOptions,
            dailyEventChartOptions: defaultDailyEventChartOptions,
            gameTotalsChartOptions: defaultGameTotalsChartOptions,
            interval: {
                minmax: { min: new Date('2021-01-01'), max: new Date() },
            },
            fetchingStatus: {
                integrationCharts: false,
                minMax: false,
                gameIdNameMap: false,
                integrationIds: false,
                eventTotals: false,
                eventGroupTotals: false,
                eventGroupDetails: false,
                eventGroupDaily: false,
                idOnes: false,
                totalEventCountByGame: false,
            },
            panelSwitchingButtons: [
                {
                    name: 'event_table',
                    text: 'Campaign Engagements'
                },
                // {
                //     name: 'event_group_daily_table',
                //     text: 'Daily Engagements'
                // }, 
                {
                    name: 'event_group_totals',
                    text: 'Event Group Totals'
                }
            ],
            gameDropdownRefresher: 0,
            datePickerRefresher: 0,
        }
    },
    computed: {
        selectedIntegrationId() {
            return this.current_integration.event_id_05
        },

        gamesDropdown(){
            if(!this.current_integration || !this.current_integration?.games) return []
            return this.current_integration.games
        },

        intervalMinMax() {
            if(!this.current_integration) return {minMax: {min: new Date('2021-01-01'), max: new Date()} }
            const result = {
                minMax: {
                    min: new Date(this.current_integration.start_date),
                    max: new Date(this.current_integration.end_date),
                },
            }
            return result
        },

        presetRanges() {
            return [
                {
                    label: 'Last 30 days',
                    range: [subDays(new Date(), 31), subDays(new Date(), 1)],
                },
                {
                    label: 'Last 60 days',
                    range: [subDays(new Date(), 61), subDays(new Date(), 1)],
                },
                {
                    label: 'Last 90 days',
                    range: [subDays(new Date(), 91), subDays(new Date(), 1)],
                },
                {
                    label: 'All time',
                    range: [
                        this.intervalMinMax.minMax.min,
                        this.intervalMinMax.minMax.max,
                    ],
                },
            ]
        },

        userSelectionRange() {
            // Guard clause for initial validation of userSelectionRangeProp
            if (!Array.isArray(this.userSelectionRangeProp) || this.userSelectionRangeProp.length !== 2 ||
                !(this.userSelectionRangeProp[0] instanceof Date) || !(this.userSelectionRangeProp[1] instanceof Date)) {
                return { startDate: null, endDate: null };
            }

            // Determine the base start and end dates
            let startDate = this.userSelectionRangeProp[0];
            let endDate = this.userSelectionRangeProp[1];

            // Adjust the range based on current_integration and intervalMinMax constraints
            if (this.current_integration && typeof this.current_integration === 'object') {
                // Adjust startDate and endDate within the allowed minMax range, without exceeding the user-selected range
                const { min, max } = this.intervalMinMax.minMax; // Destructuring for clarity
                const narrowedStart = new Date(Math.max(startDate, min)); // Ensures start is not before min
                const narrowedEnd = new Date(Math.min(endDate, max)); // Ensures end is not after max

                // Update startDate and endDate if they are within the intervalMinMax boundaries
                if (narrowedStart <= narrowedEnd) {
                    startDate = narrowedStart;
                    endDate = narrowedEnd;
                }
            }

            // Return the ISO string representations of the adjusted or original dates
            return {
                startDate: startDate,
                endDate: endDate,
            };
        },

        selectedGameId() {
            if (!this.selectedGame) return null
            return this.selectedGame.ga_id
        },

        selectedGameName() {
            if (!this.selectedGame) return "Select a Game (required)"
            return this.selectedGame.game_name
        },

        canFetchGameEventChartSeries() {
            if (!this.selectedGameId) {
                return { success: false, reason: 'Game ID is not selected.' }
            }

            if (
                !this.userSelectionRange ||
                !this.userSelectionRange.startDate ||
                !this.userSelectionRange.endDate
            ) {
                return {
                    success: false,
                    reason: 'Date range is incomplete or invalid.',
                }
            }

            return { success: true, reason: '' }
        },

        canFetchCampaignEngagementsDeliveredEngagementsTables(){
            if(
                !this.selectedGameId ||
                !this.userSelectionRange ||
                !this.userSelectionRange.startDate ||
                !this.userSelectionRange.endDate ||
                !this.selectedIntegrationId ||
                !this.current_integration
            ) {
                return false
            }
            return true
        },

        loading() {
            return !Object.values(this.fetchingStatus).every(
                (value) => value === false
            )
        },

        engagementPercentageProgress() {
            if (!this.current_integration) return 0;

            const percentage = (this.current_integration.delivered_engagements / this.current_integration.target_engagements) * 100;
            return Math.round(percentage);
        },

        engagementTimePercentageProgress(){
            if(!this.current_integration) return 0
            if(!this.current_integration.start_date) return 0
            if(!this.current_integration.end_date) return 0
            return this.getTimePercentage(this.current_integration.start_date, this.current_integration.end_date)
        },

        showIntegrationIdDropdown(){
            if(
                !this.selectedGameName ||
                this.selectedGameName === noneSelectedRequired ||
                this.selectedGameName === '' ||
                this.fetchingStatus.integrationIds !== false
            ) {
                return false
            }

            return true
        },

        showDownloadButton(){
            if(
                !this.selectedGameName ||
                this.selectedGameName === noneSelectedRequired ||
                this.selectedGameName === '' ||
                this.loading ||
                this.eventGroupDailyData.length === 0 ||
                this.eventTotalsData.length === 0

            ) {
                return false
            }
            return true
        },

        headerName() {
            if(!this.current_integration) return 'N/A'
            return this.current_integration.name
        },

        headerDate() {
            if(this.current_integration) {
                return ' From ' + this.headerStartDateString + ' To ' + this.headerEndDateString
            }
            return 'N/A'
        },

        headerStartDateString() {
            if(!this.userSelectionRange?.startDate) return this.integrationStartDateString
            return moment(this.userSelectionRange.startDate).format('MMMM D, YYYY');
        },

        headerEndDateString() {
            if(!this.userSelectionRange?.endDate) return this.integrationEndDateString
            return moment(this.userSelectionRange.endDate).format('MMMM D, YYYY');
        },

        integrationStartDateString() {
            if(!this.current_integration?.start_date) return 'N/A'
            return moment(this.current_integration.start_date).format('MMMM D, YYYY');
        },

        integrationEndDateString() {
            if(!this.current_integration?.end_date) return 'N/A'
            return moment(this.current_integration.end_date).format('MMMM D, YYYY');
        },

        lastUpdated() {
            if(!this.current_integration?.updated_at) return 'N/A'
            return moment(this.current_integration.updated_at).format('MMMM D, YYYY');
        },
    },

    created() {},

    mounted() {},

    methods: {
        fetchNewData(){
            // this.getIntegrationChartSeries()
                        //Fetch
            this.getEventTotalsData()
            this.getEventGroupTotalsData()
            this.getEventGroupDetailsData()
            this.getEventGroupDailyData()
            this.getTotalEventCountByGame()
            this.getIntegrationTotalEngagements()
        },

        async getIntegrationChartSeries() {
            this.fetchingStatus.integrationCharts = true

            const validation = this.canFetchGameEventChartSeries
            const reason = validation.reason || "Can't fetch integration chart data."

            if (!validation.success) {
                this.$notify({ type: 'error', text: reason })
                this.fetchingStatus.integrationCharts = false
                return
            }

            const params = new URLSearchParams()
            params.append('country', this.selectedCountry)
            params.append('integrationId', this.selectedIntegrationId)
            params.append('gameId', this.selectedGameId)
            params.append('start', this.userSelectionRange.startDate.toISOString())
            params.append('end', this.userSelectionRange.endDate.toISOString())

            this.$http.get(`/game-analytics-design-events/integration/event-charts?${params.toString()}`)
                .then((response) => {
                    if (response.data && response.data.length > 0) {
                        this.defaultGameDesignEventChartOptions.series = response.data
                        this.fetchingStatus.integrationCharts = false
                        this.$notify({type: "success", text: "Integration Data Fetched"})
                    } else {
                        this.fetchingStatus.integrationCharts = false
                    }
                })
                .catch((error) => {
                    this.fetchingStatus.integrationCharts = false
                    console.error('Error fetching integration chart data:', error.message)
                })
        },

        async getEventTotalsData() {
            try {
                this.fetchingStatus.eventTotals = true; 
                //reset the eventTotalsData
                this.eventTotalsData = []

                const queryParams = {
                    gameId: this.selectedGameId,
                    start: this.userSelectionRange.startDate.toISOString(),  
                    end: this.userSelectionRange.endDate.toISOString(),
                    integrationId: this.selectedIntegrationId,
                    country: this.selectedCountry,
                };

                const response = await this.$http.get('/game-analytics-design-events/event-totals', {
                    params: queryParams
                });

                this.eventTotalsData = response.data;  // Assume you have a data property called eventTotalsData

                this.fetchingStatus.eventTotals = false;
            } catch (error) {
                console.error('Error fetching event totals:', error.message);
                this.fetchingStatus.eventTotals = false;
            }
        },

        async getEventGroupTotalsData() {
            try {
                this.fetchingStatus.eventGroupTotals = true; 
                //reset the eventTotalsData
                this.eventGroupTotalsData = []

                const queryParams = {
                    gameId: this.selectedGameId,
                    start: this.userSelectionRange.startDate.toISOString(),  
                    end: this.userSelectionRange.endDate.toISOString(),
                    integrationId: this.selectedIntegrationId,
                    country: this.selectedCountry,
                };

                const response = await this.$http.get('/game-analytics-design-events/event-group-totals', {
                    params: queryParams
                });

                this.eventGroupTotalsData = response.data;  // Assume you have a data property called eventTotalsData
                this.updateChartOptions(this.eventGroupTotalsData)

                console.log('event group totals', this.eventGroupTotalsData)

                this.fetchingStatus.eventGroupTotals = false;
            } catch (error) {
                console.error('Error fetching event totals:', error.message);
                this.fetchingStatus.eventGroupTotals = false;
            }
        },

        async getEventGroupDetailsData() {
            try {
                this.fetchingStatus.eventGroupDetails = true; 
                // Reset the eventGroupDetailsData
                this.eventGroupDetailsData = [];

                const queryParams = {
                    gameId: this.selectedGameId,
                    start: this.userSelectionRange.startDate.toISOString(),
                    end: this.userSelectionRange.endDate.toISOString(),
                    integrationId: this.selectedIntegrationId,
                    country: this.selectedCountry,
                };

                const response = await this.$http.get('/game-analytics-design-events/event-group-details', {
                    params: queryParams
                });

                this.eventGroupDetailsData = response.data; 

                this.fetchingStatus.eventGroupDetails = false;
            } catch (error) {
                console.error('Error fetching event group details:', error.message);
                this.fetchingStatus.eventGroupDetails = false;
            }
        },

        async getIntegrationTotalEngagements() {
            // Constructing the URL query parameters
            const params = new URLSearchParams({
                start: this.userSelectionRange.startDate.toISOString(),
                end: this.userSelectionRange.endDate.toISOString(),
                integrationId: this.selectedIntegrationId,
            }).toString(); // Converts the URLSearchParams object into a query string

            // Making the HTTP GET request with the parameters included in the URL
            return this.$http.get(`/game-analytics-design-events/integration-total-engagements?${params}`)
                .then(({ data }) => {
                    this.integrationTotalEngagements = data.totalEngagements; // Storing the result
                    this.$emit('total-engagements', data.totalEngagements);
                })
                .catch((error) => {
                    console.error(error.message);
                });
        },

        async getEventGroupDailyData() {
            try {
                this.fetchingStatus.eventGroupDaily = true;

                this.eventGroupDailyData = [];

                const queryParams = {
                    gameId: this.selectedGameId,
                    start: this.userSelectionRange.startDate.toISOString(),
                    end: this.userSelectionRange.endDate.toISOString(),
                    integrationId: this.selectedIntegrationId,
                    country: this.selectedCountry,
                };

                const response = await this.$http.get('/game-analytics-design-events/daily-group-event-count', {
                    params: queryParams
                });

                this.eventGroupDailyData = response.data;

                this.updateDailyEventChartOptions(this.eventGroupDailyData)

                this.fetchingStatus.eventGroupDaily = false;
            } catch (error) {
                console.error('Error fetching event group daily data:', error.message);
                this.fetchingStatus.eventGroupDaily = false;
            }
        },

        async getTotalEventCountByGame() {
            try {
                this.fetchingStatus.totalEventCountByGame = true;
                // Reset the totalEventCountByGameData to an empty state before fetching new data
                this.totalEventCountByGameData = [];

                const queryParams = {
                    gameId: this.selectedGameId,
                    start: this.userSelectionRange.startDate.toISOString(),
                    end: this.userSelectionRange.endDate.toISOString(),
                    integrationId: this.selectedIntegrationId,
                    country: this.selectedCountry, 
                };

                const response = await this.$http.get('/game-analytics-design-events/total-event-count-by-game', {
                    params: queryParams
                });

                this.totalEventCountByGameData = response.data;

                this.updateGameTotalsChart(this.totalEventCountByGameData)

                this.fetchingStatus.totalEventCountByGame = false;
            } catch (error) {
                console.error('Error fetching total event count by game:', error.message);
                this.fetchingStatus.totalEventCountByGame = false;
            }
        },

        updateChartOptions(data) {
            const series = data.map(item => ({
                name: item.Group, // Use the Group value as the name of the series
                data: [parseInt(item.Total.replace(/,/g, ''), 10)] // Remove commas, convert to number, and wrap it in an array
            }));

            this.chartOptions = {
                ...defaultGameDesignEventChartOptions,
                xAxis: {
                    ...defaultGameDesignEventChartOptions.xAxis,
                    categories: ['Total'], // Since each series only contains one data point, the category can be a single generic label
                },
                series: series, // Set the series to the array we constructed
            };
        },

        updateDailyEventChartOptions(tableData) {
            const dates = tableData.map(item => item.Date); // Extract dates
            const totalSeriesData = []; // Initialize an array to hold the sum of all activities for each date

            tableData.forEach(item => {
                // Sum the values of all activities for the current item, excluding the 'Date'
                const totalForDate = Object.keys(item).reduce((sum, key) => {
                    if (key !== 'Date') {
                        let value = item[key];
                        if (typeof value === 'string') {
                            value = value.replace(/,/g, ''); // Remove commas if it's a string
                        }
                        return sum + (parseFloat(value) || 0); // Ensure value is a number, using 0 for non-numeric or undefined/null values
                    }
                    return sum;
                }, 0);

                // Add the total to the series data
                totalSeriesData.push(totalForDate);
            });

            // Update chart options for a single series line chart
            this.dailyEventChartOptions = {
                ...defaultDailyEventChartOptions,
                xAxis: {
                    ...defaultDailyEventChartOptions.xAxis,
                    categories: dates
                },
                series: [{
                    name: 'Total Engagements',
                    data: totalSeriesData
                }]
            };
        },

        updateGameTotalsChart(data) {
            const series = data.map(item => ({
                name: item.game_name, // Use the game_name as the name of the series
                data: [parseInt(item.total_engagement, 10)] // Convert total_engagement to number
            }));

            const gameNames = data.map(item => item.game_name);

            this.gameTotalsChartOptions = {
                ...defaultGameTotalsChartOptions, 
                xAxis: {
                    ...defaultGameTotalsChartOptions.xAxis,
                    categories: gameNames, // Set the categories to the game names extracted from the data
                },
                series: series, // Set the series to the array we constructed
            };
        },

        changeSelectedGame(selectedGame) {
            this.selectedGame = selectedGame
        },

        changeSelectedCountry(newCountry) {
            this.selectedCountry = newCountry
        },

        panelChanged(panelName){
            this.currentlyVisiblePanel = panelName
        },

        showStat(stat) {
            if(stat === 0) return true
            if(!stat || stat === overviewNoData) {
                return false
            }
            return true
        },

        getTimePercentage(startDate, endDate) {
            const start = new Date(startDate).getTime();
            const end = new Date(endDate).getTime();

            // Guard clause for invalid dates
            if (start >= end) return 0;

            const totalDuration = end - start;
            const elapsedDuration = new Date().getTime() - start;

            const elapsedPercentage = Math.round(Math.min(Math.max(elapsedDuration / totalDuration * 100, 0), 100));

            return elapsedPercentage;
        },

        formatNumber(value, locales = 'en-US', options = {}) {
            // Default options
            const defaultOptions = {
                maximumFractionDigits: 2,
                ...options
            };

            // Using Intl.NumberFormat for formatting
            return new Intl.NumberFormat(locales, defaultOptions).format(value);
        },

        downloadTablesAsExcel() {
            const createAndDownloadWorkbook = (data, fileName) => {
                const workbook = XLSX.utils.book_new();
                const sheet = XLSX.utils.json_to_sheet(data, { header: Object.keys(data[0]) });
                XLSX.utils.book_append_sheet(workbook, sheet, fileName);
                XLSX.writeFile(workbook, `${fileName}-${this.selectedIntegrationId}-${this.selectedGameName}.xlsx`);
            };
            createAndDownloadWorkbook(this.eventTotalsData, 'Campaign Engagements');
            createAndDownloadWorkbook(this.eventGroupDailyData, 'Delivered Engagements');
        },
    },
}
</script>

<style lang="postcss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');


.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.card-container {
    @apply bg-white rounded-md flex flex-col items-center justify-center p-4 gap-4;
}

.date-picker {
    @apply lg:max-w-lg sm:max-w-xs w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md;
}

.inner-card {
    @apply flex flex-col gap-2 items-center;
}

.card-container span {
    @apply text-3xl font-bold;
}

:deep .dp__action_button {
    color: black; 
    background-color: white; 
    opacity: 1 !important; 
    border: 1px solid black;
    padding: 0.8em 0.4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

:deep(.dp__action_button:hover) {
    color: white; 
    background-color: blue; 
}

</style>
