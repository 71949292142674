<template>
    <div>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'blank-container',
}
</script>