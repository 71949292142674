<template>
    <div class="w-full">
        <div class="h-12 w-full bg-indigo-500 p-4 text-white font-medium rounded-md my-4 flex items-center justify-center" v-if="'archived' in item && item['archived']['value']"> This Item Is Archived </div>

        <div class="grid lg:grid-flow-col grid-flow-row w-full gap-4">

            <!-- Item Information || Top Left -->
            <ItemViewer @delete-item="deleteItem" v-if="item" :item="item" :friendlyItemType="friendlyItemType"/>
            
            <!-- Image + Edit Image Section || Top Right -->
            <div class="flex flex-col items-center justify-center gap-2 bg-white rounded-md p-4">

                <!-- SHOW ITEM IMAGE -->
                <img v-if="!change_image && item.img_url" :src="item.img_url.value" alt="The item image" class="object-fit" loading="lazy">
                <button v-if="item.img_url.user_modifiable && !this.change_image" @click="this.change_image = true" class="btn-primary green"> Change Image</button>

                <!-- IF USER WANTS TO UPLOAD A NEW IMAGE -->
                <UploadFile class="w-full h-full flex justify-center items-center" v-if="change_image" :accept="itemImageType" />
                <button @click="updateImage" v-if="stagedFile && change_image" class="btn-primary light-blue"> Update Image </button>

                <!-- CANCEL BUTTON -->
                <button class="btn-primary red" v-if="change_image" @click="cancelChanges"> Cancel Changes</button>

            </div>

        </div>

        <!-- If Item Is Placement, Allow Adding Test Creatives + Users -->
        <div v-if="this.url_params.item_type == 'placement'" class="my-6 flex flex-col gap-4 border-b-2">
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                <label for="game" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Select a Test Creative
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2 max-w-lg sm:max-w-xs">
                    <DropdownComponent
                        selected="Select a Test Creative"  
                        :items="all_creatives" 
                        @user_select="changedTestCreative"
                        display="user_defined_name"/>
                </div>
            </div>
            <TestUserSelector class="mb-4" :placement_uuid="this.url_params.uuid" @removed-test-user="removeTestUser" @added-test-user="addedTestUser" />
        </div>

        <!-- If Routed to a placement from a run, show an image picker -->
        <RunPlacementPickers
        v-if="run_uuid && this.url_params.item_type == 'placement'"
        :placement_uuid="this.url_params.uuid"
        :run_uuid="this.run_uuid"
        :run_name="this.run_name" />

        <div class="my-4" v-if="this.url_params.item_type == 'game' && game_campaign_data && !game_campaign_loading">
            <TableComponent :table_data="game_campaign_data" :exported_file_name="'Game Campaign Data'" :show_total_row="false" :show_average_row="false"/>
        </div>
        <div class="my-4" v-if="this.url_params.item_type == 'game' && game_campaign_loading" >
            Please Wait About 30 Seconds For The Data To Load <br>
            <LoadingComponent/>
        </div>

        <div class="mt-8 flex flex-col bg-white rounded-md p-4 gap-4" v-if="this.item.img_type && this.item.img_type.value == '.gif'">
            <span class="text-2xl font-medium"> Roblox Asset IDs</span>
            <div class="flex flex-row gap-4 flex-wrap border-t border-gray-400 p-2">
                <div v-for="asset_id in gif_asset_ids" :key="asset_id.roblox_id" @click="asset_id.roblox_id != 'Sprite Sheet Asset IDs:' ? deleteGifAssetID(asset_id.roblox_id) : ''">
                    <div class="text-lg font-medium text-gray-900 p-2 rounded-md" :class="asset_id.roblox_id != 'Sprite Sheet Asset IDs:' ? 'bg-indigo-300 hover:bg-red-400 cursor-pointer' : 'bg-green-300'">
                        {{asset_id.roblox_id}}
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import ItemViewer from '@/components/ItemViewer.vue'
import RunPlacementPickers from '@/components/ImagePickers/RunPlacementPickers.vue'
import TestUserSelector from '@/components/TestUserSelector.vue'
import TableComponent from '@/components/TableComponent.vue'

export default {
    components: {
        ItemViewer,
        RunPlacementPickers,
        TestUserSelector,
        TableComponent,
    },
    data(){
        return {
            change_image: false,
            game_campaign_loading: true,
            url_params: {
                'item_type': this.$route.params.item_type,
                'uuid': this.$route.params.uuid,
            },
            run_uuid: this.$route.params.run_uuid,
            run_name: undefined,
            game_campaign_data: [],
            all_creatives: [],
            selected_creative: {},
            gif_asset_ids: [],
        }
    },
    methods: {
        updateImage() {
            const form_data = new FormData()
            form_data.append('image', this.stagedFile.file_object)
            form_data.append('uuid', this.url_params.uuid)
            if(this.item?.img_type){
                const file_extention = '.' + this.stagedFile.file_object.name.split('.').pop()
                form_data.append('img_type', file_extention)
            }
            this.$store.dispatch('file_upload_module/sendHttpRequest', {api_url: `item/update-image/${this.url_params.item_type}/${this.url_params.uuid}`, form_data: form_data})
                .then( async ()=> {
                    this.change_image = false
                    this.$store.commit('file_upload_module/clearAllFiles')
                    await this.$store.dispatch('item_module/fetchItem', this.url_params)
                })
                .catch(() => {
                    this.change_image = false
                    this.$store.commit('file_upload_module/clearAllFiles')
                })
        },
        deleteItem(){
            const toasts = {'success': 'Item Deleted', 'error': 'Error Deleting Item'}
            if(confirm(`Are You Sure You Want To Delete? There's No Way To Undo This Operation`)) {
                this.$http_request(this.$http.post, `item/delete/${this.url_params.item_type}/${this.url_params.uuid}`, toasts)
                    .then(() => {
                        this.$router.go(-1) 
                    })
            }
        },
        getRunName(){
            if(!this.run_uuid) return
            
            this.$http_request(this.$http.get, `runs/${this.run_uuid}`)
                .then(({data}) => {
                    this.run_name = data.name
                })
        },
        changedTestCreative(creative){
            const request = {
                placement_uuid: this.url_params.uuid,
                creative_uuid: creative.uuid
            }
            const toasts = {'success': 'Added Test Creative', 'error': 'Error Adding Test Creative'}
            this.$http_request(this.$http.post, 'placements/update-test-creative', toasts, request)
        },
        addedTestUser(test_user_uuid){
            const test_user_uuids = [test_user_uuid]
            const request = {
                placement_uuid: this.url_params.uuid,
                test_user_uuids: test_user_uuids,
            }
            const toasts = {'success': 'Test User Added', 'error': 'Error Adding Test User'}
            this.$http_request(this.$http.post, `placements/add-test-users`, toasts, request)
        },
        removeTestUser(test_user_uuid){
            const request = {
                placement_uuid: this.url_params.uuid,
                test_user_uuid: test_user_uuid,
            }
            const toasts = {'success': 'Test User Removed', 'error': 'Error Removing Test User'}
            this.$http_request(this.$http.post, `placements/remove-test-user`, toasts, request)
        },
        async getAllCreatives(){
            return this.$http_request(this.$http.get, 'creatives')
                .then(({data}) => {
                    this.all_creatives = data
                })
        },
        setSelectedCreativeFromCreativeUuid(creative_uuid){
            let placement_test_creative = {}
            for(const creative of this.all_creatives){
                if(creative.uuid == creative_uuid) placement_test_creative = creative
            }
            this.selected_creative = placement_test_creative
        },
        cancelChanges(){
            this.$store.commit('file_upload_module/clearAllFiles')
            this.change_image = false
        },
        getAssetIds() {
            this.$http.get('/gif-assets/'+this.item.uuid.value)
                .then( ({data}) => {
                    this.gif_asset_ids = data
                })
        },
        deleteGifAssetID(roblox_id) {
            if(!confirm("Are You Sure You Want To Delete This Asset ID ("+roblox_id+")? This Action Is Irreversible!"))
                return

            this.$http.post('/delete-gif-frame', {roblox_id}).then( ()=> {
                this.getAssetIds()
                this.$notify({type: 'success', text: 'Deleted Asset ID' })
            }).catch( (error) => {
                this.$notify({type: 'error', text: error.message })
            })
        },
        getGameCampaignData() {
            this.$http.post('/game-campaign-data', { name: this.item.displayed_properties.name.value})
                .then( ({data}) => {
                    this.game_campaign_data = data

                    if(data.length == 0)
                        this.$notify({type: 'error', text: 'This Game Has Not Been On Any Campaigns'})

                    this.game_campaign_loading = false
                })
                .catch( (error) => {
                    console.error(error)
                    this.game_campaign_loading = false
                })
        }
    },
    computed: {
        item(){
            return this.$store.getters['item_module/getItem'](this.url_params)
        },
        friendlyItemType(){
            return this.$capitalizeFirstLetter(this.url_params.item_type)
        },
        stagedFile(){
            const staged_files = this.$store.getters['file_upload_module/getAllFiles']
            return staged_files[0]
        },
        loading() {
            return this.$store.getters['getImageUploadLoadingState']
        },
        itemImageType() {
            if(this.item.img_type && this.item.img_type.value=='.gif')
                return 'image/gif'
            return undefined
        }
    },
    async created(){
        if(!this.loading)
            this.$store.commit('file_upload_module/clearAllFiles')

        await this.$store.dispatch('item_module/fetchItem', this.url_params)

        if(this.url_params.item_type == 'placement'){ 
            this.getRunName()
            await this.getAllCreatives()
            this.setSelectedCreativeFromCreativeUuid(this.item.test_creative_uuid.value) 
        }

        if(this.url_params.item_type == 'game')
            this.getGameCampaignData()

        if(this.item.img_type && this.item.img_type.value == '.gif')
            this.getAssetIds()
    },
}
</script>