<template>
    <div>
        <draggable
        v-model="sidebar_items"
        item-key="position"
        @start="dragging = true"
        @end="dragging = false"
        ghost-class="outer-ghost"
        :scroll-sensitivity="200"
        class="flex flex-col gap-1 p-1">
            <template #item="{ element }">
                <div>
                    <div class="border border-black p-2 cursor-move bg-white font-medium">{{ element.name }}</div>
                    <draggable
                    v-if="element.children"
                    v-model="element.children"
                    item-key="position"
                    @start="dragging = true"
                    @end="dragging = false"
                    ghost-class="inner-ghost"
                    class="flex flex-col gap-1 p-1 ml-4">
                        <template #item="{ element }">
                            <div class="border border-black p-2 cursor-move bg-green-300 font-medium">{{ element.name }}</div>
                        </template>
                    </draggable>
                </div>
            </template>
        </draggable>

        <button class="btn-primary green" @click="submitSidebarUpdates"> Submit </button>
    </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
    name: "SidebarPositionEditor",
    components: {
        draggable,
    },
    props: {},
    data() {
        return {
            sidebar_items: [],
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getAllPagesToEdit()
    },
    methods: {
        getAllPagesToEdit() {
            this.$http.get('/pages-to-edit')
                .then( ({data}) => {
                    this.sidebar_items = data
                })
        },
        submitSidebarUpdates() {
            this.$http.post('/pages/edit', {items: this.sidebar_items})
                .then( () => {
                    //refresh the page
                    this.$router.go(0);
                })
                .catch( (error) => {
                    console.error(error)
                })
        }
    }
}
</script>

<style>
.outer-ghost {
    opacity: 0.5;
    background: #0f7eb1;
}

.inner-ghost {
    opacity: 0.5;
    background: #068b18;
}
</style>