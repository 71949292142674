<template>
  <div class="flex w-full justify-center items-center">
    <div class="w-full flex flex-col flex-wrap items-center justify-center gap-4">
      <div class="w-full flex flex-col items-center justify-center gap-2 bg-white rounded-md py-4 px-2">
        <div class="flex flex-row flex-wrap items-center justify-center gap-2">
          <DropdownComponent 
          v-if="all_campaigns.length"
          :items='all_campaigns'
          display="name"
          selected="Select a Campaign"
          @user_select="setCurrentCampaign($event)"
          class="sm:w-96 w-72 self-center"/>

          <DropdownComponent 
          v-if="current_campaign"
          :items='campaign_creatives'
          display="user_defined_name"
          selected="Select a Creative"
          @user_select="setCurrentCreative($event)"
          :key="creative_dropdown_refresher"
          class="sm:w-96 w-72 self-center"/>

          <DropdownComponent
            v-if="current_creative"
            class="sm:w-96 w-72 self-center"
            selected="Select a Country (optional)" 
            :items="all_countries" 
            display="name"
            @user_select="setCountryLimit"
            :key="dropdown_refresher"/>
          
          <button v-if="current_creative" class="btn-primary indigo" @click="selected_country = null; dropdown_refresher++"> Clear Country Limit </button>
        </div>

        <button v-if="current_creative" class="btn-primary green" @click="getVideoData"> Submit </button>
      </div>

      <div class="w-full flex flex-row gap-2 items-center justify-center bg-white rounded-md py-4 px-2" v-if="last_updated">
        <span> Last Updated: {{ last_updated }}</span>
        <button class="btn-primary indigo" @click="resetCachedValues"> Refresh </button>
      </div>

      <span v-if="loading"> Please wait this might take a few minutes... </span>
      <LoadingComponent class="mt-4 self-center" v-if="loading" />

      <div class="w-full flex flex-col gap-4" v-if="!loading">
        <VideoReportingInfo v-if="creative_data" :creative_data="creative_data"/>

        <div class="bg-white rounded-lg shadow p-4 flex flex-col gap-2 items-center justify-center" v-if="show_unique_chart">
          <TabButtons v-if="video_length >= 500" class="w-full" :buttons="panelSwitchingButtons" :active_button="user_unique_graph_choice" :panel-changed-method="panelChanged" />
          <highcharts class="w-full" :options="unique_impressions_per_choice" />
        </div>

        <div class="bg-white rounded-lg shadow p-4 flex flex-col gap-2 items-center justify-center" v-if="show_total_chart">
          <highcharts class="w-full" :options="impressions_vs_percentage_viewed_chart" />
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import VideoReportingInfo from '../components/VideoReportingInfo.vue';
import {Chart} from 'highcharts-vue'
import TabButtons from '../components/TabButtons.vue'

const impressions_vs_percentage_viewed_chart = {
    chart: {
        type: "line",
        marginRight: 90
    },
    title: {
        text: "Total Impressions Per Percentage Watched",
    },
    xAxis: {
        title: {
        text: "Percentage Watched",
        },
        labels: {
            overflow: 'justify',
        }
    },
    yAxis: {
      title: {
        text: "Impressions",
      },
      labels: {
            overflow: 'justify',
            formatter: function() {
              return this.value >= 1000000 ? this.value / 1000000 + 'M' : this.axis.defaultLabelFormatter.call(this);
            }
      } 
    },
    tooltip: {
        formatter: function () {
            return `Percentage Watched: <b>+${this.x}</b><br/>${this.series.name}: <b>${this.y.toLocaleString()}</b>`;
        },
    },
    series: [
        {
            name: "Impressions",
            data: [],
        },
    ],
    credits: {
        enabled: false,
    },
    exporting: {},
};

const unique_impressions_per_choice = {
    chart: {
        type: "line",
        marginRight: 90
    },
    title: {
        text: "Unique Impressions Per Interval",
    },
    xAxis: {
        title: {
            text: "Seconds",
        },
        labels: {
            overflow: 'justify',
        }
    },
    yAxis: {
        title: {
            text: "Impressions",
        },
        labels: {
            overflow: 'justify',
            formatter: function() {
              return this.value >= 1000000 ? this.value / 1000000 + 'M' : this.axis.defaultLabelFormatter.call(this);
            }
        }
    },
    tooltip: {
      formatter: function () {
        const xAxisTitle = this.series.xAxis.axisTitle.textStr;
        return `${xAxisTitle} Watched: <b>+${this.x}</b><br/>${this.series.name}: <b>${this.y.toLocaleString()}</b>`;
      },
    },
    series: [
        {
            name: "Impressions",
            data: [],
        },
    ],
    credits: {
        enabled: false,
    },
    exporting: {},
};

export default {
    name: "VideoImpressionsReporting",

    components: {
      VideoReportingInfo,
      TabButtons,
      highcharts: Chart,
    },

    props: {},

    data() {
        return {
            loading: true,
            all_countries: [],
            all_campaigns: [],
            campaign_creatives: [],
            views_per_interval: null,
            creative_data: null,
            current_campaign: null,
            current_creative: null,
            dropdown_refresher: 0,
            creative_dropdown_refresher: 0,
            show_unique_chart: false,
            show_total_chart: false,
            video_length: 0,
            user_unique_graph_choice: 'percentage_chart',
            last_updated: '',
            unique_impressions_per_choice: unique_impressions_per_choice,
            impressions_vs_percentage_viewed_chart: impressions_vs_percentage_viewed_chart,
            panelSwitchingButtons: [
                {
                    name: 'percentage_chart',
                    text: 'Percentage Chart'
                },
                {
                    name: 'time_interval',
                    text: 'Time Chart'
                },
            ],
        }
    },

    computed: {},

    watch: {
      current_campaign: {
        handler: function() {
          this.fetchCampaignCreatives()
          this.creative_data = null
          this.show_unique_chart = false
          this.show_total_chart = false
          this.last_updated = ''
          this.creative_dropdown_refresher++
        },
        deep: true
      },

      user_unique_graph_choice: {
        handler: function() {
          this.show_unique_chart = false
          this.fetchUniquesChart()
        },
      }
    },

    created() {},

    async mounted() {
      this.loading = true
      await this.fetchAllCampaigns()
      await this.getCountries()
      this.loading = false
    },
    
    methods: {
      async getCountries(){
        return this.$http_request(this.$http.get, '/countries')
            .then(({data}) => {
                this.all_countries = data
            })
            .catch((error) => {
                console.error(error.message)
            })
      },

      async fetchAllCampaigns() {
          await this.$http.get(`/campaigns/get/all-video`).then(({data}) => {
              this.all_campaigns = data
          })
      },

      async fetchCampaignCreatives() {
          const toasts = {success: 'Fetched campaign creatives', error: 'Failed to fetch campaign creatives'}
          await this.$http_request(this.$http.get, `/campaign-creatives/video/${this.current_campaign.uuid}`, toasts).then(({data}) => {
              this.campaign_creatives = data
          })
          .catch((error) => {
            console.error(error)
          })
      },

      getVideoData() {
        this.loading = true
        this.creative_data = null
        this.show_unique_chart = false
        this.show_total_chart = false
        this.last_updated = ''
        this.fetchCreativeVideoData()
        this.fetchUniquesChart()
        this.fetchLastUpdated()
      },

      async fetchCreativeVideoData() {
          const toasts = {success: 'Fetched Creative Video Data', error: 'Failed to fetch Creative Video Data'}
          let api = `/video-impressions-view-by-creative/${this.current_campaign.uuid}/${this.current_creative.uuid}`
          if(this.selected_country != null)
            api += `/${this.selected_country.alpha2}`

          await this.$http_request(this.$http.get, api, toasts).then(({data}) => {
            this.creative_data = data
            this.video_length = parseFloat(data.meta_data[4].value.replace('s', ''))
            this.prepareTotalsChart()
          })
          .catch((error) => {
            console.error(error)
          })
      },

      async fetchUniquesChart() {
        let request = {
          creative_uuid: this.current_creative.uuid,
          campaign_uuid: this.current_campaign.uuid,
          country: this.selected_country ? this.selected_country.alpha2 : null,
          graph_choice: this.user_unique_graph_choice,
        }

        const toasts = {'error': `Error Getting Data`, 'success': 'Data Retreived'}
        this.$http_request(this.$http.post, '/video-impressions/by-interval', toasts, request)
          .then( ({data})=> {
            this.views_per_interval = data['chart_data']
            this.unique_impressions_per_choice.xAxis.title.text = this.user_unique_graph_choice == 'time_interval' ? data['interval_time'] : 'Percentage';
            this.unique_impressions_per_choice.title.text = this.video_length > 500 ? "Unique Impressions Per Interval Watched" : "Unique Impressions Per Percentage Watched";
            this.prepareUniquesChart()
          })
          .catch((error)=>{
              console.error(error.message)
          })
      },

      async fetchLastUpdated() {
        const country = this.selected_country ? this.selected_country.alpha2 : null

        await this.$http.get(`/video-impressions/last-updated/${this.current_creative.uuid}/${this.current_campaign.uuid}/${country}`)
          .then( ({data}) => {
              this.last_updated = data
          })
      },

      async resetCachedValues() {
        const country = this.selected_country ? this.selected_country.alpha2 : null

        await this.$http.get(`/video-impressions/uncache-values/${this.current_creative.uuid}/${this.current_campaign.uuid}/${country}`)
          .then( () => {
            this.getVideoData()
          })
      },

      setCurrentCampaign(campaign) {
        this.current_campaign = campaign
      },

      setCurrentCreative(creative) {
        this.current_creative = creative
      },

      prepareTotalsChart() {
        const graph_data =  this.creative_data.views_over_time
        let impressions_vs_percentage = [{x: 0, y: 0}]
        for (let i = 0; i < graph_data.length; i++) {
            const item = graph_data[i];
            impressions_vs_percentage.push({
                x: item.percentage,
                y: item.user_count,
            })
        }   

        this.impressions_vs_percentage_viewed_chart.series[0].data = impressions_vs_percentage

        this.show_total_chart = true
        this.loading = false
      },
      prepareUniquesChart() {
        const graph_data =  this.views_per_interval
        let graph_builder = [{x: 0, y: 0}]
        for (let i = 0; i < graph_data.length; i++) {
            const item = graph_data[i];
            graph_builder.push({
                x: item.yAxis,
                y: item.user_count,
            })
        } 

        this.unique_impressions_per_choice.series[0].data = graph_builder
        this.show_unique_chart = true
      },
      panelChanged(panelName){
          this.user_unique_graph_choice = panelName
      },
      setCountryLimit(country) {
        this.selected_country = country
      }
  }
}
</script>

<style scoped>

</style>