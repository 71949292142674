<template>
    <CardComponent
        @click="emitImageDataOnSelected"
        :src="img_src" 
        :title="name"
        :hover_effects="this.checked ? false : true"
        :class="{ 'checked-card': this.checked }">
        <template v-slot:thirdRow>
            <div v-if="additional_details" class="text-md"> <span class="font-semibold"> Active Creatives: </span> {{creative_count}}</div>
            <div v-if="impressions" class="text-md"> <span class="font-semibold"> {{impressionsText}}:  </span> {{impressions.toLocaleString()}}</div>
            <CogIcon @click="emitToNavigate()" class="cogicon-dimensions"/>
        </template>
    </CardComponent>
</template>


<script>
import { CogIcon } from '@heroicons/vue/outline'
import CardComponent from '@/components/UtilityComponents/CardComponent.vue'
export default {
    name: 'ImagePickerPlacementImage',
    components: {
        CardComponent,
        CogIcon
    },
    props: {
        name: {
            default: '',
            type: String,
        },
        additional_details: {
            default: false,
            type: Boolean,
        },
        placement_uuid: {
            default: undefined,
            type: String,
        },
        img_src: undefined,
        img_position: {
            default: 0,
            type: Number,
        },
        creative_count: {
            default: 0,
            type: Number,
        },
        impressions: {
            default: undefined,
            required: false,
        },
        show_lifetime_impressions: {
            default: false,
            type: Boolean,
        }
    },
    computed: {
        checked(){
            return this.$store.getters['picker_module/getCheckedStatus']({item_uuid: this.placement_uuid, item_type: 'placements'})
        },
        impressionsText(){
            if(this.show_lifetime_impressions) return 'Lifetime Impressions'
            else return 'Ad Set Specific Impressions'
        }
    },
    methods: {
        emitToNavigate(){
            this.$emit('emitToNavigate', this.placement_uuid)
        },
        emitImageDataOnSelected(){
            const image = { uuid: this.placement_uuid, checked: this.checked, img_position: this.img_position }
            this.$emit('selected', image)
        }
    },
}
</script>
