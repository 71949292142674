import { gtag } from 'vue-gtag'

export default {
    mounted(el, binding) {
        const { category = '', action = '', label = '', value = 0, nonInteraction = false } = binding.value || {}

        el.addEventListener('click', () => {
            gtag.event({ eventCategory: category, eventAction: action, eventLabel: label, eventValue: value, nonInteraction })
        })
    }
}
// can be used with
// v-gtag="{ category: 'Button', action: 'Click', label: 'My Button' }"