<template>
    <div>
        <div class="w-full flex flex-col justify-center items-center" v-if="data_loading">
            <LoadingComponent  />
        </div>
        <!-- Stat Cards First Row -->
        <div class="flex flex-col gap-4 w-full mb-4" v-if="active_stats.length && !data_loading">
            <div v-for="item in active_stats" :key="item.name" class="w-full">
                <div class="flex sm:flex-row flex-col gap-2 w-full">
                    <div v-for="inner_item in item" :key="inner_item.name" @click="getChartData(inner_item.chart)" class="w-full p-5 bg-white shadow rounded-lg overflow-hidden sm:p-6 hover:bg-blue-200 cursor-pointer duration-150">
                        <div class="text-sm font-medium text-gray-500">
                            {{ inner_item.name }}
                        </div>
                        <div class="mt-1 text-3xl font-semibold text-gray-900">
                            {{ inner_item.stat.toLocaleString() }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-white rounded-md p-2 flex items-center justify-center">
            <highcharts class="w-full" ref="chartRef" :options="highcharts_options" v-if="!chart_loading" />
            <LoadingComponent v-else />
        </div>
    </div>
</template>

<script>
import {Chart} from 'highcharts-vue'
import Highcharts from 'highcharts'
const highcharts_options = {
    time: {
        timezoneOffset: 0
    },
    chart: {
        zoomType: 'x',
        marginRight: 100
    },
    title: {
        text: '',
    },
    plotOptions: {
        line: {
            connectNulls: false
        }
    },
    xAxis: {
        type: 'datetime',
        labels: {
            format: '{value: %b %e}'
        },
    },
    yAxis: {
        title: {
            text: 'Impressions',
        },
        labels: {
            overflow: 'justify',
            formatter: function() {
                return this.value >= 1000000 ? this.value / 1000000 + 'M' : this.axis.defaultLabelFormatter.call(this);
            }
        },
    },
    colors: ['#82C431', '#88C6ED','#EF4444', '#394BA0', '#FAA31B', '#D54799'],
    tooltip: {
        formatter: function() {
            return Highcharts.dateFormat('%A %b %e', this.x) + '<br>' + this.series.name + ': <b>' + this.y.toLocaleString() + '</b>';
        }
    },
    series: [],
    credits: {
        enabled: false,
    },
    exporting: {
    }
}

export default {
    name: 'OverviewPage',
    data() {
        return {
            active_stats: [],
            highcharts_options: highcharts_options,
            data_loading: true,
            chart_loading: true
        }
    },
    components: {
        highcharts: Chart,
    },
    methods: {
        async getStatInfo() {
            this.data_loading = true
            await this.$http.get('/home-queries')
                .then( ({data})=> {
                    this.active_stats = [
                        [
                            { name: 'Active Campaigns', stat: data['Active Campaigns'], chart: 'Impressions'},
                            { name: 'Active Impressions', stat: data['Active Impressions'], chart: 'Impressions'},
                        ],
                        [
                            { name: 'Unique Users', stat: data['Unique Users'], chart: 'Unique Users'},
                            { name: 'Impressions Per Session', stat: data['Impressions Per Session'], chart: 'Impressions Per Session'}
                        ],
                        [
                            { name: 'Avg View Time PC', stat: data['View Time']['PC'], chart: 'PC View Time'},
                            { name: 'Avg View Time Mobile', stat: data['View Time']['Mobile'], chart: 'Mobile View Time'}
                        ]
                    ]
                    this.data_loading = false
                })
                .catch( (error) => {
                    console.error(error)
                    this.data_loading = false
                })
        },
        async getChartData(chart) {
            this.chart_loading = true
            await this.$http.get('/home-charts/'+chart)
                .then( ({data})=> {
                    const chartData = [];

                    for (const uuid in data) {
                        const seriesData = data[uuid].data.map(item => [
                            item.Time,
                            item.Data
                        ]);

                        if(chart != "PC View Time" && chart != "Mobile View Time")
                            chartData.push({
                                name: data[uuid].campaignName,
                                data: seriesData
                            });
                        else 
                            chartData.push({
                                name: data[uuid].platform,
                                data: seriesData
                            });
                    }

                    this.highcharts_options.series = chartData;
                    this.highcharts_options.title.text = chart + " Over Time"
                    this.chart_loading = false
                })
                .catch( (error) => {
                    console.error(error)
                    this.chart_loading = false
                })
        }
    },
    async created() {
        await Promise.all([
            this.getStatInfo(),
            this.getChartData('Impressions')
        ])
    },
}
</script>
