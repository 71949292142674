<template>
    <form class="bg-white w-full rounded-lg p-7 pt-2 my-2" @submit.prevent="createNewRoute">
        <div class="space-y-6 sm:space-y-5">
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-tsm:border-gray-200 sm:pt-5">
                <label for="route_name" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Route Name
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <input type="text" maxlength="50" name="route_name" autocomplete="off"  v-model="route_name" id="route_name" required class="max-w-lg block w-full 
                    shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="route_href" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2 ">
                    Href
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <input type="text" name="route_href" id="route_href" autocomplete="off" v-model="route_href" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="route_icon" class="block text-md font-medium sm:mt-px sm:pt-2 tooltip text-blue-400 underline hover:text-blue-600 duration-300 cursor-pointer">
                    Icon<div class="tooltiptext sm:w-64 sm:text-base w-32 text-sm ">Shows an icon near the router name. Best to fill this field out unless it has a parent. 
                        If route has a parent then this shouldn't be filled out</div>
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <input type="text" name="route_icon" id="route_icon" autocomplete="off" v-model="route_icon" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="route_parent" class="block text-md font-medium tooltip text-blue-400 underline hover:text-blue-600 duration-300 cursor-pointer sm:mt-px sm:pt-2">
                    Parent<div class="tooltiptext sm:w-64 sm:text-base w-32 text-sm ">Parent should be the NAME of the route you want to be the parent.
                    If you specify a parent, the route will be placed in a dropdown as a child of the parent. Example: Create Item is a Parent of Create Route</div>
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <input type="text" name="route_parent" id="route_parent" autocomplete="off" v-model="route_parent" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-tsm:border-gray-200 sm:pt-5">
                <label for="accessible_by" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Accessible By
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2 flex flex-row flex-wrap gap-2">
                    <div v-for="user in selected_users" :key="user" class="text-white bg-indigo-500 rounded-md px-2 py-1 cursor-pointer hover:bg-blue-300 hover:text-black" @click="removeAccessForUser(user)">
                        {{user.name}}
                    </div>
                    <button class="btn-primary light-blue" @click.prevent="selectAllUsers"> Reset </button>
                </div>
            </div>
            <div class="pt-5">
                <div class="flex justify-center">
                    <button class="btn-primary indigo" @submit.prevent="createNewRoute"> Create Route </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    name: "CreateRoute",
    components: {},
    props: {},
    data() {
        return {
            route_name: '',
            route_href: null,
            route_icon: null,
            route_parent: null,
            all_users: [],
            selected_users: [],
        }
    },
    computed: {},
    watch: {},
    async created() {
        await this.getAllUsers()
    },
    mounted() {},
    methods: {
        async createNewRoute() {
            if(!confirm("Are You Sure You Want To Create This Route?"))
                return

            const request = {
                route_name: this.route_name,
                route_href: this.route_href,
                route_icon: this.route_icon,
                route_parent: this.route_parent,
                accessible_by: this.selected_users
            }
            await this.$http.post('/pages/create', request)
                .then( ()=> {
                    this.$notify({type: 'success', text: 'Page Created'})

                    //refresh the page
                    this.$router.go(0);
                })
                .catch( (error) => {
                    console.error(error)
                })
        },
        removeAccessForUser(user) {
            this.selected_users = this.selected_users.filter( el => el.email !== user.email)
        },
        async getAllUsers() {
            await this.$http.get('/users/all')
                .then( ({data}) => {
                    this.all_users = data
                    this.selected_users = this.all_users
                })
        },
        selectAllUsers() {
            this.selected_users = this.all_users
        }
    }
}
</script>

<style scoped>
/* Tooltip container */
.tooltip {
    position: relative;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    display: inline-block;
    font-size: 1em;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 10px;
    border-radius: 6px;
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 1;
}



.tooltip:hover .tooltiptext {
    visibility: visible;
}
</style>