<template>
    <form v-on:submit.prevent="uploadTestUser" class="bg-white w-full rounded-lg p-7 pt-2 my-2">
        <div class="space-y-6 sm:space-y-5">
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-tsm:border-gray-200 sm:pt-5">
                <label for="test_user_name" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Test User Name 
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <input type="text" maxlength="50" name="test_user_name" autocomplete="off"  v-model="test_user_name" id="test_user_name" required class="max-w-lg block w-full 
                    shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="roblox_id" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Roblox User ID
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <input type="text" name="roblox_id" id="roblox_id" required autocomplete="off" v-model="roblox_user_id" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
                </div>
            </div>
            <div class="pt-5">
                <div class="flex justify-center">
                    <button class="btn-primary indigo" v-on:submit="uploadTestUser"> Create Test User </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    data() {
        return {
            test_user_name: '',
            roblox_user_id: '',
        }
    },
    methods: {
        uploadTestUser() {
            const request = {
                'test_user_name': this.test_user_name,
                'roblox_user_id': this.roblox_user_id
            }
            const toasts = {'success': 'Test User Created', 'error': 'Error Creating Test User'}
            this.$http_request(this.$http.post, 'test-users', toasts, request)
                .then(() => {
                    this.test_user_name = ''
                    this.roblox_user_id = ''
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },
    }
}
</script>