<template>
    <div class="flex flex-col gap-4 items-center">
        <div class="flex w-full justify-center rounded-md p-4">
            <span @click="showSection('Page Permissions')" class="options-picker rounded-l-md" :class="{'options-picker-active' : this.show_page_permissions == true}"> Page Permissions </span>
            <span @click="showSection('Campaign Permissions')" class="options-picker" :class="{'options-picker-active' : this.show_campaign_permissions == true}"> Campaign Permissions </span>
            <span @click="showSection('Game Permissions')" class="options-picker" :class="{'options-picker-active' : this.show_game_permissions == true}"> Game Permissions </span>
            <span @click="showSection('Integration Permissions')" class="options-picker rounded-r-md" :class="{'options-picker-active' : this.show_integration_permissions == true}"> Integration Permissions </span>
        </div>

        <div class="flex flex-col gap-4 w-full" v-if="show_page_permissions">
            <PermissionsBox :permissions="this.user_page_permissions" api="/user-page-perm/set-perm" request_string="user_page_permissions" :key="component_refresher"/>
        </div>

        <div class="flex flex-col gap-4 w-full" v-else-if="show_campaign_permissions">
            <PermissionsBox :permissions="this.user_campaign_permissions" api="/user-campaign-perm/set-perm" request_string="user_campaign_permissions"/>
        </div>

        <div class="flex flex-col gap-4 w-full" v-else-if="show_game_permissions">
            <PermissionsBox :permissions="this.user_game_permissions" api="/user-game-perm/set-perm" request_string="user_game_permissions"/>
        </div>

        <div class="flex flex-col gap-4 w-full" v-else-if="show_integration_permissions">
            <PermissionsBox :permissions="this.user_integration_permissions" api="/user-integration-perm/set-perm" request_string="user_integration_permissions"/>
        </div>

    </div>
</template>

<script>
import PermissionsBox from '@/components/PermissionsBox.vue'

export default {
    name: "UserPermissions",
    components: {
        PermissionsBox
    },
    props: {},
    data() {
        return {
            user_page_permissions: [],
            user_campaign_permissions: [],
            user_game_permissions: [],
            user_integration_permissions: [],
            show_page_permissions: true,
            show_campaign_permissions: false,
            show_game_permissions: false,
            show_integration_permissions: false,
            component_refresher: 0,
        }
    },
    computed: {},
    watch: {},
    async created() {
        await this.getUserPagePermissions()
        await this.getUserCampaignPermissions()
        await this.getUserGamePermissions()
        await this.getUserIntegrationPermissions()
    },
    mounted() {},
    methods: {
        async getUserPagePermissions() {
            await this.$http.get('/all-user-page-perm')
                .then( ({data}) => {
                    this.user_page_permissions = data
                    // need to refresh component at the beginning otherwise it won't load
                    this.component_refresher++ 
                })
        },
        async getUserCampaignPermissions() {
            await this.$http.get('/all-user-campaign-perm')
                .then( ({data}) => {
                    this.user_campaign_permissions = data
                })
        },
        async getUserGamePermissions() {
            await this.$http.get('/all-user-game-perm')
                .then( ({data}) => {
                    this.user_game_permissions = data
                })
        },
        async getUserIntegrationPermissions() {
            await this.$http.get('/all-user-integration-perm')
                .then( ({data}) => {
                    this.user_integration_permissions = data
                })
        },
        showSection(section) {
            if (section == 'Page Permissions') {
                this.show_page_permissions = true
                this.show_campaign_permissions = false
                this.show_game_permissions = false
                this.show_integration_permissions = false 
            }

            if (section == 'Campaign Permissions') {
                this.show_page_permissions = false
                this.show_campaign_permissions = true
                this.show_game_permissions = false
                this.show_integration_permissions = false 
            }

            if (section == 'Game Permissions') {
                this.show_page_permissions = false
                this.show_campaign_permissions = false
                this.show_game_permissions = true
                this.show_integration_permissions = false 
            }

            if (section == 'Integration Permissions') {
                this.show_page_permissions = false
                this.show_campaign_permissions = false
                this.show_game_permissions = false
                this.show_integration_permissions = true
            }
        }
    }
}
</script>

<style scoped>

</style>