import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index.js'
import routes from './routes';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
})

const DEFAULT_TITLE = 'AdMonitor by Silicon Digital'

// eslint-disable-next-line no-unused-vars
router.beforeEach( async(to, from, next) => {
    //if loading, don't allow them to go to another page
    const isLoading = store.getters['getImageUploadLoadingState']
    if(isLoading) return

    if (!to.meta.requiresAuth && !to.meta.requiresRead)
        return next()

    const email = await store.dispatch('login_module/getEmailFromSession')
    if(!email)
        return router.push({ name: 'Login', query: { redirect: to.path } })

    let user_permissions = store.getters['getUserPermissions']

    // get user permissions if they're not saved in store
    if(!Object.keys(user_permissions).length) {
        await store.dispatch('fetchUserPermissions', { email: email})
        user_permissions = store.getters['getUserPermissions']
        if(!user_permissions)
            router.push({ name: 'Login', query: { redirect: to.path } })
    }

    // if the page requires read access and they don't have access to it, send them to a page they have access to.
    if(to.meta.requiresRead && !user_permissions[to.name])  {
        let firstTrueKey = Object.keys(user_permissions).find(key => user_permissions[key] === true);
        // if no true value is found, return "Login"
        if (!firstTrueKey) {
            firstTrueKey = "Login";
            console.error('YOU DONT HAVE ACCESS TO ANY PAGES')
        }
        return router.push({ name: firstTrueKey })
    }
        
    return next()
})

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
        document.title = to.meta.title || DEFAULT_TITLE
})

export default router