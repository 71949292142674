<template>
    <form v-on:submit.prevent="createAccount" class="bg-white w-full rounded-lg p-7 pt-2 my-2" autocomplete="off">
        <div class="space-y-6 sm:space-y-5">
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-tsm:border-gray-200 sm:pt-5">
                <label for="user_name" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Name
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <input type="text" maxlength="50" name="user_name" autocomplete="off"  v-model="user_name" id="user_name" required class="max-w-lg block w-full 
                    shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="email" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Email
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <input type="email" name="email" id="email" required autocomplete="off" v-model="email" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="roblox_id" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Password
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <input type="password" name="password" id="password" required autocomplete="off" v-model="password" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="roblox_id" class="block text-md font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Confirm Password
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <input type="password" name="confirm_password" id="confirm_password" required autocomplete="off" v-model="confirm_password" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
                </div>
            </div>
            <div class="pt-5">
                <div class="flex justify-center">
                    <button class="btn-primary indigo" v-on:submit="createAccount"> Create User </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    name: "CreateAdtechUser",
    components: {},
    props: {},
    data() {
        return {
            user_name: '',
            email: '',
            password: '',
            confirm_password: '',
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        createAccount() {
            // Validate Text Fields
            if (!this.validateTextFields())
                return

                const request = {
                    name: this.user_name,
                    email: this.email,
                    password: this.password,
                    confirm_password: this.confirm_password,
                    moderated: true,
                }

            // Register User
            this.$store.dispatch('login_module/registerUser', request)
                .then( () => {
                    this.$notify({type: "success", text: 'Account Created'})
                    this.user_name = this.email = this.password = this.confirm_password = ''
                }).catch( () => {
                    this.$notify({type: "error", text: "Username / Email Is Already In Use!"})
                    this.user_name = this.email = this.password = this.confirm_password = ''
                })
        },
        validateTextFields() {
            if ( this.user_name.trim() == '' || this.email.trim() == '' || this.password.trim() == '' || this.confirm_password.trim() == '' ) {
                this.$notify({type: "error", text: 'Missing Fields Detected!'})
                this.user_name = this.email = this.password = this.confirm_password = ''
                return false
            }
            if ( this.password != this.confirm_password ) {
                this.$notify({type: "error", text: 'Passwords Do Not Match!'})
                this.confirm_password = this.password = ''
                return false
            }
            if (!this.validateEmail(this.email)) {
                this.$notify({type: "error", text: 'Invalid Email Format'})
                this.email = ''
                return false
            }

            return true
        },
        validateEmail(email) {
            return String(email).toLowerCase().match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
        },
    }
}
</script>

<style scoped>

</style>