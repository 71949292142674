import GraphNode from '@/views/DialogMaker/GraphNode.js'
export default class ConversationNode extends GraphNode {
    constructor({id, metadata}, {text, conversationId, characterId, isStart, isEnd, multipleChoice}) {
      super({id, metadata});
      
      if(isStart === null || isStart === undefined) isStart = false;
      if(isEnd === null || isEnd === undefined) isEnd = false;
      if(multipleChoice === null || multipleChoice === undefined) multipleChoice = false

      this.isStart = isStart;
      this.isEnd = isEnd;
      this.text = text;
      this.conversationId = conversationId;
      this.multipleChoice = multipleChoice;
      if(characterId) this.characterId = characterId;
    }
  }