<template>
    <div>
        <TabButtons :buttons="tab_buttons" :active_button="active_panel" :panel-changed-method="panelChanged" />
        <CreateRoute v-show="active_panel == 'create_route'"/>
        <SidebarPositionEditor v-show="active_panel == 'edit_positions'" />
    </div>
</template>

<script>
import TabButtons from '../components/TabButtons.vue';
import CreateRoute from '../components/CreateRoute.vue';
import SidebarPositionEditor from '../components/SidebarPositionEditor.vue'
export default {
    name: "SidebarEditor",
    components: {
        TabButtons,
        CreateRoute,
        SidebarPositionEditor,
    },
    props: {},
    data() {
        return {
            active_panel: 'create_route',
            tab_buttons: [
                {
                    name: 'create_route',
                    text: 'Create Route'
                },
                {
                    name: 'edit_positions',
                    text: 'Sidebar Position Editor'
                }
            ],
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        panelChanged(panel) {
            this.active_panel = panel
        },
    }
}
</script>

<style scoped>

</style>