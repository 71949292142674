<template>
    <div v-if="showModal" class="modal flex flex-col gap-2 text-sm sm:text-base">
        <div class="modal-content flex flex-col gap-4">
            <span class="font-medium text-lg">Schedule Reports</span>
            <div class="flex flex-col gap-4">
                <div class="border-t pt-2 flex flex-row flex-wrap gap-2 items-center justify-around">
                    <span class="font-medium"> Send To </span>
                    <DropdownComponent
                    :items='all_users'
                    display="email"
                    selected="Select a user"
                    @user_select="addUserToScheduledReport"
                    class="sm:w-64 w-56"/>
                    <span>Or</span>
                    <div class="flex flex-row gap-2 items-center">
                        <input type="text" placeholder="Enter Custom Email Address" maxlength="150" name="user_email" autocomplete="off"  v-model="custom_user_email" id="user_email" class="block 
                        shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:w-64 w-56 sm:text-sm border-gray-300 rounded-md" @keyup.enter="addUserToScheduledReport({email: this.custom_user_email})"/>
                    </div>
                </div>
                <div class="flex flex-row flex-wrap gap-2 border border-black px-2 py-4">
                    <label v-for="user in selected_users" :key="user" class="duration-200 text-sm bg-white rounded-md px-2 py-1 cursor-pointer hover:bg-blue-300 border border-black" @click="removeUser(user)">{{user.email}}</label>
                </div>
                <div class="border-t pt-2 flex flex-row gap-4 items-center">
                    <span class="font-medium"> Every </span>
                    <DropdownComponent
                        :items="['Day', 'Week', 'Month', 'Custom']"
                        :selected="selected_timespan"
                        @user_select="selectTimespan"
                        class="sm:w-64 w-56"/>
                </div>
                <div class="border-b pb-2">
                    <div v-if="selected_timespan == 'Week'" class="flex flex-col gap-2 border border-black p-2">
                        <span> Repeat On </span>
                        <div class="flex flex-row flex-wrap gap-2 p-2">
                            <span 
                            v-for="day in days_in_week" :key="day" 
                            @click="setWeekDay(day)" 
                            class="circle-letter duration-200" 
                            :class="day == selected_day ? 'bg-blue-300 hover:bg-blue-400' : 'bg-gray-300 hover:bg-gray-400'"> 
                                {{ day.letter }}
                            </span>
                        </div>
                    </div>
                    <div v-if="selected_timespan == 'Month'" class="flex flex-row gap-4 items-center border-t py-2">
                        <span class="font-medium"> Select Monthly Report Date </span>
                        <input type="number" v-model="selected_day_number" min="1" max="31" class="w-24 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" />
                    </div>
                    <div v-if="selected_timespan == 'Custom'" class="flex flex-row gap-2 items-center border-t py-2">
                        <span class="font-medium"> Select Report Delivery Date </span>
                        <input type="date" v-model="custom_date" :min="min_date" class="date-picker"> 
                    </div>
                </div>
            </div>
            <div v-if="error_msg" class="font-medium text-red-500">
                {{ error_msg }}
            </div>
            <div class="flex flex-row justify-between gap-2 items-end w-full">
                <button class="btn-primary red" @click="deleteScheduledReport"> Delete </button>
                <div class="flex-grow"></div>
                <button class="btn-primary indigo" @click="cancel">Cancel</button>
                <button class="btn-primary green" @click="apply">Apply</button>
            </div> 
        </div>
    </div>
</template>


<script>

export default {
    name: "ScheduledReportModal",
    components: {},
    props: {
        campaign: {
            type: Array,
            required: true,
        }
    },
    data() {
        return {
            min_date: this.$dateTodayFormatted(),
            showModal: false,
            selected_timespan: 'Week',
            selected_users: [],
            all_users: [],
            selected_day_number: 1,
            selected_day: '',
            custom_date: null,
            custom_user_email: '',
            error_msg: '',
            days_in_week: [
                {
                    letter: 'S',
                    name: 'Sunday'
                },
                {
                    letter: 'M',
                    name: 'Monday'
                },
                {
                    letter: 'T',
                    name: 'Tuesday'
                },
                {
                    letter: 'W',
                    name: 'Wednesday'
                },
                {
                    letter: 'T',
                    name: 'Thursday'
                },
                {
                    letter: 'F',
                    name: 'Friday'
                },
                {
                    letter: 'S',
                    name: 'Saturday'
                },
            ]
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getScheduledReportInfo()
    },
    methods: {
        async getAllUsers() {
            await this.$http.get('/users/all')
                .then( ({data}) => {
                    this.all_users = data
                })
        },
        async getScheduledReportInfo() {
            await this.$http.get('/scheduled-report/' + this.campaign.uuid)
                .then( async ({data}) => {
                    await this.getAllUsers()
                    this.error_msg = ''

                    if(!data)  {
                        return this.resetVariables()
                    }

                    this.selected_timespan = data.timespan
                    
                    if(this.selected_timespan == 'Week') {
                        const selectedDay = this.days_in_week.find(day => day.name === data.date_option);
                        this.selected_day = selectedDay;
                    } else if (this.selected_timespan == 'Month') {
                        this.selected_day_number = data.date_option
                    } else if (this.selected_timespan == 'Custom') {
                        this.custom_date = data.date_option
                    }

                    this.selected_users = []
                    for(const user of data.users) {
                        this.addUserToScheduledReport(user)
                    }
                })
        },
        deleteScheduledReport() {
            if(!confirm('Are you sure you want to delete the scheduled report? This will delete it for all the selected users as well')) return

            this.$http.delete(`/scheduled-report/delete/${this.campaign.uuid}`)
                .then( () => {
                    this.closeModal()
                    this.resetVariables()
                    this.$notify({type: 'success', text: 'Updated Successfully'})
                })
                .catch( (error) => {
                    this.error_msg = error.response.data.message
                })
        },
        addUserToScheduledReport(user) {
            if(!user.email)
                return
            
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

            // Check if the email is valid
            if (user.email && !emailRegex.test(user.email)) {
                this.error_msg = 'Invalid Email Format!'
                return; 
            }

            this.error_msg = ''

            if (!this.selected_users.some(el => el.email === user.email)) {
                // If not, add the user to selected_users and update all_users
                this.custom_user_email = ''
                this.selected_users.push(user);
                this.all_users = this.all_users.filter(el => el.email !== user.email);
            }
        },
        removeUser(user) {
            this.selected_users = this.selected_users.filter( el => el.email !== user.email)
            this.all_users.push(user)
            this.all_users.sort((a, b) => a.email.localeCompare(b.email))
        },
        selectTimespan(timespan) {
            this.selected_timespan = timespan
        },
        selectDayNumber(day_number) {
            this.selected_day_number = day_number
        },
        setWeekDay(day) {
            this.selected_day = day
        },
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        cancel() {
            this.getScheduledReportInfo()
            this.closeModal()
        },
        apply() {
            if(!this.validateDateOption()) return
            let date_option = this.getDateOption()

            const request = {
                campaign_uuid: this.campaign.uuid,
                users: this.selected_users,
                timespan: this.selected_timespan,
                date_option: date_option
            }

            const toasts = {'success': 'Report Successfully Scheduled', 'error': 'Sorry there was an error scheduling the report'}    
            this.$http_request(this.$http.post, '/scheduled-report/create', toasts, request)
                .then( () => {
                    this.error_msg = ''
                    this.closeModal()
                })
        },
        getDateOption() {
            if(this.selected_timespan == 'Week')
                return this.selected_day.name
            else if (this.selected_timespan == 'Month')
                return this.selected_day_number
            else if (this.selected_timespan == 'Custom')
                return this.custom_date
        },
        validateDateOption() {
            if(this.selected_timespan == 'Week' && this.selected_day == '') {
                this.error_msg = 'Please choose a day of the week!'
                return false
            }

            if (this.selected_timespan == 'Month' && (this.selected_day_number <= 0 || this.selected_day_number > 31) ) {
                this.error_msg = 'Please choose a number between 1 and 31 for the date!'
                return false
            }
            
            if (this.selected_timespan == 'Custom' && !this.custom_date) {
                this.error_msg = 'Please choose a date!'
                return false
            }

            return true
        },
        resetVariables() {
            this.getAllUsers()
            this.selected_timespan = 'Week'
            this.selected_users = []
            this.selected_day_number = 1
            this.selected_day = ''
            this.custom_date = null
            this.error_msg = ''
        }
    }
}
</script>

<style lang="postcss" scoped>
.date-picker {
    @apply w-44 block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md;
}
.modal {
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease 1s;
}
.modal-content {
    background: white;
    max-width: 45em;
    min-width: 300px;
    margin: 20px;
    padding: 20px;
    border-radius: 5px;
}

.circle-letter {
    cursor: pointer;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    text-align: center;
    color: #333;
    font-size: 16px;
}
</style>