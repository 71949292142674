<template>
    <div class="grid grid-flow-col grid-cols-2 gap-2 text-xl border-t-2 items-center justify-center min-h-fit py-2">
        
        <!-- Label -->
        <h6 class="font-semibold">{{label}}:</h6>

        <!-- Show Value If Not Editing -->
        <h5 v-if="!edit_mode" class="break-words">{{ input.type == 'checkbox' ? value == 1 ? 'Yes' : 'No' : value}}</h5>

        <!-- Change to Input If Editing -->
        <input 
        class="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
        v-if="editable && edit_mode && input && input.type == 'checkbox'" 
        :type="input.type" 
        v-model="checkboxValue">

        <input
        v-else-if="editable && edit_mode && input"
        class="max-w-lg sm:max-w-xs block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
        maxlength="50"
        :type="input.type" 
        v-model="input_value">

        <CheckIcon class="w-8 h-8 cursor-pointer text-white font-semibold bg-green-400 rounded-md hover:bg-green-600 duration-150" v-if="editable && edit_mode" @click="updateItemInfo" />
    </div>
</template>

<script>
import { CheckIcon } from '@heroicons/vue/outline'
export default {
    props: {
        editable: {
            type: Boolean,
            required: true,
            default: false,
        },
        edit_mode: {
            type: Boolean,
            required: true,
            default: false
        },
        label:{
            type: String,
            required: true,
            default: 'Label'
        },
        value: {
            required: true,
        },
        item_uuid: {
            required: true,
        },
        item_type: {
            required: true,
        },
        input: {
            type: Object,
            required: false
        },
        form_key: {
            required: true,
        },
    },
    components: {
        CheckIcon
    },
    data() {
        return {
            input_value: '',
        }
    },
    methods: {
        setDefaultInputValue(){
            if(this.editable){
                this.input_value = this.value
            }
        },
        updateItemInfo() {
            this.$store.dispatch('item_module/updateItem', {
                'item_type': this.item_type, 
                'uuid': this.item_uuid, 
                'form': {[this.form_key.name]: this.input_value}
            })
        }
    },
    computed: {
        checkboxValue: {
            get() {
                return this.input_value === 1;
            },
            set(value) {
                this.input_value = value ? 1 : 0;
            }
        }
    },
    mounted(){
        this.setDefaultInputValue()
    }
    
}
</script>