export default class MainState {
    state = {
        image_upload_loading_state: false,
        image_delete_loading_state: false,
        request_csrf_count: 0,
        all: {
            creatives: [],
            games: [],
            campaign: {
                creatives: [],
                games: [],
            },
            run: {
                creatives: [],
                games: [],
                placements: [],
            },
        },
        taker: {
            creatives: [],
            games: [],
            placements: [],
        },
        pages: [],
        user: [],
        timezones: [ 
            {name: 'PST',   value: 'America/Los_Angeles'}, 
            {name: 'UTC',   value: 'Africa/Abidjan'},
            {name: 'UTC+1', value: 'Europe/London'},
            {name: 'UTC+2', value: 'Asia/Jerusalem'},
        ],
        campaign_categories: [
            'Arts & Entertainment','Automotive','Business','Careers',
            'Education','Family & Parenting','Food & Drink','Health & Fitness',
            'Hobbies & Interests','Home & Garden','Illegal Content','News',
            'Non-Standard Content','Personal Finance','Pets','Real Estate',
            'Religion & Spirituality','Science','Shopping','Society','Sports',
            'Style & Fashion','Technology & Computing','Travel','Uncategorized',
            "Law, Gov't & Politics"
        ]
    }
}