<template>
    <div>
        <div
            class="flex flex-col gap-2 justify-center items-center h-full"
            v-if="loading">
            <h2>Fetching data... This might take a few minutes.</h2>
            <LoadingComponent />
        </div>

        <!-- Inputs for paramters -->
        <div class="sm:p-8 p-4 bg-blue-100 rounded-md sm:grid sm:grid-cols-2 max-sm:flex max-sm:flex-col gap-x-8 gap-y-4" v-if="!loading">
            <div>
                <div class="sm:grid sm:grid-cols-2 sm:col-start-1 sm:col-end-3 flex flex-col gap-x-4">
                    <label class="font-medium sm:col-start-1 sm:col-end-3"> Game: </label>
                    <DropdownComponent
                        :items="gameNameDropdownItems"
                        :selected="selectedGameName"
                        @user_select="changeSelectedGame"
                        class="h-full border rounded-md" />
                </div>

                <div class="sm:grid sm:grid-cols-2 sm:col-start-1 sm:col-end-3 flex flex-col gap-x-4" v-if="showIntegrationIdDropdown">
                    <label class="font-medium sm:col-start-1 sm:col-end-3"> Integration: </label>
                    <DropdownComponent
                        :items="integrationIdsDropdownItems"
                        :selected="selectedIntegrationId"
                        @user_select="changeSelectedIntegrationId"
                        class="h-full border rounded-md" />
                </div>

                <!-- <div class="sm:grid sm:grid-cols-2 sm:col-start-1 sm:col-end-3 flex flex-col gap-x-4">
                    <label class="font-medium sm:col-start-1 sm:col-end-3"> Group: </label>
                    <DropdownComponent
                        :items="eventIdOnesDropdownItems"
                        :selected="selectedEventIdOne"
                        @user_select="changeSelectedEventIdOne"
                        class="h-full border rounded-md" />
                </div> -->

                <div class="sm:grid sm:grid-cols-2 sm:col-start-1 sm:col-end-3 flex flex-col gap-x-4">
                    <label class="font-medium sm:col-start-1 sm:col-end-3"> Country: </label>
                    <DropdownComponent
                        :items="countryDropdownItems"
                        :selected="selectedCountry"
                        @user_select="changeSelectedCountry"
                        class="h-full border rounded-md" />
                </div>
            </div>
            <div>
                <div class="sm:grid sm:grid-cols-2 sm:col-start-1 sm:col-end-3 flex flex-col gap-y-2 gap-x-4">
                    <VueDatePicker
                        v-if="!fetchingStatus.minMax"
                        class="p-2 m-1"
                        v-model="interval.userSelectionRange"
                        range
                        :min-date="intervalMinMax.minMax.min"
                        :max-date="intervalMinMax.minMax.max"
                        :enable-time-picker="false"
                        prevent-min-max-navigation
                        :preset-ranges="presetRanges"
                        :clearable="false">
                        <template #yearly="{ label, range, presetDateRange }">
                            <span @click="presetDateRange(range)">{{ label }}</span>
                        </template>
                    </VueDatePicker>
                </div>

                <div class="sm:grid sm:grid-cols-2 sm:col-start-1 sm:col-end-3 flex flex-col gap-y-2 gap-x-4">
                    <button @click="fetchNewData" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Fetch New Data
                    </button>

                </div>
                <div class="sm:grid sm:grid-cols-2 sm:col-start-1 sm:col-end-3 flex flex-col gap-y-2 gap-x-4 mt-2" v-if="showDownloadButton">
                    <button class="btn-primary light-blue" @click="downloadTablesAsExcel"> 
                        Export Data
                    </button>
                </div>
            </div>
        </div>

        <!-- Cards Section -->
        <div class="grid-container gap-2" v-if="integrationData && integrationData.integration_overview && (fetchingStatus.integrationData !== true && (showStat(integrationData.integration_overview?.total_unique_engagements) || showStat(integrationData.integration_overview?.mean_engagements_per_player)))">
            <div class="card-container" v-if="showStat(integrationData.integration_overview?.total_unique_engagements)">
                <div class="inner-card">
                    <label> Total Unique Engagements: </label>
                    <span> {{ formatNumber(integrationData.integration_overview?.total_unique_engagements) }} </span>
                </div>
                <div class="inner-card" v-if="showStat(integrationData.integration_overview?.mean_engagements_per_player)">
                    <label>Average Engagements Per Player</label>
                    <span> {{ formatNumber(integrationData.integration_overview?.mean_engagements_per_player) }} </span>
                </div>
            </div>
            <div class="card-container" v-if="showStat(integrationData.integration_overview?.total_visits) || showStat(integrationData.integration_overview?.mean_seconds_spent_per_player)">
                <div class="inner-card" v-if="showStat(integrationData.integration_overview?.total_visits)">
                    <label> Total Visits </label>
                    <span> {{ formatNumber(integrationData.integration_overview?.total_visits) }} </span>
                </div>
                <div class="inner-card" v-if="showStat(integrationData.integration_overview?.mean_seconds_spent_per_player)">
                    <label> Mean Seconds Spent Per Player </label>
                    <span> {{ formatNumber(integrationData.integration_overview?.mean_seconds_spent_per_player) }} </span>
                </div>
            </div>
            <div class="card-container" v-if="showStat(integrationData?.target_engagements) && showStat(integrationData?.delivered_engagements)">
                <div class="inner-card" v-if="showStat(integrationData?.target_engagements)">
                    <label> Target Engagements </label>
                    <span> {{ formatNumber(integrationData?.target_engagements) }} </span>
                </div>
                <div class="inner-card" v-if="showStat(integrationData?.delivered_engagements)">
                    <label> Total Engagements Delivered </label>
                    <span> {{ formatNumber(integrationData?.delivered_engagements) }} </span>
                </div>
            </div>
        </div>

        <div class="p-4 bg-white rounded-md flex flex-col gap-4" v-if="!fetchingStatus.ingegrationData">
            <ProgressBar title="Current Pacing" :percentage="engagementPercentageProgress"/>
            <ProgressBar title="Target Pacing" :percentage="engagementTimePercentageProgress"/>
        </div>

        <TabButtons :buttons="panelSwitchingButtons" :active_button="currentlyVisiblePanel" :panel-changed-method="panelChanged"></TabButtons>

        <!-- Chart -->
        <!-- <highcharts
            v-if="!fetchingStatus.integrationCharts"
            :options="defaultGameDesignEventChartOptions"></highcharts> -->


        <TableComponent v-if="currentlyVisiblePanel === 'event_table'" :table_data="eventTotalsData" total_column_heading_name="Event Name" :exported_file_name="campaignEngagementsTableFilename"  :show_average_row="false"></TableComponent>

        <TableComponent v-if="currentlyVisiblePanel === 'event_group_daily_table'" :table_data="eventGroupDailyData" total_column_heading_name="Date" :exported_file_name="deliveredEngagementsTableFilename" :show_average_row="false"></TableComponent>
    </div>
</template>

<script>
import ProgressBar from '@/components/UtilityComponents/ProgressBar.vue'
import TableComponent from '@/components/TableComponent.vue';
import VueDatePicker from '@vuepic/vue-datepicker'
import TabButtons from '../components/TabButtons.vue'
import * as XLSX from 'xlsx/xlsx.mjs';

import '@vuepic/vue-datepicker/dist/main.css'
// import { Chart } from 'highcharts-vue'
import { subDays } from 'date-fns'

const noGrouping = 'All (No Grouping)';
const noneSelectedRequired = 'None Selected (Required)';

const overviewNoData = 'N/A'

const integrationDataDefault = {
                target_engagements: overviewNoData,
                delivered_engagements: overviewNoData,
                integration_overview: {
                    total_unique_engagements: overviewNoData,
                    mean_engagements_per_player: overviewNoData,
                    total_visits: overviewNoData,
                    mean_seconds_spent_per_player: overviewNoData,
                }
            } 

const defaultGameDesignEventChartOptions = {
    chart: {
        type: 'line',
    },
    title: {
        text: 'Game Design Event Chart - UTC',
    },
    xAxis: {
        type: 'datetime', // Set the x-axis type to datetime
        title: {
            text: 'Time',
        },
    },
    yAxis: {
        title: {
            text: 'Event Count',
        },
        labels: {
            overflow: 'justify',
            formatter: function () {
                return this.value >= 1000000
                    ? this.value / 1000000 + 'M'
                    : this.axis.defaultLabelFormatter.call(this)
            },
        },
    },
    credits: {
        enabled: false,
    },
    series: [],
}

export default {
    name: 'IntegrationsDashboard',
    components: {
        VueDatePicker,
        // highcharts: Chart,
        TabButtons,
        TableComponent,
        ProgressBar,
    },
    props: {},
    data() {
        return {
            defaultGameDesignEventChartOptions:
                defaultGameDesignEventChartOptions,
            interval: {
                minMax: {
                    min: new Date('2021-01-01'),
                    max: new Date(),
                },
                userSelectionRange: [
                    subDays(new Date(), 31),
                    subDays(new Date(), 1),
                ],
            },
            integrationData: integrationDataDefault,
            gameIdGameNameMap: {},
            gameNameDropdownItems: [],
            selectedGameName: noneSelectedRequired,
            integrationIdsDropdownItems: [],
            selectedIntegrationId: noneSelectedRequired,
            eventIdOnesDropdownItems: [],
            selectedEventIdOne: noGrouping,
            eventTotalsData: [],
            eventGroupDailyData: [],
            countryDropdownItems : ['US', 'All'],
            selectedCountry: 'All',
            currentlyVisiblePanel: 'event_table',
            fetchingStatus: {
                integrationData: false,
                integrationCharts: false,
                minMax: false,
                gameIdNameMap: false,
                integrationIds: false,
                eventTotals: false,
                eventGroupDaily: false,
                idOnes: false,
            },
            panelSwitchingButtons: [
                {
                    name: 'event_table',
                    text: 'Campaign Engagements'
                },
                {
                    name: 'event_group_daily_table',
                    text: 'Delivered Engagements'
                }
            ],
        }
    },
    computed: {
        intervalMinMax() {
            const result = {
                minMax: {
                    min: new Date(this.interval.minMax.min),
                    max: new Date(this.interval.minMax.max),
                },
            }
            return result
        },

        presetRanges() {
            return [
                {
                    label: 'Last 30 days',
                    range: [subDays(new Date(), 31), subDays(new Date(), 1)],
                },
                {
                    label: 'Last 60 days',
                    range: [subDays(new Date(), 61), subDays(new Date(), 1)],
                },
                {
                    label: 'Last 90 days',
                    range: [subDays(new Date(), 91), subDays(new Date(), 1)],
                },
                {
                    label: 'All time',
                    range: [
                        this.intervalMinMax.minMax.min,
                        this.intervalMinMax.minMax.max,
                    ],
                },
            ]
        },

        userSelectionRange() {
            if (
                !this.interval.userSelectionRange ||
                this.interval.userSelectionRange.length !== 2 ||
                !(this.interval.userSelectionRange[0] instanceof Date) ||
                !(this.interval.userSelectionRange[1] instanceof Date)
            ) {
                return {
                    startDate: null,
                    endDate: null,
                }
            }

            return {
                startDate: this.interval.userSelectionRange[0].toISOString(),
                endDate: this.interval.userSelectionRange[1].toISOString(),
            }
        },

        selectedGameId() {
            if (!this.gameIdGameNameMap) return null
            for (const [gaId, gaName] of Object.entries(
                this.gameIdGameNameMap
            )) {
                if (gaName === this.selectedGameName) {
                    return gaId
                }
            }
            return null
        },

        canFetchGameEventChartSeries() {
            if (!this.selectedGameId) {
                return { success: false, reason: 'Game ID is not selected.' }
            }

            if (
                !this.userSelectionRange ||
                !this.userSelectionRange.startDate ||
                !this.userSelectionRange.endDate
            ) {
                return {
                    success: false,
                    reason: 'Date range is incomplete or invalid.',
                }
            }

            return { success: true, reason: '' }
        },

        loading() {
            return !Object.values(this.fetchingStatus).every(
                (value) => value === false
            )
        },

        engagementPercentageProgress() {
            if (!this.integrationData) return 0;

            const percentage = (this.integrationData.delivered_engagements / this.integrationData.target_engagements) * 100;
            return Math.round(percentage);
        },

        engagementTimePercentageProgress(){
            if(!this.integrationData) return 0
            if(!this.integrationData.start_date) return 0
            if(!this.integrationData.end_date) return 0
            return this.getTimePercentage(this.integrationData.start_date, this.integrationData.end_date)
        },

        showIntegrationIdDropdown(){
            if(
                !this.selectedGameName ||
                this.selectedGameName === noneSelectedRequired ||
                this.selectedGameName === '' ||
                this.fetchingStatus.integrationIds !== false
            ) {
                return false
            }

            return true
        },

        showDownloadButton(){
            if(
                !this.selectedGameName ||
                this.selectedGameName === noneSelectedRequired ||
                this.selectedGameName === '' ||
                this.loading ||
                this.eventGroupDailyData.length === 0 ||
                this.eventTotalsData.length === 0

            ) {
                return false
            }
            return true
        }, 

        campaignEngagementsTableFilename() {
            const baseString = 'Campaign Engagements';
            const integrationId = this.selectedIntegrationId || 'NoIntegrationId';
            const gameName = this.selectedGameName || 'NoGameName';

            return `${baseString} - ${integrationId} - ${gameName}`;
        },

        deliveredEngagementsTableFilename() {
            const baseString = 'Delivered Engagements';
            const integrationId = this.selectedIntegrationId || 'NoIntegrationId';
            const gameName = this.selectedGameName || 'NoGameName';

            return `${baseString} - ${integrationId} - ${gameName}`;
        }
    },

    watch: {
        selectedGameId() {
            //Reset
            this.integrationIdsDropdownItems = []
            this.selectedIntegrationId = noneSelectedRequired
            this.selectedEventId = noGrouping
            this.interval.userSelectionRange = [
                subDays(new Date(), 31),
                subDays(new Date(), 1),
            ]
            //Fetch
            this.fetchIntegrationIdsData()
            this.fetchMinMaxInterval()
        },
    },

    created() {},

    mounted() {
        this.fetchGameNameDropdownData()
        this.fetchIntegrationIdsData()
        this.fetchMinMaxInterval()
    },

    methods: {
        fetchNewData(){
            // this.getIntegrationChartSeries()
                        //Fetch
            this.fetchIntegrationData()
            this.getEventTotalsData()
            this.getEventGroupDailyData()
        },

        fetchIntegrationData() {
            if (!this.selectedIntegrationId) {
                console.error('No selected integration ID');
                return;
            }
            // No params just placeholder
            const params = new URLSearchParams();

            this.fetchingStatus.integrationData = true;

            this.$http.get(`/integrations-event-id-05/${this.selectedIntegrationId}?${params.toString()}`)
                .then((response) => {
                    if (response.data) {
                        // Process the response data
                        this.integrationData = response.data.data;
                        this.fetchingStatus.integrationData = false;
                        this.$notify({ type: "success", text: "Integration Data Fetched" });
                    } else {
                        this.fetchingStatus.integrationData = false;
                    }
                })
                .catch((error) => {
                    this.fetchingStatus.integrationData = false;
                    console.error('Error fetching integration chart data:', error.message);
                });
        },

        async getIntegrationChartSeries() {
            this.fetchingStatus.integrationCharts = true

            const validation = this.canFetchGameEventChartSeries
            const reason = validation.reason || "Can't fetch integration chart data."

            if (!validation.success) {
                this.$notify({ type: 'error', text: reason })
                this.fetchingStatus.integrationCharts = false
                return
            }

            const params = new URLSearchParams()
            params.append('country', this.selectedCountry)
            params.append('integrationId', this.selectedIntegrationId)
            params.append('eventIdOne', this.getSelectedEventIdOne())
            params.append('gameId', this.selectedGameId)
            params.append('start', this.userSelectionRange.startDate)
            params.append('end', this.userSelectionRange.endDate)

            this.$http.get(`/game-analytics-design-events/integration/event-charts?${params.toString()}`)
                .then((response) => {
                    if (response.data && response.data.length > 0) {
                        this.defaultGameDesignEventChartOptions.series = response.data
                        this.fetchingStatus.integrationCharts = false
                        this.$notify({type: "success", text: "Integration Data Fetched"})
                    } else {
                        this.fetchingStatus.integrationCharts = false
                    }
                })
                .catch((error) => {
                    this.fetchingStatus.integrationCharts = false
                    console.error('Error fetching integration chart data:', error.message)
                })
        },

        async fetchMinMaxInterval() {
            try {
                this.fetchingStatus.minMax = true

                let url = `/game-analytics-design-events/event-interval`

                const params = new URLSearchParams()

                if (this.selectedIntegrationId) {
                    params.append('integration_id', this.selectedIntegrationId)
                }

                // Append the params to the URL
                if (params.toString()) {
                    url += `?${params.toString()}`
                }

                const response = await this.$http.get(url)
                if (response.data.startDate && response.data.endDate) {
                this.interval.minMax.min = new Date(response.data.startDate + 'Z'); // Assuming startDate is in 'YYYY-MM-DD' format
                this.interval.minMax.max = new Date(response.data.endDate + 'Z'); // Assuming endDate is in 'YYYY-MM-DD' format
                    this.interval.userSelectionRange = [
                        this.interval.minMax.min,
                        this.interval.minMax.max,
                    ]
                }

                this.fetchingStatus.minMax = false
            } catch (error) {
                this.$notify({
                    type: 'error',
                    text: 'Error fetching MinMax Interval',
                })
                console.error('Error fetching minMax interval:', error.message)
                this.fetchingStatus.minMax = false
            }
        },

        async fetchGameNameDropdownData() {
            try {
                this.fetchingStatus.gameIdNameMap = true
                const response = await this.$http.get(
                    '/ga-name-lookup/design-events'
                )
                const gaData = response.data
                this.gameIdGameNameMap = gaData
                this.gameNameDropdownItems = Object.values(gaData)
                this.selectedGameName = noneSelectedRequired 
                this.fetchingStatus.gameIdNameMap = false
            } catch (error) {
                console.error('Error fetching Game name data:', error.message)
                this.fetchingStatus.gameIdNameMap = false
            }
        },

        async fetchIntegrationIdsData() {
            try {
                this.fetchingStatus.integrationIds = true

                const response = await this.$http.get(
                    `/game-analytics-design-events/distinct-integrations/${this.selectedGameId}`
                )
                this.integrationIdsDropdownItems = response.data
                this.selectedEventId = noneSelectedRequired // Set the first item as the default selected item
                this.fetchingStatus.integrationIds = false
            } catch (error) {
                console.error('Error fetching Event IDs:', error.message)
                this.fetchingStatus.integrationIds = false
            }
        },

        async fetchDistinctEventIdOnes() {
            try {
                this.fetchingStatus.idOnes = true

                const params = new URLSearchParams({
                    event_id_05: this.selectedIntegrationId,
                    game_id: this.selectedGameId,
                    country: this.selectedCountry,
                });

                const response = await this.$http.get(
                    `/game-analytics-design-events/distinct-event-id-ones?${params}`
                );

                this.eventIdOnesDropdownItems = response.data.data || [];
                this.eventIdOnesDropdownItems.unshift(noGrouping);
                this.selectedEventIdOne = noGrouping; // Set the first item as the default selected item
                this.changeSelectedEventIdOne(noGrouping);

                this.fetchingStatus.idOnes = false;
            } catch (error) {
                console.error('Error fetching distinct event ID ones:', error.message);
                this.fetchingStatus.idOnes = false;
            }
        },

        async getEventTotalsData() {
            try {
                this.fetchingStatus.eventTotals = true; 
                //reset the eventTotalsData
                this.eventTotalsData = []

                const queryParams = {
                    gameId: this.selectedGameId,
                    start: this.userSelectionRange.startDate,  
                    end: this.userSelectionRange.endDate,
                    integrationId: this.selectedIntegrationId,
                    country: this.selectedCountry,
                    eventIdOne: this.getSelectedEventIdOne(), 
                };

                const response = await this.$http.get('/game-analytics-design-events/event-totals', {
                    params: queryParams
                });

                this.eventTotalsData = response.data;  // Assume you have a data property called eventTotalsData

                this.fetchingStatus.eventTotals = false;
            } catch (error) {
                console.error('Error fetching event totals:', error.message);
                this.fetchingStatus.eventTotals = false;
            }
        },

        async getEventGroupDailyData() {
            try {
                this.fetchingStatus.eventGroupDaily = true;

                this.eventGroupDailyData = [];

                const queryParams = {
                    gameId: this.selectedGameId,
                    start: this.userSelectionRange.startDate,
                    end: this.userSelectionRange.endDate,
                    integrationId: this.selectedIntegrationId,
                    country: this.selectedCountry,
                    eventIdOne: this.getSelectedEventIdOne(),
                };

                const response = await this.$http.get('/game-analytics-design-events/daily-group-event-count', {
                    params: queryParams
                });

                this.eventGroupDailyData = response.data;

                this.fetchingStatus.eventGroupDaily = false;
            } catch (error) {
                console.error('Error fetching event group daily data:', error.message);
                this.fetchingStatus.eventGroupDaily = false;
            }
        },


        changeSelectedGame(gameName) {
            if(gameName === noneSelectedRequired) {
                this.selectedGameName = ''
            }
            else {
                this.selectedGameName = gameName
            }
            //Reset
            this.integrationData = integrationDataDefault
            this.eventTotalsData = []
            this.eventGroupDailyData = []
            this.defaultGameDesignEventChartOptions.series = []
            this.integrationIdsDropdownItems = []
            this.selectedIntegrationId = noneSelectedRequired
            this.eventIdsDropdownItems = []
            this.selectedEventId = noGrouping
            this.eventIdOnesDropdownItems = []
            this.selectedEventIdOne = noGrouping
            this.interval.userSelectionRange = [
                subDays(new Date(), 31),
                subDays(new Date(), 1),
            ]
            //Fetch
            this.fetchIntegrationIdsData()
        },

        changeSelectedIntegrationId(newIntegrationId) {
            if(newIntegrationId === noneSelectedRequired) {
                this.selectedIntegrationId = ''
            }
            else {
                this.selectedIntegrationId = newIntegrationId
            }

            //Reset
            this.integrationData = integrationDataDefault
            this.eventTotalsData = []
            this.eventGroupDailyData = []
            this.defaultGameDesignEventChartOptions.series = []
            this.eventIdsDropdownItems = []
            this.selectedEventId = ''
            this.eventIdOnesDropdownItems = []
            this.selectedEventIdOne = noGrouping
            this.interval.userSelectionRange = [
                subDays(new Date(), 31),
                subDays(new Date(), 1),
            ]

            this.fetchMinMaxInterval()
            this.fetchDistinctEventIdOnes()
        },

        changeSelectedEventIdOne(newEventIdOne) {
            this.selectedEventIdOne = newEventIdOne
        },

        getSelectedEventIdOne() {
            if(this.selectedEventIdOne === noGrouping) {
                return ''
            }
            return this.selectedEventIdOne
        },

        changeSelectedCountry(newCountry) {
            this.selectedCountry = newCountry

            //Reset
            this.interval.userSelectionRange = [
                subDays(new Date(), 31),
                subDays(new Date(), 1),
            ]
            //Fetch
            this.fetchMinMaxInterval()
        },

        panelChanged(panelName){
            this.currentlyVisiblePanel = panelName
        },

        showStat(stat) {
            if(stat === 0) return true
            if(!stat || stat === overviewNoData) {
                return false
            }
            return true
        },

        getTimePercentage(startDate, endDate) {
            const start = new Date(startDate).getTime();
            const end = new Date(endDate).getTime();

            // Guard clause for invalid dates
            if (start >= end) return 0;

            const totalDuration = end - start;
            const elapsedDuration = new Date().getTime() - start;

            const elapsedPercentage = Math.round(Math.min(Math.max(elapsedDuration / totalDuration * 100, 0), 100));

            return elapsedPercentage;
        },

        formatNumber(value, locales = 'en-US', options = {}) {
            // Default options
            const defaultOptions = {
                maximumFractionDigits: 2,
                ...options
            };

            // Using Intl.NumberFormat for formatting
            return new Intl.NumberFormat(locales, defaultOptions).format(value);
        },

        downloadTablesAsExcel() {
            const createAndDownloadWorkbook = (data, fileName) => {
                const workbook = XLSX.utils.book_new();
                const sheet = XLSX.utils.json_to_sheet(data, { header: Object.keys(data[0]) });
                XLSX.utils.book_append_sheet(workbook, sheet, fileName);
                XLSX.writeFile(workbook, `${fileName}-${this.selectedIntegrationId}-${this.selectedGameName}.xlsx`);
            };
            createAndDownloadWorkbook(this.eventTotalsData, 'Campaign Engagements');
            createAndDownloadWorkbook(this.eventGroupDailyData, 'Delivered Engagements');
        },
    },
}
</script>

<style lang="postcss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');


.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.card-container {
    @apply bg-white rounded-md flex flex-col items-center justify-center p-4 gap-4;
}

.date-picker {
    @apply lg:max-w-lg sm:max-w-xs w-full block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md;
}

.inner-card {
    @apply flex flex-col gap-2 items-center;
}

.card-container span {
    @apply text-3xl font-bold;
}

:deep(.dp__action_button:hover) {
    color: white; 
    background-color: blue; 
}

:deep .dp__action_select {
    color: white; 
    background-color: green; 
    opacity: 1 !important; 
    border: 1px solid black;
    padding: 0.8em 0.4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

:deep .dp__action_cancel {
    color: white; 
    background-color: red; 
    opacity: 1 !important; 
    border: 1px solid black;
    padding: 0.8em 0.4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>
