<template>
    <div class="flex flex-col">
        <!-- Overview Stats -->
        <div class="p-4 bg-white rounded-lg shadow-md inline-block">
            <h3 class="text-lg font-medium text-gray-800 mb-2">Roblox Stats (Last 90 days)</h3>
            <ul class="grid grid-cols-2">
                <span>
                    <li v-for="stat in robloxOverviewData.global" :key="stat.name" class="flex text-gray-700 mb-2">
                        <span class="mr-2">{{ stat.name }}:</span>
                        <span class="font-medium">{{ stat.value }}</span>
                    </li>
                </span>
                <span>
                    <li v-for="stat in robloxOverviewData.us" :key="stat.name" class="flex text-gray-700 mb-2">
                        <span class="mr-2">{{ stat.name }}:</span>
                        <span class="font-medium">{{ stat.value }}</span>
                    </li>
                </span>

            </ul>
        </div>
        <!-- Panel Selector Buttons -->
        <TabButtons :buttons="panelSwitchingButtons" :active_button="currentlyVisiblePanel" :panel-changed-method="panelChanged"></TabButtons>

        <!-- Panels -->
        <div class="-my-2 sm:-mx-6 lg:-mx-8" v-if="(this.currentlyVisiblePanel === 'main_table' || this.currentlyVisiblePanel === 'us_table') && !this.main_loading">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="flex flex-column items-center mt-2">
                    <label class="text-lg font-medium mr-4">Days In Interval:</label>
                    <p class="align-top">{{ daysIn }}</p>
                    <h5 class="p-3 m-1"> (Percentages are % of total visits) </h5>
                </div>
                <div class="date-picker-wrapper">
                    <VueDatePicker 
                        class="p-2 m-1"
                        v-model="interval.userSelectionRange" 
                        range 
                        :min-date="intervalMinMax.minMax.min" 
                        :max-date="intervalMinMax.minMax.max" 
                        @update:model-value="fetchMainTableData"
                        :enable-time-picker="false"
                        prevent-min-max-navigation
                        :preset-ranges="presetRanges"
                        :clearable="false"
                    >
                        <template #yearly="{ label, range, presetDateRange }">
                            <span @click="presetDateRange(range)">{{ label }}</span>
                        </template>
                    </VueDatePicker>
                </div>


                <TableComponent v-if="currentlyVisiblePanel == 'main_table'" :table_data="tableData" :calculate_weighted_average="true" :weighted_average_columns="weighted_average_columns"  weighting_factor="Total Visits" default_sort_by="Total Visits" :exported_file_name="'main-ga-table-'+intervalString" :disallowed_totals="main_disallowed_totals"></TableComponent>

                <TableComponent v-if="currentlyVisiblePanel == 'us_table'" :table_data="usTableData" :calculate_weighted_average="true" :weighted_average_columns="weighted_average_columns"  weighting_factor="Total Visits" default_sort_by="Total Visits" :exported_file_name="'us-main-ga-table-'+intervalString"></TableComponent>
            </div>
        </div>

        <div v-if="this.currentlyVisiblePanel === 'age_table' && !this.age_loading">
            <!-- Age table (Doesn't need interval selector) -->
            <h5 class="p-3 m-1"> (Age table has had 'Unknown' column removed) </h5>
            <TableComponent :table_data="ageTableData" total_column_heading_name="Friendly Name" default_sort_by="Friendly Name" :default_sort_desc="false" :exported_file_name="'age-percentages-per-game'" :show_total_row="false" ></TableComponent>
        </div>

        <!-- Gender Table (Doesn't need interval selector) -->
        <TableComponent v-if="this.currentlyVisiblePanel === 'gender_table' && !this.gender_loading" :table_data="genderTableData" default_sort_by="Name" :default_sort_desc="false" :exported_file_name="'gender-percentages-per-game'" :show_total_row="false" :show_average_row="false"></TableComponent>

        <div class="-my-2 sm:-mx-6 lg:-mx-8" v-if="this.currentlyVisiblePanel === 'rolling_averages' && !this.average_loading">
            <TabButtons :buttons="averageSwitchingButtons" :active_button="currentlyVisibleAverage" :panel-changed-method="averagePanelChanged"></TabButtons>
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="flex flex-row items-center mt-4 mb-4">
                    <label for="end" class="text-lg font-medium mr-4">End</label>
                    <input type="date" id="end" :min="interval.minMax.min" :max="interval.minMax.max" v-model="interval.rollingAverages.end" class="w-64 py-2 px-3 rounded-md border-gray-300 focus:outline-none focus:ring focus:ring-blue-200 mr-4">
                    <button class="btn-primary green" @click="fetchAllRollingAveragesData">Submit</button>
                </div>
                <TableComponent v-if="currentlyVisibleAverage == 'global_average'" :table_data="rollingAveragesTableData" default_sort_by="1d (daily avg) Visits" :exported_file_name="'global-rolling-averages-'+interval.rollingAverages.end"></TableComponent>

                <TableComponent v-if="currentlyVisibleAverage == 'us_average'" :table_data="rollingAveragesUsTableData" default_sort_by="1d (daily avg) Visits" :exported_file_name="'us-rolling-averages-'+interval.rollingAverages.end"></TableComponent>
            </div>
        </div>
        <LoadingComponent v-if="showLoadingComponent"/>
    </div>
</template>

<script>
import TabButtons from '../components/TabButtons.vue'
import TableComponent from '../components/TableComponent.vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {subDays} from 'date-fns';

export default {
    name: "GaTable",

    components: {
        TableComponent,
        TabButtons,
        VueDatePicker,
    },

    props: {},

    data() {
        return {
            main_loading: true,
            age_loading: true,
            gender_loading: true,
            average_loading: true,
            tableData: [],
            usTableData: [],
            ageTableData: [],
            genderTableData: [],
            currentlyVisiblePanel: 'main_table',
            currentlyVisibleAverage: 'global_average',
            rollingAveragesTableData: [],
            rollingAveragesUsTableData: [],
            robloxOverviewData: [],
            main_disallowed_totals: [
                'US', 'AU', 'BR', 'CA', 'DE', 'ES', 'FR', 'GB', 'IT', 'MX', 'RU', 'ROW'
            ],
            weighted_average_columns: ['US', 'AU', 'BR', 'CA', 'DE', 'ES', 'FR', 'GB', 'IT', 'MX', 'ROW'],
            panelSwitchingButtons: [
                {
                    name: 'main_table',
                    text: 'Main Table'
                },
                {
                    name: 'us_table',
                    text: 'US Table'
                },
                {
                    name: 'rolling_averages',
                    text: 'Rolling Averages'
                },
                {
                    name: 'age_table',
                    text: 'Age %'
                },
                {
                    name: 'gender_table',
                    text: 'Gender %'
                }
            ],
            averageSwitchingButtons: [
                {
                    name: 'global_average',
                    text: 'Global'
                },
                {
                    name: 'us_average',
                    text: 'US'
                },
            ],
            interval: {
                minMax: {
                    min: null,
                    max: null
                },
                userSelectionRange: [subDays(new Date(), 61), subDays(new Date(), 1)],
                rollingAverages: {
                    end: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000).toISOString().split('T')[0] // yesterday (GA data is 1 day behind)
                }
            },
        }
    },

    computed: {
        startDateFormatted () {
            return this.interval.userSelectionRange[0].toISOString().split('T')[0]
        },
        endDateFormatted () {
            return this.interval.userSelectionRange[1].toISOString().split('T')[0]
        },
        intervalString () {
            return `${this.startDateFormatted}-${this.endDateFormatted}`
        },
        intervalMinMax () {
                return   {
                    minMax: {
                        min: new Date(this.interval.minMax.min),
                        max: new Date(this.interval.minMax.max),
                },
            }
        },

        daysIn() {
            if(!this.interval.userSelectionRange) return 0
            if(!this.interval.userSelectionRange[0] || !this.interval.userSelectionRange[1]) return 0
            const timeDiff = Math.abs(this.interval.userSelectionRange[1].getTime() - this.interval.userSelectionRange[0].getTime());
            const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
            return daysDiff
        },

        presetRanges() {
            return [
                {
                    label: 'Last 30 days',
                    range: [subDays(new Date(), 31), subDays(new Date(), 1)],
                },
                {
                    label: 'Last 60 days',
                    range: [subDays(new Date(), 61), subDays(new Date(), 1)],
                },
                {
                    label: 'Last 90 days',
                    range: [subDays(new Date(), 91), subDays(new Date(), 1)],
                },
                {
                    label: 'All time',
                    range: [new Date(this.interval.minMax.min), new Date(this.interval.minMax.max)],
                }
            ];
        },

        showLoadingComponent() {
            if (this.currentlyVisiblePanel === 'rolling_averages' && this.average_loading)
                return true
            if(this.currentlyVisiblePanel === 'gender_table' && this.gender_loading)
                return true
            if(this.currentlyVisiblePanel === 'age_table' && this.age_loading)
                return true
            if((this.currentlyVisiblePanel === 'main_table' || this.currentlyVisiblePanel === 'us_table') && this.main_loading)
                return true

            return false
        }
    },

    watch: {},

    created() {},

    async mounted() {
        this.fetchAllTableData()
        this.fetchIntervalMinMax()
        this.fetchRobloxOverviewData()
        this.fetchAgeTableData()
        this.fetchGenderTableData()
    },

    methods: {
        fetchRobloxOverviewData(){
            this.$http.get(`/roblox-overview`)
                .then(({data}) => {
                    this.robloxOverviewData = data
                    this.$notify({type: 'success', text: 'Fetched Roblox overview'})
                })
                .catch((error) => {
                    this.$notify({type: 'error', text: 'Failed to fetch Roblox overview data'})
                    console.error(error.message)
                })
        },

        fetchAllRollingAveragesData(){
            this.fetchRollingAveragesData()
            this.fetchUsRollingAveragesData()
        },

        fetchMainTableData(){
            this.fetchTableData()
            this.fetchUsTableData()
        },

        fetchAllTableData(){
            this.fetchMainTableData()
            this.fetchAllRollingAveragesData()
        },

        fetchIntervalMinMax(){
            const toasts = {success: 'Fetched table interval', error: 'Failed to fetch table interval'}
            this.$http_request(this.$http.get, 'game-analytics-daily-table-interval', toasts)
                .then(({data}) => {
                    this.interval.minMax.min = data.min 
                    this.interval.minMax.max = data.max
                })
                .catch((error) => {
                    console.error(error.message)
                })
        },

        fetchTableData(){
            this.$http.get(`game-analytics-daily-table/${this.startDateFormatted}/${this.endDateFormatted}`)
                .then(({data}) => {
                    this.tableData = data.table
                    this.$notify({type: 'success', text: 'Fetched table data'})
                })
                .catch((error) => {
                    this.$notify({type: 'error', text: 'Failed to fetch table data'})
                    console.error(error.message)
                })
        },

        fetchUsTableData(){
            this.$http.get(`game-analytics-us-daily-table/${this.startDateFormatted}/${this.endDateFormatted}`)
                .then(({data}) => {
                    this.usTableData = data
                    this.main_loading = false
                    this.$notify({type: 'success', text: 'Fetched US table data'})
                })
                .catch((error) => {
                    this.$notify({type: 'error', text: 'Failed to fetch US table data'})
                    this.main_loading = false
                    console.error(error.message)
                })
        },

        async fetchRollingAveragesData(){
            await this.$http.get(`game-analytics-rolling-averages-table/${this.interval.rollingAverages.end}`)
                .then(({data}) => {
                    this.rollingAveragesTableData = data
                    this.$notify({type: 'success', text: 'Fetched rolling averages table data'})
                })
                .catch((error) => {
                    this.$notify({type: 'error', text: 'Failed to fetch rolling averages table data'})
                    console.error(error.message)
                })
        },

        async fetchUsRollingAveragesData(){
            await this.$http.get(`game-analytics-us-rolling-averages-table/${this.interval.rollingAverages.end}`)
                .then(({data}) => {
                    this.rollingAveragesUsTableData = data
                    this.average_loading = false
                    this.$notify({type: 'success', text: 'Fetched rolling averages US table data'})
                })
                .catch((error) => {
                    this.$notify({type: 'error', text: 'Failed to fetch rolling averages US table data'})
                    this.average_loading = false
                    console.error(error.message)
                })
        },

        fetchAgeTableData(){
            this.$http.get(`/romo-data/age-table`)
                .then(({data}) => {
                    this.ageTableData = data
                    this.age_loading = false
                    this.$notify({type: 'success', text: 'Fetched age table data'})
                })
                .catch((error) => {
                    this.$notify({type: 'error', text: 'Failed to fetch age table data'})
                    this.age_loading = false
                    console.error(error.message)
                })
        },

        fetchGenderTableData(){
            this.$http.get(`/game-analytics-game-genders`)
                .then(({data}) => {
                    this.genderTableData = data
                    this.gender_loading = false
                    this.$notify({type: 'success', text: 'Fetched gender table data'})
                })
                .catch((error) => {
                    this.gender_loading = false
                    console.error(error.message)
                    this.$notify({type: 'error', text: 'Failed to fetch gender table data'})
                })
        },

        panelChanged(panelName){
            this.currentlyVisiblePanel = panelName
        },

        averagePanelChanged(panelName){
            this.currentlyVisibleAverage = panelName
        },
    }
}
</script>

<style lang="postcss" scoped>
.date-picker-wrapper {
    display: inline-block;   /* Makes the div to fit its content */
}

.date-picker-wrapper .vue-daterange-picker {
    width: auto;  /* Adjust the width of date picker */
}

:deep .dp__action_select {
    color: white; 
    background-color: green; 
    opacity: 1 !important; 
    border: 1px solid black;
    padding: 0.8em 0.4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

:deep .dp__action_cancel {
    color: white; 
    background-color: red; 
    opacity: 1 !important; 
    border: 1px solid black;
    padding: 0.8em 0.4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

:deep(.dp__action_button:hover) {
    color: white; 
    background-color: blue; 
}
</style>