<template>
    <Disclosure as="div" v-slot="{ open }" class="w-full border border-black border-b-0 bg-white" v-for="(items, email) in permission_items" :key="email">

    <DisclosureButton class="flex flex-row items-center cursor-pointer w-full justify-between gap-2 p-4 border-b border-black"> 
        <span class="text-lg"> {{ email }} </span>
        <svg class="ml-auto flex-shrink-0 h-5 w-5 transform transition-colors ease-in-out duration-150" :class="{'rotate-90' : open}" viewBox="0 0 20 20" aria-hidden="true">
            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
        </svg>
    </DisclosureButton>


    <!-- THEN SHOW SUB SUBCHILD -->
    <DisclosurePanel>
        <div class="flex flex-row flex-wrap gap-4 p-8">
            <div 
            v-for="(has_access, item_name) in items" :key="item_name" 
            @click="toggleItemPermission(email, item_name)" 
            class="font-semibold text-lg rounded-md px-4 py-2 cursor-pointer text-black duration-300"  
            :class="has_access ? 'bg-green-400 hover:bg-green-500' : 'bg-red-400 hover:bg-red-500'">
                {{ item_name }}
            </div>
        </div>
        <div class="bg-gray-200 flex flex-row items-center justify-around gap-4 p-2 border-b border-black">
            <button class="btn-primary green" @click="submitPermissionChanges(email)"> Submit Changes </button>
            <button class="btn-primary light-blue" @click="setPermissionsAsAdmin(email)"> Select All </button>
        </div>
    </DisclosurePanel>

    </Disclosure>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

export default {
    name: "PermissionsBox",
    components: {
        DisclosureButton,
        Disclosure,
        DisclosurePanel
    },
    props: {
        permissions: {
            required: true,
            type: Array
        },
        api: {
            required: true,
            type: String,
        },
        request_string: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            permission_items: this.permissions
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        submitPermissionChanges(email) {
            const request = {
                'email': email,
                [this.request_string]: this.permission_items[email]
            }

            const toasts = {'success': 'Changes Submitted Successfully', 'error': `Error Submitting Changes`}
            this.$http_request(this.$http.post, this.api, toasts, request)    
        },
        setPermissionsAsAdmin(email) {
            for (let item in this.permission_items[email]) {
                this.permission_items[email][item] = true;
            }
        },
        toggleItemPermission(email, item_name) {
            this.permission_items[email][item_name] = !this.permission_items[email][item_name]
        },
    }
}
</script>

<style scoped>

</style>