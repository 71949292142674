const state = () => ({
    taker: {
        selected: {
            creatives: {},
            games: {},
            placements: {}
        }
    },
    adder: {
        selected: {
            creatives: {},
            games: {},
            placements: {}
        }
    }
})

const getters = {
    getRestorationState(state){
        return state
    }
}

const mutations = {
    setRestorationState(state, current_state){
        const num_of_selected_items = Object.keys(current_state.taker.selected.creatives).length 
                + Object.keys(current_state.taker.selected.games).length
                + Object.keys(current_state.taker.selected.placements).length
                + Object.keys(current_state.adder.selected.creatives).length
                + Object.keys(current_state.adder.selected.games).length
                + Object.keys(current_state.adder.selected.placements).length
        if(num_of_selected_items > 0){
            state.taker.selected.creatives = current_state.taker.selected.creatives
            state.taker.selected.games = current_state.taker.selected.games
            state.taker.selected.placements = current_state.taker.selected.placements
            state.adder.selected.creatives = current_state.adder.selected.creatives
            state.adder.selected.games = current_state.adder.selected.games
            state.adder.selected.placments = current_state.adder.selected.placements
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
}