import { createStore } from 'vuex'
import main_module from './modules/MainModule'
import picker_module from './modules/PickerModule'
import restore_point_module from './modules/RestorePointModule'
import create_creative_module from './modules/CreateCreativeModule'
import file_upload_module from './modules/FileUploadModule'
import adverts_module from './modules/AdvertsModule'
import login_module from './modules/LoginModule'
import ndr_module from './modules/NativeDisplayReportingModule'
import createPersistedState from 'vuex-persistedstate'
import manage_all_items_module from './modules/ManageAllItemsModule'
import item_module from './modules/ItemModule'
import MainState from '@/classes/MainState.js'
const main_state = new MainState()

const getDefaultState = () => main_state.state
export default createStore({
    strict: true,
    state: getDefaultState(),
    getters: {},
    mutations: {
        RESET: (state) => {
            Object.assign(state, getDefaultState())
        },
    },
    actions: {},
    modules: {
        main_module,
        picker_module,
        restore_point_module,
        create_creative_module,
        file_upload_module,
        adverts_module,
        login_module,
        manage_all_items_module,
        item_module,
        ndr_module
    },
    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
        }),
    ],
})
